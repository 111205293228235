import React, { useEffect, useMemo, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { TextToolbar } from './textbox-toolbar';
import { TextboxModes } from '../fib-utils';

// Importing this ensures the formats file is run & blank-blot is registered in quill
// eslint-disable-next-line no-unused-vars
import BlankBlot from './quill-formats';

const TextBox = (props) => {
  const { elementID, onUpdate, value, textPlaceholder = 'You can start typing here', forwardRef, mode = TextboxModes.TEXTBOX } = props;

  const editorRef = useRef();

  const setValue = (newVal) => {
    onUpdate(elementID, newVal);
  };

  // TODO: This does not work as intended
  useEffect(() => {
    if (!value) {
      editorRef.current?.editor.focus();
    }
    // DO NOT ADD VALUE AS DEP OTHERWISE THIS WILL GO INTO A LOOP OF FOCUS
    // This is only inteded to focus if the element is created new
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoisedQuillHandlers = useMemo(
    function () {
      return {
        toolbar: {
          container: `#toolbar-${elementID}`
        }
      };
    },
    [elementID]
  );

  return (
    <Box minH="24px" display="flex" flexDirection="column" bg="white">
      <TextToolbar toolbarID={`toolbar-${elementID}`} mode={mode} />
      <ReactQuill
        // If parent has given the ref for this textbox, use it instead of the local ref
        ref={forwardRef ? forwardRef : editorRef}
        theme="snow"
        value={value}
        className={props.className}
        onChange={setValue}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          flexBasis: '0',
          border: 'none'
        }}
        placeholder={textPlaceholder}
        modules={memoisedQuillHandlers}
      />
    </Box>
  );
};

export default TextBox;

TextBox.propTypes = {
  elementID: PropTypes.string,
  onUpdate: PropTypes.func
};
