/* eslint-disable no-prototype-builtins */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Input,
  Box,
  Text,
  Flex,
  useToast,
  Stack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../auth/useAuth';
import { useForm, Controller } from 'react-hook-form';
import { isNil } from 'lodash';
import ReactDatePicker from 'react-datepicker';
import { getAssignmentData } from '../canvas/redux/selectors';
import { getGoogleToken } from '../../../api/userApis';
import Select, { components } from 'react-select';
import {
  getGoogleCourses,
  getGoogleStudents,
  getGoogleTopics
} from '../../../api/fetchGoogleAssignmentData';
import { appConfig } from '../../../constants';
import { getAssignmentSettingModalStatus } from '../canvas/redux/selectors';
import { createGoogleAssignment } from '../../../api/writeGoogleAssignmentData';
import { useParams } from 'react-router-dom';

export default function GcAssignmentModal() {
  const { showGcCreateAssignment } = useSelector(getAssignmentSettingModalStatus);
  const assignmentData = useSelector(getAssignmentData);

  const dispatch = useDispatch();
  const toast = useToast();

  const defaultValRef = React.useRef();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const { token } = useAuth();

  const studentAllOption = [{ id: 'all', value: 'all', label: 'All students' }];
  const noTopicOption = [{ id: 'notopic', value: 'notopic', label: 'No topic' }];

  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState('');

  const [students, setStudents] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const [topics, setTopics] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(noTopicOption);

  const [googleAccessToken, setGoogleAccessToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const routerParams = useParams();
  const assignmentID = routerParams.assignmentID;
  const startDate =
    assignmentData?.hasOwnProperty('startDate') && !isNil(assignmentData?.startDate)
      ? new Date(assignmentData?.startDate)
      : null;
  const endDate =
    assignmentData?.hasOwnProperty('dueDate') && !isNil(assignmentData?.dueDate)
      ? new Date(assignmentData?.dueDate)
      : null;

  defaultValRef.current = {
    assignmentName: assignmentData.name,
    assignmentDate: [startDate, endDate],
    maxPoints: 100
  };

  const assignmentURL = appConfig.studentAppUrl + '/start/' + assignmentID;

  const { handleSubmit, register, control, reset, setValue } = useForm({
    defaultValues: defaultValRef.current,
    mode: 'onChange'
  });

  const toastMessage = React.useCallback(
    (message, type = 'success') => {
      toast({
        title: message,
        status: type,
        duration: 3000
      });
    },
    [toast]
  );

  const getGoogleCoursesOptions = React.useCallback(async () => {
    const [accessToken, googleUserId] = await getGoogleToken(token);
    if (accessToken) {
      const courses = await getGoogleCourses(accessToken, googleUserId);
      const students = await getGoogleStudents(accessToken, courses);
      const topics = await getGoogleTopics(accessToken, courses);

      const courseOptions = courses.map((c) => {
        return { value: c.id, label: c.name, section: c.section };
      });

      setCourseOptions(courseOptions);
      setStudents(students);
      setTopics(topics);
      setGoogleAccessToken(accessToken);
    }
  }, [token]);

  useEffect(() => {
    getGoogleCoursesOptions();
  }, [getGoogleCoursesOptions, token]);

  // If the GC create assignment Modal were opened, force the assignment values to take in the default values
  useEffect(() => {
    if (showGcCreateAssignment) {
      reset(defaultValRef.current);
    }
  }, [reset, showGcCreateAssignment]);

  const onCourseChange = React.useCallback(async (selectedCourse) => {
    const courseId = selectedCourse.value;
    setStudentOptions([]);
    setSelectedStudents([]);
    setTopicOptions([]);
    setSelectedTopic(noTopicOption);

    setSelectedCourseId(courseId);
    let studentOptions = students
      .filter((s) => s.courseId == courseId)
      .map((s) => {
        return { id: s.userId, value: s.userId, label: s.profile.name.fullName };
      });
    studentOptions = studentAllOption.concat(studentOptions);
    setStudentOptions(studentOptions);
    setSelectedStudents(studentOptions);

    let topicOptions = topics
      .filter((t) => t.courseId == courseId)
      .map((t) => {
        return { id: t.topicId, value: t.topicId, label: t.name };
      });
    topicOptions = noTopicOption.concat(topicOptions);
    setTopicOptions(topicOptions);
    setSelectedTopic(noTopicOption);
  });

  const onClose = React.useCallback(() => {
    setSelectedCourseId('');
    setStudentOptions([]);
    setSelectedStudents([]);
    setTopicOptions([]);
    setSelectedTopic(noTopicOption);
    setIsSubmitting(false);

    dispatch({
      type: 'GC_CREATE_ASSIGNMENT_MODAL_CLOSED'
    });
  }, [dispatch]);

  const updateStore = useCallback(
    (dueDate, startDate) => {
      dispatch({
        type: 'UPDATE_ASSIGNMENT_STARTDAY',
        payload: startDate
      });
      dispatch({
        type: 'UPDATE_ASSIGNMENT_DUEDATE',
        payload: dueDate
      });
    },
    [dispatch]
  );

  const validateDates = useCallback(
    (startDate, dueDate) => {
      if (startDate && dueDate) {
        if (startDate > dueDate) {
          setValue('assignmentDate', []);
          toastMessage('Due date must be later than start date', 'error');
          return false;
        }
      }
      return true;
    },
    [toastMessage, setValue]
  );

  const onGcAssignmentCreate = useCallback(
    async (formValues) => {
      const { assignmentName, assignmentDate, maxPoints } = formValues;
      const [startDate, dueDate] = assignmentDate;

      const isDateValid = validateDates(startDate, dueDate);

      if (isDateValid) {
        setIsSubmitting(true);

        const allStudentsSelected = selectedStudents?.filter((s) => s.id == 'all')?.length > 0;
        const selectedStuds = selectedStudents?.filter((s) => s.id !== 'all');

        const currentDay = new Date();
        const scheduleAssignment = currentDay < startDate;

        const payload = {
          title: assignmentName,
          description: 'posted from Classwise',
          materials: [
            {
              link: {
                url: assignmentURL
              }
            }
          ],
          state: scheduleAssignment ? 'DRAFT' : 'PUBLISHED',
          workType: 'ASSIGNMENT',
          assigneeMode: allStudentsSelected ? 'ALL_STUDENTS' : 'INDIVIDUAL_STUDENTS',
          individualStudentsOptions: allStudentsSelected
            ? {}
            : {
                studentIds: selectedStuds.map((s) => s.id)
              },
          maxPoints: maxPoints
        };

        if (dueDate) {
          payload.dueDate = {
            year: dueDate.getFullYear(),
            month: dueDate.getMonth() + 1,
            day: dueDate.getDate()
          };
          payload.dueTime = {
            hours: dueDate.getHours(),
            minutes: dueDate.getMinutes(),
            seconds: dueDate.getSeconds(),
            nanos: dueDate.getMilliseconds()
          };
        }

        if (scheduleAssignment) {
          payload.scheduledTime = startDate.toISOString();
        }

        if (selectedTopic.id !== 'notopic') {
          payload.topicId = selectedTopic.id;
        }

        await createGoogleAssignment(googleAccessToken, selectedCourseId, payload);

        updateStore(dueDate, startDate);
        toastMessage('Assignment posted on Google Classroom successfully');
        setIsSubmitting(false);
        onClose();
      }
    },
    [
      assignmentID,
      toastMessage,
      token,
      selectedStudents,
      selectedCourseId,
      selectedTopic,
      updateStore,
      validateDates
    ]
  );

  const handleStudentChange = (values, action) => {
    const all = values.find((s) => s.value == 'all');
    const allSelected = action.action == 'select-option' && action.option.value == 'all';
    const allDeselected = action.action == 'deselect-option' && action.option.value == 'all';

    if (allSelected) {
      setSelectedStudents(studentOptions);
    } else if (allDeselected) {
      setSelectedStudents([]);
    } else if (values.length == studentOptions.length - 1) {
      if (all) {
        setSelectedStudents(values.filter((s) => s.value != 'all'));
      } else {
        setSelectedStudents(studentOptions);
      }
    } else {
      setSelectedStudents(values);
    }
  };

  const ValueContainer = ({ getValue, ...props }) => {
    var maxlength = studentOptions.length - 1;
    var length = Math.min(getValue().length, maxlength);

    return (
      <components.ValueContainer {...props}>
        {`${length == maxlength ? 'All' : length} student${
          length < 2 && length !== maxlength ? '' : 's'
        }`}
      </components.ValueContainer>
    );
  };

  const SingleValue = ({ data }) => {
    return (
      <Stack direction="row" mt={-6} width="full">
        <Text>{data.label}</Text>
        <Text>{data.section}</Text>
      </Stack>
    );
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size="xl"
        isCentered
        isOpen={showGcCreateAssignment}
        onClose={onClose}
        closeOnOverlayClick={true}
      >
        <ModalOverlay bg="rgba(0,0,0,0.8)" />
        <ModalContent m={3}>
          <ModalCloseButton />
          <ModalBody pb={0}>
            <ModalHeader textAlign="center" my={5}>
              {'Share Assignment on Google Classroom'}
            </ModalHeader>
            <form onSubmit={handleSubmit(onGcAssignmentCreate)} id="reset">
              <FormControl px={4}>
                <Box width="100%" pl={0} className="durationDropdown" position={'relative'}>
                  <Text position="absolute" fontSize="xs" left={1} top={-2} px={2} bg="#fff">
                    Assignment Name
                  </Text>
                  <Input
                    borderWidth={0}
                    fontSize={'14px'}
                    p={3}
                    h={50}
                    ref={initialRef}
                    {...register('assignmentName')}
                    isReadOnly
                  />
                </Box>
                <Stack direction={{ base: 'column', md: 'row' }} mt={4} position={'relative'}>
                  <Box w="50%" h="100%">
                    <Select
                      // width={'500px'}
                      isSearchable={false}
                      placeholder="Choose class"
                      options={courseOptions}
                      formatOptionLabel={(data) => (
                        <Stack direction="column" w="100%">
                          <Text>{data.label}</Text>
                          <Text fontSize="12px">{data.section}</Text>
                        </Stack>
                      )}
                      onChange={onCourseChange}
                      backspaceRemovesValue={false}
                      components={{ SingleValue }}
                    />
                  </Box>
                  {selectedCourseId && studentOptions?.length > 0 && (
                    <Box w="50%" h="100%">
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={studentOptions}
                        onChange={handleStudentChange}
                        value={selectedStudents}
                        components={{ ValueContainer }}
                        label="Students"
                        hideSelectedOptions={false}
                        tabSelectsValue={false}
                        backspaceRemovesValue={false}
                      />
                    </Box>
                  )}
                  <Text position="absolute" fontSize="xs" left={1} top={-2} px={2} bg="#fff">
                    For
                  </Text>
                </Stack>
                {selectedCourseId && studentOptions?.length > 0 && (
                  <Box mt={2} gap={2}>
                    <Stack direction={{ base: 'column', md: 'row' }} w="100%" mt={4}>
                      <Box w="100%">
                        <Controller
                          control={control}
                          name="assignmentDate"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <ReactDatePicker
                                selected={value[0]}
                                onChange={(start) => onChange([start, value[1]])}
                                selectsStart
                                startDate={value[0]}
                                showTimeInput
                                dateFormat="MMMM d, yyyy h:mm aa"
                                name="dueDate"
                              />
                            );
                          }}
                        />
                        <Text
                          position="absolute"
                          fontSize={'xs'}
                          marginLeft={1}
                          marginTop={-14}
                          px={2}
                          bg="#fff"
                        >
                          Assignment Start Date
                        </Text>
                      </Box>
                      <Box w={'100%'}>
                        <Controller
                          control={control}
                          name="assignmentDate"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <ReactDatePicker
                                selected={value[1]}
                                onChange={(end) => onChange([value[0], end])}
                                selectsEnd
                                startDate={value[0]}
                                endDate={value[1]}
                                minDate={value[0]}
                                showTimeInput
                                dateFormat="MMMM d, yyyy h:mm aa"
                                name="dueDate"
                              />
                            );
                          }}
                        />
                        <Text
                          position="absolute"
                          fontSize={'xs'}
                          marginLeft={1}
                          marginTop={-14}
                          px={2}
                          bg="#fff"
                        >
                          Assignment Due Date
                        </Text>
                      </Box>
                    </Stack>
                    <Box h="100%" mt={4} position={'relative'}>
                      <Select
                        width={'500px'}
                        isSearchable={false}
                        placeholder="Choose topic"
                        options={topicOptions}
                        formatOptionLabel={(data) => <Text>{data.label}</Text>}
                        onChange={(topic) => setSelectedTopic(topic)}
                        selected={selectedTopic}
                        backspaceRemovesValue={false}
                      />
                      <Text position="absolute" fontSize="xs" left={1} top={-2} px={2} bg="#fff">
                        Topic
                      </Text>
                    </Box>
                    <Box
                      width="100%"
                      mt={4}
                      pl={0}
                      className="durationDropdown"
                      position={'relative'}
                    >
                      <Text position="absolute" fontSize="xs" left={1} top={-2} px={2} bg="#fff">
                        Points
                      </Text>
                      <Input
                        borderWidth={0}
                        fontSize={'14px'}
                        p={3}
                        h={50}
                        ref={initialRef}
                        {...register('maxPoints')}
                        isReadOnly
                      />
                    </Box>
                  </Box>
                )}
              </FormControl>

              <Flex justifyContent="center" width="100%" alignItems="center" direction="column">
                <Button
                  isLoading={isSubmitting}
                  loadingText="Saving..."
                  colorScheme="purple"
                  type="submit"
                  mb={4}
                  mt={2}
                >
                  Save
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
