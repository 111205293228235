import { Box, Heading, HStack, WrapItem } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import ContentBlock from '../utils/content-block'
import QuestionTypes from '../utils/question-types'
import { canvasQuestionTypesSidebar } from '../constant'

export default function OtherPage({ pageID }) {
  const dispatch = useDispatch();

  const onLibraryModalOpen = React.useCallback(() => {
    dispatch({
        type: 'UPDATE_LIBRARY_MODAL_STATUS',
        payload: {
          isLibraryDialogOpen: true,
          source: 'right-sidebar'
        },
    });
}, [dispatch])

  return (
    <>
      <ContentBlock pageID={pageID}/>
      {/* <Heading
        as="h5"
        px={5}
        mt={6}
        mb={2}
        position="relative"
        fontWeight={700}
        color="#686868"
        size="md"
        noOfLines={1}
      >
        {canvasQuestionTypesSidebar.title}
      </Heading>
      <WrapItem>
      <QuestionTypes />
        <Box p={3} mt={3.5} h={100} w={170} shadow="sm" borderWidth="1px" cursor="pointer"
          borderColor="#B2BEDA"
          textAlign={"center"}
          borderRadius="8px" bg={'#744cc6'}
          onClick={onLibraryModalOpen}
          >
          <HStack justify="space-between" align="center" py={3} >
            <Heading
              fontSize={"lg"}
              fontWeight={500}
              color={'white'}
              userSelect="none"
              w={'100%'}
            >
             Pick from <br />CW Library
            </Heading>
          </HStack>
        </Box>
      </WrapItem> */}

    </>
  )
}
