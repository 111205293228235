import { toLower } from 'lodash';

export const getFIBQuestionStats = (question, response) => {
  const blankConfig = question.fibMetadata.blankConfig;
  const blankAnswers = response.answers;
  let hasCorrect = false;
  let hasIncorrect = false;
  let correctBlanks = 0;
  let totalBlanks = 0;
  let isPartial = false;
  let isCorrect = false;
  const reviews = Object.entries(blankAnswers).reduce((review, [key, value]) => {
    const isCorrect = toLower(blankConfig?.[key]?.answers?.[0]) === toLower(value?.[0]);
    totalBlanks++;
    // If one of the answers is right, but not all of them are
    if (isCorrect) {
      hasCorrect = true;
      correctBlanks++;
    } else {
      hasIncorrect = true;
    }

    review[key] = {
      answers: blankConfig?.[key]?.answers,
      value,
      isCorrect
    };
    return review;
  }, {});

  // If the response by the student has both right & wrong answers within FIB -> partial correctness
  if (hasCorrect && hasIncorrect) isPartial = true;
  // Else, if the response has all correct -> all correct
  else if (hasCorrect) isCorrect = true;

  return {
    correctBlanks,
    totalBlanks,
    isCorrect,
    isPartial,
    reviews
  };
};

export const mergeBlankConfig = (allConfig, blankConfig) => {
  Object.entries(blankConfig).forEach(([key, value]) => {
    allConfig[key] = value;
  });
};
