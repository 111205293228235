import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import React from 'react';

export const AssignmentCreateButton = ({ text, onClick, leftIcon }) => {
  return (
    <Flex w="100%" alignItems="center">
      <Button
        leftIcon={leftIcon}
        borderColor="#5D38DB"
        borderWidth="2px"
        // color="white"
        width="90%"
        justifyContent="flex-start"
        size="lg"
        variant="outline"
        px={3}
        onClick={onClick}
      >
        {text}
      </Button>
    </Flex>
  );
};

export const AssignmentBox = ({ onClick, image, text }) => {
  return (
    <Box
      w={{ base: '40%', lg: '130px' }}
      h="150px"
      border="1px"
      borderColor="#9E9E9E "
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      display="flex"
      shadow={'md'}
      borderRadius={5}
    >
      <Center
        flexDirection="column"
        justifyContent={'center'}
        gap="2px"
        padding="8px"
        onClick={onClick}
      >
        {image}
        <Text
          mt={2}
          textAlign={'center'}
          fontSize={{ base: 'sm', md: 'md', lg: 'md' }}
          fontWeight="bold"
        >
          {text}
        </Text>
      </Center>
    </Box>
  );
};
