import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { Box, Button } from '@chakra-ui/react'
import { MdCheckBoxOutlineBlank } from 'react-icons/md'
import { AiFillCheckCircle } from 'react-icons/ai'
import { BiReset } from 'react-icons/bi'
import { setConstraints } from './utils'

const CropContainer = (props) => {
    const { canvasRef, cropMode, children, onCropEnd } = props

    const boxRef = useRef()
    const dirRef = useRef()
    const croppedCanvasRef = useRef()

    const drawCroppedCanvas = useCallback(() => {
      if (!canvasRef.current || !boxRef.current) return;

      const top = boxRef.current.offsetTop;
      const left = boxRef.current.offsetLeft;
      const width = boxRef.current.offsetWidth;
      const height = boxRef.current.offsetHeight;

      const croppedCtx = croppedCanvasRef.current.getContext('2d');
      const mainCanvasCtx = canvasRef.current.getContext('2d');
      const imageData = mainCanvasCtx.getImageData(left, top, width, height);

      croppedCanvasRef.current.width = width;
      croppedCanvasRef.current.height = height;
      croppedCanvasRef.current.style.top = `${top}px`;
      croppedCanvasRef.current.style.left = `${left}px`;
      croppedCtx.putImageData(imageData, 0, 0);
    }, [canvasRef]);

    useEffect(() => {
        if (cropMode) {
            boxRef.current.style.height = `${canvasRef.current.height}px`
            boxRef.current.style.width = `${canvasRef.current.width}px`

            drawCroppedCanvas()
        }
    }, [canvasRef, cropMode, drawCroppedCanvas])

    const cropStyles = useMemo(() => {
        return {
            border: '2px dashed black',
            display: 'inline-flex',
            position: 'absolute',
            boxSizing: 'border-box',
        }
    }, [])

    const mouseDown = (dir) => (e) => {
        e.preventDefault()

        // Set the direction in the ref
        dirRef.current = dir

        // Attach event handlers
        document.addEventListener('mousemove', mouseMove)
        document.addEventListener('mouseup', mouseUp)
    }

    const mouseUp = () => {
        // Remove event handlers
        document.removeEventListener('mousemove', mouseMove)
        document.removeEventListener('mousemove', mouseUp)

        drawCroppedCanvas()
    }

    const mouseMove = (e) => {
        if (e.buttons === 1) {
            e.preventDefault()

            const boxRect = boxRef.current.getBoundingClientRect()

            const mx = e.movementX
            const my = e.movementY

            const boxStyle = boxRef.current.style

            const containerHeight = canvasRef.current.height
            const containerWidth = canvasRef.current.width

            const origParams = {
                top: boxRef.current.offsetTop,
                left: boxRef.current.offsetLeft,
                width: boxRect.width,
                height: boxRect.height,
            }

            let top = boxRef.current.offsetTop
            let left = boxRef.current.offsetLeft
            let newW = boxRect.width
            let newH = boxRect.height
            let areRightAnchors = false

            // For the top left direction, we control both top, left & height, width
            if (dirRef.current === 'tl') {
                top += my
                left += mx
                newW -= mx
                newH -= my
            }

            // For the bottom left direction, we control height, width & left as the top position is already anchored by the top-left drag handle
            if (dirRef.current === 'bl') {
                // The mouse movement will decrease the width & increase the height as relative position is bottom left
                newW -= mx
                newH += my
                left += mx
            }

            // The top right controls only height, width & top, never the left position as that is anchored by the anchors on the left
            // We subtract my from height as my is flowing in a positive dir but decreases the height
            if (dirRef.current === 'tr') {
                newW += mx
                newH -= my
                top += my
                areRightAnchors = true
            }

            // The bottom right anchor can only control height & width & we simply add mx,my to it as the relative position is always negative
            if (dirRef.current === 'br') {
                newW += mx
                newH += my
                areRightAnchors = true
            }

            setConstraints({
                top,
                left,
                newH,
                newW,
                containerWidth,
                containerHeight,
                origParams,
                areRightAnchors,
                boxStyle,
            })
        }
    }

    const resetCrop = () => {
        const containerWidth = canvasRef.current.width
        const containerHeight = canvasRef.current.height

        setConstraints({
            top: 0,
            left: 0,
            newH: containerHeight,
            newW: containerWidth,
            containerWidth,
            containerHeight,
            origParams: {},
            boxStyle: boxRef.current.style,
        })

        drawCroppedCanvas()
    }

    const onCropConfirm = () => {
        const base64Str = croppedCanvasRef.current.toDataURL()
        onCropEnd(base64Str)
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box style={{ display: 'inline-flex', position: 'relative' }}>
                {children}
                {cropMode && (
                    <>
                        <Box
                            style={{
                                position: 'absolute',
                                display: 'inline-flex',
                            }}
                        >
                            <canvas
                                style={{ position: 'absolute' }}
                                ref={croppedCanvasRef}
                            />
                        </Box>
                        <Box style={cropStyles} ref={boxRef}>
                            {/* Top left */}
                            <MdCheckBoxOutlineBlank
                                onMouseDown={mouseDown('tl')}
                                style={{
                                    position: 'absolute',
                                    left: '-8px',
                                    top: '-8px',
                                    cursor: 'nwse-resize',
                                }}
                            />
                            {/* Bottom left */}
                            <MdCheckBoxOutlineBlank
                                onMouseDown={mouseDown('bl')}
                                style={{
                                    position: 'absolute',
                                    left: '-8px',
                                    bottom: '-8px',
                                    cursor: 'nesw-resize',
                                }}
                            />
                            {/* Top right */}
                            <MdCheckBoxOutlineBlank
                                onMouseDown={mouseDown('tr')}
                                style={{
                                    position: 'absolute',
                                    right: '-8px',
                                    top: '-8px',
                                    cursor: 'nesw-resize',
                                }}
                            />
                            {/* Bottom right */}
                            <MdCheckBoxOutlineBlank
                                onMouseDown={mouseDown('br')}
                                style={{
                                    position: 'absolute',
                                    right: '-8px',
                                    bottom: '-8px',
                                    cursor: 'nwse-resize',
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
            {cropMode && (
                <Box mt="3" display="flex" justifyContent="center">
                    <Button
                        colorScheme="teal"
                        variant="outline"
                        mr="2"
                        onClick={onCropConfirm}
                    >
                        Crop
                        <AiFillCheckCircle style={{ marginLeft: '0.4em' }} />
                    </Button>
                    <Button
                        colorScheme="red"
                        variant="outline"
                        onClick={resetCrop}
                    >
                        Reset
                        <BiReset style={{ marginLeft: '0.4em' }} />
                    </Button>
                </Box>
            )}
        </Box>
    )
}

export default CropContainer
