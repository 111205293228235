import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Badge, Flex } from '@chakra-ui/react';
import React from 'react';

/* 
  Component for displaying an FIB blank with the context of the student's answers. Hence:
   - If correct, student answers will be highlighted in green 
   - If incorrect, student answers will be highlighted in red with the right answer highlighted in green besides it
*/
const FIBBlankReview = ({ isCorrect, value, answers }) => {
  const colorScheme = isCorrect ? 'green' : 'red';

  return (
    <Flex flexWrap="wrap" mx={2} border="transparent" boxShadow="0 0 0 1px rgba(0, 0, 0, 0.2)">
      <Badge
        fontSize="14px"
        colorScheme={colorScheme}
        display="flex"
        alignItems="center"
        p={1}
        textDecoration={isCorrect ? 'unset' : 'line-through'}
      >
        {value?.[0]}
        {isCorrect ? <CheckCircleIcon color="green" ml={1} /> : <WarningIcon color="red" ml={1} />}
      </Badge>
      {!isCorrect && (
        <Badge colorScheme="green" display="flex" alignItems="center" p={1} fontSize="14px" pl={2}>
          {answers?.[0]}
          <CheckCircleIcon color="green" ml={1} />
        </Badge>
      )}
    </Flex>
  );
};

export default FIBBlankReview;
