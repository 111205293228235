export const createFormDataPayloadWithFile = ({ data, file }) => {
  const payload = new FormData();
  Object.keys(data).forEach((key) => {
    payload.append(key, data[key]);
  });
  if (file) {
    payload.append('file', file);
  }
  return payload;
};
