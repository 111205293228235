import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Box, Button } from '@chakra-ui/react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';

ReactQuill.Quill.register('modules/blotFormatter', BlotFormatter);

const VideoBox = (props) => {
  const { elementID, onUpdate, value } = props;

  const setValue = (newVal) => {
    onUpdate(elementID, newVal);
  };

  const { containerID, toolbarID } = useMemo(() => {
    return {
      containerID: `video-${elementID}`,
      toolbarID: `toolbar-${elementID}`
    };
  }, [elementID]);

  useEffect(() => {
    onUpdate(elementID, value);
  }, [elementID, onUpdate, value]);

  return (
    <Box
      overflow="hidden"
      minH="auto"
      display="flex"
      flexDirection="column"
      bg="white"
      className={`${containerID}`}
    >
      <Box
        id={toolbarID}
        // Do not show if the value does not exist, but we need the toolbar elem in DOM else quill crashes
        className={`editor-toolbar ql-toolbar video-toolbar ${value ? 'display--none' : ''}`}
      >
        {!value && (
          <Button className="ql-video" w={100}>
            Upload Video
          </Button>
        )}
      </Box>
      <ReactQuill
        theme="snow"
        value={value}
        className={`video-elem ${props.className}`}
        scrollingContainer="#scrolling-container"
        onChange={setValue}
        modules={{
          toolbar: {
            container: `#${toolbarID}`
          },
          blotFormatter: {}
        }}
        formats={['video']}
        bounds={`.${containerID}`}
      />
    </Box>
  );
};

export default VideoBox;

VideoBox.propTypes = {
  elementID: PropTypes.string,
  onUpdate: PropTypes.func
};
