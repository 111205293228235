import { chakra } from "@chakra-ui/react"
import PropTypes from 'prop-types'

export const CustomSortableWrap = chakra('div', {
    // attach style props
    baseStyle: {
      ml: '3',
      mb: '4',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  })
  
  CustomSortableWrap.displayName = 'CustomSortableWrap'
  CustomSortableWrap.propTypes = {
    children: PropTypes.element,
  }