import { v4 as uuidV4 } from 'uuid';
import { ASSIGNMENT_TYPES, CREATE_TYPES, UNTITLED_ASSIGNMENT, appConfig } from '../../constants';
import { eventLogger } from '../../api/eventLogger';

export const onAssignmentCreate = async (
  token,
  { source, subject, createType = CREATE_TYPES.ASSIGNMENTS_EMPTY_CTA },
  navigate
) => {
  const assignmentData = {
    name: UNTITLED_ASSIGNMENT,
    welcomeScreen: {
      id: uuidV4(),
      elements:
        createType === CREATE_TYPES.HOME_LESSON_PLAN ||
        createType === CREATE_TYPES.HOME_LEARNING_ACTIVITY
          ? []
          : [
              {
                id: uuidV4(),
                type: 'textbox',
                html: '<p></p>'
              }
            ]
    },
    thankYouScreen: {
      id: uuidV4(),
      elements:
        createType === CREATE_TYPES.HOME_LESSON_PLAN ||
        createType === CREATE_TYPES.HOME_LEARNING_ACTIVITY
          ? []
          : [{ id: uuidV4(), type: 'textbox', html: '<p></p>' }]
    },
    duration: null,
    // questions: [
    //     {
    //         id: uuidV4(),
    //         type: 'mcq',
    //         score: 10,
    //         level: 1,
    //         settings: {
    //             allowMultipleSelection: false,
    //             randomizeOptions: true,
    //         },
    //         elements: [],
    //         solution: {
    //             elements: [],
    //         },
    //         options: [
    //             { id: uuidV4(), isCorrect: true, elements: [] },
    //             { id: uuidV4(), isCorrect: false, elements: [] },
    //         ],
    //     },
    // ],
    settings: {
      revealSolutions: true,
      randomiseQuestions: false,
      allowMultipleSubmissions: true
    },
    dueDate: null,
    startDate: null,
    // New indicator of assignment type to indicate with which intent the assignment was created with
    assignmentType: getTypeFromCreateType(createType)
  };

  const res = await fetch(appConfig.getAPIURL('/assignments'), {
    method: 'POST',
    body: JSON.stringify(assignmentData),
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + `${token}`
    }
  });

  if (res.ok) {
    const resData = await res.json();
    eventLogger(token, source, resData?._id, subject);
    navigate(`/assignments/${resData?._id}/create`);
  } else {
    const error = await res.text();
    console.log('Update assignment failure', res.status, error);

    let errorMessage = error;
    try {
      const parsedError = JSON.parse(error);
      errorMessage = parsedError?.message || error;
    } catch (e) {
      // Nothing to do
    }
    console.log(errorMessage);
  }
};

export const onUpdateAssignmentSettings = async (token, assignmentID, payload) => {
  const res = await fetch(appConfig.getAPIURL(`/assignments/${assignmentID}`), {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + `${token}`
    }
  });
  const response = await res.json();
  return response;
};

export const getTypeFromCreateType = (createType) => {
  switch (createType) {
    case CREATE_TYPES.HOME_HERO_CTA:
    case CREATE_TYPES.HOME_NEW_USER_ONBOARD_MODAL:
    case CREATE_TYPES.ASSIGNMENTS_EMPTY_CTA:
    case CREATE_TYPES.ASSIGNMENTS_LIST_CTA:
      return ASSIGNMENT_TYPES.PLAIN;
    case CREATE_TYPES.HOME_HERO_CTA_AI:
      return ASSIGNMENT_TYPES.AI_QUESTIONS;
    case CREATE_TYPES.HOME_TEMPLATE_CLONE:
      return ASSIGNMENT_TYPES.TEMPLATE;
    case CREATE_TYPES.HOME_LESSON_PLAN:
      return ASSIGNMENT_TYPES.LESSON_PLAN;
    case CREATE_TYPES.HOME_LEARNING_ACTIVITY:
      return ASSIGNMENT_TYPES.LEARNING_ACTIVITY;
    default:
      return ASSIGNMENT_TYPES.PLAIN;
  }
};
