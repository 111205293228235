import React from 'react';
import { Column, TextDivider, getHtml } from './utils';

const WelcomeEndPreview = (page) => {
  return (
    <Column key={page.id} style={{ width: '100%' }}>
      <TextDivider text={page.type} />
      {page.elements?.map((elem, idx) => {
        const key = `${page.id}_${idx}`;
        return getHtml(elem.type, elem.value || elem.html, key);
      })}
    </Column>
  );
};

export default WelcomeEndPreview;
