import {
  Flex,
  Box,
  Text,
  Button,
  Popover,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  useDisclosure,
  PopoverTrigger,
  IconButton
} from '@chakra-ui/react';
import { get, isEqual, pick, throttle } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo, useRef } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { ReactSortable } from 'react-sortablejs';
import { getCanvasElement } from '../../canvas-utils';
import Wrapper from '../../canvas-utils/wrapper';
import CanvasContext from '../../core/Providers/canvas-provider';
import { assignmentPages } from '../../../constants';
import {
  getPageElementOrder,
  getPageElements,
  getPages,
  getWelcomePageTextPlaceholder
} from './redux/selectors';
import { compareListOrder } from '../../../utils';
import useUpdateAssignment from './shared/hooks/use-update-assignment';
import RightSidebar from './sidebars/right-sidebar';
import { HiDotsVertical } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { isMobile } from 'react-device-detect';

// eslint-disable-next-line react/prop-types
function CanvasArea({ pageID, pageType }) {
  const dispatch = useDispatch();
  // const [elements, setElements] = useState([])
  const page = get(useSelector(getPages), pageID);
  const pageElements = useSelector(getPageElements(pageID));
  const pageElementOrder = useSelector(getPageElementOrder(pageID));
  const welcomePageTextPlaceholder = useSelector(getWelcomePageTextPlaceholder);
  const updateAssignment = useUpdateAssignment();

  const {
    isOpen: isSidebarOpen,
    onToggle: toggleRightSidebar,
    onClose: closeRightSidebar
  } = useDisclosure();

  const lastTextboxRef = useRef();

  const iterablePageElementOrder = useMemo(() => {
    return pageElementOrder.map((elem) => ({
      ...elem
    }));
  }, [pageElementOrder]);

  const lastTextElement = useMemo(() => {
    let elem = iterablePageElementOrder?.findLast(({ id }) => {
      return pageElements[id]?.type === 'textbox';
    });

    return elem?.id;
  }, [iterablePageElementOrder, pageElements]);

  // Need to prepend the ID with alphabets as IDs can't start with numbers
  const canvasID = useMemo(() => `canvas-${pageID}`, [pageID]);

  const throttledUpdate = useMemo(
    () =>
      throttle((elementID, value) => {
        dispatch({
          type: 'UPDATE_PAGE_ELEMENT',
          payload: {
            pageID,
            elementID,
            value
          }
        });
      }, 300),
    [dispatch, pageID]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateElementValue = useCallback(
    (elementID, value) => {
      if (!elementID) return;
      throttledUpdate(elementID, value);
    },
    [throttledUpdate]
  );

  const setList = (items) => {
    const stateItems = items.map((item) => {
      const itemState = {
        id: item.id || uuidV4(),
        type: item.type,
        ...pick(item, ['selected', 'chosen', 'filtered']) // Props necessary for react sortable to function
      };
      return itemState;
    });

    const isStateEqual = compareListOrder(stateItems, iterablePageElementOrder);

    // This fires even when both are same, triggering an assignment update
    if (!isStateEqual) {
      dispatch({
        type: 'UPDATE_ELEMENT_ORDER',
        payload: {
          pageID,
          order: stateItems
        }
      });
    }
  };

  const onDelete = (elementID) => {
    if (!elementID) return;
    dispatch({
      type: 'DELETE_PAGE_ELEMENT',
      payload: { elementID, pageID }
    });
  };

  const closeActivePageModal = () => {
    dispatch({
      type: 'CLOSE_ACTIVE_PAGE_MODAL',
      payload: {}
    });

    // Update the assignment if it needs to be updated
    updateAssignment();
  };

  const getTextBoxPlaceholder = () => {
    if (pageType === assignmentPages.WELCOME.displayName) {
      return welcomePageTextPlaceholder;
    } else {
      return 'You can start typing here';
    }
  };

  // Only happens on the click of the area on the welcome page beneath the elements
  const focusLastTextbox = () => {
    if (lastTextboxRef.current) {
      lastTextboxRef.current.editor?.focus();
    }
  };

  return (
    <CanvasContext.Provider value={{ canvasID }}>
      <Box
        id={canvasID}
        display="flex"
        minH={{ base: '100vh', lg: '85vh' }}
        flexDirection="column"
        position="relative"
        // onDrop={onElementDrop}
        onDragOver={(e) => e.preventDefault()}
        flexGrow="1"
        pb={{ base: '56px', lg: 'unset' }}
        m={{ md: '2rem' }}
        boxShadow="md"
        bg={'#fff'}
        overflowY={{ md: 'auto' }}
      >
        <Box px={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" py={3}>
            <Text
              mt={{ sm: 0, md: 3 }}
              fontSize={['20px', '30px']}
              ml={{ base: 0, lg: 3 }}
              color="gray"
              fontWeight="bold"
              right={4}
              opacity={0.3}
              className={`${pageType}_page_heading`}
            >
              {pageType} Page
            </Text>
            {/* Mobile only, allows to open the right sidebar as a drawer & has a save button that closes the modal & saves the assignment */}
            <Box display={{ base: 'flex', lg: 'none' }} gap="2" alignItems="center">
              <Popover isOpen={isSidebarOpen} onClose={closeRightSidebar} isLazy>
                <PopoverTrigger>
                  <IconButton
                    icon={
                      <HiDotsVertical style={{ height: '24px', width: '24px', color: '#888' }} />
                    }
                    onClick={toggleRightSidebar}
                  ></IconButton>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody padding={0}>
                      <RightSidebar />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
              <IconButton
                icon={
                  <IoMdClose
                    style={{ height: '24px', width: '24px', color: '#888' }}
                    onClick={closeActivePageModal}
                  />
                }
              ></IconButton>
            </Box>
          </Box>
          <ReactSortable
            style={
              isMobile
                ? {
                    overflowY: 'scroll',
                    // This is specifically added as iOS devices browsers have a bottom bar that does not hide
                    maxHeight: 'calc(100vh - 200px)',
                    minHeight: 'calc(100vh - 200px)'
                  }
                : {}
            }
            group={{ name: 'canvas-elements' }}
            list={iterablePageElementOrder}
            setList={setList}
            animation={200}
            delay={2}
            handle=".drag-handle"
          >
            {iterablePageElementOrder.map(({ id }) => {
              const elem = pageElements[id];
              if (!elem) return;
              const Component = getCanvasElement(elem?.type);
              return (
                <Wrapper
                  key={elem.id}
                  onDelete={onDelete.bind(null, elem?.id)}
                  pageType={page?.type}
                >
                  <Component
                    pageID={pageID}
                    elementID={elem.id}
                    onUpdate={updateElementValue}
                    onDelete={onDelete}
                    value={elem.value}
                    textPlaceholder={getTextBoxPlaceholder()}
                    forwardRef={lastTextElement === elem.id ? lastTextboxRef : null}
                  />
                </Wrapper>
              );
            })}
          </ReactSortable>
        </Box>
        <Flex
          display={{ base: 'none', lg: 'flex' }}
          flex={1}
          onClick={focusLastTextbox}
          maxH="30vh"
        ></Flex>
        <Flex
          display={{ base: 'flex', lg: 'none' }}
          justifyContent="center"
          alignItems="flex-end"
          px={2}
          py={2}
          zIndex={2}
          width="100%"
          background="#fff"
          pos="fixed"
          bottom="0"
        >
          <Button
            backgroundColor="#744CC6"
            color="white"
            borderRadius="none"
            width="90%"
            size="sm"
            bg="#5D38DB"
            onClick={closeActivePageModal}
          >
            Save
          </Button>
        </Flex>
      </Box>
    </CanvasContext.Provider>
  );
}

export default React.memo(CanvasArea, isEqual);
