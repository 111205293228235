/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { AddIcon, ArrowLeftIcon } from '@chakra-ui/icons';
import { v4 as uuidV4 } from 'uuid';
import {
  Box,
  Flex,
  Text,
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
  Portal,
  Tooltip
} from '@chakra-ui/react';
import { map, pick, noop } from 'lodash';
import { BsFlag } from 'react-icons/bs';
import { ReactSortable } from 'react-sortablejs';
import { TbGridDots } from 'react-icons/tb';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getActivePageID, getPages, getAssignmentID } from '../../redux/selectors';
import { canvasQuestionTypesSidebar } from '../right-sidebar/constant';
import { getPreviewHeight, setPageDefaults } from './utils';
import { compareListOrder } from '../../../../../utils';
import { isMobile } from 'react-device-detect';
import { useAuth } from '../../../../auth/useAuth';
import { eventLogger } from '../../../../../api/eventLogger';
import AiQuestionsModal from '../../ai-question/AiQuesionsModal';
import { PAGE_TYPES, assignmentPages } from '../../../../../constants';
import useCommonDispatchFunctions from '../../../common/joyride-onboarding-tour/use-common-dispatch-fns';
import useAiModalState from './use-ai-modal-state';
import CreateLessonPlanModal from './lesson-plan-modal/create-lesson-plan-modal';
import CreateLearningActivityModal from './learning-activity-modal/create-learning-activity-modal';
import QuestionPagePreview from './question-page-preview';
import WelcomePreview from './welcome-page-preview';

function logEvent(token, assignmentId, source = '') {
  eventLogger(
    token,
    'canvas-addquestion' + (source ? '-' + source : '') + '-clicked',
    assignmentId
  );
}

// eslint-disable-next-line react/prop-types
function QuestionCard({ title, desc, icon, type, onPageInsert, onClose, onClick, ...rest }) {
  const { stopTour } = useCommonDispatchFunctions();

  const height = isMobile
    ? 'auto'
    : type === PAGE_TYPES.LIBRARY_QUESTION || type === PAGE_TYPES.AI_QUESTION
    ? 100
    : 'auto';
  return (
    <Flex
      p={2}
      shadow="sm"
      flexDirection="column"
      w={{ base: '100%', lg: '47%' }}
      borderWidth="1px"
      h={height}
      onClick={() => {
        onClick();
        onPageInsert({ title, type });
        onClose();

        stopTour();
      }}
      bg={desc ? '#fff' : '#744CC6'}
      justifyContent={!desc && 'center'}
      {...rest}
    >
      <HStack justify="space-between" align="center">
        <Heading
          fontSize="md"
          textAlign={desc ? 'left' : 'center'}
          fontWeight={500}
          color={desc ? '#686868' : '#fff'}
        >
          {title}
        </Heading>
        {icon}
      </HStack>
      {!isMobile && (
        <Text mt={1} color="#686868" fontSize="xs">
          {desc}
        </Text>
      )}
    </Flex>
  );
}

// eslint-disable-next-line react/prop-types
function AddPageButton({ pages: totalPages, onPageInsert, pageIndex, ...rest }) {
  const pages = useMemo(() => {
    return [...canvasQuestionTypesSidebar.content];
  }, []);

  const addButtonClassName = useMemo(() => {
    const addButtonIdxClass = `add-btn-${pageIndex}`;
    return Reflect.ownKeys(totalPages).length === 2
      ? `ripple ${addButtonIdxClass}`
      : addButtonIdxClass;
  }, [pageIndex, totalPages]);

  const { token } = useAuth();
  const assignmentId = useSelector(getAssignmentID);

  return (
    <Popover placement={isMobile ? 'auto' : 'right'} closeOnBlur={false} size="xs">
      {({ onClose }) => (
        <>
          <Box py={5} shadow="sm" {...rest}>
            <Flex align="center" justify="center" position="relative">
              <Box position="absolute" backgroundColor="#E6E8F1" h={1} width="100%" my={4}></Box>
              <PopoverTrigger>
                <Flex
                  zIndex={1}
                  align="center"
                  cursor="pointer"
                  justify="center"
                  background="#744CC6"
                  w={10}
                  borderRadius="25px"
                  height={10}
                  color="#FFFFFF"
                  p={2}
                  className={addButtonClassName}
                  onClick={() => logEvent(token, assignmentId)}
                >
                  <Tooltip hasArrow label="Click here to add questions" bg="green.600">
                    <AddIcon />
                  </Tooltip>
                </Flex>
              </PopoverTrigger>
              <Portal>
                <PopoverContent color="white" background="white" className="add_question_popover">
                  <PopoverArrow />
                  {!isMobile && <PopoverCloseButton />}
                  {!isMobile && (
                    <PopoverHeader bg="#744CC6" color="#fff">
                      Select Page Type
                    </PopoverHeader>
                  )}
                  <PopoverBody p={2} shadow="dark-lg">
                    <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={2} flexWrap="wrap">
                      {pages.map((m) => {
                        return (
                          <QuestionCard
                            key={m.title}
                            title={m.title}
                            desc={m.description}
                            cursor="pointer"
                            borderColor="#B2BEDA"
                            borderRadius="8px"
                            icon={m.icon}
                            onPageInsert={onPageInsert}
                            onClose={onClose}
                            onClick={() => logEvent(token, assignmentId, m.type)}
                            {...m}
                          />
                        );
                      })}
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Flex>
          </Box>
        </>
      )}
    </Popover>
  );
}

// eslint-disable-next-line react/prop-types
function Card({
  id,
  title,
  icon,
  pageIndex,
  activePage,
  showAddButton = false,
  isDraggable,
  onPageInsert = noop,
  pages,
  ...rest
}) {
  const dispatch = useDispatch();

  const goToPage = useCallback(() => {
    dispatch({
      type: 'UPDATE_ACTIVE_PAGE',
      payload: {
        pageID: id
      }
    });
    if (isMobile) {
      dispatch({
        type: 'OPEN_ACTIVE_PAGE_MODAL',
        payload: {}
      });
    }
  }, [dispatch, id]);

  const insertPage = useCallback(
    ({ title, type }) => onPageInsert({ pageTitle: title, pageIndex, pageType: type }),
    [pageIndex, onPageInsert]
  );

  return (
    <Box id={id} className="sortItem" cursor="pointer">
      <Box
        p={3}
        shadow="sm"
        minHeight="80px"
        h={getPreviewHeight(title)}
        maxHeight={{ base: '200px', lg: '100px' }}
        overflow="hidden"
        textOverflow="ellipsis"
        borderColor={activePage === id ? '#744cc6' : '#B2BEDA'}
        borderRadius="8px"
        // my={4}
        bgColor={activePage === id ? '#f1f1f1' : '#fff'}
        borderWidth="1px"
        onClick={goToPage}
        {...rest}
      >
        <VStack align="left">
          <HStack justify="space-between" align="start">
            <HStack justify="start" align="center">
              {isDraggable ? (
                <Box>
                  <TbGridDots
                    className="drag-leftSidebar"
                    cursor="move"
                    size={20}
                    color="#1D1D1B"
                  />
                </Box>
              ) : (
                <Box>{icon}</Box>
              )}

              <Heading fontSize="lg" pl={2} fontWeight={500} color="#686868">
                {title}
              </Heading>
            </HStack>
          </HStack>
          {isDraggable && <QuestionPagePreview pageID={id} title={title} />}
          {title === assignmentPages.WELCOME.displayName && <WelcomePreview pageID={id} />}
        </VStack>
      </Box>
      {showAddButton && (
        <AddPageButton pages={pages} onPageInsert={insertPage} pageIndex={pageIndex} />
      )}
    </Box>
  );
}

// eslint-disable-next-line react/prop-types
export default function LeftSidebar({ onToggle }) {
  const dispatch = useDispatch();
  const pages = useSelector(getPages);
  const activePage = useSelector(getActivePageID);
  const { openAiQuestionsModal } = useAiModalState();
  const pageOrder = useMemo(() => {
    const pageIds = Object.keys(pages).map((pageID) => ({
      id: pageID
    }));
    pageIds.sort((a, b) => {
      return pages[a.id].pageIndex - pages[b.id].pageIndex;
    });
    return pageIds;
  }, [pages]);

  const addPage = useCallback(
    ({ pageTitle, pageIndex, pageType }) => {
      if (pageType === PAGE_TYPES.AI_QUESTION) {
        openAiQuestionsModal({ pageIndex });
      } else if (pageType === PAGE_TYPES.LIBRARY_QUESTION) {
        dispatch({
          type: 'UPDATE_LIBRARY_MODAL_STATUS',
          payload: {
            isLibraryDialogOpen: true,
            source: 'left-sidebar',
            pageIndex
          }
        });
      } else {
        // Add a page type
        const page = {
          id: uuidV4(),
          pageIndex: pageIndex + 1,
          type: pageTitle,
          isDraggable: true,
          isEditable: true
        };

        // Dispatch the redux action to add the page
        dispatch({
          type: 'ADD_PAGE',
          payload: { page }
        });

        // Util to add some defaults - like mcq elements for example - to the page depending on its type
        // Also sets this page as the active page
        setPageDefaults(page, dispatch);
      }
    },
    [dispatch, openAiQuestionsModal]
  );

  const setPageOrder = useCallback(
    (list) => {
      // Gives a non serializable id hence we need to do a toString
      const pageList = list.map((page) => {
        const pageState = {
          id: page.id,
          ...pick(page, ['filtered', 'value']) // Props necessary for react sortable to function
        };
        return pageState;
      });
      const isStateEqual = compareListOrder(pageList, pageOrder);
      if (!isStateEqual) {
        dispatch({
          type: 'UPDATE_PAGE_INDICES',
          payload: {
            list: pageList
          }
        });
      }
    },
    [dispatch, pageOrder]
  );

  return (
    <Box px={6} py={3}>
      <Heading
        as="h5"
        position="relative"
        fontWeight={700}
        mb={8}
        color="#686868"
        size="md"
        noOfLines={1}
        display={{ base: 'none', lg: 'block' }}
      >
        Summary{' '}
        <ArrowLeftIcon
          onClick={onToggle}
          color="#686868"
          cursor="pointer"
          fontSize="12px"
          position="absolute"
          top={'10px'}
          right={1}
        />
      </Heading>

      <ReactSortable
        style={{ flexGrow: '1' }}
        group={{ name: 'left-sidebar-elements' }}
        list={map(pageOrder, (el) => ({ ...el, chosen: true }))}
        setList={setPageOrder}
        animation={100}
        // delay={1}
        handle=".drag-leftSidebar"
      >
        {pageOrder.map(({ id }, idx) => {
          const page = pages[id];
          if (!page) return;
          const showAddButton = idx !== pageOrder.length - 1;
          return (
            <Card
              key={id}
              id={id}
              title={page.type}
              showAddButton={showAddButton}
              pageIndex={page.pageIndex}
              isDraggable={page.isDraggable}
              icon={<BsFlag size={20} color="#1D1D1B" />}
              onPageInsert={addPage}
              activePage={activePage}
              {...page}
              pages={pages}
            />
          );
        })}
      </ReactSortable>
      <AiQuestionsModal />
      <CreateLessonPlanModal />
      <CreateLearningActivityModal />
    </Box>
  );
}

LeftSidebar.propTypes = {
  elements: PropTypes.array,
  setElements: PropTypes.func
};
