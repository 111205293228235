import React from 'react';
import { forEach, reduce } from 'lodash';
import { assignmentPages } from '../../../../constants';
import { renderToStaticMarkup } from 'react-dom/server';
import PDFComponent from './pdf-component';
import { v4 as uuidV4 } from 'uuid';
import { getTotalScore } from '../../../../utils';

export const getDownloadableData = (pageData, elementState) => {
  const pages = Object.entries(pageData);

  pages.sort((a, b) => {
    const pageA = a[1];
    const pageB = b[1];
    return pageA.pageIndex - pageB.pageIndex;
  });

  // Return a value with { welcome-page, end-page & questions } for rendering the demo html
  return reduce(
    pages,
    (acc, [key, value]) => {
      const elements = elementState[key]?.elements || {};
      const elemOrder = elementState[key]?.elementOrder || [];
      const questionRef = {
        id: value.id,
        elements: [],
        type: value.type,
        score: value.score
      };

      forEach(elemOrder, (order) => {
        const id = order?.id;
        const element = elements[id];
        if (!id || !element) return;

        const elemCopy = Object.assign({}, element);
        questionRef.elements.push(elemCopy);
      });

      if (value.type === assignmentPages.MCQ.displayName) {
        const mcqValue = elements[elemOrder[0].id]?.value;
        if (!mcqValue) return acc;

        questionRef.questions = mcqValue.questions;
        questionRef.options = mcqValue.options;

        // Since we want to show the questions & the solutions separately, we push the questions + options in one arr & solutions in another
        acc.questions = acc.questions || [];
        acc.questions.push(questionRef);

        acc.solutions = acc.solutions || [];
        acc.solutions.push({
          solution: mcqValue.solution,
          id: value.id
        });
      } else {
        acc[value?.type] = questionRef;
      }
      return acc;
    },
    {}
  );
};

export const getPDFDownloadProps = (assignmentData, pages, elements) => {
  const data = getDownloadableData(pages, elements);
  const welcome = data[assignmentPages.WELCOME.displayName];
  const end = data[assignmentPages.END.displayName];
  const questions = data.questions;
  const solutions = data.solutions;

  // Additional data to be shown on the PDF
  const totalScore = getTotalScore(pages);

  const pdfProps = {
    assignmentData,
    totalScore,
    welcome,
    end,
    questions,
    solutions,
    elementID: uuidV4()
  };

  return pdfProps;
};

export const downloadPDF = (assignmentData, pages, elements) => {
  const pdfProps = getPDFDownloadProps(assignmentData, pages, elements);

  renderToPDF(pdfProps, { options: true, solutions: true });
};

export const downloadPreviewTemplate = (template) => {
  const { welcomeScreen: welcome, thankYouScreen: end, ...assignmentData } = template;

  const questions = template.questions.map((q) => {
    return {
      ...q,
      questions: q.elements,
      options: q.options.map((op) => ({ ...op, value: op.elements }))
    };
  });
  const solutions = template.questions.map((q) => ({
    ...q.solution,
    solution: q.solution.elements
  }));

  // Additional data to be shown on the PDF
  const totalScore = getTotalScore(questions);

  const pdfProps = {
    assignmentData,
    totalScore,
    welcome,
    end,
    questions,
    solutions,
    elementID: uuidV4()
  };

  renderToPDF(pdfProps, { options: true, solutions: true });
};

export const renderToPDF = (pdfProps, opts = {}) => {
  const htmlString = renderToStaticMarkup(<PDFComponent {...pdfProps} options={opts} />);
  const assignmentName = pdfProps?.assignmentData?.name;
  printPDF(assignmentName, htmlString);
};

export const printPDF = (assignmentName, htmlString) => {
  var printWindow = window.open('', '');
  printWindow.document.write(`<!DOCTYPE html><head><title>${assignmentName}</title>`);
  printWindow.document.write(
    '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.3.0/katex.min.css" crossOrigin="anonymous"/>'
  );
  printWindow.document.write(
    '<link href="https://cdn.jsdelivr.net/npm/suneditor@2.45.1/dist/css/suneditor.min.css" rel="stylesheet" crossOrigin="anonymous">'
  );
  // printWindow.document.write(
  //   '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css" integrity="sha384-n8MVd4RsNIU0tAv4ct0nTaAbDJwPJzDEaqSD1odI+WdtXRGWt2kTvGFasHpSy3SV" crossorigin="anonymous">'
  // );
  printWindow.document.write(`<style>
      html, body {
        padding: 0;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
          Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      }
      a {
        color: inherit;
        text-decoration: none;
      }

      * {
        box-sizing: border-box;
        overflow: visible !important;
      }

      .katex-question textarea, .uneditable-html textarea, #id_cont_display textarea {
        display: none;
      }
      .separator {
        display: flex;
        align-items: center;
        text-align: center;
      }
      
      .separator::before,
      .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid rgba(0,0,0,0.15);
      }
      
      .separator:not(:empty)::before {
        margin-right: .25em;
      }
      
      .separator:not(:empty)::after {
        margin-left: .25em;
      }
      .uneditable-html img { 
        max-width: 100%; width: fit-content; height: fit-content;
        break-inside: avoid;
        break-before: auto;
        break-after: auto;
        padding-bottom: 3px;
        max-height: 300px;
      }
      .uneditable-html p {
        min-height: fit-content;
        padding-bottom: 3px;
      }
      .ql-indent-1 {
        margin-left: 3em;
      }
      </style>`);
  printWindow.document.write('</head><body style="width: 1000px;">');
  printWindow.document.write(htmlString);
  printWindow.document.write(
    `<script type="text/javascript">window.onload = function(){ window.print(); }</script>`
  );
  printWindow.document.write('</body></html>');
  printWindow.document.close();
};
