import React from 'react';
import parse from 'html-react-parser';
import { Flex, Text, Box } from '@chakra-ui/react';
import { Avatar } from '@chakra-ui/avatar';
import { HTMLRenderer } from './HTMLRenderer';
import { QuestionStatus, Options } from './common-components';
import { assignmentPages } from '../../../constants';
import useParserOptions from './useParserOptions';
import FIBBlankDisplay from './FIBBlankDisplay';
import FIBResultForStudent from './FibResultForStudent';
import Stats from './Stats';

export function ResultsByQuestions({ studentSubmissions, questions, activeSub, blankConfigs }) {
  const allQuestionsParseOptions = useParserOptions({
    BlankReviewComponent: FIBBlankDisplay,
    blankProps: blankConfigs
  });

  if (!questions.length || !studentSubmissions.length) {
    return <></>;
  }
  return questions.map((question, id) => {
    return (
      <Flex direction="column" id={question.id} display={question.id == activeSub ? 'flex' : 'none'} key={question._id}>
        <Flex borderBottom="1px" borderColor="#DDDFEC" fontSize="15px" flexDirection="column">
          <Flex flexDirection="row" borderBottom="1px" borderColor="#DDDFEC" p="4" justifyContent="space-between">
            <Flex alignItems="center" gap={4} mt={1}>
              <Text color={'#686868'} fontSize="18px" fontWeight="700" alignSelf="center">
                Question {id + 1}
              </Text>
              <Text color={'#888888'} fontSize="14px" fontWeight="700">
                {question.type.toUpperCase()}
              </Text>
            </Flex>
            <Stats
              type={question?.type}
              correct={question?.correct}
              partial={question?.partial}
              incorrect={question?.incorrect}
              unanswered={question?.unanswered}
            />
          </Flex>
          <Flex p={4} w="95%" flexDir={'column'}>
            {question.type === assignmentPages.FIB.key && (
              <>{parse(question?.elements?.[0].html, allQuestionsParseOptions)}</>
            )}
            {question.type === assignmentPages.MCQ.key && (
              <Text fontSize="lg">
                {question?.elements.map((e) => (
                  <HTMLRenderer element={e} key={e.id} />
                ))}
              </Text>
            )}
          </Flex>
        </Flex>
        {studentSubmissions.map((sub) => {
          const studentResponse = sub.studentResponse.find((s) => s.questionId == question.id);
          return (
            <Box
              display="flex"
              flexDirection="column"
              border="1px"
              borderColor="#DDDFEC"
              className="katex-question"
              mx="10px"
              mt="15px"
              px="4"
              key={sub.id}
            >
              <Flex direction="row" borderBottom="1px" borderColor="#DDDFEC">
                <Flex alignItems="center" fontSize="15px" flexDirection="row" gap="4" p="2">
                  <Avatar
                    referrerPolicy="no-referrer"
                    src={sub.studentinfo?.photoURL}
                    name={sub.studentinfo?.displayName}
                    size="sm"
                  />
                  <Text>{sub.studentinfo?.displayName}</Text>
                  <Text>
                    <QuestionStatus question={question} studentResponse={studentResponse} />
                  </Text>
                </Flex>
              </Flex>
              {question.type === assignmentPages.MCQ.key && (
                <Options options={question?.options} studentResponse={studentResponse} byQuestion={true} />
              )}
              {question.type === assignmentPages.FIB.key && (
                <FIBResultForStudent question={question} studentResponse={studentResponse} />
              )}
            </Box>
          );
        })}
      </Flex>
    );
  });
}
