import React from 'react'
import TextBox from './textbox/textbox';
import McqBox from './mcqbox'
import Image from './image/image'
import VideoBox from './video'
import TableBox from './table'
import UneditableHtml from './uneditable-html'
import LibraryQuestion from './library-mcq-question'
import FillInBlanks from './fill-in-blanks';

export const CanvasElements = {
  textbox: TextBox,
  image: Image,
  mcq: McqBox,
  fib: FillInBlanks,
  video: VideoBox,
  table: TableBox,
  uneditableHtml: UneditableHtml,
  libraryQuestion: LibraryQuestion
};

export function getCanvasElement(type) {
    return CanvasElements[type] || <></>
}
