import React, { useState, useEffect } from 'react'
import LoggedInUserWrapper from '../core/LoggedInUserWrapper'
import { Avatar } from '@chakra-ui/avatar'
import {
  Flex,
  Text,
  Image,
  Skeleton,
  Box,
  Table,
  Tr,
  Td,
  Tbody,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  HStack,
  VStack,
  SkeletonCircle,
  Thead
} from '@chakra-ui/react';
import TimeAgo from 'react-timeago';
import {
  getGoogleAssignments,
  getGoogleCourses,
  getGoogleStudentSubmissions,
  getGoogleStudents
} from '../../api/fetchGoogleAssignmentData';
import { getGoogleToken } from '../../api/userApis';
import { useAuth } from '../auth/useAuth';
import LinkWithGoogle from '../auth/linkWithGoogle';
import {
  getClasswiseStudents,
  getClasswiseSubmissions,
  mergeStudents
} from '../../api/fetchClasswiseAssignmentData';
import { EmptyState } from './emptyState';
import { eventLogger } from '../../api/eventLogger';
import { isMobile } from 'react-device-detect';
import { FaTrophy } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function StudentMobilePage({
  students,
  navigateToSubmission,
  getImageComponent,
  getSubmissionStatus
}) {
  return (
    <Flex flexDir="column" overflow="hidden" flex="1" border="1px" borderColor="#DDDFEC">
      {students.length ? (
        <>
          <Flex direction="row" py="8px" fontSize="14px" fontWeight="700" textColor="#686868">
            <Box flex="1" px="8">
              <Text>Student</Text>
            </Box>
            <Box width="100px">Class</Box>
          </Flex>
          <Accordion allowToggle>
            {students.map((student) => (
              <AccordionItem key={student.id}>
                <AccordionButton>
                  <Box
                    flex="1"
                    flexDirection="row"
                    display="flex"
                    alignItems="center"
                    gap="4"
                    px="4"
                  >
                    <Avatar
                      referrerPolicy="no-referrer"
                      src={student.photoUrl}
                      name={student.name}
                      size="sm"
                    />
                    <Text fontSize={'sm'}>{student.name}</Text>
                  </Box>
                  <Box width="100px" display="flex" alignItems="center">
                    {student.class}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} fontSize={'sm'} maxHeight={200} overflow={'auto'}>
                  {student?.submissions?.map((sub) => (
                    <HStack
                      key={sub.id}
                      px={4}
                      py={3}
                      borderBottom={'1px solid #eee'}
                      justifyContent={'space-between'}
                    >
                      <Flex flexDirection="column">
                        <Flex gap={2}>
                          {getImageComponent(sub.source)}
                          <Text isTruncated fontSize={'sm'}>
                            {sub.assignmentName}
                          </Text>
                        </Flex>
                        <HStack mt={3}>
                          <Flex gap={1}>
                            <Text fontSize={'12px'} color={'gray'}>
                              <TimeAgo date={sub.updateTime} />
                            </Text>
                          </Flex>
                          <Text px={1} borderRadius={5} border={'1px solid #ddd'} fontSize={'10px'}>
                            {getSubmissionStatus(sub.status)}
                          </Text>
                        </HStack>
                      </Flex>
                      <Flex gap={3} alignItems={'center'}>
                        <Flex gap={1}>
                          {sub.score && sub.assignmentScore && <FaTrophy color="#5D38DB" />}
                          <Text fontSize={'12px'}>
                            {sub.score && sub.assignmentScore
                              ? sub.score + '/' + sub.assignmentScore
                              : null}
                          </Text>
                        </Flex>
                        <Button
                          lineHeight="15px"
                          fontSize="11px"
                          p={2}
                          height={'auto'}
                          w={'50px'}
                          backgroundColor="#5D38DB"
                          textColor="#FFFFFF"
                          fontWeight="400"
                          borderRadius={'5px'}
                          onClick={(evt) => {
                            navigateToSubmission(sub.source, sub.submissionUrl);
                            evt.stopPropagation();
                          }}
                        >
                          View
                        </Button>
                      </Flex>
                    </HStack>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </>
      ) : (
        <Flex flexDir={'column'} px={6}>
          <HStack my={3}>
            <SkeletonCircle size={10} w={'15%'} />
            <VStack w={'100%'}>
              <Skeleton height="10px" w={'100%'} />
              <Skeleton height="10px" w={'100%'} mt={2} />
            </VStack>
          </HStack>
          <HStack my={3}>
            <SkeletonCircle size={10} w={'15%'} />
            <VStack w={'100%'}>
              <Skeleton height="10px" w={'100%'} />
              <Skeleton height="10px" w={'100%'} mt={2} />
            </VStack>
          </HStack>
        </Flex>
      )}
    </Flex>
  );
}

export function StudentsPage() {
  const { token } = useAuth();
  const navigate = useNavigate();

  let [students, setStudents] = useState([]);
  const [isGoogleAuthDone, setIsGoogleAuthDone] = useState(true);
  const [isEmptyState, setEmptyState] = useState(false);

  const [activeSub, setActiveSub] = useState('');

  const fetchData = async () => {
    const classwiseSubmissions = await getClasswiseSubmissions(token, null, true);
    const classwiseStudents = await getClasswiseStudents(classwiseSubmissions, token);

    const mergedStudents = await mergeStudents(classwiseStudents, classwiseSubmissions);
    setStudents(mergedStudents);
    if (mergedStudents.length > 0) {
      setActiveSub(mergedStudents[0].id);
    }

    const [accessToken, googleUserId] = await getGoogleToken(token);
    if (accessToken) {
      const courses = await getGoogleCourses(accessToken, googleUserId);
      const googleStudents = await getGoogleStudents(accessToken, courses);
      const assignments = await getGoogleAssignments(accessToken, courses);
      const subs = await getGoogleStudentSubmissions(accessToken, assignments);

      const mergedStudents = await mergeStudents(
        classwiseStudents,
        classwiseSubmissions,
        googleStudents,
        assignments,
        subs
      );
      setStudents(mergedStudents);
      if (mergedStudents.length > 0) {
        setActiveSub(mergedStudents[0].id);
      }
    } else {
      setIsGoogleAuthDone(false);
    }
    if (students.length == 0) {
      setEmptyState(true);
    }
  };

  function getSubmissionStatus(status) {
    switch (status) {
      case 'YET_TO_START':
      case 'NEW':
        return <Text color="#FF9786">Yet to Start</Text>;
      case 'STARTED':
      case 'CREATED':
        return <Text color="#89A6FF">Started</Text>;
      case 'COMPLETED':
      case 'TURNED_IN':
        return <Text color="#389411">Submitted</Text>;
    }
  }

  function getImageComponent(source = 'Google') {
    switch (source) {
      case 'Google':
        return <Image src="/assets/images/google.png" boxSize="20px" />;
      case 'Classwise':
        return <Image src="/assets/images/classwise-favicon.png" boxSize="20px" />;
    }
  }

  function navigateToSubmission(source = 'Google', submissionUrl) {
    switch (source) {
      case 'Classwise':
        navigate(submissionUrl);
        break;
      case 'Google':
        window.open(submissionUrl, '_blank');
        break;
    }
  }

  useEffect(() => {
    eventLogger(token, 'student-assignment-home-visited');
    fetchData();
  }, []);

  return (
    <LoggedInUserWrapper selectedMenu="students">
      <Flex p={[0, 8]}>
        <Flex flexDir={'column'} gap={4} w="100%" overflow={'hidden'}>
          <Text fontSize="3xl" as="b" pb={[2, 3]} pt={[5, 0]} px={[8, 0]}>
            My Students
          </Text>
          {!isGoogleAuthDone && <LinkWithGoogle />}
          {isMobile ? (
            <StudentMobilePage
              navigateToSubmission={navigateToSubmission}
              getImageComponent={getImageComponent}
              getSubmissionStatus={getSubmissionStatus}
              students={students}
            />
          ) : (
            <Flex
              flexDir="row"
              overflow="hidden"
              flex="1"
              // border="1px"
              // borderColor="#DDDFEC"
            >
              {students.length ? (
                <>
                  <Flex
                    direction="column"
                    border="1px"
                    borderColor="#DDDFEC"
                    height="calc(100vh - 200px)"
                    overflow={'auto'}
                  >
                    <Flex
                      direction="row"
                      py="8px"
                      fontSize="14px"
                      fontWeight="700"
                      textColor="#686868"
                      position={'sticky'}
                      top={0}
                      bg={'#fff'}
                      zIndex={2}
                    >
                      <HStack w={'100%'} px="6" justifyContent={'space-between'}>
                        <Text>Student</Text>
                        <Text width="85px">Class</Text>
                      </HStack>
                    </Flex>
                    {students.map((student) => (
                      <HStack
                        direction="row"
                        fontSize="13px"
                        py="20px"
                        px={2}
                        key={student?.id}
                        onClick={() => setActiveSub(student?.id)}
                        cursor="pointer"
                        bgColor={student.id == activeSub ? '#0000001F' : '#FFFFFF'}
                        borderTop="1px"
                        borderColor="#DDDFEC"
                        justifyContent={'space-between'}
                      >
                        <Box
                          flex="1"
                          flexDirection="row"
                          display="flex"
                          alignItems="center"
                          gap="4"
                          px="4"
                        >
                          <Avatar
                            referrerPolicy="no-referrer"
                            src={student.photoUrl}
                            name={student.name}
                            size="sm"
                          />
                          <Text>{student.name}</Text>
                        </Box>
                        <Box width="100px" display="flex" alignItems="end">
                          {student.class}
                        </Box>
                      </HStack>
                    ))}
                  </Flex>
                  <Flex
                    overflow="auto"
                    flex={1}
                    mx="15px"
                    boxShadow="xl"
                    border="1px"
                    borderColor="#DDDFEC"
                    height="calc(100vh - 200px)"
                  >
                    {students?.map((student) => (
                      <Flex
                        direction="column"
                        flex={1}
                        // boxShadow="xl"
                        // border="1px"
                        // borderColor="#DDDFEC"
                        // minHeight="calc(100vh - 110px)"
                        alignItems="center"
                        fontSize="13px"
                        id={student.id}
                        key={student?.id}
                        display={student.id == activeSub ? 'flex' : 'none'}
                      >
                        <Table variant="simple">
                          <Thead position={'sticky'} top={0} zIndex={2}>
                            <Tr
                              fontFamily="Arial"
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="19px"
                              color="#686868"
                              bgColor="#FFFF"
                            >
                              <Td flexGrow="1">
                                <Text pl={10}>Assignment Name</Text>
                              </Td>
                              <Td>Status</Td>
                              <Td>Updated</Td>
                              <Td>Score</Td>
                              <Td fontWeight="700">Actions</Td>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {student?.submissions?.map((sub) => (
                              <Tr
                                key={sub.id}
                                fontFamily="Arial"
                                fontWeight="400"
                                lineHeight="20px"
                                color="#757575"
                                cursor="pointer"
                                onClick={(evt) => {
                                  navigateToSubmission(sub.source, sub.submissionUrl);
                                  evt.stopPropagation();
                                }}
                              >
                                <Td flexGrow="1">
                                  <Flex flexDirection="row" gap="6">
                                    {getImageComponent(sub.source)}
                                    {sub.assignmentName}
                                  </Flex>
                                </Td>
                                <Td>{getSubmissionStatus(sub.status)}</Td>
                                <Td>
                                  <Text>
                                    <TimeAgo date={sub.updateTime} />
                                  </Text>
                                </Td>
                                <Td>
                                  {sub.score && sub.assignmentScore
                                    ? sub.score + '/' + sub.assignmentScore
                                    : '-'}
                                </Td>
                                <Td>
                                  <Button
                                    borderRadius="0"
                                    maxWidth="110px"
                                    lineHeight="15px"
                                    fontFamily="Helvetica"
                                    fontSize="12px"
                                    backgroundColor="#5D38DB"
                                    textColor="#FFFFFF"
                                    fontWeight="400"
                                    onClick={(evt) => {
                                      navigateToSubmission(sub.source, sub.submissionUrl);
                                      evt.stopPropagation();
                                    }}
                                  >
                                    View Submissions
                                  </Button>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>
                    ))}
                  </Flex>
                </>
              ) : isEmptyState ? (
                <EmptyState type="students-home" />
              ) : (
                <>
                  <Flex flexDir={'column'}>
                    <Skeleton height="30px" width="430px" />
                    <Skeleton height="30px" width="430px" mt={4} />
                    <Skeleton height="30px" width="430px" mt={4} />
                    <Skeleton height="30px" width="430px" mt={4} />
                  </Flex>
                  <Flex flex={1}>
                    <></>
                  </Flex>
                </>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </LoggedInUserWrapper>
  );
}
