import React from 'react'
import { Button, Image, Box } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
  
export function QRCodeModal(props) {
    const qrcodeAPIURL = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&format=jpeg&data=' + props.assignmentURL;

    // Using fetch
    async function downloadImage(imageSrc) {
        const image = await fetch(imageSrc)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)
    
        const link = document.createElement('a')
        link.href = imageURL
        link.download = 'qrcode'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <Box>
            <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
            <ModalOverlay />
            <ModalContent maxW="270px">
                <ModalCloseButton />
                <ModalBody>
                    <Box
                        alignItems='center'
                        display='flex'
                        flexDirection='column'
                        width='220px'
                        mt='5'
                        marginBottom='5'
                        gap='7px'
                    >
                        <Box 
                            fontWeight='bold'
                            fontSize='20px'
                        >
                            Assignment QR Code
                        </Box>
                        <Box><Image src={qrcodeAPIURL}></Image></Box>
                        <Button 
                            backgroundColor='#5D38DB'
                            borderRadius='2px' 
                            textColor='#FFFFFF' 
                            fontFamily='Arial' 
                            fontWeight='400' 
                            fontSize='15px' 
                            lineHeight='20px' 
                            mt='4px'
                            onClick={()=>{downloadImage(qrcodeAPIURL)}}
                        >
                            Download QR Code
                        </Button>
                    </Box>
                </ModalBody>
            </ModalContent>
            </Modal>
        </Box>
    )
}