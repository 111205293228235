import { Image, Box, Stack, useDisclosure, useToast, useClipboard, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import ShareBox from './sharebox';
import { QRCodeModal } from './qrcode-modal';
import { appConfig } from '../../../constants';
import useUpdateAssignment from '../canvas/shared/hooks/use-update-assignment';
import { useSelector } from 'react-redux';
import { getPages, getElements, getBasicAssignmentData } from '../canvas/redux/selectors';
import { downloadPDF } from '../canvas/download-pdf/utils';
import { useParams } from 'react-router-dom';

export default function Share() {
  const toast = useToast();
  const routerParams = useParams();
  const assignmentID = routerParams.assignmentID;
  const assignmentURL = `${appConfig.studentAppUrl}/start/${assignmentID}`;
  const { onCopy } = useClipboard(assignmentURL);

  const updateAssignment = useUpdateAssignment({ forceUpdate: false });

  const pages = useSelector(getPages);
  const elements = useSelector(getElements);
  const assignmentData = useSelector(getBasicAssignmentData);

  // When landing on this page, update the assignment
  useEffect(() => {
    updateAssignment();
  }, [updateAssignment]);

  const {
    isOpen: isQRCodeModalOpen,
    onOpen: onQRCodeModalOpen,
    onClose: onQRCodeModalClose
  } = useDisclosure();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.async = true;
    document.body.appendChild(script);

    // No exhaustive deps as the deps here are somehow reconstructed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCopyLink = React.useCallback(() => {
    onCopy();
    toast({
      title: `Assignment link copied`,
      status: 'success',
      isClosable: true
    });
  }, [toast, onCopy]);

  return (
    <Stack overflow="auto" display="flex" flexDirection="column">
      <Box
        alignSelf="center"
        padding={{ base: '2', lg: '65px' }}
        mt="21px"
        border="1px"
        borderRadius="4px"
        borderColor="#DDDFEC"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Image src="/assets/images/share-header.png" alt="Share" />
        <Text
          fontSize={{ base: 'xl', lg: '2xl' }}
          mt={10}
          color="#686868"
          textAlign="center"
          px={3}
        >
          You are all set! <br />
          Your assignment is ready to be shared with your students
        </Text>
        <Text fontSize="md" mt={6} color="#686868">
          Share Via
        </Text>
        <Box
          mt="18px"
          display="flex"
          gap="16px"
          flexWrap="wrap"
          justifyContent="center"
          id="share-box-container"
        >
          <ShareBox
            onClick={onCopyLink}
            imageSrc={'/assets/images/copy-link.png'}
            shareText={'Link'}
            type={'link'}
          />
          <ShareBox
            onClick={() => downloadPDF(assignmentData, pages, elements)}
            imageSrc={'/assets/images/pdf-icon.svg'}
            shareText={'Download PDF'}
            type={'pdf'}
          />
          <ShareBox
            linkUrl={
              'https://classroom.google.com/u/0/share?url=' +
              assignmentURL +
              '&title=Testing-Share-Button&itemType=assignment&body=share-button-body'
            }
            imageSrc={'/assets/images/share-googleclassroom.png'}
            shareText={'Google Classroom'}
            isTargetBlank
            type={'google'}
          />
          <ShareBox
            linkUrl={'mailTo:?subject=New%20PrepHub%20Assignment&body=' + assignmentURL + ''}
            imageSrc={'/assets/images/share-email.png'}
            shareText={'Email'}
            isTargetBlank
            type={'email'}
          />
          <ShareBox
            linkUrl={
              'https://web.whatsapp.com/send?text=' + assignmentURL + '&type=text&preview_url=true'
            }
            imageSrc={'/assets/images/share-whatsapp.png'}
            shareText={'Whatsapp'}
            isTargetBlank
            type={'whatsapp'}
          />
          <ShareBox
            onClick={onQRCodeModalOpen}
            imageSrc={'/assets/images/share-qrcode.png'}
            shareText={'QR Code'}
            type={'qrcode'}
          />
        </Box>
        <Box textAlign="center" mt="38px" width="100%">
          <Text fontSize="md" mt={10} color="#979797" textAlign="center" px={3}>
            After sharing the assignment with your students, you can track their <br /> progress in
            the “Assignments” tab
          </Text>
        </Box>
        <Box display="flex" height="10px" mt="29px">
          <Box width="90px" backgroundColor="#EED68A">
            <br />
          </Box>
          <Box width="90px" backgroundColor="#EF8888">
            <br />
          </Box>
          <Box width="90px" backgroundColor="#C0E281">
            <br />
          </Box>
          <Box width="90px" backgroundColor="#A57DCA">
            <br />
          </Box>
        </Box>
        <QRCodeModal
          assignmentURL={assignmentURL}
          isOpen={isQRCodeModalOpen}
          onClose={onQRCodeModalClose}
        />
      </Box>
    </Stack>
  );
}
