import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS, STATUS } from 'react-joyride';
import { getLSItem, removeLSItem, setLSItem } from '../local-storage-access';
import { getOnboardingState } from '../../canvas/redux/selectors';
import { useParams } from 'react-router-dom';

const ONBOARDING_LS_FLAG = 'onboarding_notification_dot';

const useCommonDispatchFunctions = () => {
  const dispatch = useDispatch();
  const routerParams = useParams();
  const assignmentID = routerParams.assignmentID;

  const { tourMode, lastAction, lastStatus } = useSelector(getOnboardingState);
  const [isOnboardingReminderOpen, setIsOnboardingReminderOpen] = useState({
    tooltip: false,
    notification: false
  });

  useEffect(() => {
    if (!assignmentID) return;

    const lsItem = getLSItem(ONBOARDING_LS_FLAG, true);
    if (lsItem?.[assignmentID] === undefined) {
      removeLSItem(ONBOARDING_LS_FLAG);
    } else {
      const notifState = lsItem?.[assignmentID];
      setIsOnboardingReminderOpen((state) => ({ ...state, notification: notifState }));
    }
  }, [assignmentID]);

  const setStepIndex = useCallback(
    (stepIndex) => {
      dispatch({
        type: 'UPDATE_STEP',
        payload: {
          stepIndex
        }
      });
    },
    [dispatch]
  );

  const setTourRunning = useCallback(
    (running, stepIndex = 0) => {
      dispatch({
        type: 'SET_TOUR_MODE',
        payload: { tourMode: running }
      });
      dispatch({
        type: 'SET_RUNNING',
        payload: { running }
      });

      // Start with the provided step index or reset to 0
      setStepIndex(stepIndex);
    },
    [dispatch, setStepIndex]
  );

  const startTour = useCallback(() => {
    // Set active page as welcome page if not already there
    dispatch({
      type: 'GOTO_WELCOME_PAGE'
    });

    // Start the tour
    setTourRunning(true);
  }, [dispatch, setTourRunning]);

  const goToPage = useCallback(
    (id) => {
      dispatch({
        type: 'UPDATE_ACTIVE_PAGE',
        payload: {
          pageID: id
        }
      });
    },
    [dispatch]
  );

  const stopTour = useCallback(() => setTourRunning(false), [setTourRunning]);

  const setRunning = useCallback(
    (running) => {
      dispatch({
        type: 'SET_RUNNING',
        payload: { running }
      });
    },
    [dispatch]
  );

  const resetCreateMode = useCallback(() => {
    dispatch({
      type: 'CREATE_MODE_RESET'
    });
  }, [dispatch]);

  useEffect(() => {
    // If the tour was closed but the tour was not finished, show the onboarding reminder tooltip
    if (!tourMode && (lastAction === ACTIONS.CLOSE || lastAction === ACTIONS.SKIP)) {
      setIsOnboardingReminderOpen({ tooltip: true, notification: true });
      setLSItem(ONBOARDING_LS_FLAG, { [assignmentID]: true });

      setTimeout(() => {
        setIsOnboardingReminderOpen((state) => ({ ...state, tooltip: false }));
      }, 4000);
    } else if (tourMode || (!tourMode && lastStatus === STATUS.FINISHED)) {
      // If the tour started or the tour was finished, remove the notification
      setIsOnboardingReminderOpen({ tooltip: false, notification: false });
      removeLSItem(ONBOARDING_LS_FLAG);
    }
  }, [assignmentID, lastAction, lastStatus, tourMode]);

  return useMemo(
    () => ({
      startTour,
      stopTour,
      setStepIndex,
      setRunning,
      isOnboardingReminderOpen,
      resetCreateMode,
      goToPage
    }),
    [
      startTour,
      stopTour,
      setStepIndex,
      setRunning,
      isOnboardingReminderOpen,
      resetCreateMode,
      goToPage
    ]
  );
};

export default useCommonDispatchFunctions;
