import { post } from './APIClient';

export const createAiLearningActivity = async ({ data, token }) => {
  try {
    const response = await post({ url: 'learning-activity/ai', token, data });
    return response;
  } catch (e) {
    console.error('error creating AI request: ', e);
    return null;
  }
};

export const createAiLearningActivityPdf = async ({ data, token, toast }) => {
  try {
    const response = await post({
      url: 'learning-activity/ai/pdf',
      token,
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
  } catch (e) {
    if (e.response.data.message) {
      toast({
        status: 'error',
        title: `Unable to generate Learning Activity`,
        description: e.response.data.message,
        isClosable: true,
        duration: 15000
      });
    }
    console.error('error creating AI Learning activity: ', e.response.data.message);
    return null;
  }
};
