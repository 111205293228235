import React from 'react'
import Lottie from 'react-lottie';
import animationData from './cw-animation.json';
import { Box } from '@chakra-ui/react';

export default function ClasswiseLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Box w={180} p="fixed" zIndex={2000}>
      <Lottie options={defaultOptions} height={'100%'} width={'100%'} />
    </Box>
  );
}
