import { appConfig } from '../../constants';
import { eventLogger } from '../../api/eventLogger';

const cloneAssignmentTemplate = async (token, templateID, navigate) => {
  const res = await fetch(`${appConfig.getAPIURL('/assignments')}/clone/${templateID}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + `${token}`
    }
  });

  if (res.ok) {
    const resData = await res.json();
    eventLogger(token, `home-clone-clicked-${templateID}`, resData?._id);
    navigate(`/assignments/${resData?._id}/create`);
  } else {
    const error = await res.text();
    console.log('Create clone assignment failure', res.status, error);

    let errorMessage = error;
    try {
      const parsedError = JSON.parse(error);
      errorMessage = parsedError?.message || error;
    } catch (e) {
      // Nothing to do
    }
    console.log(errorMessage);
    return errorMessage;
  }
};

export default cloneAssignmentTemplate;
