import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import TemplateFilters from './filters';
import { CREATE_TYPES, STAGING_TEMPLATE, isProduction } from '../../../constants';
import TemplateBox from './template-box';
import cloneAssignmentTemplate from '../../assignments/clone-template-assignment';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../auth/useAuth';
import { LoadingSkeleton } from '../../assignments/loadingSkeleton';
import TemplatePreviewModal from './template-preview-modal';
import useTemplatesFetcher from './templates-fetcher';
import { useCreateAssignmentOptions } from '../create-options';
import { useNavigate } from 'react-router-dom';

const CloneTemplatesSection = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useAuth();

  const { templates, templatesLoading, allTemplatesFetched } = useTemplatesFetcher();

  const [previewTemplate, setPreviewTemplate] = useState();

  const {
    isOpen: isTemplatePreviewOpen,
    onOpen: openTemplatePreviewModal,
    onClose: closeTemplatePreview
  } = useDisclosure();

  const { onCreateUsingAI } = useCreateAssignmentOptions({ setIsLoading });

  const [filteredTemplates, setFilteredTemplates] = useState([]);

  // Once templates are fetched, set them in the filtered set
  useEffect(() => {
    setFilteredTemplates(templates);
  }, [templates]);

  const onCloneTemplateClicked = async (templateID) => {
    // If the env is not production, the template assignments don't exist on that env
    templateID = isProduction() ? templateID : STAGING_TEMPLATE;
    setIsLoading(true);
    await cloneAssignmentTemplate(token, templateID, navigate);
    dispatch({
      type: 'ASSIGNMENT_CREATED',
      payload: {
        createType: CREATE_TYPES.HOME_TEMPLATE_CLONE
      }
    });
  };

  const openTemplatePreview = (templateID) => {
    const template = templates.find((template) => template.assignmentId === templateID);
    setPreviewTemplate({
      ...template,
      assignmentId: isProduction() ? templateID : STAGING_TEMPLATE
    });
    openTemplatePreviewModal();
  };

  return (
    // Min & max width set on this to prevent the div from shrinking & expanding when searching increases or decreases the count of templates
    <Flex direction="column" minW="85vw" mt={{ base: 4, lg: 10 }}>
      <Box>
        <Text fontSize={{ base: '2xl', md: 'xl', lg: '2xl' }} as="b" mt={{ base: 4, lg: 10 }}>
          You May Also Like
        </Text>
        <Flex my={2} maxW="100%">
          <TemplateFilters
            allData={templates}
            setData={setFilteredTemplates}
            openTemplatePreview={openTemplatePreview}
            onCreateUsingAI={onCreateUsingAI}
            allTemplatesFetched={allTemplatesFetched}
          />
        </Flex>
        {templatesLoading ? (
          <Flex maxW="90%" ml="-2.2%">
            <LoadingSkeleton />
          </Flex>
        ) : (
          // w={{ base: '100%', lg: 'min(100%, 960px)' }}
          <Flex direction="row" w="100%" mt="4" mb={10} gap={6} flexWrap="wrap">
            {filteredTemplates?.map((template, index) => {
              return (
                <TemplateBox
                  template={template}
                  key={`${index}-${template.id}`}
                  openTemplatePreview={openTemplatePreview}
                />
              );
            })}
          </Flex>
        )}
        <TemplatePreviewModal
          closeTemplatePreview={closeTemplatePreview}
          isTemplatePreviewOpen={isTemplatePreviewOpen}
          previewTemplate={previewTemplate}
          onCloneTemplateClicked={onCloneTemplateClicked}
        />
      </Box>
    </Flex>
  );
};

export default CloneTemplatesSection;
