import React, { useRef } from 'react'

const ImagePreview = ({ base64String }) => {
    const imgRef = useRef()

    return (
        <img
            style={{ maxWidth: '100%', width: 'fit-content' }}
            ref={imgRef}
            src={base64String}
        />
    )
}

export default ImagePreview
