import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { Avatar } from '@chakra-ui/avatar';
import { HTMLRenderer } from './HTMLRenderer';
import { QuestionStatus, Options } from './common-components';
import { assignmentPages } from '../../../constants';
import FIBResultForStudent from './FibResultForStudent';
import Stats from './Stats';

export function ResultsByStudents({ studentSubmissions, activeSub }) {
  return studentSubmissions.length ? (
    studentSubmissions.map((sub) => (
      <Flex direction="column" id={sub._id} display={sub._id == activeSub ? 'flex' : 'none'} key={sub._id}>
        <Flex direction="row" px="15px" borderBottom="1px" borderColor="#DDDFEC">
          <Flex flex="1" alignItems="center" fontSize="15px" flexDirection="row" gap="4" p="4">
            <Avatar
              referrerPolicy="no-referrer"
              src={sub.studentinfo?.photoURL}
              name={sub.studentinfo?.displayName}
              size="sm"
            />
            <Text>{sub.studentinfo?.displayName}</Text>
          </Flex>
          {(sub.status == 'COMPLETED' || sub.status == 'STARTED') && (
            <Stats
              correct={sub?.result.correct}
              partial={sub?.result.partial}
              incorrect={sub?.result.incorrect}
              unanswered={sub?.result.unanswered}
            />
          )}
        </Flex>
        {sub?.assignment?.questions.map((question, id) => {
          const studentResponse = sub.studentResponse.find((s) => s.questionId == question.id);
          return (
            <Box
              display="flex"
              flexDirection="column"
              position="relative"
              flexGrow="1"
              boxShadow="xl"
              border="1px"
              borderColor="#DDDFEC"
              className="katex-question"
              m="15px"
              key={question.id}
            >
              <Flex
                borderBottom="1px"
                px="18px"
                py="11px"
                borderColor="#DDDFEC"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex gap="4" alignItems="center">
                  <Text color={'#686868'} fontSize="18px" fontWeight="700">
                    Question {id + 1}
                  </Text>
                  <Text>
                    <QuestionStatus question={question} studentResponse={studentResponse} />
                  </Text>
                </Flex>
                <Text color={'#888888'} fontSize="14px" fontWeight="700">
                  {question.type.toUpperCase()}
                </Text>
              </Flex>
              {question.type === assignmentPages.FIB.key && (
                <FIBResultForStudent question={question} studentResponse={studentResponse} />
              )}
              {question.type === assignmentPages.MCQ.key && (
                <Flex px="18px" py="11px" flexDir={'column'}>
                  <Flex my={2} w="95%" flexDir={'column'}>
                    <Text fontSize="lg">
                      {question?.elements.map((e) => (
                        <HTMLRenderer element={e} key={e.id} />
                      ))}
                    </Text>
                  </Flex>
                  <Options options={question?.options} studentResponse={studentResponse} />
                </Flex>
              )}
            </Box>
          );
        })}
      </Flex>
    ))
  ) : (
    <></>
  );
}
