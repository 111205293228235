import React, { useCallback, useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactSortable } from 'react-sortablejs';
import { pick, startCase } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import CanvasContext from '../../core/Providers/canvas-provider';
import Wrapper from '../wrapper';
import { getCanvasElement } from '../../canvas-utils';
import AddContentBlock from './add-content-block';
import produce from 'immer';
import map from 'lodash/map';
import { compareListOrder } from '../../../utils';
import { isMobile } from 'react-device-detect';

export default function GenericElementContainer({
  name,
  setState: setParentElements,
  textPlaceholder = 'You can start typing here',
  value
}) {
  const canvasID = useMemo(() => `canvas-${uuidV4()}`, []);

  const setElements = useCallback(
    (elemVals) => {
      if (typeof elemVals === 'function') {
        setParentElements(elemVals(value));
      } else {
        setParentElements(elemVals);
      }
    },
    [setParentElements, value]
  );

  const setList = useCallback(
    (items) => {
      const stateItems = items.map((item) => {
        const itemState = {
          type: item.type,
          id: item.id || uuidV4(),
          ...pick(item, ['filtered', 'value']) // Props necessary for react sortable to function
        };
        return itemState;
      });

      const isStateEqual = compareListOrder(stateItems, value);
      // Only if this is an actual update, else we will update redux & assignment on server
      if (!isStateEqual) {
        setElements(stateItems);
      }
    },
    [setElements, value]
  );

  const onDelete = useCallback(
    (id) => {
      if (!id) return;
      setElements((state) => {
        return state.filter((elem) => elem?.id !== id);
      });
    },
    [setElements]
  );

  const onContentBlockInsert = useCallback(
    (contentType) => {
      setElements((state) => {
        return [...state, { type: contentType, id: uuidV4() }];
      });
    },
    [setElements]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateElementValue = useCallback(
    (elementID, value) => {
      setElements(
        produce((draftState) => {
          const elem = draftState.find((elem) => elem.id === elementID);
          if (elem) elem.value = value;
        })
      );
    },
    [setElements]
  );

  return (
    <CanvasContext.Provider value={{ canvasID }}>
      <Flex
        flex={1}
        borderColor={'#A1ADCD'}
        position="relative"
        borderRadius={5}
        borderWidth={1}
        px={2}
        width={{ base: '100%', lg: 'unset' }}
      >
        <Text
          p={1}
          style={{
            position: 'absolute',
            top: -15,
            fontWeight: 800,
            backgroundColor: '#fff'
          }}
        >
          {name.replace(/\w+/g, startCase)}
        </Text>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          height="100%"
          flexGrow="1"
          mb={2}
          id={canvasID}
        >
          <ReactSortable
            style={{ flexGrow: '1' }}
            group={{ name: `${name}-canvas-elements` }}
            list={map(value, (el) => ({ ...el, chosen: true }))}
            setList={setList}
            animation={200}
            delay={2}
            handle=".drag-handle"
          >
            {value &&
              value.map((elem) => {
                const Component = getCanvasElement(elem?.type);
                return (
                  <Wrapper key={elem?.id} onDelete={onDelete.bind(null, elem?.id)}>
                    <Component
                      elementID={elem?.id}
                      onUpdate={updateElementValue}
                      textPlaceholder={textPlaceholder}
                      value={elem.value}
                    />
                  </Wrapper>
                );
              })}
          </ReactSortable>
        </Box>
        {!isMobile && <AddContentBlock onContentBlockInsert={onContentBlockInsert} />}
      </Flex>
    </CanvasContext.Provider>
  );
}
