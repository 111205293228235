import { isMobile } from 'react-device-detect';

export const STEPS = Object.freeze({
  START_ONBOARDING: 'START_ONBOARDING',
  WELCOME_TEXT: 'WELCOME_TEXT',
  QUESTION_CREATE: 'QUESTION_CREATE',
  ASSIGNMENT_SETTINGS: 'ASSIGNMENT_SETTINGS',
  SHARE_MODAL: 'SHARE_MODAL',
  RESULTS_PAGE: 'RESULTS_PAGE',
  REVIEW_QUESTIONS: 'REVIEW_QUESTIONS',
  QUESTION_SETTINGS_AND_SCORES: 'QUESTION_SETTINGS_AND_SCORES'
});

export const DESKTOP_STEP_ORDER = [
  STEPS.START_ONBOARDING,
  STEPS.WELCOME_TEXT,
  STEPS.REVIEW_QUESTIONS,
  STEPS.QUESTION_SETTINGS_AND_SCORES,
  STEPS.QUESTION_CREATE,
  STEPS.ASSIGNMENT_SETTINGS,
  STEPS.SHARE_MODAL,
  STEPS.RESULTS_PAGE
];

export const MOBILE_STEP_ORDER = [
  STEPS.START_ONBOARDING,
  STEPS.QUESTION_CREATE,
  STEPS.ASSIGNMENT_SETTINGS,
  // STEPS.SHARE_MODAL,
  STEPS.RESULTS_PAGE
];

const STEP_DATA = Object.freeze({
  // Wrapping some of these in a function as querySelector needs to be triggered at runtime
  [STEPS.START_ONBOARDING]: {
    target: '#assignment-tour-cta',
    title: 'Your First Step',
    content: 'Begin your journey and learn how to create assignments using Classwise.',
    disableBeacon: true,
    locale: { next: 'Start the tour' },
    showSkipButton: true
  },
  [STEPS.WELCOME_TEXT]: () => ({
    // It is the first element added in the welcome page, & so automatically highlighted or if not available, the welcome title
    target: '.Welcome_page_heading',
    title: 'Welcome Page',
    content:
      'Personalize Your Introduction - Welcome students with text, images, or videos to set the tone for your assignment.',
    disableBeacon: true
  }),
  [STEPS.QUESTION_CREATE]: () => ({
    target: '.add_question_popover',
    title: 'Add Questions',
    content:
      'Click the "+" icon to add questions your way: from your own content, a vast library, or let our AI generate them.',
    disableBeacon: true,
    placement: isMobile ? 'bottom' : 'right'
  }),
  [STEPS.ASSIGNMENT_SETTINGS]: {
    target: '#assignment-settings-container',
    title: 'Advance Settings',
    content:
      "Tailor Assignments to Perfection - Tweak dates, set timers, and explore advanced options to fine-tune your assignment's specifics.",
    disableBeacon: true
  },
  [STEPS.SHARE_MODAL]: {
    target: '.share-modal-popover',
    title: 'Share with Students',
    content:
      'Effortless Assignment Distribution - Share assignments as PDFs, links, through Google Classroom, or even with a quick QR code.',
    disableBeacon: true
  },
  [STEPS.RESULTS_PAGE]: {
    target: '[data-step-link="results-step-link"]',
    title: 'Results',
    content:
      'Track Progress with Precision - Dive into student performance with class-level and detailed individual insights.',
    disableBeacon: true
  },
  [STEPS.REVIEW_QUESTIONS]: () => ({
    target: '.left-summary-sidebar',
    title: 'Explore Your Questions',
    content: "Check out each question from the summary section to make sure they're just right.",
    disableBeacon: true,
    placement: 'right-start'
  }),
  [STEPS.QUESTION_SETTINGS_AND_SCORES]: () => ({
    target: '.right-sidebar',
    title: 'Fine-Tune Your Assignment',
    content:
      'Adjust scores, individual question settings, or delete questions in the settings section to meet your needs.',
    disableBeacon: true,
    placement: 'left-start'
  })
});

const stepEval = (step_key) => {
  return typeof STEP_DATA[step_key] === 'function' ? STEP_DATA[step_key]() : STEP_DATA[step_key];
};

export const getSteps = () => {
  if (isMobile) {
    return MOBILE_STEP_ORDER.map((step_key) => stepEval(step_key));
  } else {
    return DESKTOP_STEP_ORDER.map((step_key) => stepEval(step_key));
  }
};

export function getCurrentTourStep(stepIndex) {
  return isMobile ? MOBILE_STEP_ORDER[stepIndex] : DESKTOP_STEP_ORDER[stepIndex];
}
