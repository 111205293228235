import React from 'react';
import ImagePreview from './image/img-preview';
import UneditableHtml from './uneditable-html';
import { startCase } from 'lodash';
import VideoURLQR from './video-qr-preview';
import Qrious from 'qrious';
import TablePreview from './table-preview';

/*
    Util functions & components to get PDF printable html as we don't have access to chakra ui css
    This is unavailable via CDN too :(
*/
export const getHtml = (type, value, key) => {
  if (!value) {
    return <></>;
  }
  if (type === 'video') {
    const videoURL = getVideoURLQR(value);
    return <VideoURLQR key={key} value={videoURL} />;
  }
  if (type === 'image') {
    return <ImagePreview key={key} base64String={value} />;
  }
  if (type === 'table') {
    return <TablePreview key={key} value={value} />;
  }

  // Default & for type "textbox"
  return <UneditableHtml key={key} value={value} />;
};

export const TextHeader = ({ text, style, ...rest }) => {
  return (
    <p
      style={{
        fontStyle: 'italic',
        fontWeight: 800,
        fontSize: '16px',
        marginBottom: '3px',
        ...(style || {})
      }}
      {...rest}
    >
      {text.replace(/\w+/g, startCase)}
    </p>
  );
};

export const TextDivider = ({ text, textBg = 'white', style, ...rest }) => {
  return (
    <div
      style={{
        position: 'relative',
        paddingTop: '5px',
        paddingBottom: '5px',
        width: '100%',
        ...(style || {})
      }}
      {...rest}
    >
      <PureHDivider text={text} textBg={textBg} />
    </div>
  );
};

export const Column = React.forwardRef(({ children, style, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        overflowY: 'auto',
        ...(style || {})
      }}
      {...rest}
    >
      {children}
    </div>
  );
});

Column.displayName = 'Column';

export const Row = React.forwardRef(({ children, style, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        ...(style || {})
      }}
      {...rest}
    >
      {children}
    </div>
  );
});

Row.displayName = 'Row';

export const PureHDivider = ({ text }) => {
  return <div className="separator">{text && <TextHeader text={text} style={{ marginBottom: '0px' }} />}</div>;
};

export const getVideoURLQR = (value) => {
  var parser = new DOMParser();

  var parsedIframe = parser.parseFromString(value, 'text/html');
  let iFrame = parsedIframe.getElementsByTagName('iframe');
  let src = iFrame?.[0]?.src;

  if (!src) return '';

  var qr = new Qrious({
    value: src,
    size: 200
  });

  return qr.toDataURL('image/svg');
};

// Just returns a pretty date like "Tuesday, Oct 10, 2023"
export const getPrettyDate = (date) => {
  return new Date(date).toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

export const getPDFTitleElement = (key, value) => {
  const style = { fontWeight: '500', display: 'inline' };
  return (
    <p
      style={{
        fontSize: '16px',
        marginBottom: '3px'
      }}
    >
      <TextHeader style={style} text={`${key}`} />
      :&nbsp;
      <TextHeader style={style} text={`${value}`} />
    </p>
  );
};

