import { Flex } from '@chakra-ui/react';
import React from 'react';
import UneditableHtml from '../uneditable-html';
import { Column, TextHeader, getHtml } from '../utils';

export function StaticContentWrap({ type, contentArray }) {
  return (
    <Flex direction="column" py={4}>
      {typeof type === 'string' ? <TextHeader text={type} /> : <>{type}</>}
      <Flex direction="column" pl={2} bg={'#fafafa'}>
        {contentArray.map((q) => (
          <UneditableHtml key={q.id} elementID={q.id} value={q.value || q.html} />
        ))}
      </Flex>
    </Flex>
  );
}

export function PureStaticContentWrap({ type, contentArray }) {
  return (
    <Column style={{ padding: '10px 0px' }}>
      {typeof type === 'string' ? <TextHeader text={type} /> : <>{type}</>}
      <Column style={{ paddingLeft: '10px', backgroundColor: '#fafafa' }}>
        {contentArray.map((q) => getHtml(q.type, q.value || q.html, `${q.id}`))}
      </Column>
    </Column>
  );
}
