import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { omit, isEqual, identity } from 'lodash';
import { assignmentPages } from '../../../../constants';
import { getTotalScore } from '../../../../utils';

const DEFAULT_EMPTY = Object.freeze({});

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// TODO: Still not returning the same object when only active page has changed
const basicAssignmentDataSelector = createSelector(
  (store) => store.assignment,
  (assignment) => {
    return omit(assignment, ['pages', 'settings', 'activePage', 'libraryConfig']);
  }
);

export const getBasicAssignmentData = createDeepEqualSelector(
  basicAssignmentDataSelector,
  identity
);

export const getPages = (store) => store.assignment.pages;
export const getElements = (store) => store.elements;
export const getPage = (pageID) => (store) => store.assignment.pages[pageID];
export const getWelcomePage = createSelector([getPages], (pages) => {
  const welcomePage = Object.values(pages).find((page) => {
    page?.type === assignmentPages.WELCOME.displayName;
  });

  return welcomePage;
});

export const getTotalScoreSelector = createSelector([getPages], (pages) => {
  return getTotalScore(pages);
});

export const getPagesLength = createSelector([getPages], (pages) => {
  return Object.values(pages).length;
});

export const getPageOrder = createDeepEqualSelector(getPages, (pages) => {
  const pageIds = Object.keys(pages).map((pageID) => ({
    id: pageID
  }));
  pageIds.sort((a, b) => {
    return pages[a.id].pageIndex - pages[b.id].pageIndex;
  });
  return pageIds;
});

export const getPageElements = (pageID) => (store) => get(store, `elements.${pageID}.elements`, {});
export const getPageElementOrder = (pageID) => (store) =>
  get(store, `elements.${pageID}.elementOrder`, []);
export const getActivePageID = (store) => store.assignment.activePage;

export const getActivePage = createSelector(getActivePageID, getPages, (activePageID, pages) => {
  return pages[activePageID];
});

export const getActivePageModalOpen = (store) => store.assignment.activePageModalOpen;

export const getAssignmentID = (store) => store.assignment.assignmentID;

// Using create selector so it does not return a new empty object always
const libraryConfig = (store) => get(store, 'assignment.libraryConfig') || DEFAULT_EMPTY;
export const getLibraryDialogConfig = createSelector(libraryConfig, (config) => config);

export const getActiveAssignmentPageID = (store) => store.assignment.activePage;
export const getActiveAssignmentPage = createSelector(
  getPages,
  getActiveAssignmentPageID,
  (pages, pageID) => pages[pageID]
);

export const getAssignmentData = (store) => store.assignment;
export const getElementData = (store) => store.elements;

export const getAssignmentDataSettings = (store) => store.assignment.settings;

export const getAssignmentUpdateState = (store) => store.updateAssignmentState;
export const getAssignmentSettingModalStatus = (store) => store.assignmentSetting;

export const isAssignmentFetched = createSelector(
  getAssignmentUpdateState,
  (state) => state.initialFetching
);

export const getOnboardingState = (store) => store.onboardingTour;

export const getWelcomePageTextPlaceholder = (store) =>
  store.assignmentSetting.welcomePageTextPlaceholder;

export const getAssignmentMetadata = (store) => store.assignmentMetadata;

export const getAIQuestionsModalState = (store) => store.aiQuestionsModalState;
