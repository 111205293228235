import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPageElementOrder, getPageElements } from '../../redux/selectors';
import { Text, Tooltip } from '@chakra-ui/react';
import parse from 'html-react-parser';

const WelcomePreview = ({ pageID }) => {
  const pageElements = useSelector(getPageElements(pageID));
  const pageElementOrder = useSelector(getPageElementOrder(pageID));

  const previewText = useMemo(() => {
    const firstTextElementID = pageElementOrder?.find(({ id }) => {
      return pageElements[id]?.type === 'textbox';
    });

    if (!firstTextElementID) return '';

    return pageElements[firstTextElementID.id].value;
  }, [pageElementOrder, pageElements]);

  if (previewText) {
    return (
      <Tooltip
        placement="right"
        portalProps="containerRef"
        hasArrow
        label={<span className="preview-tooltip">{previewText && parse(previewText)}</span>}
      >
        <Text
          _focus={{
            bg: 'transparent'
          }}
          _active={{
            bg: 'transparent'
          }}
          fontSize={'10px'}
          w={{ sm: 'auto', lg: 120 }}
          alignItems="start"
          borderRadius={0}
          shadow="sm"
          overflow="hidden"
          listStylePosition={'inside'}
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            overflowWrap: 'break-word'
          }}
        >
          {previewText && parse(previewText)}
        </Text>
      </Tooltip>
    );
  }

  return <></>;
};

export default WelcomePreview;
