import { Flex } from '@chakra-ui/react';
import parse from 'html-react-parser';
import React, { useMemo } from 'react';
import { getFIBQuestionStats } from './utils';
import FIBBlankReview from './FibBlankReview';
import useParserOptions from './useParserOptions';

const FIBResultForStudent = ({ question, studentResponse }) => {
  const { elements } = question;

  const fibText = elements?.[0]?.html;

  const fibStats = useMemo(() => getFIBQuestionStats(question, studentResponse), [question, studentResponse]);

  const parseReplaceOptions = useParserOptions({ BlankReviewComponent: FIBBlankReview, blankProps: fibStats.reviews });

  const parsedHtmlContent = useMemo(() => {
    return parse(fibText, parseReplaceOptions);
  }, [fibText, parseReplaceOptions]);

  return (
    <Flex my="2" w="100%" fontSize="lg" pl={3}>
      {parsedHtmlContent}
    </Flex>
  );
};

export default FIBResultForStudent;
