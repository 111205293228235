import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { PieChart } from 'react-minimal-pie-chart';

const BigPictureResults = ({ assignmentStatuses }) => {
  return (
    <Box borderBottom="1px" borderColor="#DDDFEC" width="100%">
      <Text fontSize="30px" fontWeight="700" ml="30px" mt="25px">
        Big Picture
      </Text>
      <Flex direction="row" ml="30px" my="25px" gap="30px">
        <Flex direction="column" alignItems="center">
          <PieChart
            data={[
              {
                value: assignmentStatuses.yet_to_start,
                color: '#FF9786'
              },
              { value: assignmentStatuses.started, color: '#F2F2F2' },
              {
                value: assignmentStatuses.submitted,
                color: '#F2F2F2'
              }
            ]}
            lineWidth={24}
            label={() => assignmentStatuses.yet_to_start}
            labelStyle={{
              fontSize: '18px',
              fill: '#333333',
              fontStyle: 'italic'
            }}
            labelPosition={0}
            style={{ height: '60px' }}
          />
          <Text fontSize="12px" fontWeight="300" color="#333333" fontStyle="italic" mt="10px">
            Yet to Start
          </Text>
        </Flex>
        <Flex direction="column" alignItems="center">
          <PieChart
            data={[
              {
                value: assignmentStatuses.yet_to_start,
                color: '#F2F2F2'
              },
              { value: assignmentStatuses.started, color: '#89A6FF' },
              {
                value: assignmentStatuses.submitted,
                color: '#F2F2F2'
              }
            ]}
            lineWidth={20}
            label={() => assignmentStatuses.started}
            labelStyle={{
              fontSize: '18px',
              fill: '#333333',
              fontStyle: 'italic'
            }}
            labelPosition={0}
            style={{ height: '60px' }}
          />
          <Text fontSize="12px" fontWeight="300" color="#333333" fontStyle="italic" mt="10px">
            Started
          </Text>
        </Flex>
        <Flex direction="column" alignItems="center">
          <PieChart
            data={[
              {
                value: assignmentStatuses.yet_to_start,
                color: '#F2F2F2'
              },
              { value: assignmentStatuses.started, color: '#F2F2F2' },
              {
                value: assignmentStatuses.submitted,
                color: '#389411'
              }
            ]}
            lineWidth={20}
            label={() => assignmentStatuses.submitted}
            labelStyle={{
              fontSize: '18px',
              fill: '#333333',
              fontStyle: 'italic'
            }}
            labelPosition={0}
            style={{ height: '60px' }}
          />
          <Text fontSize="12px" fontWeight="300" color="#333333" fontStyle="italic" mt="10px">
            Submitted
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default BigPictureResults;
