import { Flex, Text } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import LibraryStaticOption from './option'

const LibraryOptionsContainer = ({ options, setOptions }) => {
    const canDelete = useMemo(() => options.length > 2, [options.length])

    // Delete an option
    const onDeleteOption = useCallback(
        (id) => {
            if (!canDelete) return
            setOptions((state) => {
                return state.filter((option) => option.id !== id)
            })
        },
        [canDelete, setOptions]
    )

    return (
        <Flex direction="column" py={4}>
            <Text
                fontSize="xl"
                style={{
                    fontStyle: 'italic',
                    fontWeight: 800,
                    backgroundColor: '#fff',
                }}
            >
                {`Options`}
            </Text>
            {options.map((opt) => (
                <LibraryStaticOption
                    key={opt.id}
                    onDeleteOption={onDeleteOption}
                    option={opt}
                    canDelete={canDelete}
                />
            ))}
        </Flex>
    )
}

export default LibraryOptionsContainer
