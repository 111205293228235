import { appConfig } from "../constants"

export const requestOptions = (accessToken, method = 'GET') => {
    return {
        method: `${method}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + `${accessToken}`,
        },
    }
}

export const CLASSWISE_API = {
    ASSIGNMENTS: '/assignments',
    USER_ROLE: '/users/role',
    USER_GOOGLE_TOKEN: '/users/getGoogleToken',
    GOOGLE_AUTH: '/googleAuth',
    GET_USERS: '/users/getUsers',
    STUDENT_ASSIGNMENTS_BY_TEACHER: '/student-assignments/getStudentAssignmentsByTeacher',
    STUDENT_ASSIGNMENTS_BY_ASSIGNMENT: '/student-assignments/getStudentAssignmentsByAssignment',
    EVENT_LOGGER: '/event-logger'
}

export const getClasswiseAPIURL = (api) => {
    const url= appConfig.getAPIURL(`${api}`);
    return url;
}

export const SUBSCRIPTION_PLANS = {
  FREE: 'FREE',
  PRO: 'PRO'
};
