import {
    Box,
    Heading,
    HStack,
    NumberInput,
    NumberInputField,
    Text,
    VStack,
} from '@chakra-ui/react'
import React from 'react'
import Settings from '../shared/settings'
import SwitchButton from '../utils/switch-button'
import { useDispatch, useSelector } from 'react-redux'
import { getPage } from '../../../redux/selectors'

export default function McqPage({ pageID, pageName }) {
    const mcqPage = useSelector(getPage(pageID))
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (!mcqPage.score || isNaN(mcqPage.score) || Number(mcqPage.score) < 0) {
            // Dispatch with 0 value
            dispatch({
                type: 'UPDATE_PAGE_SETTING',
                payload: {
                    pageID,
                    score: 1,
                },
            })
        }
    }, [dispatch, mcqPage.score, pageID])

    const onChange = React.useCallback(
        (key, value) => {
            dispatch({
                type: 'UPDATE_PAGE_SETTING',
                payload: {
                    pageID,
                    [`settings.${key}`]: value,
                },
            })
        },
        [dispatch, pageID]
    )

    const onScoreChange = (_, value) => {
        if (!value || isNaN(value) || Number(value) < 0) {
            // Dispatch with 0 value
            dispatch({
                type: 'UPDATE_PAGE_SETTING',
                payload: {
                    pageID,
                    score: undefined,
                },
            })
            return
        }

        dispatch({
            type: 'UPDATE_PAGE_SETTING',
            payload: {
                pageID,
                score: Number(value),
            },
        })
    }

    return (
        <>
            <Settings pageID={pageID} pageName={pageName} />
            <Box mb={5} p={3}>
                <Text
                    fontSize="12px"
                    color="gray"
                    fontWeight="normal"
                    right={4}
                    opacity={0.9}
                >
                    Score
                </Text>
                <HStack spacing={0}>
                    <NumberInput
                        px={1}
                        value={mcqPage.score}
                        type="number"
                        onChange={onScoreChange}
                    >
                        <NumberInputField
                            borderColor="purple.400"
                            color="purple.400"
                            borderLeft={0}
                            borderTop={0}
                            borderRight={0}
                            borderRadius={0}
                        />
                    </NumberInput>
                    <Text
                        fontSize="14px"
                        color="purple.400"
                        borderBottom={'1px solid'}
                        fontWeight="normal"
                        py={'9px'}
                        pr={3}
                        opacity={0.9}
                    >
                        Points
                    </Text>
                </HStack>
            </Box>
            <Box px={3} py={3}>
                <hr />
                <Heading
                    as="h5"
                    px={3}
                    mt={6}
                    mb={2}
                    position="relative"
                    fontWeight={700}
                    color="#686868"
                    size="md"
                    noOfLines={1}
                >
                    MCQ Settings
                </Heading>
                <Box p={3}>
                    <VStack spacing={3}>
                        <SwitchButton
                            isActive={mcqPage?.settings?.randomizeOptions}
                            label="Randomise Options"
                            datakey="randomizeOptions"
                            onChange={onChange}
                        />
                    </VStack>
                </Box>
            </Box>
        </>
    )
}
