import {
  Box,
  Flex,
  HStack,
  Spinner,
  Text,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { LibraryContext } from '../library-context';
import { questionLevel } from '../../utils';
import { AiOutlineClose } from 'react-icons/ai';

function QuestionLevel({ level }) {
  const qusLevel = questionLevel[level];
  return (
    <Text fontSize="sm" mx={2} bg={qusLevel.bgColor} px={3} color={'#fff'}>
      {qusLevel?.levelType}
    </Text>
  );
}

function QuestionOptions({ option }) {
  return (
    <Flex mb={5} alignItems="center">
      <Box
        w={6}
        height={6}
        bgColor={option.is_correct ? '#5D38DB' : '#fff'}
        border="1px"
        borderColor="#5D38DB"
        borderRadius={'30px'}
      />
      <Box ml={3} border="1px" borderColor="gray.300" minW={'300px'} p={2}>
        {parse(option?.option_content_html || '')}
      </Box>
    </Flex>
  );
}

function Question({ question, level, options, solution }) {
  return (
    <Box className="katex-question singleQuestion" h={'90vh'} w={'100%'} overflowY="auto">
      <HStack
        pb={5}
        borderBottom="1px"
        overflowY="auto"
        borderColor="gray.300"
        w={'100%'}
        justifyContent={'space-between'}
        alignItems="flex-start"
        bg={'#fff'}
        position="sticky"
        top={0}
        zIndex={99}
      >
        <Flex alignItems={'center'} width={'70%'}>
          {parse(question || '')}
        </Flex>
        <Flex>
          <Text color="#6364D8" fontWeight={'bold'}>
            Difficulty Level:
          </Text>
          <QuestionLevel level={level} />
        </Flex>
      </HStack>
      <VStack pb={5} mt={5} justifyContent="flex-start">
        <Text w={'100%'} color={'gray.500'} pb={3}>
          Options:
        </Text>
        <Box w={'100%'} justifyContent="flex-start">
          {options &&
            options.length > 0 &&
            options.map((option) => <QuestionOptions key={option._id} option={option} />)}
        </Box>
      </VStack>
      <VStack py={5} borderTop="1px" borderColor="gray.300" justifyContent="flex-start">
        <Text w={'100%'} color={'gray.500'} pb={3}>
          Soultion:
        </Text>
        <Box w={'100%'}>{parse(solution) || ''}</Box>
      </VStack>
    </Box>
  );
}

function SingleQuestion() {
  const { query } = useContext(LibraryContext);
  return (
    <Box
      justifyContent="space-between"
      w={'100%'}
      borderTop="1px"
      borderColor="gray.300"
      py={5}
      mb={'56px'}
    >
      {query?.selectedSingleQuestion ? (
        <Question
          question={query?.selectedSingleQuestion?.question_content_html}
          level={query?.selectedSingleQuestion?.level}
          options={query?.selectedSingleQuestion?.options}
          solution={query?.selectedSingleQuestion?.solution_content_html}
        />
      ) : (
        <Flex justify="center" alignItems="center" height={'90vh'}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      )}
    </Box>
  );
}

function MultipleQuestions() {
  const { query, onQuestionRemove } = useContext(LibraryContext);
  const [tabIndex, setTabIndex] = React.useState(0);

  const onTabChange = React.useCallback((tabIndex) => {
    setTabIndex(tabIndex);
  }, []);

  React.useEffect(() => {
    setTabIndex(query.selectedMultipleQuestions.length - 1);
  }, [query.selectedMultipleQuestions]);

  return (
    <Box
      justifyContent="space-between"
      borderTop="1px"
      borderColor="gray.300"
      py={5}
      overflow="hidden"
      width="100%"
      px={{ base: 2, lg: 6 }}
      mb={'56px'}
    >
      {query?.selectedMultipleQuestions.length > 0 ? (
        <Tabs
          variant="soft-rounded"
          defaultIndex={tabIndex}
          index={tabIndex}
          onChange={(index) => onTabChange(index)}
          size="sm"
        >
          <TabList overflow="auto">
            {query?.selectedMultipleQuestions.map((q, idx) => {
              return (
                <Tab key={q._id} _selected={{ color: 'white', bg: '#5D38DB' }}>
                  <span style={{ whiteSpace: 'nowrap' }}>{`Question ${idx + 1}`}</span>
                  <Box ml={3} onClick={() => onQuestionRemove(q._id)}>
                    <AiOutlineClose color="gray" size={15} />
                  </Box>
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator display="none" />
          <TabPanels w="100%">
            {query?.selectedMultipleQuestions.map((question) => {
              return (
                <TabPanel w="100%" px={0} key={question._id}>
                  <Question
                    question={question.question_content_html}
                    level={question?.level}
                    options={question?.options}
                    solution={question?.solution_content_html}
                  />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      ) : (
        <Flex justify="center" alignItems="center" height="90vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      )}
    </Box>
  );
}

export { SingleQuestion, MultipleQuestions };
