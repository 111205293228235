import React, { useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssignmentMetadata,
  getAssignmentSettingModalStatus,
  getOnboardingState,
  getPageOrder,
  isAssignmentFetched
} from '../../canvas/redux/selectors';
import { DESKTOP_STEP_ORDER, STEPS, getCurrentTourStep, getSteps } from './joyride-tour-utils';
import usePrevious from '../../canvas/shared/hooks/use-previous';
import { isMobile } from 'react-device-detect';
import useCommonDispatchFunctions from './use-common-dispatch-fns';
import useEventLogger from '../use-event-logger';
import useAiModalState from '../../canvas/sidebars/left-sidebar/use-ai-modal-state';
import { useAiRequestStatus } from '../../canvas/shared/hooks';
import { CREATE_TYPES } from '../../../../constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const JoyrideContainer = () => {
  const { tourMode, running, stepIndex, createTrigger } = useSelector(getOnboardingState);
  const dispatch = useDispatch();
  const [steps, setSteps] = useState();

  const routerParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // Selectors containing data that tell us which other modals are open or if assignment is ready
  const { showAssignmentSetting } = useSelector(getAssignmentSettingModalStatus);
  const isFetching = useSelector(isAssignmentFetched);
  const { isAiQuestionsModalOpen } = useAiModalState();
  const { isAiRequestLoading, isCreatingAiRequest } = useAiRequestStatus();
  const { createType } = useSelector(getAssignmentMetadata);

  const prevStepIndex = usePrevious(stepIndex);

  const eventLoggerHook = useEventLogger();

  const { stopTour, setRunning, setStepIndex, startTour, resetCreateMode, goToPage } =
    useCommonDispatchFunctions();

  const pageOrder = useSelector(getPageOrder);
  const welcomePage = pageOrder[0].id;
  const firstMcqPage = useMemo(() => {
    if (pageOrder.length > 2) {
      return pageOrder[1].id;
    }

    return null;
  }, [pageOrder]);

  const currentPage = useMemo(() => {
    return location.pathname.substring(location.pathname.lastIndexOf('/'));
  }, [location.pathname]);

  const prevPage = usePrevious(currentPage);

  const assignmentID = routerParams.assignmentID;

  const gotoCreate = () => {
    navigate(`/assignments/${assignmentID}/create`);
  };

  const gotoResults = () => {
    navigate(`/assignments/${assignmentID}/results`);
  };

  const clickQuestionDialog = () => {
    document.querySelector('.add-btn-0')?.click();
  };

  const toggleShareModal = () => {
    document.querySelector('.share-modal-trigger').click();
  };

  const callback = (data) => {
    const { action, index, status, type } = data;

    const currentStep = getCurrentTourStep(index);

    /*
    const docs: https://github.com/gilbarbara/react-joyride/blob/main/src/literals/index.ts
      Tour state changes
      1. Tour start via event start or actions start
      2. Tour close via status finished / skipped, or action close
      3. Tour step ahead or step back via event = step after or event = step before & status is running or ready
      Observations:
      1. START = action: start & status: waiting -> running
      2. NEXT = action: next, type: step:after, status: running
      3. PREV = action: prev, type: step:after, status: running
      4. CLOSE = action: close, type: step:after, status: running
      5. SKIP = action: skip, type: tour:end, status: skipped
      6. FINISH = action: next, type: tour:end, status: finished
    */

    // Start of tour - if we're not already on create, go to create
    if (
      type === EVENTS.TOUR_START ||
      (type === EVENTS.TOUR_STATUS && action === ACTIONS.START && stepIndex === 0)
    ) {
      if (currentPage !== '/create') {
        setRunning(false);
        gotoCreate();
      }

      // For mobile, click on the question popover trigger to open it - this condition should only happen once & if we're on the create page
      if (
        currentPage === '/create' &&
        isMobile &&
        currentStep === STEPS.QUESTION_CREATE &&
        status === STATUS.RUNNING
      ) {
        clickQuestionDialog();
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
      // When status is finished or skipped, reset tour & go back to create
      // Need to set our running state to false, so we can restart if we click start again.
      stopTour();

      dispatch({
        type: 'UPDATE_TOUR_STATUSES',
        payload: { lastAction: action, lastStatus: status }
      });

      // Go back to the create page
      gotoCreate();

      // Set the welcome page as selected
      goToPage(welcomePage);
    } else if (
      [EVENTS.STEP_AFTER].includes(type) &&
      [STATUS.RUNNING, STATUS.READY].includes(status)
    ) {
      // If the event is a step +/-
      let nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      const nextStep = getCurrentTourStep(nextStepIndex);

      // If the step is within create but the previous was outside of it, travel to the create page
      if (
        nextStep === STEPS.ASSIGNMENT_SETTINGS &&
        currentStep === STEPS.RESULTS_PAGE &&
        action === ACTIONS.PREV
      ) {
        setRunning(false);
        gotoCreate();
      } else if (
        nextStep === STEPS.SHARE_MODAL &&
        currentStep === STEPS.RESULTS_PAGE &&
        action === ACTIONS.PREV
      ) {
        setRunning(false);
        gotoCreate();

        toggleShareModal();
      } else if (currentStep === STEPS.WELCOME_TEXT && nextStep === STEPS.REVIEW_QUESTIONS) {
        // Go to the first question if available, else directly go to the create question step
        if (firstMcqPage) {
          goToPage(firstMcqPage);
        } else {
          // Directly go to the create question step where the dialog is auto opened
          nextStepIndex = DESKTOP_STEP_ORDER.indexOf(STEPS.QUESTION_CREATE);
          clickQuestionDialog();
        }
      } else if (
        currentStep === STEPS.QUESTION_CREATE &&
        nextStep === STEPS.QUESTION_SETTINGS_AND_SCORES &&
        !firstMcqPage
      ) {
        // While going back, go back to the create add question step
        nextStepIndex = DESKTOP_STEP_ORDER.indexOf(STEPS.WELCOME_TEXT);
        clickQuestionDialog();
      } else if (currentStep === STEPS.REVIEW_QUESTIONS && nextStep === STEPS.WELCOME_TEXT) {
        // Go to the welcome page so welcome page text can be highlighted
        goToPage(welcomePage);
      } else if (nextStep === STEPS.QUESTION_CREATE) {
        // Instructions when traveling to the create question step
        clickQuestionDialog();
      } else if (currentStep === STEPS.QUESTION_CREATE && nextStep !== STEPS.QUESTION_CREATE) {
        // If the current step was question create but the next step is not
        clickQuestionDialog();
      } else if (nextStep === STEPS.ASSIGNMENT_SETTINGS && currentStep === STEPS.SHARE_MODAL) {
        // If moving back from the share step, close share modal
        toggleShareModal();
      } else if (nextStep === STEPS.SHARE_MODAL) {
        // If going to the share step, open the share modal via trigger but suspend the tour temporarily
        toggleShareModal();
      } else if (nextStep === STEPS.RESULTS_PAGE) {
        // If going to the results step
        if (!isMobile) {
          toggleShareModal();
        }
        setRunning(false);
        gotoResults();
      }

      // Update the step index in any case
      setStepIndex(nextStepIndex);
    }

    // Analytics handling
    if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP) {
      eventLoggerHook(`onboarding-tour-${action}`, `User action on onboarding tour - ${action}`);
    }
  };

  useEffect(() => {
    // If we're still in the tour mode, & the page was changed, start running the tour
    if (tourMode && currentPage !== prevPage) {
      setRunning(true);
    }
  }, [currentPage, prevPage, setRunning, tourMode]);

  // Set the steps for the onboarding tour when the tour starts, so we can figure out steps dynamically
  useEffect(() => {
    if (tourMode) {
      setSteps(getSteps());
    }
  }, [tourMode]);

  // When the assignment is created, we set createTrigger in redux state. We start onboarding for every created assignment
  /*
    Auto opened only on the following conditions:
      - Create trigger is set - when assignment was created & createType is present
      - Assignment has finished loading - isFetching is false
      - Assignment settings are no longer shown - they're shown once the assignment is created forcing the user to add the assignment name
      - AI question modal isn't open - it's auto opened when the assignment is created through the "Create using AI" option
  */
  useEffect(() => {
    if (
      createTrigger &&
      !isFetching &&
      !showAssignmentSetting &&
      !isAiQuestionsModalOpen &&
      !isAiRequestLoading &&
      !isCreatingAiRequest &&
      createType !== CREATE_TYPES.HOME_LESSON_PLAN &&
      createType !== CREATE_TYPES.HOME_LEARNING_ACTIVITY
    ) {
      startTour();
      resetCreateMode();

      // Log that we started the tour for this user
      eventLoggerHook(
        'onboarding-tour-start-oncreate',
        'Started onboarding tour for the assignment on creation'
      );
    }
  }, [
    createType,
    createTrigger,
    eventLoggerHook,
    isFetching,
    showAssignmentSetting,
    resetCreateMode,
    startTour,
    isAiQuestionsModalOpen,
    isAiRequestLoading,
    isCreatingAiRequest
  ]);

  useEffect(() => {
    // If the step index has changed & has incremented, log the usage
    if (stepIndex > prevStepIndex) {
      eventLoggerHook(
        `onboarding-tour-step-${stepIndex}`,
        'User moved to the next step in the onboarding tour'
      );
    }
  }, [eventLoggerHook, prevStepIndex, stepIndex]);

  return (
    <>
      <Joyride
        steps={steps}
        continuous={true}
        run={running}
        stepIndex={stepIndex}
        callback={callback}
        spotlightClicks={true}
        locale={{
          last: 'Close'
        }}
        styles={{
          options: {
            primaryColor: '#744CC6'
          }
        }}
      />
    </>
  );
};

export default JoyrideContainer;
