import React from 'react';
import { Image } from '@chakra-ui/react';
import parse from 'html-react-parser';

const HTMLRenderer = (props) => {
  const {
    element: { html, type }
  } = props;
  if (type === 'image') {
    return <Image src={html} alt="image" />;
  }
  if (!html) {
    return <></>;
  }
  return <span>{parse(html)}</span>;
};

export { HTMLRenderer };
