import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import { MenuItem } from '@chakra-ui/react';

export const CheckboxMenuItem = ({ label, value, isChecked, onChange }) => {
  const onCheckboxToggle = (e) => {
    onChange(value, e.target.checked);
  };

  return (
    <MenuItem>
      <Checkbox onChange={onCheckboxToggle} isChecked={isChecked}>
        {label}
      </Checkbox>
    </MenuItem>
  );
};
