import { useEffect } from "react";
import { firebaseAuth } from './firebase';
import { eventLogger } from '../../api/eventLogger';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';

export const HandleGoogleRedirect = () => {
    const navigate = useNavigate();
    const docUrl = new URL(document.location);
    const { token } = useAuth();

    const refreshTokenAndRedirect = async() => {
        eventLogger(token, 'google-classroom-connect-successful')
        return firebaseAuth.onAuthStateChanged(async (user) => {
            if (!user) {
                console.info(`no user found...`);
                return;
            }
    
            await user.getIdToken(/* forceRefresh */ true);
            
            const redirectURI = docUrl.searchParams.get('referrerURI');
            navigate(redirectURI);
        });
    }

    useEffect(() => {
        refreshTokenAndRedirect()
    }, []);

    return <></>;
} 