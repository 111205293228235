import { ACTIONS, STATUS } from 'react-joyride';
import { produce } from 'immer';

const initialState = {
  tourMode: false,
  lastAction: ACTIONS.INIT,
  lastStatus: STATUS.IDLE,
  running: false,
  stepIndex: 0,
  createTrigger: false
};

export const onboardingTourReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'ASSIGNMENT_CREATED': {
      // Onboarding is shown for all assignments
      draft.createTrigger = true;
      return;
    }
    case 'CREATE_MODE_RESET':
      draft.createTrigger = false;
      return;
    case 'SET_TOUR_MODE':
      draft.tourMode = payload.tourMode;

      // If the tour was started, set running to true
      if (payload.tourMode) {
        draft.running = true;
      }
      return;

    case 'UPDATE_TOUR_STATUSES':
      draft.lastAction = payload.lastAction || draft.lastAction;
      draft.lastStatus = payload.lastStatus || draft.lastStatus;
      return;
    case 'SET_RUNNING':
      draft.running = payload.running;
      return;
    case 'UPDATE_STEP':
      draft.stepIndex = payload.stepIndex;
      return;

    case 'CLEAR_ASSIGNMENT_STATE':
      return initialState;
    default:
      // Always return draft as the default is initial state otherwise the draft object
      return draft;
  }
});
