import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Text, useDisclosure } from '@chakra-ui/react';

import LoggedInUserWrapper from '../../components/core/LoggedInUserWrapper';
import LinkWithGoogle from '../auth/linkWithGoogle';
import { getGoogleToken } from '../../api/userApis';
import { useAuth } from '../auth/useAuth';
import UserOnboardModal from '../app/share/user-onboard-modal';
import { eventLogger } from '../../api/eventLogger';
import CloneTemplatesSection from './clone-templates';
import UpgradeSuccessModal from '../upgrade/UpgradeSuccessModal';
import { SUBSCRIPTION_PLANS } from '../../api/constants';
import { isMobile } from 'react-device-detect';
import { CreateOptionsDesktop, CreateOptionsMobile } from './create-options';
import { useSearchParams } from 'react-router-dom';

export default function Home() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isGoogleAuthDone, setIsGoogleAuthDone] = useState(true);
  const [searchParams] = useSearchParams();
  const upgradeStatus = searchParams.get('upgradeStatus');
  const { userSubscriptionPlan, token, refreshTheToken } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkGoogleAuth = useCallback(async () => {
    if (!token) return;

    const [accessToken, , missingScopes] = await getGoogleToken(token);
    if (!accessToken || missingScopes) {
      setIsGoogleAuthDone(false);
    }
  }, [token]);

  useEffect(() => {
    eventLogger(token, 'homepage-visited');
    checkGoogleAuth();
  }, [checkGoogleAuth]);

  useEffect(() => {
    if (upgradeStatus === 'success') {
      // Refetch the token to get the latest subscription status
      refreshTheToken();
    }
  }, [refreshTheToken, upgradeStatus]);

  useEffect(() => {
    const isNewUser = searchParams.get('isNewUser');
    if (isNewUser) {
      onOpen();
    }
  }, [onOpen, searchParams]);

  return (
    <LoggedInUserWrapper selectedMenu="home" isLoading={isLoading}>
      <Flex px={{ base: 0, sm: '50' }} w="100%" h="100%" display="flex">
        <Flex direction="column" w="100%">
          {/* Not showing this on mobile */}
          {!isGoogleAuthDone && !isMobile && <LinkWithGoogle />}
          <Flex direction="column" px={6} my="4" w="100%">
            <Text fontSize={{ base: '2xl', md: 'xl', lg: '2xl' }} as="b">
              Create
            </Text>
            {isMobile ? (
              <CreateOptionsMobile setIsLoading={setIsLoading} />
            ) : (
              <CreateOptionsDesktop />
            )}
            <CloneTemplatesSection setIsLoading={setIsLoading} />
          </Flex>
        </Flex>
      </Flex>
      <UserOnboardModal isOpen={isOpen} onClose={onClose} />
      {userSubscriptionPlan === SUBSCRIPTION_PLANS.PRO && <UpgradeSuccessModal />}
    </LoggedInUserWrapper>
  );
}
