import React from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Stack,
  Text,
  useClipboard,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { EmailIcon, LinkIcon } from '@chakra-ui/icons';
import { isMobile } from 'react-device-detect';
import { useAuth } from '../auth/useAuth';
import { emailTeacherLink } from '../../api/userApis';
import { useSelector } from 'react-redux';
import {
  getAIQuestionsModalState,
  getAssignmentSettingModalStatus
} from '../app/canvas/redux/selectors';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function SwitchToDesktopComponent() {
  const [showEmailIcon, setShowEmailIcon] = useState(false);
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { token, user } = useAuth();
  const { showAssignmentSetting: assignmentSettingsOpen } = useSelector(
    getAssignmentSettingModalStatus
  );
  const { isOpen: isAIModalOpen } = useSelector(getAIQuestionsModalState);
  // eslint-disable-next-line no-undef
  const assignmentURL = `${import.meta.env.ASSIGNMENT_FE_TEACHER_APP_URL}${location.pathname}?${
    location.search
  }`;

  const conflictModalOpen =
    assignmentSettingsOpen || // Assignment settings aren't open - this happens for a new assignment
    isAIModalOpen; // AI question addition modal isn't open - when assignment is created with AI CTA

  const { onCopy, hasCopied } = useClipboard(assignmentURL);

  // Suppressed as mobile view is formed enough to be usable & for this to not auto open
  // React.useEffect(() => {
  //   if (isMobile && !conflictModalOpen) {
  //     onOpen();
  //   }
  //   // Disabling exhaustive deps as this only needs to run on start
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onOpen]);

  React.useEffect(() => {
    if (isMobile) {
      if (!isOpen && !conflictModalOpen) {
        setShowEmailIcon(true);
      } else {
        setShowEmailIcon(false);
      }
    }
  }, [isOpen, assignmentSettingsOpen, conflictModalOpen]);

  const onCopyUrl = React.useCallback(() => {
    onCopy();
    toast({
      title: `Assignment link copied`,
      status: 'success',
      isClosable: true,
      position: 'top'
    });
  }, [onCopy, toast]);

  const onEmailLink = React.useCallback(async () => {
    await emailTeacherLink({
      token,
      payload: {
        to: user?.email,
        link: assignmentURL
      }
    });
    toast({
      title: `Assignment link sent to ${user.email}`,
      status: 'success',
      isClosable: true,
      position: 'top'
    });
  }, [assignmentURL, toast, token, user?.email]);

  if (showEmailIcon) {
    return (
      <Box position={'fixed'} bottom={30} right={5}>
        <IconButton
          isRound={true}
          variant="solid"
          colorScheme="purple"
          aria-label="Done"
          fontSize="20px"
          onClick={() => onOpen()}
          icon={<EmailIcon />}
        />
      </Box>
    );
  }

  return (
    <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent w={'94vw'} ml={'3vw'} borderTopRadius={5}>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="0px" pt={4} pb={0}>
          Use on Bigger Screen
        </DrawerHeader>
        <DrawerBody>
          <Text color="#686868">
            Don’t miss the full power of Classwise on a Desktop/ tablet device
          </Text>
          <Stack direction="row" spacing={4} mt={5} mb={3}>
            <Button
              onClick={onCopyUrl}
              leftIcon={<LinkIcon />}
              borderRadius={3}
              fontSize={13}
              colorScheme="gray"
              variant="outline"
            >
              {hasCopied ? 'Copied!' : 'Copy Link'}
            </Button>
            <Button
              leftIcon={<EmailIcon />}
              onClick={onEmailLink}
              borderRadius={3}
              color="white"
              fontSize={13}
              bg="#5D38DB"
              variant="solid"
              _focus={{
                color: 'white',
                bg: '#5D38DB'
              }}
              _hover={{
                color: 'white',
                bg: '#5D38DB'
              }}
            >
              Email Link
            </Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
