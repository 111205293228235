import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  Button,
  Flex,
  ModalCloseButton,
  Box,
  Text,
  Alert,
  ScaleFade,
  useDisclosure,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  useToast
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';

import { useAuth } from '../../../../../auth/useAuth';
import { CREATE_TYPES } from '../../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import useUpdateAssignment from '../../../shared/hooks/use-update-assignment';
import { eventLogger } from '../../../../../../api/eventLogger';
import { getAssignmentMetadata } from '../../../redux/selectors';
import { Classes, Sections } from './lesson-plan-constants';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { CheckboxMenuItem } from './utils';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { countries } from '../../../../../../utils/countriesList';
import { createAILessonPlan, createAILessonPlanPDF } from '../../../../../../api/assignmentApis';
import addLessonPlan from '../../../redux/thunks/add-lesson-plan';
import { AiRequestTopicWithAttachment } from '../../../ai-question/AiRequestTopicWithAttachment';
import { createFormDataPayloadWithFile } from '../../../../../../helpers/data.helpers';
import { useParams } from 'react-router-dom';

const CreateLessonPlanModal = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const toast = useToast();
  const { assignmentID } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const updateAssignment = useUpdateAssignment({ forceUpdate: true });
  const [status, setStatus] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState();

  const onSectionChange = (value, isChecked, values, formOnChange) => {
    let newValues = [...values];
    if (isChecked) {
      const toAdd = Sections.find((sec) => sec.value === value);
      newValues.push(toAdd);
    } else {
      newValues = newValues.filter((val) => val.value !== value);
    }

    // Call the form's onChange func with the new values
    formOnChange({
      values: newValues,
      label: newValues.length === Sections.length ? 'All Sections' : `${newValues.length} Sections`
    });
  };

  // We use the AI createType to open this modal on creation of the assignment
  const { createType } = useSelector(getAssignmentMetadata);

  const onSubmit = async (data) => {
    console.log(data);
    setIsLoading(true);
    setStatus('Hang tight. It can take upto 30s to generate a lesson plan');

    // Since this modal was opened on create, save lesson plan name as assignment name
    dispatch({
      type: 'UPDATE_ASSIGNMENT_NAME',
      payload: `Lesson Plan - ${data.topic}`
    });

    const payload = {
      topic: data.topic,
      sections: data.sections.values.map((sec) => sec.value),
      board: data.board.value,
      grade: data.grade.value,
      country: data.country.value
    };

    const formData = createFormDataPayloadWithFile({ data: payload, file: selectedFile });

    let lessonPlan = null;
    if (selectedFile) {
      lessonPlan = await createAILessonPlanPDF({
        token,
        toast,
        data: formData
      });
    } else {
      lessonPlan = await createAILessonPlan({
        token,
        data: formData
      });
    }

    if (!lessonPlan) {
      setStatus('Unable to generate Lesson Plan. Please try again.');
    } else {
      dispatch(addLessonPlan(lessonPlan.data));
      eventLogger(token, 'lesson-plan-using-ai', assignmentID, {
        ...payload,
        isPDFAdded: !!selectedFile
      });
      setIsLoading(false);
      setStatus(null);
      updateAssignment();
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (createType === CREATE_TYPES.HOME_LESSON_PLAN) {
      onOpen();
    }
    // Props do not change & hence this should only be invoked once
  }, [createType, onOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      size="lg"
      isCentered
    >
      <ModalOverlay bg="rgba(0,0,0,0.8)" />
      <ModalContent m={3}>
        <ModalCloseButton />
        <ModalHeader textAlign="center" mt={4}>
          Generate using AI
        </ModalHeader>
        <ModalBody>
          {/* Text input for topic */}
          <FormControl isRequired>
            <AiRequestTopicWithAttachment
              maxPages={Infinity}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              formProps={{ ...register('topic', { required: true }) }}
            />
            <Text fontSize="12px" color="red">
              {errors.topic?.message}
            </Text>
          </FormControl>

          {/* Dropdown for Grade / Class level selection */}
          <FormControl mt={4} borderBottom="1px solid lightgrey" pb={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Grade / Class Level</FormLabel>
              <Box minWidth="200px">
                <Controller
                  control={control}
                  name="grade"
                  defaultValue={{ label: 'Class 10', value: 'Class 10' }}
                  render={(renderProps) => {
                    const {
                      field: { onChange, value }
                    } = renderProps;
                    return (
                      <CreatableSelect
                        placeholder={'Grade / Class Level'}
                        options={Classes.map((l) => ({ label: l, value: l }))}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          option: (styles) => ({ ...styles, fontSize: '14px' })
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Flex>
          </FormControl>

          {/* Dropdown for selecting sections - checkbox */}
          <FormControl mt={4} borderBottom="1px solid lightgrey" pb={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Sections</FormLabel>
              <Box minWidth="200px">
                <Controller
                  control={control}
                  name="sections"
                  defaultValue={{
                    values: [...Sections],
                    label: 'All Sections'
                  }}
                  rules={{
                    validate: (value) => {
                      if (value?.values.length === 0) return 'Please select at least one section';
                      return true;
                    }
                  }}
                  render={(renderProps) => {
                    const {
                      field: { onChange, value }
                    } = renderProps;
                    return (
                      <Menu closeOnSelect={false}>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} width="100%">
                          {value?.label}
                        </MenuButton>
                        <MenuList>
                          {Sections.map((section) => {
                            const isChecked = value?.values?.some(
                              (curr) => curr.value === section.value
                            );
                            return (
                              <CheckboxMenuItem
                                key={section.value}
                                label={section.label}
                                value={section.value}
                                isChecked={isChecked}
                                onChange={(checkboxValue, isChecked) =>
                                  onSectionChange(checkboxValue, isChecked, value?.values, onChange)
                                }
                              />
                            );
                          })}
                        </MenuList>
                      </Menu>
                    );
                  }}
                />
              </Box>
            </Flex>
            {errors.sections?.message && (
              <Text fontSize="12px" color="red">
                {errors.sections?.message}
              </Text>
            )}
          </FormControl>

          {/* Inclusion of practice questions for the lesson plan */}
          {/* <FormControl mt={4} borderBottom="1px solid lightgrey" pb={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Practice Questions</FormLabel>
              <Box minWidth="200px">
                <Controller
                  control={control}
                  name="practiceQuestions"
                  defaultValue={{ label: '10 Questions', value: 10 }}
                  render={(renderProps) => {
                    const {
                      field: { onChange, value }
                    } = renderProps;
                    return (
                      <Select
                        placeholder={'Practice Questions'}
                        options={PracticeQuestions}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          option: (styles) => ({ ...styles, fontSize: '14px' })
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Flex>
          </FormControl> */}

          <FormControl mt={4} borderBottom="1px solid lightgrey" pb={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Country</FormLabel>
              <Box minWidth="248px">
                <Controller
                  control={control}
                  name="country"
                  defaultValue={{ label: 'United States', value: 'United States' }}
                  render={(renderProps) => {
                    const {
                      field: { onChange, value }
                    } = renderProps;
                    return (
                      <CreatableSelect
                        placeholder="Select / Add Country"
                        options={countries.map((c) => ({ label: c, value: c }))}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          option: (styles) => ({ ...styles, fontSize: '14px' })
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Flex>
          </FormControl>
          <FormControl mt={4} borderBottom="1px solid lightgrey" pb={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Board</FormLabel>
              <Box minWidth="200px">
                <Controller
                  control={control}
                  name="board"
                  defaultValue={{ label: 'Any', value: 'Any' }}
                  render={(renderProps) => {
                    const {
                      field: { onChange, value }
                    } = renderProps;
                    return (
                      <Select
                        placeholder={'Board'}
                        options={[
                          { label: 'Any', value: 'Any' },
                          { label: 'CBSE', value: 'CBSE' },
                          { label: 'IGCSE', value: 'IGCSE' }
                        ]}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          option: (styles) => ({ ...styles, fontSize: '14px' })
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Flex>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Flex justifyContent="center" width="100%" alignItems="center" direction="column">
            <Button
              isLoading={isLoading}
              colorScheme="purple"
              mr={3}
              onClick={handleSubmit(onSubmit)}
            >
              Generate ✨
            </Button>
            {!!status && (
              <Box minH="32px" mt={4} w={'100%'} py="4px">
                <ScaleFade initialScale={0.2} in={!!status}>
                  <Alert opacity={0.8} status="info" borderRadius={5} justifyContent={'center'}>
                    {status}
                  </Alert>
                </ScaleFade>
              </Box>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateLessonPlanModal;
