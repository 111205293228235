import { requestOptions } from "./constants";

export async function createGoogleAssignment(accessToken, courseId, payload) {
    try {
        const options = requestOptions(accessToken, 'POST');
        options.body = JSON.stringify(payload);

        const response = await fetch(   
            `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork`,
            options
        );

        if(response.ok) {
            const data = await response.json();
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}

