import { Flex, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { throttle } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Search2Icon } from '@chakra-ui/icons';
import { searchTopics } from '../../utils/fetch';

const SearchTopics = ({ subject, onItemClick }) => {
  const [input, setInput] = useState('');
  const [topics, setTopics] = useState([]);

  const onInputChange = (evt) => {
    const value = evt.target.value;
    setInput(value);
  };

  const search = useMemo(() => {
    return throttle((value) => {
      if (!value || value.length < 3) {
        setTopics([]);
        return;
      }

      searchTopics(value, subject).then((data) => {
        console.log(data);
        setTopics(data);
      });
    }, 1000);
  }, [subject]);

  useEffect(() => {
    search(input);
  }, [input, search]);

  const onSelect = (topic) => {
    if (!topic) {
      return;
    }
    onItemClick(topic.name, topic.goal.name);

    setInput('');
    setTopics([]);
  };

  return (
    <InputGroup maxW={{ base: 'unset', lg: '40vw' }} position="relative" zIndex={1}>
      <Input
        height="100%"
        minHeight={12}
        placeholder="Search for topics"
        value={input}
        onChange={onInputChange}
      />
      <InputRightElement pointerEvents="none">
        <Search2Icon color="gray.300" />
      </InputRightElement>
      {topics.length && (
        <Flex
          maxHeight="49vh"
          overflowY="scroll"
          direction="column"
          position="absolute"
          top="50px"
          bg="white"
          border="1px solid #ddd"
          w="100%"
        >
          {topics.map((topic, idx) => {
            return <TopicItem topic={topic} key={idx} onItemClick={onSelect} />;
          })}
        </Flex>
      )}
    </InputGroup>
  );
};

export default SearchTopics;

const TopicItem = ({ topic = {}, onItemClick }) => {
  return (
    <Flex
      key={topic._id}
      borderBottom={'1px solid #eee'}
      px={3}
      py={2}
      direction={'column'}
      cursor="pointer"
      _hover={{ bg: '#f5f5f5' }}
      onClick={() => onItemClick(topic)}
    >
      <Text fontSize="md">{topic.name}</Text>
      <Text fontSize="xs">{topic.goal?.name}</Text>
    </Flex>
  );
};
