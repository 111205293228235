/* eslint-disable no-undef */
import { Avatar } from '@chakra-ui/avatar';
import {
  Box,
  Text,
  Flex,
  Image,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal
} from '@chakra-ui/react';
import { Tag, TagLabel } from '@chakra-ui/tag';
import React from 'react';
import { leftSidebarMenu } from './LeftMenuSidebarConstant';
import { useAuth } from '../../../auth/useAuth';
import ProductTourModal from '../../../app/share/product-tour-modal';
import { HamburgerIcon } from '@chakra-ui/icons';
import { SUBSCRIPTION_PLANS } from '../../../../api/constants';
import { fetchCustomerPortalLink } from '../../../../api/userApis';
import { Link } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
export default function LeftMenuSidebar({ isSmallMenu = true, selected = '', onCloseMenu }) {
  return (
    <>
      {isSmallMenu ? (
        <Drawer
          blockScrollOnMount
          closeOnEsc
          size="xs"
          isOpen={isSmallMenu}
          onClose={onCloseMenu}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody px={0} className="sidebar_menu">
              <SlideMenu isSmallMenu={isSmallMenu} onCloseMenu={onCloseMenu} selected={selected} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : (
        <Box display={{ base: 'none', sm: 'flex' }} height="100%">
          <SlideMenu isSmallMenu={isSmallMenu} onCloseMenu={onCloseMenu} selected={selected} />
        </Box>
      )}
    </>
  );
}

function SlideMenu({ isSmallMenu, selected, onCloseMenu }) {
  const { user, signOut, userSubscriptionPlan, token } = useAuth();
  const { isOpen, onOpen: onOpenTutorial, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSignOut = () => {
    signOut();
  };

  const handleMyPlanClick = () => {
    setIsLoading(true);
    fetchCustomerPortalLink({ token })
      .then((result) => {
        setIsLoading(false);
        if (result?.url) {
          window.open(result?.url, '_newtab');
        } else {
          window.open(import.meta.env.ASSIGNMENT_FE_STRIPE_PORTAL_LINK, '_newtab');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("couldn't fetch the signed in link: ", e);
        window.location.replace(import.meta.env.ASSIGNMENT_FE_STRIPE_PORTAL_LINK);
      });
  };

  const onSettingsClick = (moduleName) => {
    if (moduleName === 'help') {
      onOpenTutorial();
    }
    if (moduleName === 'book-demo-sm') {
      window.open('https://calendly.com/prephub/classwise-product-demo');
    }
    return;
  };

  function getImageComponent(item_key) {
    const src = '/assets/images/' + item_key + (selected == item_key ? '-selected' : '') + '.png';
    return <Image src={src} />;
  }

  function setStyle(selected_key) {
    let style;
    if (isSmallMenu) {
      style = {
        justifyContent: 'start',
        alignItems: 'center',
        color: selected == selected_key ? '#8954BA' : '#727272',
        fontWeight: selected == selected_key ? 'bold' : 'normal'
      };
    } else {
      style = {
        color: selected == selected_key ? '#8954BA' : '#727272',
        fontWeight: selected == selected_key ? 'bold' : 'normal'
      };
    }

    return style;
  }

  return (
    <>
      <Box
        style={{
          zIndex: 10,
          position: 'relative',
          borderRight: 1,
          borderStyle: 'solid',
          overflow: 'auto',
          borderRightColor: '#F2F2F2',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%'
          // transition: '0.1s linear'
        }}
        width={{ base: '100%', sm: !isSmallMenu ? 160 : '100%' }}
        display={'flex'}
        px={2}
      >
        <Box></Box>
        <Box align="center" display="flex" flexDirection={'column'} width="100%">
          {isSmallMenu && (
            <Box
              align="center"
              position={'absolute'}
              top={2}
              flexDir="row"
              display="flex"
              alignItems="center"
              px={4}
            >
              <HamburgerIcon w={8} h={8} color="gray.400" cursor="pointer" onClick={onCloseMenu} />
              <Box p="2" mx="12px">
                <Image src="/assets/images/logo.png" htmlWidth="171px" alt="Classwise" />
              </Box>
            </Box>
          )}
          {leftSidebarMenu.appMenu.map((sidebarMenu) => {
            return (
              <Link
                to={sidebarMenu.link}
                key={sidebarMenu.title}
                display="flex"
                justifyContent={isSmallMenu ? 'flex-start' : 'center'}
              >
                <Box
                  display="flex"
                  flexDirection={isSmallMenu ? 'row' : 'column'}
                  mb={isSmallMenu ? `45px` : `28px`}
                  px={!isSmallMenu ? 3 : 5}
                  style={setStyle(sidebarMenu.key)}
                  _hover={{
                    color: '#8954BA',
                    cursor: 'pointer'
                  }}
                >
                  <Box>{getImageComponent(sidebarMenu.key)}</Box>
                  <Text>
                    {!isSmallMenu ? sidebarMenu.smTitle : <Text ml={2}>{sidebarMenu.title}</Text>}
                  </Text>
                </Box>
              </Link>
            );
          })}
        </Box>
        <Box align="center" w="100%">
          {leftSidebarMenu.miscMenu.map((sidebarMenu) => {
            return (
              <Box
                px={!isSmallMenu ? 3 : 5}
                key={sidebarMenu.title}
                _active={{
                  color: '#8954BA',
                  cursor: 'pointer'
                }}
                _hover={{
                  color: '#8954BA',
                  cursor: 'pointer'
                }}
                display="flex"
                flexDirection={isSmallMenu ? 'row' : 'column'}
                justifyContent={isSmallMenu ? 'flex-start' : 'center'}
                style={setStyle(sidebarMenu.key)}
                mb={4}
                onClick={() => onSettingsClick(sidebarMenu.key)}
              >
                <Box ml={!isSmallMenu && -1}>{getImageComponent(sidebarMenu.key)}</Box>
                <Text ml={isSmallMenu && 2} mt={!isSmallMenu && 2}>
                  {isSmallMenu ? sidebarMenu.title : sidebarMenu.smTitle}
                </Text>
              </Box>
            );
          })}

          <Flex
            py={5}
            borderTop="1px"
            justify={isSmallMenu ? 'start' : 'center'}
            width="100%"
            borderColor="#F2F2F2"
          >
            <Popover placement="top">
              <PopoverTrigger>
                <Tag size="lg" cursor="pointer" colorScheme="#fff" px={!isSmallMenu ? 3 : 5}>
                  <Avatar
                    size="sm"
                    name={user?.displayName}
                    mr={2}
                    backgroundColor="#8954BA"
                    color="#fff"
                  />
                  {isSmallMenu && <TagLabel>{user?.displayName}</TagLabel>}
                </Tag>
              </PopoverTrigger>
              <Portal>
                <Box zIndex="9999" className="menu-portal">
                  <PopoverContent w="auto" minW="280px" mb="8px" marginLeft="28px">
                    <Flex p={4} alignItems="center">
                      <Avatar
                        size="sm"
                        name={user?.displayName}
                        backgroundColor="#8954BA"
                        color="#fff"
                      />
                      <Flex ml={3} direction="column">
                        <Text as="b" fontSize="16px">
                          {user?.displayName}
                        </Text>
                        <Text color="#686868" fontSize="16px">
                          {user?.email}
                        </Text>
                      </Flex>
                    </Flex>
                    {userSubscriptionPlan === SUBSCRIPTION_PLANS.PRO && (
                      <Flex
                        color="#5D38DB"
                        p={2}
                        cursor="pointer"
                        borderTop="1px"
                        borderColor="#F2F2F2"
                        onClick={handleMyPlanClick}
                      >
                        <Image src="/assets/images/my-plan-icon.svg" mx={3} />
                        <Text ml={1} as="b">
                          My Plan
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      color="#5D38DB"
                      p={2}
                      cursor="pointer"
                      borderTop="1px"
                      borderColor="#F2F2F2"
                      onClick={handleSignOut}
                    >
                      <Image src="/assets/images/logout-icon.svg" mx={3} />
                      <Text ml={1} as="b">
                        Logout
                      </Text>
                    </Flex>
                  </PopoverContent>
                </Box>
              </Portal>
            </Popover>
          </Flex>

          {/* <Flex
            py={5}
            borderTop="1px"
            justify={!isSmallMenu ? 'center' : 'start'}
            width="100%"
            borderColor="#F2F2F2"
          >
            <Menu placement="right" strategy="fixed">
              <MenuButton>
                <Tag size="lg" colorScheme="#fff" px={!isSmallMenu ? 3 : 5}>
                  <Avatar
                    size="sm"
                    name={user?.displayName}
                    mr={2}
                    backgroundColor="#8954BA"
                    color="#fff"
                  />
                  {isSmallMenu && <TagLabel>{user?.displayName}</TagLabel>}
                </Tag>
              </MenuButton>
              <MenuList maxW={'max-content'}>
                <MenuItem minH="100px">
                  <Flex>
                    <Avatar
                      size="sm"
                      name={user?.displayName}
                      mr={2}
                      backgroundColor="#8954BA"
                      color="#fff"
                    />
                    <Box>
                      <Text>{user?.displayName}</Text>
                      <Text>{user?.email}</Text>
                    </Box>
                  </Flex>
                </MenuItem>
                {userSubscriptionPlan === SUBSCRIPTION_PLANS.PRO && (
                  <MenuItem
                    _hover={{
                      opacity: 0.8
                    }}
                    bgColor={'#5D38DB'}
                    color="#FFFFFF"
                    fontWeight="400"
                    onClick={handleMyPlanClick}
                  >
                    My Plan
                  </MenuItem>
                )}

                <MenuItem
                  _hover={{
                    opacity: 0.8
                  }}
                  bgColor={'#5D38DB'}
                  color="#FFFFFF"
                  fontWeight="400"
                  onClick={handleSignOut}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex> */}
        </Box>
      </Box>
      <ProductTourModal isOpen={isOpen} onClose={onClose} />
      {/* {isLoading && <ClasswiseLoader/>} */}
    </>
  );
}
