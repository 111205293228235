/* eslint-disable react/prop-types */
import { DeleteIcon } from '@chakra-ui/icons'
import { Box, Flex, Tooltip, Badge, IconButton } from '@chakra-ui/react'
import React from 'react'
import UneditableHtml from '../uneditable-html'

// eslint-disable-next-line react/prop-types
export default function LibraryStaticOption({
    option,
    onDeleteOption,
    canDelete,
}) {
    const { id, isCorrect, value } = option

    return (
        <Flex direction="column" bg={'#fafafa'} my={2} pl={3}>
            <Flex alignItems={'center'} my={2}>
                <Flex flex={1}>
                    {value.map((q) => (
                        <UneditableHtml
                            key={q.id}
                            elementID={q.id}
                            value={q.value}
                        />
                    ))}
                </Flex>
                <Box pl={2} mr={2}>
                    {isCorrect && (
                        <Badge colorScheme="green">Correct Answer</Badge>
                    )}
                </Box>
                {!isCorrect && (
                    <Flex alignItems="center" mx={2}>
                        <Tooltip hasArrow label={'Delete option'} fontSize="md">
                            <IconButton
                                isDisabled={!canDelete}
                                onClick={() => onDeleteOption(id)}
                                aria-label="Search database"
                                icon={
                                    <DeleteIcon color={'#1D1D1B'} size="lg" />
                                }
                            />
                        </Tooltip>
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}
