import React, { useState, useEffect } from 'react'
import {
  Table,
  Tbody,
  Tr,
  Td,
  Flex,
  Text,
  Image,
  Button,
  Box,
  Thead
} from '@chakra-ui/react'
import {
  getGoogleAssignments,
  getGoogleCourses,
  getGoogleStudentSubmissions,
  getGoogleTopics,
  updateGoogleAssignments,
} from '../../api/fetchGoogleAssignmentData'
import { useAuth } from '../auth/useAuth';
import { getGoogleToken } from '../../api/userApis'
import { LoadingSkeleton } from './loadingSkeleton'
import { EmptyState } from './emptyState'
import LinkWithGoogle from '../auth/linkWithGoogle';
import { isMobile } from 'react-device-detect';

export function AssignmentsPageGoogle() {
  const { token } = useAuth()
  const [isGoogleAuthDone, setGoogleAuthDone] = useState(true)
  const [isEmptyState, setEmptyState] = useState(false)
  let [assignmentItems, setAssignments] = useState([])

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const fetchGoogleData = async () => {
    const [accessToken, googleUserId] = await getGoogleToken(token)
    if (accessToken) {
      const courses = await getGoogleCourses(accessToken, googleUserId)
      let assignments = await getGoogleAssignments(accessToken, courses)
      setAssignments(assignments)

      const submissions = await getGoogleStudentSubmissions(
        accessToken,
        assignments
      )
      const topics = await getGoogleTopics(accessToken, courses)

      assignments = await updateGoogleAssignments(
        assignments,
        submissions,
        topics
      )

      setAssignments(assignments)

      if (assignmentItems.length == 0) {
        setEmptyState(true)
      }
    } else {
      setGoogleAuthDone(false);
    }
  }

  useEffect(() => {
    fetchGoogleData();
  }, [])

  function getAssignmentStatus(status) {
    switch (status) {
      case 'PUBLISHED':
      case 'ACTIVE':
        return (
          <Text
            color="#11941B"
            fontWeight="400"
            fontSize="15px"
            lineHeight="20px"
            fontFamily="Arial"
          >
            Active
          </Text>
        )
      case 'DRAFT':
        return (
          <Text
            color="#F02E18"
            fontWeight="400"
            fontSize="15px"
            lineHeight="20px"
            fontFamily="Arial"
          >
            Draft
          </Text>
        )
    }
  }

  return (
    <Flex direction="column" width="100%">
      {!isGoogleAuthDone ? (
        <LinkWithGoogle />
      ) : assignmentItems.length ? (
        <Flex
          direction="column"
          w="100%"
          height={'calc(100vh - 300px)'}
          overflowY={'auto'}
          overflowX="hidden"
        >
          <Box>
            <Table variant="simple">
              <Thead position={'sticky'} bg={'#fff'} top={0} zIndex={4}>
                <Tr
                  fontFamily="Arial"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="19px"
                  color="#686868"
                >
                  <Td fontWeight="700" pl={[0, 16]}>
                    Assignment Status
                  </Td>
                  <Td flexGrow="1">Assignment Name</Td>
                  {!isMobile && (
                    <>
                      <Td>Class Name</Td>
                      <Td>Topic Name</Td>
                      <Td>Due Date</Td>
                      <Td>Yet to Start</Td>
                      <Td>Started</Td>
                      <Td>Submitted</Td>
                    </>
                  )}
                  <Td fontWeight="700">Actions</Td>
                </Tr>
              </Thead>
              <Tbody>
                {assignmentItems.map((item) => (
                  <Tr
                    key={item.id}
                    fontFamily="Arial"
                    fontSize="15px"
                    fontWeight="400"
                    lineHeight="20px"
                    color="#757575"
                    cursor="pointer"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      if (item?.alternateLink) {
                        window.open(item?.alternateLink, '_blank');
                      }
                    }}
                  >
                    <Td pl={[0, 16]}>
                      <Flex flexDirection="row" gap="6">
                        <Image src="/assets/images/google.png" boxSize="16px" />
                        {getAssignmentStatus(item.state)}
                      </Flex>
                    </Td>
                    <Td>{item.title}</Td>
                    {!isMobile && (
                      <>
                        <Td>{item.courseName}</Td>
                        <Td>{item.topicName}</Td>
                        <Td>
                          {item.dueDate !== undefined && !Object.values(item.dueDate).includes(NaN)
                            ? monthNames[item.dueDate.month - 1] +
                              ' ' +
                              item.dueDate.day +
                              ', ' +
                              item.dueDate.year
                            : ''}
                        </Td>
                        <Td>{item.yet_to_start}</Td>
                        <Td>{item.started}</Td>
                        <Td>{item.submitted}</Td>
                      </>
                    )}
                    <Td>
                      <Button
                        borderRadius="0"
                        maxWidth={['auto', '110px']}
                        lineHeight={['auto', '15px']}
                        fontFamily="Helvetica"
                        fontSize={['10px', '12px']}
                        backgroundColor="#5D38DB"
                        textColor="#FFFFFF"
                        fontWeight="400"
                        height={'30px'}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          if (item?.alternateLink) {
                            window.open(item?.alternateLink, '_blank');
                          }
                        }}
                      >
                        {isMobile ? 'View' : 'View Submissions'}
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Flex>
      ) : isEmptyState ? (
        <EmptyState type="assignments-home-google" onClick={() => {}} />
      ) : (
        <LoadingSkeleton />
      )}
    </Flex>
  );
}
