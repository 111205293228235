import { Flex, ListItem, OrderedList, Text } from '@chakra-ui/react';
import { domToReact } from 'html-react-parser';
import React, { useMemo } from 'react';

const useParserOptions = ({ BlankReviewComponent, blankProps }) => {
  const parserOptions = useMemo(() => {
    return {
      replace: (domNode) => {
        if (!domNode) return;

        switch (domNode.name) {
          case 'p':
            return (
              <Flex wrap="wrap" alignItems="center">
                {domToReact(domNode?.children, parserOptions)}
              </Flex>
            );
          case 'ol':
            return <OrderedList>{domToReact(domNode?.children, parserOptions)}</OrderedList>;
          case 'li':
            return (
              <ListItem my={2}>
                <Flex alignItems="center">{domToReact(domNode?.children, parserOptions)}</Flex>
              </ListItem>
            );
        }

        // Inside these P tags, there is either a text node or a blank span embed
        if (domNode.type === 'text') {
          return <Text>{domNode.data}</Text>;
        }

        // In place editing of the input
        if (domNode.attribs && domNode.attribs['data-blank_id']) {
          const blankId = domNode.attribs['data-blank_id'];
          return <BlankReviewComponent {...blankProps[blankId]} />;
        }
      }
    };
  }, [blankProps]);

  return parserOptions;
};

export default useParserOptions;
