import axios from 'axios';
import { appConfig } from '../../../../../../constants';
import { get } from 'lodash';

export async function getTopic({ goal, language, subject, source = 'All' }) {
  let TopicApiData = await axios.get(
    `${appConfig.adminTemplateURL}/api/v2/topics?goal=${goal}&subject=${subject}&language=${language}&source_name=${source}`
  );
  const topicData = TopicApiData.data.response;
  const topicsPayload = topicData.map((topic) => topic.name).join(',');
  const sourceName = source ? source : 'All';
  return await getTopicWithCount(goal, sourceName, topicsPayload);
}

export async function getTopicWithCount(goal, source, topics) {
  let TopicWithCountApiData = await axios.get(
    `${appConfig.adminTemplateURL}/api/v2/questions/count/topic?goal=${goal}&source=${source}&topic=${topics}`
  );
  return TopicWithCountApiData.data.response;
}

export async function getQuestionsList({ goal, language, subject, source = 'All', topic }) {
  let questionsList = await axios.get(
    `${appConfig.adminTemplateURL}/api/v2/questions/list?goal=${goal}&subject=${subject}&language=${language}&source=${source}&topic=${topic}`
  );
  return questionsList.data.response;
}

export async function getSolutionByQuestionById(questionId) {
  let singleQuestionResponse = await axios.get(
    `${appConfig.adminTemplateURL}/api/v2/questions/${questionId}/`
  );
  return singleQuestionResponse.data.response;
}

export async function getTemplateAssignments(offset = 0, limit = 50) {
  const res = await axios.get(
    `${appConfig.adminTemplateURL}/api/v2/classwiseAssignments?offset=${offset}&limit=${limit}`
  );

  return get(res, 'data.response', []);
}

export async function searchTopics(searchStr, subject) {
  if (!searchStr) {
    return [];
  }
  const res = await axios.get(
    `${appConfig.adminTemplateURL}/api/v2/topics/search?query=${searchStr}&subject=${subject}`
  );
  return get(res, 'data.response', []);
}
