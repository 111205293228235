import React from "react";
import { Table, Tr, Td, Skeleton, Tbody } from "@chakra-ui/react";

export const LoadingSkeleton = () => {
    return (
        <Table>
            <Tbody>
                <Tr>
                    <Td colSpan="10">
                        <Skeleton height="20px" />
                    </Td>
                </Tr>
                <Tr>
                    <Td colSpan="10">
                        <Skeleton height="20px" />
                    </Td>
                </Tr>
                <Tr>
                    <Td colSpan="10">
                        <Skeleton height="20px" />
                    </Td>
                </Tr>
                <Tr>
                    <Td colSpan="10">
                        <Skeleton height="20px" />
                    </Td>
                </Tr>
            </Tbody>
        </Table>
    )
  }