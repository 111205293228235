import * as React from 'react';
import { Button, Box, Text, Flex, Spinner } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import parse from 'html-react-parser';
import { LibraryContext } from '../library-context';
import { isMobile } from 'react-device-detect';
import { ALL_LEVELS_FILTER } from './questions-list';

function HeaderText({ headerName }) {
  return (
    <Box w={'100%'}>
      <Text fontWeight={800} fontSize={'md'} textAlign="left">
        {headerName}
      </Text>
    </Box>
  );
}

export function QuestionDataTable({ selectedLevel }) {
  const { query, setSingleQuestion, setMultipleQuestion } = React.useContext(LibraryContext);
  const [questions, setQuestions] = React.useState(query.questions || []);
  const [selectedQuestions, setSelectedQuestions] = React.useState([]);
  const [isLoadingButton, setLoadingButton] = React.useState(false);

  const onQuestionSelect = React.useCallback(
    (questionId) => {
      setMultipleQuestion([]);
      setSingleQuestion(questionId);
    },
    [setSingleQuestion, setMultipleQuestion]
  );

  const handleChange = React.useCallback((state) => {
    setSelectedQuestions(state.selectedRows);
  }, []);

  const onMultipleQuestionPreview = React.useCallback(() => {
    setLoadingButton(true);
    const selectdRows = selectedQuestions.length > 0 ? selectedQuestions.map((row) => row._id) : [];
    setMultipleQuestion(selectdRows);
  }, [setMultipleQuestion, selectedQuestions]);

  React.useEffect(() => {
    if (selectedLevel === ALL_LEVELS_FILTER) {
      setQuestions(query.questions);
    } else {
      const filtered = query.questions.filter((q) => {
        return q.level === selectedLevel;
      });
      setQuestions(filtered);
    }
  }, [query.questions, selectedLevel]);

  const questionColumn = {
    id: 'question',
    name: <HeaderText headerName="Questions" />,
    selector: (row) => row['content'],
    sortable: false,
    width: '55%',
    cell: ({ old_question_id, question_content_html }) => (
      <Box key={old_question_id} py={2} overflowY={true} className="katex-question">
        {parse(question_content_html)}
      </Box>
    )
  };

  const levelColumn = {
    id: 'level',
    name: <HeaderText headerName="Level" />,
    selector: (row) => row['level'],
    sortable: true,
    width: '15%',
    cell: ({ level }) => <div className="text-xs">{level}</div>
  };

  const actionColumn = {
    id: 'action',
    name: <HeaderText headerName="Action" />,
    selector: (row) => row['goal_name'],
    sortable: false,
    width: '35%',
    cell: (row) => {
      const questionID = row?.old_question_id ? row.old_question_id : row._id;
      return (
        <Button
          size={'xs'}
          w={100}
          rounded={false}
          onClick={() => onQuestionSelect(questionID)}
          background="#744CC6"
          color="#fff"
          _hover={{ background: '#744CC6', opacity: 0.7 }}
        >
          See Preview
        </Button>
      );
    }
  };

  const columns = [];

  columns.push(questionColumn);
  if (!isMobile) {
    columns.push(levelColumn);
  }
  columns.push(actionColumn);

  return (
    <Box pb={20} overflow="auto">
      <DataTable
        className="questionsTable"
        striped
        pagination
        columns={columns}
        data={questions}
        selectableRows
        highlightOnHover
        paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
        paginationPerPage={20}
        onSelectedRowsChange={handleChange}
        defaultSortFieldId="level"
      />
      {selectedQuestions.length > 0 && (
        <>
          <hr />
          <Flex
            position="fixed"
            left={0}
            p={2}
            bg={'#fff'}
            bottom={0}
            flexDirection="row"
            mt={3}
            justify="flex-end"
            width="100%"
            boxShadow="xs"
          >
            <Button
              onClick={onMultipleQuestionPreview}
              size={'md'}
              rounded={false}
              background={!isLoadingButton ? '#744CC6' : 'gray.500'}
              color="#fff"
              _hover={{ background: '#744CC6', opacity: 0.7 }}
            >
              {isLoadingButton ? (
                <>
                  <Spinner color="white.500" mr={2} size="md" /> <span>Please wait...</span>
                </>
              ) : (
                <>
                  See {selectedQuestions.length} Question{selectedQuestions.length > 1 ? 's' : ''}{' '}
                  Preview{' '}
                </>
              )}
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
}
