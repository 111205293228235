// We'll start with the image having an initial width of 400px
export const INITIAL_IMAGE_WIDTH = 600

export const drawCanvasFromBase64Str = ({
    canvasRef,
    imageContent,
    onLoad,
    restrictWidth,
}) => {
    const canvasCtx = canvasRef.current?.getContext('2d')
    if (canvasCtx) {
        const img = new Image()
        img.src = imageContent
        img.onload = () => {
            let canvasWidth = img.width
            let canvasHeight = img.height
            if (restrictWidth && img.width > INITIAL_IMAGE_WIDTH) {
                // Whatever percent we shrink the width by, we need to also shrink the height by the same % amount to maintain aspect ratio of the applied image
                let shrinkPercent =
                    1 - (img.width - INITIAL_IMAGE_WIDTH) / img.width
                canvasHeight = canvasHeight * shrinkPercent
                canvasWidth = INITIAL_IMAGE_WIDTH
            }

            canvasRef.current.width = canvasWidth
            canvasRef.current.height = canvasHeight
            canvasCtx.drawImage(img, 0, 0, canvasWidth, canvasHeight)

            onLoad(img)
        }
    }
}

// Util function to set the constraints for top, left, height, width when cropping is done
const MIN_SQUARE_SIDE = 32
export const setConstraints = ({
    top,
    left,
    newH,
    newW,
    containerHeight,
    containerWidth,
    areRightAnchors,
    origParams,
    boxStyle,
}) => {
    // Top will never be less than 0, & cannot be more than the container's height
    top = Math.min(Math.max(top, 0), containerHeight)

    // Left will never be less than 0, & cannot be more than the container's width
    left = Math.min(Math.max(left, 0), containerWidth)

    // Min height & width should be MIN_SQUARE_SIDE but less than the container's height, width
    newW = Math.min(Math.max(newW, MIN_SQUARE_SIDE), containerWidth)
    newH = Math.min(Math.max(newH, MIN_SQUARE_SIDE), containerHeight)

    // The height & width + top & left cannot be more than the container's height & width
    if (areRightAnchors) {
        if (top + newH > containerHeight) {
            top = origParams.top
            newH = origParams.height
        }

        if (left + newW > containerWidth) {
            left = origParams.left
            newW = origParams.width
        }
    } else {
        if (top + newH > containerHeight) {
            top = containerHeight - MIN_SQUARE_SIDE
            newH = MIN_SQUARE_SIDE
        }

        if (left + newW > containerWidth) {
            left = containerWidth - MIN_SQUARE_SIDE
            newW = MIN_SQUARE_SIDE
        }
    }

    boxStyle.width = `${newW}px`
    boxStyle.height = `${newH}px`
    boxStyle.top = `${top}px`
    boxStyle.left = `${left}px`
}
