import React, { useContext, useEffect } from 'react';
import {
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import TopicList from './topic/topic-list';
import QuestionsHeader from './questions/questions-header';
import QuestionsList from './questions/questions-list';
import { LibraryContext } from './library-context';
import SingleQuestionHeader from './singleQuestion/single-question-header';
import { SingleQuestion, MultipleQuestions } from './singleQuestion/single-question';

export default function LibraryModal({
  isOpen,
  selectedCountry,
  allSubjects,
  onClose,
  onCountryChange,
  onSubjectChange,
  onAddQuestion,
  onAddMultipleQuestion
}) {
  const { query, setTopic, setSingleQuestion, setMultipleQuestion } = useContext(LibraryContext);

  const onBackFromQuestionsHeader = React.useCallback(() => {
    setTopic(true, {
      selectedTopic: ''
    });
  }, [setTopic]);

  const onBackFromSingleQuestionHeader = React.useCallback(() => {
    setSingleQuestion();
    setMultipleQuestion([]);
  }, [setSingleQuestion, setMultipleQuestion]);

  useEffect(() => {
    if (document) {
      document.getElementById('chakra-modal-tabScroll')?.scrollIntoView();
    }
  }, [query?.selectedSingleQuestion, query.selectedMultipleQuestions]);

  return (
    <Modal
      id="tabScroll"
      closeOnOverlayClick={false}
      isCentered={false}
      isOpen={isOpen}
      onClose={onClose}
      size="full"
    >
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalHeader>
          <HStack>
            {!query?.selectedTopic && (
              <Heading as="h5" fontSize={{ base: '2xl', xl: 'xl' }}>
                Question Library
              </Heading>
            )}
            {!!query?.selectedTopic &&
              !query.selectedQuestionId &&
              query.selectedMultipleQuestions.length === 0 && (
                <QuestionsHeader onBackFromQuestionsHeader={onBackFromQuestionsHeader} />
              )}
            {!!query?.selectedTopic &&
              (!!query.selectedQuestionId || query.selectedMultipleQuestions.length > 0) && (
                <SingleQuestionHeader
                  onBackFromSingleQuestionHeader={onBackFromSingleQuestionHeader}
                />
              )}
          </HStack>
        </ModalHeader>
        <ModalCloseButton
          m={1}
          background="#744CC6"
          color="#fff"
          borderRadius={30}
          _hover={{ background: '#744CC6', opacity: 0.7 }}
        />
        <ModalBody pb={6} display="flex" overflow="hidden" px={{ base: '2', lg: '3' }}>
          {!query?.selectedTopic && (
            <TopicList
              allSubjects={allSubjects}
              selectedCountry={selectedCountry}
              onSubjectChange={onSubjectChange}
              onCountryChange={onCountryChange}
            />
          )}
          {!!query?.selectedTopic &&
            !query.selectedQuestionId &&
            query.selectedMultipleQuestions.length === 0 && <QuestionsList />}
          {!!query?.selectedTopic &&
            !!query.selectedQuestionId &&
            query.selectedMultipleQuestions.length === 0 && <SingleQuestion />}
          {!!query?.selectedTopic &&
            !query.selectedQuestionId &&
            query.selectedMultipleQuestions.length > 0 && <MultipleQuestions />}

          {!!query?.selectedTopic &&
            !query.selectedQuestionId &&
            query.selectedMultipleQuestions.length > 0 && (
              <Flex
                position="fixed"
                left={0}
                zIndex={999}
                p={2}
                bg={'#fff'}
                pr={4}
                w={'100%'}
                bottom={0}
                flexDirection="row"
                mt={3}
                justify="flex-end"
                boxShadow="xs"
              >
                <Button
                  bgColor="#744CC6"
                  color="#fff"
                  fontSize="sm"
                  rounded={false}
                  onClick={() => onAddMultipleQuestion(query?.selectedMultipleQuestions)}
                  _hover={{ background: '#744CC6', opacity: 0.7 }}
                >
                  Add {query.selectedMultipleQuestions.length} Question
                  {query.selectedMultipleQuestions.length > 1 ? 's' : ''}
                </Button>
              </Flex>
            )}

          {!!query?.selectedTopic &&
            !!query.selectedQuestionId &&
            query.selectedMultipleQuestions.length === 0 && (
              <Flex
                position="fixed"
                left={0}
                zIndex={999}
                p={2}
                pr={4}
                w={'100%'}
                bg={'#fff'}
                bottom={0}
                flexDirection="row"
                mt={3}
                justify="flex-end"
                boxShadow="xs"
              >
                <Button
                  bgColor="#744CC6"
                  color="#fff"
                  fontSize="sm"
                  rounded={false}
                  onClick={() => onAddQuestion(query?.selectedSingleQuestion)}
                  _hover={{ background: '#744CC6', opacity: 0.7 }}
                >
                  Add Question
                </Button>
              </Flex>
            )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
