import { requestOptions } from "./constants";

export async function getGoogleCourses(accessToken, googleUserId) {
    try {
        const response = await fetch(   
            `https://classroom.googleapis.com/v1/courses?teacherId=${googleUserId}&courseStates=ACTIVE`,
            requestOptions(accessToken)
        );

        if(response.ok) {
            const data = await response.json();
            return data?.courses;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}

export async function getGoogleAssignments(accessToken, courses) {
    let assignments = [];
    if (courses?.length > 0) {
        await Promise.all(courses?.map(async(course) => {
            const courseId = course.id;
            const response = await fetch(
                `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork`,
                requestOptions(accessToken)
            );
            const data = await response.json();
            data?.courseWork?.map((assignment) => {
                assignment['courseName'] = course.name; 
                assignments.push(assignment);
            });
        }));
    }
    return assignments;
}

export async function getGoogleStudents(accessToken, courses) {
    let students = [];
    let studentIds = [];
    if (courses?.length > 0) {
        await Promise.all(courses?.map(async(course) => {
            const response = await fetch(
                `https://classroom.googleapis.com/v1/courses/${course.id}/students`,
                requestOptions(accessToken)
            );
            const data = await response.json();
            data?.students?.forEach(student => {
                if (!studentIds.includes(student.userId)) {
                    students.push(student);
                    studentIds.push(student.userid);
                }
            });
        }));
    }   
    return students;
}

export async function getGoogleTopics(accessToken, courses) {
    let topics = [];
    if (courses?.length > 0) {
        await Promise.all(courses.map(async(course) => {
            const response = await fetch(
                `https://classroom.googleapis.com/v1/courses/${course.id}/topics`,
                requestOptions(accessToken)
            );
            const data = await response.json();
            data?.topic?.map((t) => topics.push(t));
        }));
    }
    return topics;
}

export async function getGoogleStudentSubmissions(accessToken, assignments) {
    let submissions = [];
    await Promise.all(assignments.map(async(assignment) => {
        const response = await fetch(
            `https://classroom.googleapis.com/v1/courses/${assignment.courseId}/courseWork/${assignment.id}/studentSubmissions`,
            requestOptions(accessToken)
        );
        const data = await response.json();
        const submission = {
            'id': assignment.id,
            'yet_to_start': 0,
            'started': 0,
            'submitted': 0,
            'values': data.studentSubmissions
        }
        data?.studentSubmissions?.map((sub) => {
            switch(sub.state) {
                case 'NEW':
                    submission.yet_to_start++;
                    break;
                case 'CREATED':
                    submission.started++;
                    break;
                case 'TURNED_IN':
                    submission.submitted++;
                    break;
            }
        })
        submissions.push(submission)
    }))
    return submissions;
}

export const updateGoogleAssignments = async(assignments, submissions, topics) => {
    let newAssignments = assignments;
    for (let idx in assignments) {
        for (let sub of submissions) {
            if(assignments[idx].id == sub.id) {
                newAssignments[idx]['yet_to_start'] = sub.yet_to_start;
                newAssignments[idx]['started'] = sub.started;
                newAssignments[idx]['submitted'] = sub.submitted;
            }
        }
        for (let t of topics) {
            if(assignments[idx].courseId == t.courseId && assignments[idx]?.topicId == t.topicId) {
                newAssignments[idx]['topicName'] = t.name;
            }
        }
    }
    return newAssignments;
}