import { find } from 'lodash';
import { assignmentPages } from '../../../../../../constants';
import { v4 as uuidV4 } from 'uuid';

const addLessonPlan = (lessonPlan) => {
  return (dispatch, getState) => {
    // Object keys will become titles
    // Content inside these objects are strings, which will be plain p tags with the content.
    const {
      assignment: { pages }
    } = getState();

    const welcomePage = find(pages, (page) => page?.type === assignmentPages.WELCOME.displayName);

    Object.entries(lessonPlan).forEach(([key, value]) => {
      console.log('CREATING ELEMENT', { key, value });
      const elementHtml = `${htmlHelpers.header(key)}${htmlHelpers.list(value)}`;
      const elemID = uuidV4();

      const elemStruct = {
        id: elemID,
        ID: elemID,
        type: 'textbox',
        html: elementHtml,
        value: elementHtml
      };

      dispatch({
        type: 'ADD_PAGE_ELEMENT',
        payload: {
          pageID: welcomePage.id,
          element: elemStruct,
          directAdd: true
        }
      });
    });
  };
};

const htmlHelpers = {
  header: (str) => `<h2>${str}</h2>`,
  lineBreak: () => `<p><br></p>`,
  list: (items) => `<ul>${items.map((item) => `<li>${item}</li>`).join('')}</ul>`
};

export default addLessonPlan;
