import { extendTheme } from '@chakra-ui/react'


const breakpoints = {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    '2xl': '1350px',
  }

const theme = extendTheme({
  fonts: {
    body: `'Open Sans', sans-serif`,
  },
  fontSizes: {
    lg: '18px',
  },
  ...breakpoints
})

export default theme