import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import WelcomePage from './pages/welcome-page';
import { Box, Button } from '@chakra-ui/react';
import { assignmentPages } from '../../../../../constants';
import { getActivePage } from '../../redux/selectors';
import EndPage from './pages/end-page';
import OtherPage from './pages/other-page';
import McqPage from './pages/mcq-page';
import { RiDeleteBin6Line } from 'react-icons/ri';
import FibPage from './pages/fib-page';

export default function RightSidebar(props) {
  const close = props?.close;

  const activePage = useSelector(getActivePage);
  const pageID = get(activePage, 'id');
  const pageName = get(activePage, 'type');
  const dispatch = useDispatch();

  const hasOtherPage = useMemo(
    () =>
      pageName !== assignmentPages.WELCOME.displayName &&
      pageName !== assignmentPages.END.displayName &&
      pageName !== assignmentPages.MCQ.displayName &&
      pageName !== assignmentPages.FIB.displayName,
    [pageName]
  );

  const canDeletePage = useMemo(
    () =>
      pageName !== assignmentPages.WELCOME.displayName &&
      pageName !== assignmentPages.END.displayName,
    [pageName]
  );

  const deletePage = () => {
    dispatch({
      type: 'DELETE_PAGE',
      payload: { pageID }
    });

    dispatch({
      type: 'DELETE_ALL_PAGE_ELEMENTS',
      payload: { pageID }
    });

    // If this is open in the mobile view, close the sidebar drawer & the open question modal
    dispatch({
      type: 'CLOSE_ACTIVE_PAGE_MODAL',
      payload: {}
    });

    typeof close === 'function' && close();
  };

  return (
    <Box
      position="relative"
      borderLeft="1px"
      backgroundColor="#ffffff"
      borderColor="#F2F2F2"
      display="flex"
      flexDirection="column"
      w={{ lg: '200px', xl: '300px' }}
      overflowY="auto"
      pb={3}
      flex="1"
      className="right-sidebar"
      boxShadow={{ base: 'dark-lg', lg: 'unset' }}
    >
      {pageName === assignmentPages.WELCOME.displayName && <WelcomePage pageID={pageID} />}
      {pageName === assignmentPages.MCQ.displayName && (
        <McqPage pageID={pageID} pageName={pageName} />
      )}
      {pageName === assignmentPages.FIB.displayName && (
        <FibPage pageID={pageID} pageName={pageName} />
      )}

      {pageName === assignmentPages.END.displayName && <EndPage pageID={pageID} />}
      {hasOtherPage && <OtherPage pageID={pageID} />}
      {canDeletePage && (
        <Box display="flex" flex="1" alignItems="flex-end">
          <Button
            leftIcon={<RiDeleteBin6Line color="red" />}
            variant="solid"
            onClick={deletePage}
            width="100%"
            mx={3}
          >
            Delete Question
          </Button>
        </Box>
      )}
    </Box>
  );
}
