import { Box, Heading, WrapItem } from '@chakra-ui/react'
import React from 'react'
import { v4 as uuidV4 } from 'uuid'
import { ReactSortable } from 'react-sortablejs'
import { canvasContentBlockSidebar } from '../constant'
import Card from '../shared/card'
import { CustomSortableWrap } from '../shared/custom-sortable-wrapper'
import addContentBlock from '../../../redux/thunks/add-content-block'
import { useDispatch } from 'react-redux'

export default function ContentBlock({ pageID }) {
    const dispatch = useDispatch()

    const onContentBlockClick = (elemType) => {
        const elemID = uuidV4()
        dispatch(addContentBlock(pageID, elemID, elemType))
    }

    return (
        <Box display="flex" flexDirection="column">
            <Heading
                as="h5"
                px={4}
                mt={6}
                mb={2}
                position="relative"
                fontWeight={700}
                color="#686868"
                size="md"
                noOfLines={1}
            >
                {canvasContentBlockSidebar.title}
            </Heading>
            <ReactSortable
                tag={CustomSortableWrap}
                group={{
                    name: 'canvas-elements',
                    put: false,
                    pull: 'clone',
                    revertClone: true,
                }}
                list={canvasContentBlockSidebar.content}
                setList={() => {}}
                sort={false}
                animation={200}
                delay={2}
            >
                {canvasContentBlockSidebar.content.map((m) => {
                    return (
                        <WrapItem key={m.title} mt={3} mr={3}>
                            {
                                <Card
                                    key={m.title}
                                    title={m.title}
                                    desc={m.description}
                                    w={{ md: 200, lg: 170, xl: 170 }}
                                    h={{ md: 100, lg: 100, xl: 100 }}
                                    cursor="pointer"
                                    borderColor="#B2BEDA"
                                    borderRadius="8px"
                                    icon={m.icon}
                                    {...m}
                                    onContentBlockClick={onContentBlockClick}
                                />
                            }
                        </WrapItem>
                    )
                })}
            </ReactSortable>
            <hr />
        </Box>
    )
}
