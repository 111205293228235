import React from 'react'
import { Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux';
import { useAuth } from '../../auth/useAuth'
import { onAssignmentCreate } from '../../auth/createAssignment'
import { eventLogger } from '../../../api/eventLogger'
import { CREATE_TYPES } from '../../../constants';
import { useNavigate } from 'react-router-dom';

export default function UserOnboardModal({ isOpen, onClose }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { user, token } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAssignmentCreation = () => {
    setIsLoading(true);
    dispatch({
      type: 'UPDATE_ASSIGNMENT_DURATION',
      payload: null
    });

    dispatch({
      type: 'UPDATE_ASSIGNMENT_STARTDAY',
      payload: null
    });
    dispatch({
      type: 'UPDATE_ASSIGNMENT_DUEDATE',
      payload: null
    });
    dispatch({
      type: 'UPDATE_ALL_ASSIGNMENT_SETTINGS',
      payload: {
        revealSolutions: true,
        randomiseQuestions: false,
        allowMultipleSubmissions: true
      }
    });
    onAssignmentCreate(
      token,
      {
        source: 'createassignment-welcomebanner-clicked',
        createType: CREATE_TYPES.HOME_NEW_USER_ONBOARD_MODAL
      },
      navigate
    );
    dispatch({
      type: 'ASSIGNMENT_CREATED',
      payload: {
        createType: CREATE_TYPES.HOME_NEW_USER_ONBOARD_MODAL
      }
    });
    onClose();
  };

  const onBookDemo = () => {
    eventLogger(token, 'bookdemo-welcomebanner-clicked');
    if (typeof window !== 'undefined') {
      window.open('https://calendly.com/prephub/classwise-product-demo', '_blank');
    }
    onClose();
  };

  return (
    <Modal m={'3%'} onClose={onClose} isOpen={isOpen} isCentered size={{ base: 'lg', sm: '2xl' }}>
      <ModalOverlay />
      <ModalContent m={3}>
        <ModalCloseButton size={'sm'} bg="#5D38DB" color="#fff" borderRadius={25} />
        <ModalBody
          bgImage={{
            base: './assets/images/card-mobile-bg.png',
            sm: './assets/images/card-bg.png'
          }}
          backgroundRepeat={'no-repeat'}
          backgroundSize={{ base: 'cover', sm: 'cover' }}
        >
          <>
            <Flex p={10} flexDirection="column" alignItems="center">
              <Heading mb={5} align="center" color="#686868" as="h3" size="lg">
                Welcome to Classwise, {user?.displayName}
              </Heading>
              <Text align="center">
                Create your first assignment and unlock a world of time-saving features to elevate
                your teaching game! 🚀
              </Text>
              <Button
                onClick={onAssignmentCreation}
                isLoading={isLoading}
                loadingText="Please wait..."
                _hover={{ bg: '#5D38DB', opacity: 0.7 }}
                mt={8}
                shadow="md"
                bg="#5D38DB"
                color="#FFFFFF"
              >
                Create New Assignment
              </Button>
              <Button
                onClick={onBookDemo}
                mt={2}
                bg="transparent"
                color={'#686868'}
                _hover={{ bg: 'transparent' }}
                fontSize="sm"
              >
                Book a Demo
              </Button>
            </Flex>
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
