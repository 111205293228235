import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { assignmentPages } from '../../../../../../constants';
import { pick } from 'lodash';

export function adaptUpdateData(data) {
  const welcomeScreenRef = data.welcomeScreen;
  const thankYouScreenRef = data.thankYouScreen;

  delete welcomeScreenRef.config;
  delete welcomeScreenRef.type;
  delete thankYouScreenRef.config;
  delete thankYouScreenRef.type;

  // Adapt welcome & thank you pages
  forEach([welcomeScreenRef, thankYouScreenRef], adaptPage);

  forEach(data.questions, adaptQuestion);
}

export function adaptPage(page) {
  delete page.isDraggable;
  delete page.isEditable;
  delete page.pageIndex;

  page.elements = map(page.elements, adaptElement);
}

export function adaptElement(e) {
  const copied = pick(e, ['id', 'type', 'html']);
  copied.html = e.value || ''; // In case the element is fetched first but then not modified, it does not have a value
  return copied;
}

export function adaptQuestion(question) {
  const { type } = question;
  if (typeof questionAdapters[type] === 'function') {
    questionAdapters[type](question);
  } else {
    console.error(`Adapter not found for ${type}, returning question as is`);
    return;
  }
}

// Question type adapters to turn redux data into server data
const questionAdapters = {
  // Adapter for type MCQ
  [assignmentPages.MCQ.displayName]: (question) => {
    // TODO: TEMP ADDITION OF VARIABLES HERE, THIS NEEDS TO COME FROM STATE - @SHAHID
    if (!question.score) question.score = 100;
    if (!question.level) question.level = 1;
    question.type = question.type.toLowerCase();
    question.settings = pick(question.settings, ['allowMultipleSelection', 'randomizeOptions']);

    delete question.isDraggable;
    delete question.isEditable;
    delete question.pageIndex;
    delete question.subType;

    const questions = question.value.questions;
    const options = question.value.options;
    const solution = question.value.solution;

    question.elements = map(questions, adaptElement);
    question.solution = {
      elements: map(solution, adaptElement)
    };

    question.options = map(options, (option) => {
      const copied = pick(option, ['id', 'isCorrect']);
      copied.elements = map(option.value, adaptElement);

      return copied;
    });

    delete question.value;
  },

  [assignmentPages.FIB.displayName]: (question) => {
    // TODO: TEMP ADDITION OF VARIABLES HERE, THIS NEEDS TO COME FROM STATE - @SHAHID
    if (!question.score) question.score = 100;
    if (!question.level) question.level = 1;
    question.type = question.type.toLowerCase();

    delete question.isDraggable;
    delete question.isEditable;
    delete question.pageIndex;
    delete question.subType;

    const paragraph = question.value.paragraph;
    const solution = question.value.solution;
    const fibMetadata = question.value.fibMetadata;

    question.elements = [adaptElement(paragraph)];
    question.solution = {
      elements: map(solution, adaptElement)
    };
    question.fibMetadata = fibMetadata;

    question.options = [];

    delete question.value;
  }
};
