import { Box } from '@chakra-ui/react';
import React from 'react';
import CanvasArea from './canvas-area';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { getActivePage, getActivePageModalOpen } from './redux/selectors';

export default function CanvasPageColumn() {
  // Assignment details are now fetched in the canvas header so all three pages - create, share & results have the reference to it from redux store
  const activePage = useSelector(getActivePage);
  const activeModalOpen = useSelector(getActivePageModalOpen);

  return (
    <Box
      width="100%"
      overflow="auto"
      display={{ base: activeModalOpen ? 'block' : 'none', lg: 'unset' }}
      background={{ base: 'white', lg: '#ebecf0' }}
      style={{
        position: activeModalOpen && isMobile ? 'fixed' : 'unset',
        ...(isMobile
          ? {
              top: 0,
              left: 0,
              height: '100vh',
              oveflow: 'auto',
              zIndex: '2'
            }
          : {})
      }}
    >
      {activePage && (
        <CanvasArea
          pageIndex={activePage.pageIndex}
          key={activePage.id}
          pageID={activePage.id}
          pageType={activePage.type}
        />
      )}
    </Box>
  );
}
