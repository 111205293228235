import { Flex, Box } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { availableCountries } from '../../utils';
import { memo } from 'react';
import { LibraryContext } from '../library-context';
import { first } from 'lodash';
import { getSpecificFilter } from '../../utils/defaultFilters';
import { GrLocation } from 'react-icons/gr';

function CountryDropdown({ selectedCountry, onCountryChange }) {
  const { filterData, setQuery, setTopic } = useContext(LibraryContext);
  const goal = React.useMemo(() => first(filterData?.goals), [filterData]);
  const { language, source } = getSpecificFilter(filterData, goal);

  const onCountryChangeEffect = React.useCallback(async () => {
    setTopic(false);
  }, [setTopic]);
  React.useEffect(() => {
    onCountryChangeEffect();
  }, [filterData, goal, language, onCountryChangeEffect, setQuery, source]);


  return (
    <Flex
      alignItems="center"
      border="1px"
      borderColor="#000"
      borderRadius="3px"
      flex={1}
      // minWidth={{ base: 'unset', lg: '200px' }}
      maxWidth="200px"
      py={3}
    >
      <Flex alignItems="center">
        <GrLocation style={{ margin: '0px 5px 0px 10px', height: '16px', width: '16px' }} />
        <Text color="#8F8F8F" fontSize="sm" fontWeight="bold" pr={1}>
          COUNTRY:
        </Text>
      </Flex>
      <Box>
        <Menu preventOverflow>
          <MenuButton
            fontSize={'sm'}
            as={Button}
            rightIcon={<ChevronDownIcon ml={0} mr={2} />}
            variant="link"
          >
            <Text as="span" fontSize="sm" fontWeight="bold" color="#6364D8">
              {' '}
              {selectedCountry.label}{' '}
            </Text>
          </MenuButton>
          <MenuList fontSize={'sm'} minWidth="80px">
            {availableCountries.map((country, idx) => (
              <MenuItem key={`${country.name}_${idx}`} onClick={() => onCountryChange(country)}>
                {country.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}

export default memo(CountryDropdown);
