import React, { useEffect, useMemo } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { v4 as uuidV4 } from 'uuid';
import PropTypes from 'prop-types';
import McqOptions from './options/mcq-options';
import GenericElementContainer from './generic-element-container';
import { getStateSetter } from '../../../utils';

const defalutOptions = [
  {
    id: uuidV4(),
    name: 'Option 1',
    isCorrect: true,
    tooltip: 'option hint',
    value: [{ id: uuidV4(), type: 'textbox' }]
  },
  {
    id: uuidV4(),
    name: 'Option 2',
    isCorrect: false,
    tooltip: 'option hint',
    value: [{ id: uuidV4(), type: 'textbox' }]
  },
  {
    id: uuidV4(),
    name: 'Option 3',
    isCorrect: false,
    tooltip: 'option hint',
    value: [{ id: uuidV4(), type: 'textbox' }]
  }
];

const McqBox = (props) => {
  const { elementID, onUpdate, value } = props;

  useEffect(() => {
    if (!value) {
      onUpdate(elementID, {
        questions: [{ type: 'textbox', id: uuidV4() }],
        options: defalutOptions,
        solution: [{ type: 'textbox', id: uuidV4() }]
      });
    }
  }, [elementID, onUpdate, value]);

  const containerID = useMemo(() => `mcq-${uuidV4()}`, []);

  const setQuestions = useMemo(() => {
    const setter = (newVal) => {
      onUpdate(elementID, { ...value, questions: newVal });
    };

    return getStateSetter(value?.questions, setter);
  }, [elementID, onUpdate, value]);

  const setOptions = useMemo(() => {
    const setter = (newVal) => {
      onUpdate(elementID, { ...value, options: newVal });
    };

    return getStateSetter(value?.options, setter);
  }, [elementID, onUpdate, value]);

  const setSolution = useMemo(() => {
    const setter = (newVal) => {
      onUpdate(elementID, { ...value, solution: newVal });
    };

    return getStateSetter(value?.solution, setter);
  }, [elementID, onUpdate, value]);

  if (!value) {
    return <></>;
  }

  return (
    <Box
      overflow="hidden"
      minH={{ base: 'fit-content' }}
      display="flex"
      flexDirection="column"
      bg="white"
      py={{ base: 2, lg: 8 }}
      px={{ base: 0, lg: 8 }}
      mb={{ base: 8, lg: 0 }}
      className={`${containerID} mcq-element`}
    >
      <Flex minHeight={100}>
        <GenericElementContainer
          name={'question'}
          textPlaceholder="Start typing your question here..."
          setState={setQuestions}
          value={value.questions}
        />
      </Flex>
      <McqOptions options={value.options} setOptions={setOptions} />

      <Flex minHeight={100}>
        <GenericElementContainer
          name={'solution'}
          textPlaceholder="Enter Solution"
          setState={setSolution}
          value={value.solution}
        />
      </Flex>
    </Box>
  );
};

export default McqBox;

McqBox.propTypes = {
  elementID: PropTypes.string,
  onUpdate: PropTypes.func
};
