import React from 'react';

import { useAiRequestStatus } from '../shared/hooks';
import Banner from '../../common/Banner';

const AddingAiQuestions = () => {
  const { isAiRequestLoading, questions } = useAiRequestStatus();
  return (
    <Banner show={isAiRequestLoading} description={`Generating question ${questions.length + 1}`} />
  );
};

export default AddingAiQuestions;
