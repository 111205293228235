import React, { useMemo } from 'react'
import { Box, Divider } from '@chakra-ui/react'
import { v4 as uuidV4 } from 'uuid'
import PropTypes from 'prop-types'
import { StaticContentWrap } from './utils'
import LibraryOptionsContainer from './library-options-container'
import { getStateSetter } from '../../../utils'

const LibraryQuestion = (props) => {
    const { elementID, onUpdate, value } = props

    const questions = value?.questions
    const options = value?.options
    const solution = value?.solution
    const containerID = useMemo(() => `mcq-${uuidV4()}`, [])

    const setOptions = useMemo(() => {
        const setter = (newVal) => {
            onUpdate(elementID, { ...value, options: newVal })
        }

        return getStateSetter(value?.options, setter)
    }, [elementID, onUpdate, value])

    return (
      <Box
        overflow="hidden"
        minH="100vh"
        display="flex"
        flexDirection="column"
        bg="white"
        pb={3}
        className={`${containerID}`}
      >
        <StaticContentWrap type="Question" contentArray={questions} />
        <Divider />
        <LibraryOptionsContainer options={options} setOptions={setOptions} key={elementID} />
        <Divider />
        <StaticContentWrap type="Solution" contentArray={solution} />
      </Box>
    );
}

export default LibraryQuestion

LibraryQuestion.propTypes = {
    elementID: PropTypes.string,
    onUpdate: PropTypes.func,
}
