/* eslint-disable react/prop-types */
import React from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';

export default function AddOption({ onAddOption }) {
  return (
    <Flex align="center" justify="center" position="relative" w="100%" mb={3}>
      <Box position="absolute" backgroundColor="#E6E8F1" h={1} width="100%" my={4}></Box>
      <Flex
        zIndex={1}
        align="center"
        cursor="pointer"
        justify="center"
        background="#744CC6"
        w={10}
        borderRadius="25px"
        height={10}
        color="#FFFFFF"
        p={2}
        onClick={onAddOption}
      >
        <AddIcon />
      </Flex>
    </Flex>
  );
}
