import { assignmentPages } from '../../../../../../constants'
import adaptLibraryQuestion from './utils'

export default function addLibraryQuestion(question, pageIndex) {
    return async function addLibraryQuestionThunk(dispatch) {
        const questionType = question.type

        switch (questionType) {
            case assignmentPages.MCQ.displayName:
                {
                    const { libraryPage, libraryMcqElement } =
                        adaptLibraryQuestion[questionType](question, pageIndex)
                    dispatch({
                        type: 'ADD_PAGE',
                        payload: { page: libraryPage },
                    })

                    dispatch({
                        type: 'ADD_PAGE_ELEMENT',
                        payload: {
                            pageID: libraryPage.id,
                            element: libraryMcqElement,
                            directAdd: true,
                        },
                    })

                    // Now, set the view to the recently added question
                    dispatch({
                        type: 'UPDATE_ACTIVE_PAGE',
                        payload: {
                            pageID: libraryPage.id,
                        },
                    })

                    dispatch({
                        type: 'UPDATE_PAGE_SETTING',
                        payload: {
                            pageID: libraryPage.id,
                            [`settings.randomizeOptions`]: true
                        },
                    })

                    // Scroll the newly added element into view
                    setTimeout(() => {
                        const element = document.getElementById(
                            `canvas-${libraryPage.id}`
                        )
                        if (element) {
                            // 👇 Will scroll smoothly to the top of the next section
                            element.scrollIntoView({ behavior: 'smooth' })
                        }
                    }, 0)
                }
                break
            default:
                return
        }
    }
}
