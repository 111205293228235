import { clone, get } from 'lodash';

export function insertStringAt(str, strIndex, strToInsert) {
  return str.slice(0, strIndex) + strToInsert + str.slice(strIndex);
}

export function getStateSetter(originalValue, setter) {
  return function (value) {
    if (typeof value === 'function') {
      setter(value(clone(originalValue)));
    } else {
      setter(value);
    }
  };
}

export function compareListOrder(a, b) {
  for (let i = 0; i < a.length; i++) {
    if (!(a[i] === b[i] || get(a[i], 'id') === get(b[i], 'id'))) {
      return false;
    }
  }
  return true;
}

export function minutesLabel(minutes) {
  return `${minutes} ${minutes > 10 ? ' minutes' : ' minute'}`;
}

export const getMinutes = (duration) => {
  if (duration) {
    return +duration / 60000;
  } else {
    return null;
  }
};

export const getTotalScore = (pages) => {
  return Object.values(pages).reduce((acc, curr) => {
    acc += curr.score || 0;
    return acc;
  }, 0);
};
