import React, { useMemo, useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { fetchAssignmentByID } from '../../app/canvas/redux/thunks/get-assignment/utils';
import { useAuth } from '../../auth/useAuth';
import AssignmentCarousel from './assignment-carousel';
import { downloadPreviewTemplate } from '../../app/canvas/download-pdf/utils';
import { isMobile } from 'react-device-detect';
import { LoadingSkeleton } from '../../assignments/loadingSkeleton';

const Wrapper = ({ isTemplatePreviewOpen, closeTemplatePreview, children }) => {
  if (isMobile) {
    return (
      <Drawer
        placement="bottom"
        isOpen={isTemplatePreviewOpen}
        onClose={closeTemplatePreview}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent minH="70vh">
          <DrawerCloseButton mt="4px" />
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Modal isOpen={isTemplatePreviewOpen} onClose={closeTemplatePreview} size="6xl" minH="80vh">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody px={{ base: 4, lg: 6 }} minH="55vh">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const TemplatePreviewModal = ({
  previewTemplate,
  isTemplatePreviewOpen,
  closeTemplatePreview,
  onCloneTemplateClicked
}) => {
  const { token } = useAuth();
  const [previewData, setPreviewData] = useState(null);

  useMemo(async () => {
    if (!previewTemplate?.assignmentId) return;

    const res = await fetchAssignmentByID(previewTemplate.assignmentId, token);

    if (!res.ok) {
      return;
    }

    const assignmentData = await res.json();
    // Add any additional data from the template table
    assignmentData.difficulty = previewTemplate.difficulty;
    console.log(assignmentData);
    setPreviewData(assignmentData);
  }, [previewTemplate?.assignmentId, previewTemplate?.difficulty, token]);

  return (
    <Wrapper
      closeTemplatePreview={closeTemplatePreview}
      isTemplatePreviewOpen={isTemplatePreviewOpen}
    >
      {previewData ? (
        <Flex w="100%" mt={{ base: 2, lg: 8 }} flexDirection={{ base: 'column', lg: 'row' }}>
          {/* Assignment info on mobile is on top */}
          {isMobile && (
            <Flex flexDirection="column" mb={4}>
              <Heading as="h2" size={{ base: 'md', lg: 'xl' }}>
                {previewData.name}
              </Heading>
              <Text fontSize={{ base: 'md', lg: '2xl' }} mt={1}>
                {previewData.questions.length} Questions, {previewData.difficulty}
              </Text>
            </Flex>
          )}
          {/* For questions preview */}
          <Flex w={{ base: '100%', lg: '50%' }} mr={10}>
            <AssignmentCarousel {...previewData} />
          </Flex>
          {/* For assignment information */}
          <Flex flexDirection="column" flex={1}>
            {!isMobile && (
              <Flex flexDirection="column">
                <Heading as="h2" size={{ base: 'md', lg: 'xl' }}>
                  {previewData.name}
                </Heading>
                <Text fontSize={{ base: 'md', lg: '2xl' }} mt={1}>
                  {previewData.questions.length} Questions, {previewData.difficulty}
                </Text>
              </Flex>
            )}
            <Flex justifyContent="space-between" mt={2}>
              <Button
                borderRadius="none"
                width="49%"
                fontSize={{ base: '12px', lg: '14px' }}
                bg="#5D38DB"
                color="white"
                whiteSpace="normal"
                _hover={{ bg: '#6d4ed4' }}
                position="unset"
                onClick={() => onCloneTemplateClicked(previewTemplate.assignmentId)}
              >
                Customise this Assignment
              </Button>
              <Button
                borderRadius="none"
                width="49%"
                fontSize={{ base: '12px', lg: '14px' }}
                variant="outline"
                whiteSpace="normal"
                position="unset"
                onClick={() => downloadPreviewTemplate(previewData)}
              >
                Download as PDF
              </Button>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <LoadingSkeleton />
      )}
    </Wrapper>
  );
};

export default TemplatePreviewModal;
