import { Box, VStack } from '@chakra-ui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ContentBlock from '../utils/content-block'
import ButtonNameInput from '../utils/page-input'
import SwitchButton from '../utils/switch-button'
import { getAssignmentDataSettings } from '../../../redux/selectors'

// eslint-disable-next-line react/prop-types
export default function EndPage({ pageID }) {
    const dispatch = useDispatch()
    const settings = useSelector(getAssignmentDataSettings)

    const onChange = React.useCallback(
        (dataKey, checked) => {
            dispatch({
                type: 'UPDATE_ASSIGNMENT_SETTING',
                payload: {
                    pageID,
                    dataKey,
                    value: checked,
                },
            })
        },
        [dispatch, pageID]
    )

    return (
        <Box>
            <ContentBlock pageID={pageID}/>
            {/* <ButtonNameInput pageID={pageID} /> */}
            {/* <VStack my={3} px={3}>
                <SwitchButton
                    label="Allow Multiple Submissions"
                    isActive={settings?.allowMultipleSubmissions}
                    datakey="allowMultipleSubmissions"
                    onChange={onChange}
                />
                <SwitchButton
                    label="Randomise Questions"
                    isActive={settings?.randomiseQuestions}
                    datakey="randomiseQuestions"
                    onChange={onChange}
                />
                <SwitchButton
                    label="Reveal Solutions"
                    isActive={settings?.revealSolutions}
                    datakey="revealSolutions"
                    onChange={onChange}
                />
            </VStack> */}
        </Box>
    )
}
