import React from 'react';
import { Box, Center, Spinner } from '@chakra-ui/react';

const Banner = ({ description, show }) => {
  if (!show) {
    return null;
  }

  return (
    <Center
      position="fixed"
      top={{ base: 'auto', md: '10%' }}
      bottom={{ base: '10%', md: 'auto' }}
      w="100vw"
      zIndex="9999"
    >
      <Box bg="white" color="black" p="2" borderRadius="md" shadow="md" pl={3} pr={3} w="300px">
        <Spinner size="xs" mr={3} color="black" />
        {description}
      </Box>
    </Center>
  );
};

export default Banner;
