// import { v4 as uuidV4 } from 'uuid'
import { produce, current } from 'immer';

const initialState = {};

export const elementsReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ASSIGNMENT_ELEMENTS': {
      return payload;
    }

    case 'ADD_PAGE_ELEMENT': {
      const { pageID, element, directAdd = true } = payload;
      if (!draft[pageID]) {
        draft[pageID] = {};
      }
      const elemID = element.ID;
      draft[pageID].elements = draft[pageID].elements || {};
      draft[pageID].elements[elemID] = {
        ...element,
        id: elemID
      };

      if (!draft[pageID].elementOrder) {
        draft[pageID].elementOrder = [{ id: elemID }];
      } else {
        // Some elements are directly added & we don't expect the list to change via sortable, hence we need to set the elementOrder also
        if (directAdd) {
          draft[pageID].elementOrder.push({ id: elemID });
        }
      }

      return;
    }

    case 'DELETE_PAGE_ELEMENT': {
      const { pageID, elementID } = payload;
      delete draft[pageID]?.elements?.[elementID];
      // Remove the element from the order that we're maintaining
      draft[pageID].elementOrder = current(draft[pageID].elementOrder).filter(
        (elem) => elem.id !== elementID
      );
      return;
    }

    case 'DELETE_ALL_PAGE_ELEMENTS': {
      const { pageID } = payload;
      delete draft[pageID];
      return;
    }

    case 'UPDATE_PAGE_ELEMENT': {
      const { pageID, elementID, value } = payload;
      if (!draft[pageID]) {
        draft[pageID] = {
          elements: {}
        };
      }
      draft[pageID].elements[elementID] = draft[pageID].elements[elementID] || {};
      draft[pageID].elements[elementID].value = value;
      return;
    }

    case 'UPDATE_ELEMENT_ORDER': {
      const { pageID, order } = payload;
      if (!draft[pageID]) {
        draft[pageID] = {};
      }
      draft[pageID].elements = draft[pageID].elements || {};
      const elements = draft[pageID].elements;
      draft[pageID].elementOrder = order.map((item) => ({ id: item.id }));

      // If any elements are not present in the list, add them
      order.forEach((elem) => {
        if (elements && !elements[elem.id]) {
          elements[elem.id] = { id: elem.id, type: elem.type };
        }
      });
      return;
    }

    case 'CLEAR_ASSIGNMENT_STATE':
      return initialState;
    default: {
      // Always return draft as the default is initial state otherwise the draft object
      return draft;
    }
  }
});
