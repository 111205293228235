import { MenuItem } from '@chakra-ui/react';
import React from 'react';

export const MobileMenuListItem = ({ Icon, iconColor = '', text, ...rest }) => {
  return (
    <MenuItem fontSize="14px" icon={<Icon size="14px" color={iconColor} />} closeOnSelect {...rest}>
      {text}
    </MenuItem>
  );
};
