import { find } from 'lodash';
import { assignmentPages } from '../../../../../../constants';
import { v4 as uuidV4 } from 'uuid';

const addWelcomePageElement = ({ dispatch, getState, elementHtml }) => {
  const {
    assignment: { pages }
  } = getState();
  const welcomePage = find(pages, (page) => page?.type === assignmentPages.WELCOME.displayName);
  const elemID = uuidV4();
  const elemStruct = {
    id: elemID,
    ID: elemID,
    type: 'textbox',
    html: elementHtml,
    value: elementHtml
  };

  dispatch({
    type: 'ADD_PAGE_ELEMENT',
    payload: {
      pageID: welcomePage.id,
      element: elemStruct,
      directAdd: true
    }
  });
};
const addLearningActivity = (learningActivity) => {
  return (dispatch, getState) => {
    const { title, learningObjectives, activities, extensions, duration } = learningActivity;
    // Chapter
    const chapterElementHtml = `<h1><strong>${title}</strong></h1><p>Duration - ${duration}</p>`;
    addWelcomePageElement({ dispatch, getState, elementHtml: chapterElementHtml });
    // Learning Objectives
    const learningObjectivesHtml = `${htmlHelpers.header(
      'Key Learning Objectives'
    )}${htmlHelpers.list(learningObjectives)}`;
    addWelcomePageElement({ dispatch, getState, elementHtml: learningObjectivesHtml });

    // Activities
    addWelcomePageElement({ dispatch, getState, elementHtml: htmlHelpers.header('Activities') });
    activities.forEach((activity, activityIndex) => {
      const {
        title,
        objective,
        taskSteps,
        sampleAnswer,
        type,
        resourcesNeeded,
        skillsLearned,
        actionExpected
      } = activity;
      let activityHtml = `<h3><strong>Activity ${activityIndex + 1}: "${title}"</strong></h3>
        <ul>
          <li><strong>Objective: </strong>${objective}</li>
          <li><strong>Type of Activity: </strong>${type}</li>
          <li><strong>Resources Needed: </strong>${resourcesNeeded}</li>
          <li><strong>Skills Students Will Learn: </strong>${skillsLearned}</li>
          <li><strong>Action Expected: </strong>${actionExpected}</li>
          <li>
            <strong>Task:</strong>
            ${htmlHelpers.list(taskSteps)}
          </li>
          <li><strong>Sample Answer: </strong>${sampleAnswer}</li>
        </ul>`;
      addWelcomePageElement({ dispatch, getState, elementHtml: activityHtml });
    });

    //Extensions
    const extensionsHtml = `${htmlHelpers.header('Extensions')}${htmlHelpers.list(extensions)}`;
    addWelcomePageElement({ dispatch, getState, elementHtml: extensionsHtml });
  };
};

const htmlHelpers = {
  header: (str) => `<h2><strong>${str}</strong></h2>`,
  para: (str) => `<p>${str}</p>`,
  lineBreak: () => `<p><br></p>`,
  list: (items) => `<ul>${items.map((item) => `<li>${item}</li>`).join('')}</ul>`
};

export default addLearningActivity;
