import { combineReducers, configureStore } from '@reduxjs/toolkit';
import assignmentReducer from './reducers/assignment-reducer';
import { elementsReducer } from './reducers/elements-reducer';
import { updateStateReducer } from './reducers/update-state-reducer';
import { assignmentSettingReducer } from './reducers/assignment-setting-reducer';
import { onboardingTourReducer } from './reducers/onboarding-tour-reducer';
import { assignmentMetadataReducer } from './reducers/assignment-metadata-reducer';
import { aiQuestionsModalStateReducer } from './reducers/ai-questions-modal-state-reducer';

export default configureStore({
  reducer: combineReducers({
    assignment: assignmentReducer,
    elements: elementsReducer,
    updateAssignmentState: updateStateReducer,
    assignmentSetting: assignmentSettingReducer,
    onboardingTour: onboardingTourReducer,
    assignmentMetadata: assignmentMetadataReducer,
    aiQuestionsModalState: aiQuestionsModalStateReducer
  }),
  devTools: true
});
