import { Badge } from '@chakra-ui/react';
import React from 'react';

// Component for displaying the FIB question as is, with the right answers
const FIBBlankDisplay = ({ answers }) => {
  return (
    <Badge fontSize="12px" colorScheme="green" display="flex" alignItems="center" mx={1}>
      {answers?.[0]}
    </Badge>
  );
};

export default FIBBlankDisplay;
