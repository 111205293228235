import { useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignmentUpdateState } from '../canvas/redux/selectors';
import { throttle } from 'lodash';
import useUpdateAssignment from '../canvas/shared/hooks/use-update-assignment';
import usePrevious from '../canvas/shared/hooks/use-previous';

const UPDATE_WAIT_TIME = 10_000;
const ATLEAST_UPDATE_INTERVAL = 30_000;
const MIN_COUNT_FORCE_UPDATE = 10;
/*
  When do we want to update the assignment in DB?
  - When the last user update has happened & atleast 10s has passed
  - Once every 30s in case the user is rapidly updating

  If the user tries to leave the page before the update has succeeded, we should ask them for confirmation

  Flags initialFetching & loading will be handled on the redux thunk directly so no update happens if either flags are true

  If there is an error, we set error but the useEffect hooks looking at lastUserUpdate / lastBackendUpdate do not trigger unless the user triggers another update
*/

const useAutoAssignmentUpdate = () => {
  const dispatch = useDispatch();
  const updateTimeout = useRef();
  const updateCount = useRef(0);

  const updateAssignmentAction = useUpdateAssignment();

  const { needsUpdate, lastUserUpdate, lastBackendUpdate } = useSelector(getAssignmentUpdateState);

  const prevUserUpdate = usePrevious(lastUserUpdate);

  // Effect to prevent user from traversing out of the page if an update has not happened
  useEffect(() => {
    // Internally defined to preserve context
    const onUnload = (evt) => {
      if (needsUpdate) {
        evt.returnValue =
          'Assignment saving is in progress, please press Cancel if you wish to save before you leave.';
      } else {
        return '';
      }
    };
    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [needsUpdate]);

  // Effect that updates the times user has updated the assignment
  useEffect(() => {
    if (lastUserUpdate !== prevUserUpdate && lastUserUpdate > lastBackendUpdate) {
      updateCount.current++;
    }
  }, [lastBackendUpdate, lastUserUpdate, prevUserUpdate]);

  // Once the user has updated the assignment MIN_COUNT_FORCE_UPDATE times, & the assignment has not been updated in the last ATLEAST_UPDATE_INTERVAL seconds, force update it
  useEffect(() => {
    const currTime = Date.now();
    if (
      updateCount.current > MIN_COUNT_FORCE_UPDATE &&
      currTime - lastBackendUpdate >= ATLEAST_UPDATE_INTERVAL
    ) {
      updateCount.current = 0;
      clearTimeout(updateTimeout.current);
      updateAssignmentAction();
    }
    // Deps has lastUserUpdate as we need to trigger this every time a user update comes in & we cannot have a watcher on the ref
  }, [lastUserUpdate, lastBackendUpdate, updateAssignmentAction]);

  // Effect that updates the assignment after waiting for atleast UPDATE_WAIT_TIME seconds after the last user update
  useEffect(() => {
    if (needsUpdate) {
      clearTimeout(updateTimeout.current);
      updateTimeout.current = setTimeout(() => {
        if (needsUpdate) {
          updateCount.current = 0;
          updateAssignmentAction();
        }
      }, UPDATE_WAIT_TIME);
    } else {
      clearTimeout(updateTimeout.current);
    }

    // Deps containing lastUserUpdate as we need to reset the timer & ensure it runs 5s after the last user action
  }, [needsUpdate, lastUserUpdate, updateAssignmentAction]);

  const debouncedUpdate = useMemo(
    () =>
      throttle(
        (flag) => {
          dispatch({
            type: 'ASSIGNMENT_NEEDS_UPDATE',
            payload: flag
          });
        },
        2000,
        { trailing: true }
      ),
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setNeedsUpdate = useCallback(
    (...args) => {
      debouncedUpdate(...args);
    },
    [debouncedUpdate]
  );

  useEffect(() => {
    /*
            If there is no call to update assignment been done & an FE update has already happened
            OR if the last assignment update to BE happened before the last user update
              TRUE
            If an assignment update has happened after the last FE update
            OR no FE update has happened yet
              FALSE
        */
    if ((!lastBackendUpdate && lastUserUpdate > 0) || lastBackendUpdate < lastUserUpdate) {
      setNeedsUpdate(true);
    } else {
      setNeedsUpdate(false);
    }
  }, [lastBackendUpdate, lastUserUpdate, setNeedsUpdate]);
};

export default useAutoAssignmentUpdate;
