import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Box, Text, Input, Button, usePrevious, Divider } from '@chakra-ui/react';
import { v4 as uuidV4 } from 'uuid';
import GenericElementContainer from './mcqbox/generic-element-container';
import TextBox from './textbox/textbox';
import Wrapper from './wrapper';
import { TextboxModes, getBlankNode, getFibBlankConfig, getFibTotalScore, updateNodeHtml } from './fib-utils';
import { isEqual, throttle } from 'lodash';
import { useDispatch } from 'react-redux';

const FillInBlanks = (props) => {
  const { pageID, elementID, onUpdate, value: reduxValue } = props;

  const editorRef = useRef();

  const dispatch = useDispatch();

  const reduxValueRef = useRef(reduxValue);
  reduxValueRef.current = reduxValue;

  // Throttled update to directly update the state from this component & not use the wrapper via canvas area
  // This makes it an uncontrolled managed component unlike other question types
  // This is primarily done as the state for paragraph & blankConfig update simultaneously, & when that happens,
  // we lose one of the two states as one of the two updates override the other

  /*
    This is how we'll be updating this question's overall state
    
    There are three possibilities for a state change:
    1. Update solution - This update is unrelated to the other two elements & hence when this is happening, it can directly update the element state
    2. Blank text or score - Although this update happens to blanks, it mostly happens independently & can be done directly
    3. Paragraph text OR Blank addition - This needs two updates: the paragraph text that changes & blank addition to state; but both need to
       also not override the new state for each other
  */

  // Managed blank state & ref to state to use in functions that cannot be remade
  const [blankStates, setBlankStates] = useState({});
  const prevBlankStates = usePrevious(blankStates);
  const lastUpdatedBlankConfig = useRef({
    adaptedBlankConfig: reduxValue?.fibMetadata?.blankConfig
  });
  lastUpdatedBlankConfig.current.prevBlankStates = prevBlankStates;

  // Managed paragraph state
  const [paragraphText, setParagraphText] = useState(reduxValue?.paragraph?.value || '');

  // Managed solution state
  const [solution, setSolution] = useState(reduxValue?.solution || []);

  // Throttled function to update the state in redux
  const throttledUpdate = useMemo(
    () =>
      throttle((elementID, value) => {
        dispatch({
          type: 'UPDATE_PAGE_ELEMENT',
          payload: {
            pageID,
            elementID,
            value
          }
        });
      }, 500),
    [dispatch, pageID]
  );

  const throttledScoreUpdate = useMemo(
    () =>
      throttle(() => {
        dispatch({
          type: 'UPDATE_PAGE_SETTING',
          payload: {
            pageID,
            score: getFibTotalScore(lastUpdatedBlankConfig.current.adaptedBlankConfig)
          }
        });
      }, 1000),
    [dispatch, pageID]
  );

  // When question's overall element is created or if the page is refreshed
  useEffect(() => {
    // On create
    if (!reduxValue) {
      throttledUpdate(elementID, {
        paragraph: { type: 'textbox', id: uuidV4() },
        solution: [{ type: 'textbox', id: uuidV4() }],
        fibMetadata: {
          blankConfig: {}
        }
      });
    } else {
      /* On refresh of the page when redux has the FIB element's state */
      // Set paragraph state
      setParagraphText(reduxValue?.paragraph?.value || '');

      // Set solution state
      setSolution(reduxValue?.solution);

      // Set blank states
      setBlankStates((state) => {
        const blankConfig = reduxValue?.fibMetadata?.blankConfig;
        if (!blankConfig) return state;

        return {
          ...state,
          ...Object.keys(blankConfig).reduce((acc, key) => {
            const existingBlankState = state[key] || {};
            const apiBlankState = blankConfig[key];
            const textValue = apiBlankState?.answers?.[0];

            acc[key] = {
              ...existingBlankState,
              value: textValue,
              score: apiBlankState?.score
            };
            return acc;
          }, {})
        };
      });
    }
    // Adding value as a dependency here makes this a loop update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementID, onUpdate]);

  // Updating paragraphText & blankStates in redux
  useEffect(() => {
    // If the blank states have not changed, & this is not the first render, don't recreate the blank config
    if (
      lastUpdatedBlankConfig.current.prevBlankStates &&
      blankStates !== lastUpdatedBlankConfig.current.prevBlankStates
    ) {
      lastUpdatedBlankConfig.current.adaptedBlankConfig = getFibBlankConfig(blankStates);
    }

    // If equal, don't update in redux state as this causes the assignment to update via API unnecessarily
    if (
      isEqual(lastUpdatedBlankConfig.current.adaptedBlankConfig, reduxValueRef.current?.fibMetadata?.blankConfig) &&
      isEqual(paragraphText, reduxValueRef.current?.paragraph?.value)
    ) {
      return;
    }

    throttledUpdate(elementID, {
      ...reduxValueRef.current,
      paragraph: {
        ...(reduxValueRef.current?.paragraph || {}),
        value: paragraphText
      },
      fibMetadata: {
        blankConfig: lastUpdatedBlankConfig.current.adaptedBlankConfig
      }
    });

    // Update the page's score but in a heavily throttled manner
    throttledScoreUpdate();
  }, [blankStates, dispatch, elementID, pageID, paragraphText, throttledScoreUpdate, throttledUpdate]);

  // Updating solution in redux
  useEffect(() => {
    // Prevent update if the values are referentially equal
    if (isEqual(reduxValueRef.current?.solution, solution)) return;

    throttledUpdate(elementID, { ...reduxValueRef.current, solution });
  }, [elementID, solution, throttledUpdate]);

  // Initial update of the nodes within the paragraph text to reflect the answers of the blanks
  useEffect(() => {
    // Also update the local blank node states
    const blankConfig = reduxValueRef.current?.fibMetadata?.blankConfig;
    if (!blankConfig) return;

    // Initial state update from paragraphText can override these updates, hence adding after a timeout
    setTimeout(() => {
      Object.keys(blankConfig).forEach((key) => updateNodeHtml(editorRef.current, key, blankConfig));
    }, 10);
  }, []);

  const containerID = useMemo(() => `fib-${uuidV4()}`, []);

  const onBlankInsert = useCallback(function () {
    // TODO: Move to either toolbar's fib button or this component's button once UX is decided
    const quill = this?.quill || editorRef.current;

    if (!quill) return;

    const blankID = uuidV4();
    const cursorPosition = quill.getSelection(true).index;

    quill.insertEmbed(cursorPosition, 'blank-blot', { blankID }, 'api');

    setTimeout(() => {
      quill.insertText(cursorPosition + 1, ' ', 'api');
      quill.setSelection(cursorPosition + 2, 'api');
    });
  }, []);

  const editorRenderCallback = useCallback((reactQuill) => {
    // We've already attached & run through these events
    if (!reactQuill || editorRef.current?.editor === reactQuill?.editor) return;

    // console.log('Reattaching editor events');

    editorRef.current = reactQuill?.editor;

    // Attach event handlers on editor
    editorRef.current.scroll.emitter.on('blot-removed', (e) => {
      const blankData = e.detail.blotDetail?.['blank-blot'];
      // console.log('Removed blank UUID', blankData);

      if (!blankData) return;

      setBlankStates((state) => {
        const blankState = state[blankData.blankID];
        if (!blankState || !blankState.active) return state;

        return {
          ...state,
          [blankData.blankID]: { ...blankState, active: false }
        };
      });
    });

    editorRef.current.scroll.emitter.on('blot-added', (e) => {
      const blankData = e.detail.blotDetail?.['blank-blot'];
      // console.log('Added blank UUID', blankData);

      setBlankStates((state) => {
        const blankState = state[blankData.blankID] || {};

        if (blankState.active) return state;

        const newBlankState = {
          ...blankState,
          blankID: blankData.blankID,
          active: true,
          value: blankState.value || `Blank`,
          score: blankState.score || 10 // Default score
        };
        return {
          ...state,
          [blankData.blankID]: newBlankState
        };
      });
    });
  }, []);

  const blankValueChange = useCallback((evt, blankID) => {
    const value = evt.target.value;
    setBlankStates((state) => {
      const blankState = state[blankID];
      return {
        ...state,
        [blankID]: { ...blankState, value }
      };
    });

    // Update value in the editor's span node
    const textNode = getBlankNode(editorRef.current, blankID);
    textNode.innerHTML = value;
  }, []);

  const updateBlankScore = (e, blankID) => {
    const value = e.target.value;
    setBlankStates((state) => {
      const blankState = state[blankID];
      return {
        ...state,
        [blankID]: { ...blankState, score: Number(value) }
      };
    });
  };

  // console.log('Rendering', reduxValue);
  if (!reduxValue) {
    return <></>;
  }

  return (
    <Box
      overflow="hidden"
      minH="100vh"
      display="flex"
      flexDirection="column"
      bg="white"
      className={`${containerID} mcq-element`}
      px={{ base: 0, lg: 8 }}
    >
      <Flex minHeight={100} my={8}>
        <Flex
          flex={1}
          borderColor={'#A1ADCD'}
          position="relative"
          borderRadius={5}
          borderWidth={1}
          px={2}
          width={{ base: '100%', lg: 'unset' }}
        >
          <Text
            p={1}
            style={{
              position: 'absolute',
              top: -15,
              fontWeight: 800,
              backgroundColor: '#fff'
            }}
          >
            Question
          </Text>
          <Button
            position="absolute"
            right="6px"
            top="-20px"
            variant="outline"
            backgroundColor="white"
            borderColor="#5D38DB"
            borderWidth="2px"
            size="sm"
            onClick={onBlankInsert}
          >
            Add New Blank
          </Button>
          <Wrapper key={`${elementID}-paragraph`} static={true}>
            <TextBox
              forwardRef={editorRenderCallback}
              elementID="fib-textbox"
              onUpdate={(_, value) => setParagraphText(value)}
              value={paragraphText}
              mode={TextboxModes.FILL_IN_BLANKS}
              onBlankInsert={onBlankInsert}
            />
          </Wrapper>
        </Flex>
      </Flex>

      <Flex mb={8} position="relative" width={{ base: '100%', lg: 'unset' }} flexDirection="column" gap={3}>
        <Text
          style={{
            fontWeight: 800,
            backgroundColor: '#fff'
          }}
          mb={2}
        >
          Blank Answers
        </Text>
        {Object.values(blankStates).map((state) => {
          if (!state.active) return <></>;

          return (
            <>
              <Flex
                key={state.blankID}
                alignItems={{ base: 'start', lg: 'center' }}
                flexDirection={{ base: 'column', lg: 'row' }}
              >
                <Text whiteSpace="nowrap" mr={8} fontWeight={600}>
                  Blank Text:
                </Text>
                <Flex>
                  <Flex flexDirection={{ base: 'column', lg: 'row' }} alignItems={{ base: 'start', lg: 'center' }}>
                    <Text whiteSpace="nowrap" mr={4}>
                      Answer:
                    </Text>
                    <Input
                      focusBorderColor="#5D38DB"
                      type="text"
                      onChange={(e) => blankValueChange(e, state.blankID)}
                      value={state.value}
                    />
                  </Flex>
                  <Flex
                    flexDirection={{ base: 'column', lg: 'row' }}
                    ml={{ base: 4, lg: 0 }}
                    alignItems={{ base: 'start', lg: 'center' }}
                  >
                    <Text whiteSpace="nowrap" mr={{ base: 0, lg: 4 }} ml={{ base: 0, lg: 4 }}>
                      Score:
                    </Text>
                    <Input
                      focusBorderColor="#5D38DB"
                      type="number"
                      onChange={(e) => updateBlankScore(e, state.blankID)}
                      value={state.score}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Divider />
            </>
          );
        })}
      </Flex>

      <Flex minHeight={100}>
        <GenericElementContainer
          name={'solution'}
          textPlaceholder="Enter Solution"
          setState={setSolution}
          value={solution}
        />
      </Flex>
    </Box>
  );
};

export default FillInBlanks;
