import React from 'react';
import { TextHeader } from './utils';
import ImagePreview from './image/img-preview';

const VideoURLQR = ({ value }) => {
  return (
    <div>
      <TextHeader text="Video" style={{ marginBottom: '2px' }} />
      <ImagePreview base64String={value} />
    </div>
  );
};

export default VideoURLQR;
