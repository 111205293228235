import React from 'react';
import { StudentsPage } from '../../assignments/students-home';
import { withAuth } from '../../auth/withAuth';

const StudentsHomePage = () => {
  return <StudentsPage />;
};

const StudentsHomeWithAuth = withAuth(StudentsHomePage);

export default StudentsHomeWithAuth;
