import React from 'react';
import {
  Column,
  PureHDivider,
  Row,
  TextDivider,
  getPDFTitleElement,
  getPrettyDate
} from '../../../canvas-utils/utils';
import WelcomeEndPreview from '../../../canvas-utils/welcome-end-preview';
import MCQQuestionOptionPreview from '../../../canvas-utils/mcq-question-option-preview';
import MCQSolutionPreview from '../../../canvas-utils/mcq-solution-preview';

/*
  AVOID FLEX IF IT IS JUST ROW / COLUMN UNLESS WRAP IS NEEDED AS PRINTING BREAKS OTHERWISE
  - FOR WRAPPING ELEMENTS, USE BREAK-INSIDE: AVOID, BREAK-AFTER & BREAK-BEFORE AS AUTO.

  CANNOT USE HOOKS OR ANY DYNAMIC THINGS IN THIS COMPONENT AS THIS IS PRINT ONLY
*/
const PDFComponent = ({
  assignmentData,
  totalScore,
  elementID,
  welcome,
  questions,
  solutions,
  end,
  options
}) => {
  const { name: assignmentName, duration, dueDate, startDate } = assignmentData;

  const { options: renderOptions, solutions: renderSolutions, customImageSrc } = options;

  return (
    <Column id={elementID}>
      <Row style={{ margin: '10px' }}>
        {assignmentName && getPDFTitleElement('Name', assignmentName)}
        {totalScore > 0 && getPDFTitleElement('Total Score', totalScore)}
        {duration && getPDFTitleElement('Duration', `${Math.floor(duration / 60_000)} minutes`)}
        {startDate && getPDFTitleElement('Starts on', getPrettyDate(startDate))}
        {dueDate && getPDFTitleElement('Due on', getPrettyDate(dueDate))}
      </Row>
      {welcome && (
        <Row
          style={{
            padding: '0 10px 10px',
            width: '100%'
          }}
        >
          <WelcomeEndPreview {...welcome} />
        </Row>
      )}
      {questions && (
        <>
          <TextDivider
            text="Questions"
            style={{
              marginTop: '1em',
              width: '100%'
            }}
          />
          <Row style={{ flexWrap: 'wrap' }}>
            {questions.map((ques, idx) => {
              return (
                <MCQQuestionOptionPreview
                  key={idx}
                  {...ques}
                  index={idx}
                  renderOptions={renderOptions}
                />
              );
            })}
          </Row>
        </>
      )}
      {end && end?.elements?.length > 0 && (
        <Row
          style={{
            padding: '10px 10px 0px',
            width: '100%'
          }}
        >
          <WelcomeEndPreview {...end} />
        </Row>
      )}
      {renderSolutions && solutions && solutions.length > 0 && (
        <>
          <TextDivider text="Solutions" style={{ breakBefore: 'page' }} />
          <Row style={{ flexWrap: 'wrap' }}>
            {solutions &&
              solutions.map((soln, idx) => {
                return <MCQSolutionPreview key={idx} {...soln} index={idx} />;
              })}
          </Row>
        </>
      )}
      <PureHDivider />
      <Row style={{ textAlign: 'center', marginTop: '2em' }}>
        <img
          style={{ display: 'inline-block', height: customImageSrc ? '100px' : '40px' }}
          src={customImageSrc ? customImageSrc : '/assets/images/logo.png'}
        />
      </Row>
    </Column>
  );
};

export default PDFComponent;
