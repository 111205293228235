import React, { useMemo, useState } from 'react';
import { Search2Icon } from '@chakra-ui/icons';
import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { debounce, toLower } from 'lodash';

const EMPTY_DEFAULT_ARRAY = [];

const TemplateFilters = ({
  allData,
  openTemplatePreview,
  onCreateUsingAI,
  allTemplatesFetched
} = {}) => {
  const [searchVal, setSearchVal] = useState('');

  const [searchOptions, setSearchOptions] = useState(EMPTY_DEFAULT_ARRAY);

  // TODO: This is redundant once the search moves to backend mongo table
  // Performs an insensitive case search on the available assignment names
  const debouncedSearch = useMemo(() => {
    return debounce((input = '') => {
      const lowerCaseInput = toLower(input);
      if (!lowerCaseInput) {
        setSearchOptions(EMPTY_DEFAULT_ARRAY);
        return;
      }

      let count = 0;
      const filtered = new Array(5);
      for (let i = 0; i < allData.length; i++) {
        const datum = allData[i];
        const name = toLower(datum?.assignmentName);
        if (name?.indexOf(lowerCaseInput) !== -1) {
          filtered[count++] = datum;
          if (count === 5) {
            break;
          }
        }
      }

      setSearchOptions(filtered);
    }, 100);
  }, [allData]);

  const search = (evt) => {
    const input = evt.target.value;

    // Set local state
    setSearchVal(input);

    // Call throttled search
    debouncedSearch(input);
  };

  const onItemClick = (templateID) => {
    openTemplatePreview(templateID);
  };

  const createUsingAI = () => {
    onCreateUsingAI(searchVal);
  };

  const clearInput = () => {
    setSearchVal('');
    debouncedSearch('');
  };

  return (
    <Flex mr="3" minW={{ base: '100%', lg: '50%' }} flex={{ base: 1, lg: 'unset' }}>
      <InputGroup maxW={{ base: 'unset', lg: '30vw' }} position="relative">
        <Input
          type="text"
          placeholder="Search thousands of assignments"
          value={searchVal}
          onChange={search}
          onBlur={clearInput}
        />
        <InputRightElement>
          {allTemplatesFetched ? (
            <Search2Icon color="gray.300" />
          ) : (
            <Flex alignItems="center" justifyContent="center">
              <Tooltip label="Templates are still fetching..">
                <Spinner size="sm" />
              </Tooltip>
            </Flex>
          )}
        </InputRightElement>
        {searchOptions.length && (
          <Flex
            direction="column"
            position="absolute"
            top="40px"
            bg="white"
            border="1px solid #ddd"
            w="100%"
          >
            {searchOptions.map((option, idx) => {
              return <SearchMenuOption option={option} key={idx} onItemClick={onItemClick} />;
            })}
            <SearchMenuOption
              isStatic
              option={{ assignmentName: searchVal, label: '✨ Create using AI' }}
              onItemClick={createUsingAI}
            />
          </Flex>
        )}
      </InputGroup>
    </Flex>
  );
};

export default TemplateFilters;

const SearchMenuOption = ({ option, isStatic, onItemClick }) => {
  return (
    <Flex
      key={option.assignmentId}
      borderBottom={'1px solid #eee'}
      px={3}
      py={2}
      direction={'column'}
      cursor="pointer"
      _hover={{ bg: '#f5f5f5' }}
      onClick={() => onItemClick(option.assignmentId)}
    >
      <Text fontSize="md">{option.assignmentName}</Text>
      {isStatic ? (
        <Text fontSize="xs">{option.label}</Text>
      ) : (
        <Text fontSize="xs">
          {option.noOfQuestions} Questions, {option.difficulty}
        </Text>
      )}
    </Flex>
  );
};
