import React, { useState, useEffect, useRef } from 'react'
import {
  Table,
  Tbody,
  Tr,
  Td,
  Flex,
  Text,
  Image,
  Button,
  Box,
  Thead
} from '@chakra-ui/react'
import {
  getClasswiseAssignments,
  getClasswiseSubmissions,
  updateClasswiseAssignments,
} from '../../api/fetchClasswiseAssignmentData'
import { useAuth } from '../auth/useAuth';
import { useDispatch } from 'react-redux';
import { onAssignmentCreate } from '../auth/createAssignment';
import { LoadingSkeleton } from './loadingSkeleton';
import { EmptyState } from './emptyState';
import { isNil } from 'lodash';
import { isMobile } from 'react-device-detect';
import { CREATE_TYPES } from '../../constants';
import MobileAssignmentCard from './assignment-card-mobile';
import { useNavigate } from 'react-router-dom';

export function AssignmentsPageClasswise() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const [isEmptyState, setEmptyState] = useState(false);

  const listInnerRef = useRef();
  const noOfRecs = 15;
  const [currentPage, setCurrentPage] = useState(0);
  const [prevPage, setPrevPage] = useState(-1);
  const [classwiseLastList, setClasswiseLastList] = useState(false);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  let [assignmentItems, setAssignments] = useState([]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const fetchClasswiseData = async () => {
    let classwiseAssignments = await getClasswiseAssignments(token, noOfRecs, currentPage);

    if (!classwiseAssignments.length) {
      setClasswiseLastList(true);
    } else {
      setPrevPage(currentPage);
      const allAssignments = assignmentItems.concat(classwiseAssignments);
      setAssignments(allAssignments);

      const classwiseSubmissions = await getClasswiseSubmissions(token);
      classwiseAssignments = await updateClasswiseAssignments(allAssignments, classwiseSubmissions);
      setAssignments(classwiseAssignments);
    }
  };

  useEffect(() => {
    if (prevPage == currentPage) {
      return;
    }

    if (!classwiseLastList) {
      fetchClasswiseData();
    }

    if (classwiseLastList && assignmentItems.length == 0) {
      setEmptyState(true);
    }
  }, [currentPage, prevPage, classwiseLastList]);

  function navigateToAssignment(
    assignmentID,
    source = 'Google',
    googleAssignmentURL,
    viewResultsPage
  ) {
    switch (source) {
      case 'Classwise': {
        let URL = `/assignments/${assignmentID}/${viewResultsPage ? 'results' : 'create'}`;
        navigate({
          pathname: `${URL}`,
          search: 'referrerUrl=/assignments/'
        });
        break;
      }
      case 'Google':
        window.open(googleAssignmentURL, '_blank');
        break;
    }
  }

  function getAssignmentStatus(status) {
    switch (status) {
      case 'PUBLISHED':
      case 'ACTIVE':
        return (
          <Text
            color="#11941B"
            fontWeight="400"
            fontSize="15px"
            lineHeight="20px"
            fontFamily="Arial"
          >
            Active
          </Text>
        );
      case 'DRAFT':
        return (
          <Text
            color="#F02E18"
            fontWeight="400"
            fontSize="15px"
            lineHeight="20px"
            fontFamily="Arial"
          >
            Draft
          </Text>
        );
    }
  }

  function getImageComponent(source = 'Google') {
    switch (source) {
      case 'Google':
        return <Image src="/assets/images/google.png" boxSize="20px" />;
      case 'Classwise':
        return <Image src="/assets/images/classwise-favicon.png" boxSize="20px" />;
    }
  }
  const onAssignmentCreation = () => {
    onAssignmentCreate(
      token,
      {
        source: 'createassignment-assignmenthome-empty-clicked',
        createType: CREATE_TYPES.ASSIGNMENTS_EMPTY_CTA
      },
      navigate
    );
    dispatch({
      type: 'ASSIGNMENT_CREATED',
      payload: {
        createType: CREATE_TYPES.ASSIGNMENTS_EMPTY_CTA
      }
    });
  };

  return (
    <Flex direction="column" width="100%">
      {assignmentItems.length ? (
        <Flex direction="column" w="100%">
          <Box
            onScroll={onScroll}
            ref={listInnerRef}
            height={'calc(100vh - 300px)'}
            overflowY={'auto'}
            overflowX="hidden"
          >
            {isMobile ? (
              <>
                {assignmentItems.map((assignment) => (
                  <MobileAssignmentCard assignment={assignment} key={assignment.id} />
                ))}
              </>
            ) : (
              <Table variant="simple">
                <Thead position={'sticky'} bg={'#fff'} top={0} zIndex={4}>
                  <Tr
                    fontFamily="Arial"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="19px"
                    color="#686868"
                  >
                    <Td fontWeight="700" pl={[0, 16]}>
                      Assignment Status
                    </Td>
                    <Td flexGrow="1">Assignment Name</Td>
                    {!isMobile && (
                      <>
                        <Td>Due Date</Td>
                        <Td>Yet to Start</Td>
                        <Td>Started</Td>
                        <Td>Submitted</Td>
                      </>
                    )}
                    <Td fontWeight="700">Actions</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {assignmentItems.map((item) => (
                    <Tr
                      key={item.id}
                      fontFamily="Arial"
                      fontSize="15px"
                      fontWeight="400"
                      lineHeight="20px"
                      color="#757575"
                      cursor="pointer"
                      onClick={(evt) => {
                        navigateToAssignment(item.id, item?.source, item?.alternateLink);

                        evt.stopPropagation();
                      }}
                    >
                      <Td pl={[0, 16]}>
                        <Flex flexDirection="row" gap={['3', '6']}>
                          {getImageComponent(item?.source)}
                          {getAssignmentStatus(item.state)}
                        </Flex>
                      </Td>
                      <Td flexGrow="1">{item.title}</Td>
                      {!isMobile && (
                        <>
                          <Td>
                            {!isNil(item.dueDate) && !Object.values(item.dueDate).includes(NaN)
                              ? monthNames[item.dueDate.month - 1] +
                                ' ' +
                                item.dueDate.day +
                                ', ' +
                                item.dueDate.year
                              : ''}
                          </Td>
                          <Td>{item.yet_to_start}</Td>
                          <Td>{item.started}</Td>
                          <Td>{item.submitted}</Td>
                        </>
                      )}

                      <Td>
                        <Button
                          borderRadius="0"
                          maxWidth={['auto', '110px']}
                          lineHeight={['auto', '15px']}
                          fontFamily="Helvetica"
                          fontSize={['10px', '12px']}
                          backgroundColor="#5D38DB"
                          textColor="#FFFFFF"
                          fontWeight="400"
                          height={'30px'}
                          onClick={(evt) => {
                            navigateToAssignment(item.id, item?.source, item?.alternateLink, true);
                            evt.stopPropagation();
                          }}
                        >
                          {isMobile ? 'View' : 'View Submissions'}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </Box>
        </Flex>
      ) : isEmptyState ? (
        <EmptyState type="assignments-home-classwise" onClick={onAssignmentCreation} />
      ) : (
        <LoadingSkeleton />
      )}
    </Flex>
  );
}
