import React from 'react';
import { Text, Box, Flex } from '@chakra-ui/react';

const EmptyResultsState = () => {
  return (
    <Box width="100%" overflow="auto" border="1px" borderColor="#DDDFEC">
      <Flex
        direction="column"
        id="default"
        flexGrow="1"
        boxShadow="xl"
        border="1px"
        borderColor="#DDDFEC"
        display="flex"
        m="15px"
        minHeight="calc(100vh - 90px)"
        alignItems="center"
        justifyContent="center"
        fontSize="20px"
      >
        <Text>No student has taken this assignment yet.</Text>
        <Text>Results appear after atleast one student has started taking the assignment.</Text>
      </Flex>
    </Box>
  );
};

export default EmptyResultsState;
