/* eslint-disable react/prop-types */
import React from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, HStack, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Text, Wrap } from '@chakra-ui/react'
import { canvasContentBlockSidebar } from '../../app/canvas/sidebars/right-sidebar/constant'

function ContentCard({ title, type, desc, icon, onContentBlockInsert, onClose, ...rest }) {
  const cardHeaderColor = icon ? "#686868" : "#ffffff"

  return (
    <Flex
      p={3}
      mb={3}
      shadow="sm"
      flexDirection="column"
      alignContent="center"
      w={'47%'}
      borderWidth="1px"
      bg={!icon ? '#744cc6' : ''}
      onClick={() => {
        onContentBlockInsert(type)
        onClose()
      }}
      {...rest}
    >
      <HStack justify="space-between" align="center">
        <Heading fontSize={icon ? "md" : "lg"} fontWeight={500} color={cardHeaderColor} py={!icon && 4} textAlign={"center"}>
          {title}
        </Heading>
        {icon}
      </HStack>
      <Text mt={1} color="#686868" fontSize="xs">
        {desc}
      </Text>
    </Flex>
  )
}

export default function AddContentBlock({ onContentBlockInsert, placement="left-end", buttonSize = 8, ...rest }) {
  return (
    <Popover placement={placement}>
      {({ onClose }) => (
        <>
          <Box py={5} shadow="sm" {...rest}>
            <Flex
              align="center"
              justify="center"
              position="relative"
            >
              
              <PopoverTrigger>
                <Flex
                  zIndex={1}
                  align="center"
                  cursor="pointer"
                  justify="center"
                  background="#744CC6"
                  w={buttonSize}
                  borderRadius="25px"
                  height={buttonSize}
                  color="#FFFFFF"
                  p={2}
                  my={2}
                >
                  <AddIcon size="lg"/>
                </Flex>
              </PopoverTrigger>
              <Portal>

                <PopoverContent
                  boxShadow="xl"
                  color="white"
                  background="white"
                >
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader bg="#744CC6" color="#fff">
                    Select Content Block
                  </PopoverHeader>
                  <PopoverBody>
                    <Wrap mt={2}>
                      {canvasContentBlockSidebar.content.map(
                        (m) => {
                          return (
                            <ContentCard
                              key={m.title}
                              title={m.title}
                              desc={m.description}
                              cursor="pointer"
                              borderColor="#B2BEDA"
                              borderRadius="8px"
                              icon={m.icon}
                              onContentBlockInsert={() =>
                                onContentBlockInsert(m.type)
                              }
                              onClose={onClose}
                            />
                          )
                        }
                      )}
                    </Wrap>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Flex>
          </Box>
        </>
      )}
    </Popover>
  )

}
