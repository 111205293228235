import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { sortBy } from 'lodash';

function GenericTopicSelector({ value, data, onDataChange, dataKey }) {
  return (
    <Flex
      alignItems="center"
      border="1px"
      borderColor="#000"
      borderRadius="3px"
      direction={{ base: 'column', lg: 'row' }}
      flex={1}
      // minWidth={{ base: 'unset', lg: '200px' }}
      maxWidth={{ base: 'unset', lg: '200px' }}
      py={3}
    >
      <>
        <Text color="#8F8F8F" fontSize="sm" fontWeight="bold" pl={3} pr={1}>
          {`${dataKey}:`.toUpperCase()}
        </Text>
        <Box>
          <Menu preventOverflow>
            <MenuButton
              fontSize={'sm'}
              as={Button}
              rightIcon={<ChevronDownIcon ml={0} mr={{ base: 0, lg: 2 }} />}
              variant="link"
            >
              <Text as="span" fontSize="sm" fontWeight="bold" color="#6364D8">
                {value}
              </Text>
            </MenuButton>
            <MenuList fontSize={'sm'}>
              {sortBy(data).map((datum) => (
                <MenuItem key={datum} onClick={() => onDataChange(datum)}>
                  {datum}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
      </>
    </Flex>
  );
}

export default GenericTopicSelector;
