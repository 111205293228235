/* eslint-disable react/prop-types */
import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import React from 'react'

export default function SwitchButton({
    label,
    isActive = false,
    datakey,
    onChange,
}) {
    return (
        <FormControl
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <FormLabel
                htmlFor={label}
                fontSize="md"
                color="#686868"
                fontWeight="normal"
            >
                {label}
            </FormLabel>
            <Switch
                id={label}
                isChecked={isActive}
                size="lg"
                colorScheme="purple"
                onChange={(evt) => onChange(datakey, evt.target.checked)}
            />
        </FormControl>
    )
}
