import React, { useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TbGridDots } from 'react-icons/tb';
import { useOutsideClick } from '../../hooks';

export default function Wrapper(props) {
  const imapactRef = useRef(null);
  const [elementClassName, setElementClassName] = React.useState('quill-focus');
  const isQuestionPage = props.pageType === 'MCQ' || props.pageType === 'FIB';
  const isStatic = props.static; // Static element, cannot delete

  useOutsideClick(imapactRef, () => {
    setElementClassName('');
  });

  if (!props || !props.children) {
    return <></>;
  }

  const iconProps = {
    height: '30px',
    width: '22px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 30
  };

  return (
    <Box
      mt={4}
      minW="90%"
      position="relative"
      {...props} // Add the remaining event props
      ref={imapactRef}
      className={!isQuestionPage ? 'element-wrapper' : ''}
    >
      {!isQuestionPage && !isStatic && (
        <Box
          display="flex"
          position="absolute"
          w="calc(100% + 24px)"
          justifyContent="space-between"
          marginTop="-1px"
          marginLeft="-10px"
          className="element-action"
        >
          <Flex display={{ base: 'none', lg: 'flex' }}>
            <Box {...iconProps} _hover={{ bg: 'rgba(55, 53, 47, 0.08)' }} onClick={props.onDelete}>
              <RiDeleteBinLine color="rgba(55,53,47,0.4)" />
            </Box>
            <Box
              className="drag-handle"
              {...iconProps}
              _hover={{ bg: 'rgba(55, 53, 47, 0.08)', cursor: 'grab' }}
            >
              <TbGridDots color="rgba(55,53,47,0.4)" />
            </Box>
          </Flex>
        </Box>
      )}
      <Box className={!isQuestionPage ? 'element-editor' : ''} px={!isQuestionPage ? 10 : 0}>
        {React.cloneElement(props.children, { className: elementClassName, setElementClassName })}
      </Box>
    </Box>
  );
}

Wrapper.propTypes = {
  children: PropTypes.element.isRequired,
  pageType: PropTypes.string,
  onDelete: PropTypes.func
};
