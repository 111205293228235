import { v4 as uuidV4 } from 'uuid';
import { produce } from 'immer';
import { assignmentPages } from '../../../../../constants';
import { set } from 'lodash';

function getInitialState() {
  const welcomeId = uuidV4();
  const endId = uuidV4();
  return {
    id: uuidV4(),
    // Some elem values are dummy below, they are replaced by actual values coming from the server
    name: '',
    dueDate: null,
    startDate: null,
    duration: null, // BE takes duration in milliseconds
    settings: {
      revealSolutions: true,
      randomiseQuestions: false,
      allowMultipleSubmissions: true
    },
    startButtonText: 'Start',
    endButtonText: 'See Result',
    activePage: welcomeId,
    activePageModalOpen: false,
    libraryConfig: {
      isLibraryDialogOpen: false,
      source: '',
      pageIndex: 0
    },
    pages: {
      [welcomeId]: {
        id: welcomeId,
        pageIndex: 0,
        type: assignmentPages.WELCOME.displayName,
        isDraggable: false,
        isEditable: false,
        config: assignmentPages.WELCOME.config
      },
      [endId]: {
        id: endId,
        pageIndex: 2,
        type: assignmentPages.END.displayName,
        isDraggable: false,
        isEditable: false,
        config: assignmentPages.END.config
      }
    }
  };
}

const initialState = getInitialState();

const assignmentReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ASSIGNMENT': {
      return payload;
    }
    case 'SET_ASSIGNMENT_ID': {
      draft.assignmentID = payload.assignmentID;
      return;
    }
    case 'UPDATE_ACTIVE_PAGE': {
      draft.activePage = payload.pageID;
      return;
    }
    case 'GOTO_WELCOME_PAGE': {
      const welcomePage = Object.values(draft.pages).find((page) => {
        return page?.type === assignmentPages.WELCOME.displayName;
      });
      draft.activePage = welcomePage?.id || draft.activePage;
      return;
    }
    case 'OPEN_ACTIVE_PAGE_MODAL': {
      draft.activePageModalOpen = true;
      return;
    }
    case 'CLOSE_ACTIVE_PAGE_MODAL': {
      draft.activePageModalOpen = false;
      return;
    }
    case 'UPDATE_ASSIGNMENT_NAME': {
      draft.name = payload;
      return;
    }
    case 'UPDATE_ALL_ASSIGNMENT_SETTINGS': {
      draft.settings = payload;
      return;
    }
    case 'ADD_PAGE': {
      // Since a new page is coming in, the indexes for pages after it need to increase by one
      Object.values(draft.pages).forEach((oldPage) => {
        // If the old page has an index equal to or greater than the newly added page, increment them by 1
        if (oldPage.pageIndex >= payload.page.pageIndex) {
          oldPage.pageIndex += 1;
        }
      });

      draft.pages[payload.page.id] = payload.page;
      return;
    }
    case 'UPDATE_LIBRARY_MODAL_STATUS': {
      draft.libraryConfig = draft.libraryConfig || {};
      draft.libraryConfig.isLibraryDialogOpen = payload.isLibraryDialogOpen;
      draft.libraryConfig.source = payload.source;
      draft.libraryConfig.pageIndex = payload.pageIndex;
      return;
    }
    case 'UPDATE_PAGE_INDICES': {
      const list = payload.list;
      list.forEach((page, idx) => {
        if (draft.pages[page.id]) {
          draft.pages[page.id].pageIndex = idx + 1;
        }
      });
      return;
    }
    case 'DELETE_PAGE': {
      const toDelete = draft.pages[payload.pageID];
      if (!toDelete) return;

      delete draft.pages[payload.pageID];
      // Since a page is being deleted, the indexes for pages after it need to decrease by one
      Object.values(draft.pages).forEach((oldPage) => {
        // If the old page has an index equal to or greater than the deleted page, decrement by 1
        if (oldPage.pageIndex >= toDelete.pageIndex) {
          oldPage.pageIndex -= 1;
        }

        // We need to set the active page to the one before the deleted page
        if (toDelete.pageIndex === oldPage.pageIndex) {
          draft.activePage = oldPage.id;
        }
      });
      return;
    }
    case 'UPDATE_CORE_PAGE_BUTTON_NAME': {
      draft.pages[payload.pageID] = {
        ...draft.pages[payload.pageID],
        config: {
          ...draft.pages[payload.pageID].config,
          buttonName: payload.buttonName
        }
      };
      return;
    }
    case 'UPDATE_ASSIGNMENT_SETTING': {
      draft.settings[payload.dataKey] = payload.value;
      return;
    }

    case 'UPDATE_ASSIGNMENT_DURATION': {
      draft.duration = payload;
      return;
    }

    case 'UPDATE_ASSIGNMENT_DUEDATE': {
      draft.dueDate = payload;
      return;
    }

    case 'UPDATE_ASSIGNMENT_STARTDAY': {
      draft.startDate = payload;
      return;
    }

    case 'UPDATE_PAGE_SETTING': {
      const page = draft.pages[payload.pageID];
      if (page) {
        Object.keys(payload).forEach((key) => {
          if (key === 'pageID') return;
          set(page, key, payload[key]);
        });
      }
      return;
    }

    case 'CLEAR_ASSIGNMENT_STATE':
      return initialState;
    default:
      // Always return draft as the default is initial state otherwise the draft object
      return draft;
  }
});

export default assignmentReducer;
