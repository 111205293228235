import React from 'react';
import { Box, Button, Slide, useDisclosure } from '@chakra-ui/react';
import { ArrowRightIcon } from '@chakra-ui/icons';
import LeftSidebar from './LeftSidebar';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { getActivePageModalOpen } from '../../redux/selectors';

const SlideComponent = ({ children, isOpen }) => {
  return isMobile ? (
    <>{children}</>
  ) : (
    <Slide
      direction="left"
      in={isOpen}
      style={{
        position: 'relative',
        display: 'flex'
      }}
    >
      {children}
    </Slide>
  );
};

// eslint-disable-next-line react/prop-types
export default function CanvasLeftSidebar() {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const activeModalOpen = useSelector(getActivePageModalOpen);

  return (
    <>
      {isOpen || isMobile ? (
        <Box
          maxW={{ base: '100vw', lg: '200px' }}
          minW={{ base: '100vw', lg: '200px' }}
          overflow={{ lg: 'auto' }}
          display={{ base: isMobile && activeModalOpen ? 'none' : 'block', lg: 'block' }}
        >
          <SlideComponent isOpen={isOpen}>
            <Box
              borderRight="1px"
              overflow="auto"
              backgroundColor="#ffffff"
              borderColor="#F2F2F2"
              display="flex"
              flexDirection="column"
              width="100%"
              className="left-summary-sidebar"
              pb={12}
            >
              <LeftSidebar onToggle={onToggle} />
            </Box>
          </SlideComponent>
        </Box>
      ) : (
        <Button onClick={onToggle} mt={2} left="-1px">
          <ArrowRightIcon color="#686868" fontSize="12px" />
        </Button>
      )}
    </>
  );
}
