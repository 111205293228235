import React from 'react'
import { Box, Flex, Select } from '@chakra-ui/react'
import { BsFlag } from 'react-icons/bs';
import { IoMdArrowDropdown } from 'react-icons/io'
import { canvasQuestionTypesSidebar } from '../constant';

// const blankPage = {
//   title: 'Blank',
//   description: 'Customise the page manually',
//   icon: null,
//   type: '',
// }

export default function PageDropDown({
  pageID, 
  activePageName
}) {
  const pages = React.useMemo(() => [
    ...canvasQuestionTypesSidebar.content
  ].filter(page => page.title !==  'Pick from Classwise Library'),[])

  const dropDowdDefaultValue = React.useMemo(() => {
    const activePageType =  pages.find(page => page.title === activePageName)
    return activePageType?.type
  }, [pages, activePageName])

  const onPageChange = React.useCallback((e) => {
    // TODO: reset page and update page name here
    console.log(e.target.value);
  }, [])

  return (
    <Flex
      rounded={0}
      border="1px solid"
      borderColor="#C1C1C1"
      alignItems={'center'}
      px={3}
    >
      <BsFlag size={20} color="#1D1D1B" />
      <Select defaultValue={dropDowdDefaultValue} icon={<IoMdArrowDropdown size={5}/>} outline="none" border={'0px solid'}  onChange={onPageChange}>
        {
          pages.map(page => <option 
              key={page.title} value={page.type}>{page.title}</option>
          )
        }
      </Select>
    </Flex >
  )

}
