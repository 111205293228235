import { Card, Flex, Image, Menu, MenuButton, Text, MenuList, Portal } from '@chakra-ui/react';
import React from 'react';
import { ASSIGNMENT_TYPES } from '../../constants';
import { startCase } from 'lodash';
import { BsPencil, BsThreeDotsVertical } from 'react-icons/bs';
import { MobileMenuListItem } from './utils';
import { GrScorecard } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';

const MobileAssignmentCard = ({ assignment }) => {
  const navigate = useNavigate();

  const getAssignmentStateText = () => {
    switch (assignment.state) {
      case 'DRAFT':
        return (
          <Text fontSize="12px" color="#F02E18">
            {startCase(assignment.state?.toLowerCase())}
          </Text>
        );
      case 'PUBLISHED':
      case 'ACTIVE':
      default:
        return (
          <Text fontSize="12px" color="#11941B">
            {startCase(assignment.state?.toLowerCase())}
          </Text>
        );
    }
  };

  const getTypeBasedText = () => {
    switch (assignment.assignmentType) {
      case ASSIGNMENT_TYPES.LESSON_PLAN:
        return <Text fontSize="12px">Lesson Plan</Text>;
      case ASSIGNMENT_TYPES.LEARNING_ACTIVITY:
        return <Text fontSize="12px">Learning Activity</Text>;
      case ASSIGNMENT_TYPES.TEMPLATE:
        return <Text fontSize="12px">{assignment.questionCount} Questions | Template</Text>;
      case ASSIGNMENT_TYPES.AI_QUESTIONS:
        return <Text fontSize="12px">{assignment.questionCount} Questions | AI</Text>;
      case ASSIGNMENT_TYPES.PLAIN:
      default:
        return <Text fontSize="12px">{assignment.questionCount} Questions</Text>;
    }
  };

  const navigateToAssignment = (viewResultsPage) => {
    switch (assignment?.source) {
      case 'Classwise': {
        let URL = `/assignments/${assignment.id}/${
          viewResultsPage ? 'results' : 'create'
        }?referrerUrl=/assignments/`;
        navigate(`${URL}`);
        break;
      }
      case 'Google':
        window.open(assignment?.alternateLink, '_blank');
        break;
    }
  };

  return (
    <Card
      direction="row"
      overflow="hidden"
      my={4}
      variant="elevated"
      borderRadius={0}
      mx={2}
      alignItems="center"
    >
      <Image
        objectFit="cover"
        maxW="60px"
        maxH="60px"
        src={
          assignment?.source === 'Google'
            ? '/assets/images/google.png'
            : '/assets/images/classwise-favicon.png'
        }
        alt={`Assignment - ${assignment.name}`}
      />
      <Flex direction="column" pl={2} flex={1} onClick={() => navigateToAssignment()}>
        <Text fontSize="15px">{assignment.title}</Text>
        {getAssignmentStateText()}
        {getTypeBasedText()}
      </Flex>
      <Flex w={8} justifyContent="flex-end" alignSelf="flex-start">
        <Menu isLazy autoSelect={false}>
          <MenuButton pt={2} alignItems="flex-start" height="fit-content">
            <BsThreeDotsVertical />
          </MenuButton>
          <Portal>
            <MenuList minWidth={36}>
              <MobileMenuListItem
                Icon={BsPencil}
                text={'Edit'}
                onClick={() => navigateToAssignment()}
              />
              <MobileMenuListItem
                Icon={GrScorecard}
                text={'See Results'}
                onClick={() => navigateToAssignment(true)}
              />
              {/* <MobileMenuListItem Icon={BsShare} text={'Share'} />
              <MobileMenuListItem Icon={BsFillTrash3Fill} iconColor="red" text={'Delete'} /> */}
            </MenuList>
          </Portal>
        </Menu>
      </Flex>
    </Card>
  );
};

export default MobileAssignmentCard;
