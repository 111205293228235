import React, { useMemo } from 'react';
import { PureStaticContentWrap } from './library-mcq-question/utils';

const MCQSolutionPreview = ({ solution, index }) => {
  const styles = useMemo(() => {
    const obj = {
      // breakInside: 'avoid',
      // breakBefore: 'auto',
      // breakAfter: 'auto',
      marginBottom: '5px'
    };

    if (index > 1) {
      obj.borderTop = '1px solid rgba(0, 0, 0, .1)';
    }
    return obj;
  }, [index]);

  return (
    <div
      key={index}
      style={{
        paddingBottom: '1em',
        padding: '0 5px',
        boxSizing: 'border-box',
        ...(styles || {})
      }}
    >
      <PureStaticContentWrap type={`Solution ${index + 1}`} contentArray={solution} />
    </div>
  );
};

export default MCQSolutionPreview;
