import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  // Input,
  Select,
  Button,
  Flex,
  ModalCloseButton,
  Box,
  Text,
  Alert,
  ScaleFade,
  useToast
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import CreatableSelect from 'react-select/creatable';

import { countries } from '../../../../utils/countriesList';
import { createAiRequest, createAiRequestForFile } from '../../../../api/assignmentApis';
import { useAuth } from '../../../auth/useAuth';
import { CREATE_TYPES } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import useUpdateAssignment from '../shared/hooks/use-update-assignment';
import languageList from '../../../../utils/languagesList';
import { useAiRequestStatus } from '../shared/hooks';
import { eventLogger } from '../../../../api/eventLogger';
import useAiModalState from '../sidebars/left-sidebar/use-ai-modal-state';
import {
  // getActiveAssignmentPageID,
  getAssignmentMetadata
  // getPageElements
} from '../redux/selectors';
import { createFormDataPayloadWithFile } from '../../../../helpers/data.helpers';
import { AiRequestTopicWithAttachment } from './AiRequestTopicWithAttachment';
import { useParams } from 'react-router-dom';

const AiQuestionsModal = () => {
  const {
    openAiQuestionsModal,
    isAiQuestionsModalOpen: isOpen,
    closeAiQuestionsModal: onClose,
    modalProps
  } = useAiModalState();
  const pageIndex = modalProps?.pageIndex;
  const saveTopicAsName = modalProps?.saveTopicAsName;
  const defaultSubject = modalProps?.subject;
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      object({
        topic: string().required('Please enter topic')
      })
    )
  });
  const { token } = useAuth();
  const toast = useToast();
  const dispatch = useDispatch();
  const { assignmentID } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const updateAssignment = useUpdateAssignment({ forceUpdate: true });
  const [status, setStatus] = React.useState(null);
  const { startAiRequestCall, startAiRequestLoading } = useAiRequestStatus();

  // To add instructions
  // const welcomePageID = useSelector(getActiveAssignmentPageID);
  // const pageElements = useSelector(getPageElements(welcomePageID));

  // We use the AI createType to open this modal on creation of the assignment
  const { createType, subject } = useSelector(getAssignmentMetadata);
  const [selectedFile, setSelectedFile] = React.useState();

  const onSubmit = async (data) => {
    setIsLoading(true);
    startAiRequestLoading();
    setStatus('Hang tight. It can take upto 30s to generate questions');

    // If we opened the dialog on create, save the topic name as the assignment's name
    if (saveTopicAsName) {
      dispatch({
        type: 'UPDATE_ASSIGNMENT_NAME',
        payload: data.topic
      });
    }

    const payload = {
      ...data,
      language: data.language.value,
      country: data.country.value
    };

    const payloadWithFile = createFormDataPayloadWithFile({ data: payload, file: selectedFile });

    let aiRequest = null;
    if (selectedFile) {
      aiRequest = await createAiRequestForFile({
        token,
        toast,
        data: payloadWithFile
      });
    } else {
      aiRequest = await createAiRequest({
        token,
        data: payloadWithFile
      });
    }

    if (aiRequest) {
      startAiRequestCall({ id: aiRequest, index: pageIndex });
      eventLogger(token, 'questions-added-using-AI', assignmentID, payload, {
        ...payload,
        isPDFAdded: false
      });
      // TODO - check if it is opened for new assignment then only add the instructions
      // dispatch({
      //   type: 'UPDATE_PAGE_ELEMENT',
      //   payload: {
      //     pageID: welcomePageID,
      //     elementID: Object.values(pageElements)[0].id,
      //     value:
      //       '<h2>Instructions</h2><p>Choose the correct answer for each question from the options provided</p>'
      //   }
      // });
      updateAssignment();
      onClose();
      setSelectedFile(null);
      reset();
    } else {
      toast({
        status: 'error',
        title: 'Cannot create the AI request',
        isClosable: true
      });
    }

    setStatus(null);
    setIsLoading(false);
  };

  useEffect(() => {
    if (createType === CREATE_TYPES.HOME_HERO_CTA_AI) {
      openAiQuestionsModal({ pageIndex: 0, saveTopicAsName: true, subject });
    }
    // Props do not change & hence this should only be invoked once
  }, [createType, openAiQuestionsModal, subject]);

  // If the assignment was created using the "Create via AI" option via templates which has a default subject selected, use that as topic name
  useEffect(() => {
    if (defaultSubject) {
      setValue('topic', defaultSubject);
      console.log('Set default subject as topic', defaultSubject);
    }
  }, [defaultSubject, setValue]);

  const handleModalClose = () => {
    if (selectedFile) {
      setSelectedFile(null);
    }
    onClose();
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={false}
      size="lg"
      isCentered
    >
      <ModalOverlay bg="rgba(0,0,0,0.8)" />
      <ModalContent m={3}>
        <ModalCloseButton />
        <ModalHeader textAlign="center" mt={4}>
          Generate using AI
        </ModalHeader>
        <ModalBody>
          <FormControl isRequired>
            <AiRequestTopicWithAttachment
              maxPages={Infinity}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              formProps={{ ...register('topic', { required: true }) }}
            />
            <Text fontSize="12px" color="red">
              {errors.topic?.message}
            </Text>
          </FormControl>

          <FormControl mt={3} borderBottom="1px solid lightgrey" pb={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Number of questions</FormLabel>
              <Select size="sm" w="unset" {...register('numberOfQuestions')}>
                {[...Array(9)].map((_, i) => (
                  <option key={`no-of-questions-option-${i}`} value={i + 2}>
                    {i + 2}
                  </option>
                ))}
              </Select>
            </Flex>
          </FormControl>

          <FormControl mt={3} borderBottom="1px solid lightgrey" pb={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Difficulty level</FormLabel>
              <Select size="sm" w="unset" {...register('difficultyLevel')}>
                <option value="easy">Easy</option>
                <option value="medium" selected>
                  Medium
                </option>
                <option value="hard">Hard</option>
              </Select>
            </Flex>
          </FormControl>

          <FormControl mt={3} borderBottom="1px solid lightgrey" pb={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Language</FormLabel>
              <Box minWidth="200px">
                <Controller
                  control={control}
                  name="language"
                  defaultValue={{ label: 'English', value: 'English' }}
                  render={(renderProps) => {
                    const {
                      field: { onChange, value }
                    } = renderProps;
                    return (
                      <CreatableSelect
                        placeholder="Select/Add language"
                        options={languageList.map((l) => ({ label: l, value: l }))}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          option: (styles) => ({ ...styles, fontSize: '14px' })
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Flex>
          </FormControl>

          <FormControl mt={3} borderBottom="1px solid lightgrey" pb={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Class</FormLabel>
              <Select size="sm" w="unset" {...register('standard')}>
                {[...Array(12)].map((_, i) => (
                  <option key={`class-option-${i}`} value={`class ${i + 1}`} selected={i === 9}>
                    Class {i + 1}
                  </option>
                ))}
                <option value="others">Others</option>
              </Select>
            </Flex>
          </FormControl>

          <FormControl mt={3} borderBottom="1px solid lightgrey" pb={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Country</FormLabel>
              <Box minWidth="248px">
                <Controller
                  control={control}
                  name="country"
                  defaultValue={{ label: 'United States', value: 'United States' }}
                  render={(renderProps) => {
                    const {
                      field: { onChange, value }
                    } = renderProps;
                    return (
                      <CreatableSelect
                        placeholder="Select/Add country"
                        options={countries.map((c) => ({ label: c, value: c }))}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          option: (styles) => ({ ...styles, fontSize: '14px' })
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Flex>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Flex justifyContent="center" width="100%" alignItems="center" direction="column">
            <Button
              isLoading={isLoading}
              colorScheme="purple"
              mr={3}
              onClick={handleSubmit(onSubmit)}
            >
              Generate ✨
            </Button>
            {status ? (
              <Box minH="32px" mt={4} w={'100%'} py="4px">
                <ScaleFade initialScale={0.2} in={!!status}>
                  <Alert opacity={0.8} status="info" borderRadius={5} justifyContent={'center'}>
                    {status}
                  </Alert>
                </ScaleFade>
              </Box>
            ) : (
              <>
                <Text fontSize="sm" color="grey" mt={1} textAlign="center">
                  Questions generated by Classwise AI are a teaching aid. We recommend verifying
                  before use.
                </Text>
              </>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AiQuestionsModal;
