import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Input } from '@chakra-ui/react';
import { IoMdResize, IoMdCrop } from 'react-icons/io';
import { AiOutlineRotateRight } from 'react-icons/ai';
import { drawCanvasFromBase64Str } from './utils';
import ResizeContainer from './use-resize';
import CropContainer from './use-crop';
import { BsImage } from 'react-icons/bs';

const ImageNew = (props) => {
  const { elementID, onUpdate, value } = props;

  const [imgData, setImgData] = useState(null);

  const [resizeMode, setResizeMode] = useState(false);
  const [cropMode, setCropMode] = useState(false);

  const toolbarRef = useRef();
  const imageContainer = useRef();
  const canvasRef = useRef();
  const fileInputRef = useRef();

  const { containerID, toolbarID } = useMemo(() => {
    return {
      containerID: `image-${elementID}`,
      toolbarID: `toolbar-${elementID}`
    };
  }, [elementID]);

  const fileDropZoneStyles = {
    alignItems: 'center',
    px: 3,
    bg: 'rgb(242, 241, 238)',
    cursor: 'pointer',
    _hover: {
      bg: 'rgba(55, 53, 47, 0.08)'
    },
    flex: 1
  };

  // Initial Drawing of the canvas
  useEffect(() => {
    if (!canvasRef) return;

    const onLoad = (img) => {
      setImgData(img);
    };

    drawCanvasFromBase64Str({
      canvasRef,
      imageContent: value,
      onLoad,
      restrictWidth: true
    });
  }, [canvasRef, value]);

  // Updating the base 64 value of the image in the main redux store
  const setValue = (newval) => {
    onUpdate(elementID, newval);
  };

  // Toolbar hide & show
  const onFocus = () => {
    if (toolbarRef.current) {
      toolbarRef.current.style.visibility = 'visible';
    }
  };

  // Toolbar hide & show
  const onBlur = () => {
    if (toolbarRef.current) {
      toolbarRef.current.style.visibility = 'visible';
    }
  };

  // When the user manually uploads a file to be used as the image
  const handleInputChange = (e) => {
    if (!e.target.files?.[0]) return;

    setResizeMode(false);
    setCropMode(false);

    const imageContent = URL.createObjectURL(e.target.files[0]);

    const onLoad = () => {
      // We cannot set the loaded image as imageData as the loaded image is large but our canvas has shrinked the image to fit
      // Hence, get the canvas base64 str, create an image & then set that in state
      setImageThroughBase64();

      // Call update value as canvas has now been drawn
      canvasChanged();
    };

    drawCanvasFromBase64Str({
      canvasRef,
      imageContent,
      onLoad,
      restrictWidth: true
    });
  };

  const setImageThroughBase64 = () => {
    if (!canvasRef?.current) return;
    const base64str = canvasRef.current.toDataURL();
    const img = new Image();
    img.src = base64str;
    setImgData(img);
  };

  // Setting the value once the canvas has changed in the image or size
  const canvasChanged = () => {
    if (canvasRef.current) {
      const base64str = canvasRef.current.toDataURL();
      setValue(base64str);
    }
  };

  // Drawing the canvas on resize & when an image is uploaded
  const drawCanvas = () => {
    const canvasCtx = canvasRef.current?.getContext('2d');
    if (canvasCtx) {
      canvasCtx.drawImage(imgData, 0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  // Setting the resize mode
  const toggleResizeMode = () => {
    value && !cropMode && setResizeMode((mode) => !mode);
  };

  const toggleCropMode = () => {
    value && !resizeMode && setCropMode((mode) => !mode);
  };

  const onResizeEnd = () => {
    canvasChanged();

    setImageThroughBase64();
  };

  const onCropEnd = (base64Str) => {
    const onLoad = (img) => {
      setImgData(img);

      // Call update value as canvas has now been drawn
      canvasChanged();

      // Set crop mode to false now that cropping is done
      setCropMode(false);
    };

    drawCanvasFromBase64Str({
      canvasRef,
      imageContent: base64Str,
      onLoad,
      restrictWidth: true
    });
  };

  const rotateCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const newWidth = canvas.height;
    const newHeight = canvas.width;

    // Set the height & width of the canvas to the opposite props
    canvas.height = newHeight;
    canvas.width = newWidth;

    const canvasCtx = canvas.getContext('2d');

    // Clear the canvas for redrawing
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

    // First, translate & rotate the canvas context on the center point as the origin
    canvasCtx.translate(newWidth / 2, newHeight / 2);
    canvasCtx.rotate((90 * Math.PI) / 180);
    // Draw the image on the canvas
    canvasCtx.drawImage(imgData, -imgData.width / 2, -imgData.height / 2);

    // Reset the center point as the origin to 0,0
    canvasCtx.rotate(-(90 * Math.PI) / 180);
    canvasCtx.translate(-newWidth / 2, -newHeight / 2);

    // Set the data in the parent redux state
    canvasChanged();

    // Set the new image value in state
    setImageThroughBase64();
  };

  const triggerFileInput = (e) => {
    e.preventDefault();

    if (!fileInputRef.current) return;

    fileInputRef.current.click();
  };

  return (
    <Box
      onFocus={onFocus}
      onBlur={onBlur}
      tabindex="-1"
      display="flex"
      flexDirection="column"
      width="100%"
      cursor={'pointer'}
      position={'relative'}
      className={`element-wrapper ${containerID}`}
      border="0"
    >
      <div
        id={toolbarID}
        ref={toolbarRef}
        className={value ? 'element-action' : 'd-none'}
        style={{
          position: 'absolute',
          background: '#fff',
          width: '220px',
          zIndex: 100,
          borderRadius: '4px',
          border: '1px solid #ddd',
          marginTop: '-48px'
        }}
      >
        <FormControl display="flex" p="1" alignItems="center">
          <FormLabel
            className="image-upload-label"
            p="2"
            bg="#5d38db"
            color="#fff"
            cursor="pointer"
            mr={4}
            m={0}
            fontSize={'sm'}
          >
            Change Image
          </FormLabel>
          <Input
            ref={fileInputRef}
            placeholder="Click to upload an image"
            type="file"
            name="image-upload"
            display="none"
            onChange={handleInputChange}
            accept="image/*"
          />
          <Box ml={2} mr={2} display="flex">
            <IoMdResize
              onClick={toggleResizeMode}
              style={{
                height: '16px',
                width: '16px',
                cursor: 'pointer',
                color: resizeMode ? 'red' : 'black'
              }}
            />
          </Box>
          <Box ml={2} mr={2} display="flex">
            <IoMdCrop
              onClick={toggleCropMode}
              style={{
                height: '18px',
                width: '18px',
                cursor: 'pointer',
                color: cropMode ? 'red' : 'black'
              }}
            />
          </Box>
          <Box ml={2} mr={2} display="flex">
            <AiOutlineRotateRight
              onClick={rotateCanvas}
              style={{
                height: '18px',
                width: '18px',
                cursor: 'pointer'
              }}
            />
          </Box>
        </FormControl>
      </div>
      <Box minH="2" ref={imageContainer} display="flex" alignItems="stretch" minHeight="49px">
        {!value && (
          <Flex {...fileDropZoneStyles} onClick={triggerFileInput}>
            <Box>
              <BsImage size={20} color="rgba(55, 53, 47, 0.45)" />
            </Box>
            <Text fontSize="sm" ml={3} color="rgba(55, 53, 47, 0.45)">
              Add an image
            </Text>
          </Flex>
        )}
        <ResizeContainer
          maxX={imageContainer.current?.clientWidth - 12}
          resizeMode={resizeMode}
          onMouseUp={onResizeEnd}
          onMouseMove={drawCanvas}
          canvasRef={canvasRef}
        >
          <CropContainer cropMode={cropMode} canvasRef={canvasRef} onCropEnd={onCropEnd}>
            <canvas
              ref={canvasRef}
              style={{
                display: value ? 'unset' : 'none',
                opacity: cropMode ? '0.5' : 'unset',
                maxWidth: '100%'
              }}
            ></canvas>
          </CropContainer>
        </ResizeContainer>
      </Box>
    </Box>
  );
};

export default ImageNew;

ImageNew.propTypes = {
  elementID: PropTypes.string,
  onUpdate: PropTypes.func
};
