import React, { useEffect, useState } from 'react'
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { get } from 'lodash'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import QuestionModal from './question-modal'
import { availableCountries, questionFilters, questionLevel } from './utils'
import { useDispatch, useSelector } from 'react-redux'
import { getLibraryDialogConfig } from '../../redux/selectors'
import addLibraryQuestion from '../../redux/thunks/add-library-question'
import useUpdateAssignment from '../hooks/use-update-assignment'

function QuestionLevel({ level }) {
    const qusLevel = questionLevel[level]
    return (
        <Text fontSize="sm" mx={2} bg={qusLevel.bgColor} px={3} color={'#fff'}>
            {qusLevel?.levelType}
        </Text>
    )
}

function QuestionOptions({ option }) {
    return (
        <Flex mb={5} alignItems="center">
            <Box
                w={6}
                height={6}
                bgColor={option.is_correct ? '#5D38DB' : '#fff'}
                border="1px"
                borderColor="#5D38DB"
                borderRadius={'30px'}
            />
            <Box
                ml={3}
                border="1px"
                borderColor="gray.300"
                minW={'300px'}
                p={2}
            >
                {parse(option?.option_content_html)}
            </Box>
        </Flex>
    )
}

const LibraryBox = () => {
    const dispatch = useDispatch()
    const updateAssignment = useUpdateAssignment({ forceUpdate: false })

    const [selectedCountry, setSelectedCountry] = React.useState(
        availableCountries[0]
    )
    const [countryData, setCountryData] = React.useState(
        get(questionFilters, selectedCountry.name)
    )
    const { isLibraryDialogOpen, pageIndex } = useSelector(
        getLibraryDialogConfig
    )
    const [value, setValue] = useState(null)

    useEffect(() => {
        setCountryData(get(questionFilters, selectedCountry.name))
    }, [selectedCountry])

    const onCountryChange = React.useCallback((country) => {
        setSelectedCountry(country)
    }, [])

    const onClose = React.useCallback(() => {
      dispatch({
        type: 'UPDATE_LIBRARY_MODAL_STATUS',
        payload: {
          isLibraryDialogOpen: false,
          source: ''
        }
      });
    }, [dispatch]);

    // This is the function that creates an mcq question in the assignment & adds the library question to it
    const onElementAdd = React.useCallback(
      (question) => {
        dispatch(addLibraryQuestion(question, pageIndex + 1)); // Need to add the question at the current page index + 1
      },
      [dispatch, pageIndex]
    );

    const onAddQuestion = React.useCallback(
        (question) => {
            // onUpdate(elementID, question)
            onElementAdd(question)
            setValue(question)
            updateAssignment()
            onClose()
        },
        [onClose, onElementAdd, updateAssignment]
    )

    const onAddMultipleQuestion = React.useCallback(
        (questions) => {
            questions.reverse().forEach((question) => {
                onElementAdd(question)
                setValue(question)
            })
            updateAssignment()
            onClose()
        },
        [onClose, onElementAdd, updateAssignment]
    )

    const onModalClose = React.useCallback(() => {
        onClose()
    }, [onClose])

    return (
        <>
            <QuestionModal
                selectedCountry={selectedCountry}
                countryData={countryData}
                isOpen={isLibraryDialogOpen}
                onClose={onModalClose}
                onCountryChange={onCountryChange}
                onAddQuestion={onAddQuestion}
                onAddMultipleQuestion={onAddMultipleQuestion}
            />
            {value && (
                <Box
                    p={5}
                    bg="#fff"
                    className="katex-question singleQuestion"
                    h={600}
                    overflowY="auto"
                >
                    <HStack
                        pb={5}
                        borderBottom="1px"
                        borderColor="gray.300"
                        w={'100%'}
                        justifyContent={'space-between'}
                        alignItems="flex-start"
                        bg={'#fff'}
                    >
                        <Flex alignItems={'center'} width={'70%'}>
                            {parse(value?.question_content_html)}
                        </Flex>
                        <Flex>
                            <Text color="#6364D8" fontWeight={'bold'}>
                                Difficulty Level:
                            </Text>
                            <QuestionLevel level={value?.level} />
                        </Flex>
                    </HStack>
                    <VStack pb={5} mt={5} justifyContent="flex-start">
                        <Text w={'100%'} color={'gray.500'} pb={3}>
                            Options:
                        </Text>
                        <Box w={'100%'} justifyContent="flex-start">
                            {value?.options.length > 0 &&
                                value?.options.map((option) => (
                                    <QuestionOptions
                                        key={option._id}
                                        option={option}
                                    />
                                ))}
                        </Box>
                    </VStack>
                    <VStack
                        py={5}
                        borderTop="1px"
                        borderColor="gray.300"
                        justifyContent="flex-start"
                    >
                        <Text w={'100%'} color={'gray.500'} pb={3}>
                            Soultion:
                        </Text>
                        <Box w={'100%'}>
                            {parse(value?.solution_content_html) || ''}
                        </Box>
                    </VStack>
                </Box>
            )}
        </>
    )
}

export default LibraryBox

LibraryBox.propTypes = {
    elementID: PropTypes.string,
    onUpdate: PropTypes.func,
}
