import React from 'react'
import {
  Flex,
  Image,
  Text,
  Link,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react';
import { getGoogleAuthURL, loadCurrentUser } from '../../api/userApis';
import { useAuth } from './useAuth';
import { eventLogger } from '../../api/eventLogger';
import { useNavigate } from 'react-router-dom';

export default function LinkWithGoogle() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [source, setSource] = React.useState('direct');
  const [isSourceSchoology, setIsSourceSchoology] = React.useState(true);

  const {
    isOpen: isDetailsModalOpen,
    onOpen: openDetailsModal,
    onClose: closeDetailsModal
  } = useDisclosure();

  const handleSubmit = async (e) => {
    try {
      eventLogger(token, 'google-classroom-connect-clicked');
      e.preventDefault();
      const docUrl = new URL(document.location);
      const data = await getGoogleAuthURL(docUrl, token);
      navigate(data.authLink);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const docUrl = new URL(document.location);
    setSource(docUrl.searchParams.get('source') ?? 'direct');

    async function loadUser() {
      if (token) {
        const { user } = await loadCurrentUser(token);
        setIsSourceSchoology(
          user?.currentSource == 'schoology' || source == 'schoology' || user?.source == 'schoology'
        );
      }
    }
    loadUser();
  }, [isSourceSchoology, token]);

  return !isSourceSchoology ? (
    <Flex
      width="100%"
      mt={{ base: 0, lg: '16px' }}
      background="#EF8888"
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      maxH="fit-content"
      p={4}
      gap={4}
    >
      <Flex color="#FFFFFF" flexDirection={'column'} flex={1}>
        <Text fontSize="15px" lineHeight="20px" fontWeight="600">
          Connect Classwise with Google Classroom account
        </Text>
        <Text fontSize="12px">
          {/* Classwise's use and transfer to any other app of information received from Google APIs
            will adhere to{' '}
            <Link
              href="https://developers.google.com/terms/api-services-user-data-policy"
              target="_blank"
            >
              <Text as="u">Google API Services User Data Policy</Text>
            </Link>
            , including the Limited Use requirements. */}
          Seamlessly manage yourassignments, students & resources all in one place.{' '}
          <Link textDecoration="underline" onClick={openDetailsModal}>
            See Details
          </Link>
        </Text>
      </Flex>
      <Flex
        gap="4"
        _hover={{
          opacity: 0.8
        }}
        rounded={2}
        background="#5D38DB"
        fontWeight="700"
        fontSize="14px"
        lineHeight="20px"
        color="#FFFFFF"
        onClick={handleSubmit}
        flexDirection="row"
        p={4}
        cursor="pointer"
        alignItems="center"
        minW={{ base: '80%', sm: '220px' }}
      >
        <Image
          src="/assets/images/share-googleclassroom.png"
          boxSize={{ base: '30px', sm: '46px' }}
        />
        <Flex flexDirection={{ base: 'row', sm: 'column' }}>
          <Text mr={{ base: 1, sm: 0 }}>Connect with</Text>
          <Text>Google Classroom</Text>
        </Flex>
      </Flex>
      <Modal isOpen={isDetailsModalOpen} onClose={closeDetailsModal} size="xs" isCentered>
        <ModalOverlay />
        <ModalContent py={2}>
          <ModalBody>
            <Text fontSize="14px">
              Classwise&apos;s use and transfer to any other app of information received from Google
              APIs will adhere to{' '}
              <Link
                href="https://developers.google.com/terms/api-services-user-data-policy"
                target="_blank"
              >
                <Text as="u">Google API Services User Data Policy</Text>
              </Link>
              , including the Limited Use requirements.
            </Text>
          </ModalBody>

          <ModalFooter justifyContent="center">
            <Button
              backgroundColor="#744CC6"
              color="white"
              onClick={closeDetailsModal}
              borderRadius="none"
            >
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  ) : (
    <></>
  );
}
