import { USER_ROLES, appConfig } from '../constants';
import { get, post } from './APIClient';
import { CLASSWISE_API, getClasswiseAPIURL } from './constants';
import { requestOptions } from './constants';

export const setUserRole = async (token) => {
  const res = await fetch(appConfig.getAPIURL(CLASSWISE_API.USER_ROLE), {
    method: 'POST',
    body: JSON.stringify({
      role: USER_ROLES.TEACHER
    }),
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + `${token}`
    }
  });
  const data = await res.json();
  return data;
};

export const createTeacherApi = async ({ token, teacher }) =>
  post({ url: 'users', token, data: teacher });

export const getGoogleAuthURL = async (docUrl, token) => {
  const res = await fetch(getClasswiseAPIURL(CLASSWISE_API.GOOGLE_AUTH), {
    method: 'POST',
    body: JSON.stringify({
      redirectURI: appConfig.googleRedirectPage,
      referrerURI: docUrl
    }),
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + `${token}`
    }
  });
  const data = await res.json();
  return data;
};

export const getGoogleToken = async (accessToken) => {
  try {
    const res = await fetch(
      getClasswiseAPIURL(CLASSWISE_API.USER_GOOGLE_TOKEN),
      requestOptions(accessToken, 'POST')
    );
    if (res.ok) {
      const token = await res.json();
      return [token?.access_token, token?.google_uid, token?.missingScopes];
    } else {
      return ['', ''];
    }
  } catch (err) {
    console.log(err);
    return ['', ''];
  }
};

export const loadCurrentUser = async (token) => {
  return get({ url: 'users', token });
}

export const emailTeacherLink = async ({ token, payload }) =>
  post({ url: 'users/email', token, data: payload });

export const getCurrentSubscription = async ({ token, checkoutId }) => {
  const query = checkoutId ? `?checkoutId=${checkoutId}` : '';
  return get({ url: `subscriptions${query}`, token });
};

export const fetchCustomerPortalLink = async ({ token }) => {
  return get({ url: 'payments/portalLink', token });
};
