import axios from 'axios'
import { appConfig } from '../constants'

const apiPrefix = '/api/v1/';

const getHeaders = (token) => {
  return {
    Authorization: `Bearer ${token}`
  };
};

export const post = ({ url, data, token, headers = {} }) => {
  return axios.post(`${appConfig.apiURL}${apiPrefix}${url}`, data, {
    headers: { ...getHeaders(token), ...headers }
  });
};

export const get = async ({ url, token }) => {
  const result = await axios.get(`${appConfig.apiURL}${apiPrefix}${url}`, {
    headers: getHeaders(token)
  });
  return result.data;
};

export const patch = async ({ url, data, token }) => {
  const result = await axios.patch(`${appConfig.apiURL}${apiPrefix}${url}`, data, {
    headers: getHeaders(token)
  });
  return result.data;
};

export const put = async ({ url, data, token }) => {
  return axios.put(`${appConfig.apiURL}${apiPrefix}${url}`, data, {
    headers: getHeaders(token)
  });
};
