import React from 'react'
import ContentBlock from '../utils/content-block'
import ButtonNameInput from '../utils/page-input'

// eslint-disable-next-line react/prop-types
export default function WelcomePage({ pageID }) {
    return (
        <>
            <ContentBlock pageID={pageID} />
            {/* <ButtonNameInput pageID={pageID} /> */}
        </>
    )
}
