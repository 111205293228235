export const TextboxModes = {
  TEXTBOX: 'TEXTBOX',
  FILL_IN_BLANKS: 'FILL_IN_BLANKS'
};

export const getBlankNode = (editor, blankID) => {
  return editor?.container?.querySelector(`span[data-blank_id="${blankID}"] span`);
};

export const updateNodeHtml = (editor, key, blankConfig) => {
  const textNode = getBlankNode(editor, key);
  const textValue = typeof blankConfig === 'string' ? blankConfig : blankConfig?.[key]?.answers?.[0];
  if (textNode && textValue) textNode.innerHTML = textValue;
};

export const updateNodeCount = (editor, key, count) => {
  const textNode = getBlankNode(editor, key);
  console.log('Updating ', textNode, count);
  if (textNode) {
    textNode.setAttribute('blank_count', count);
  }
  console.log(textNode, textNode.getAttribute('blank_count'));
};

export const getFibBlankConfig = (blankStates) => {
  return Object.values(blankStates).reduce((acc, curr) => {
    if (!curr.active) return acc;
    acc[curr.blankID] = {
      answers: [curr.value],
      score: curr.score === undefined ? 10 : curr.score
    };
    return acc;
  }, {});
};

export const getFibTotalScore = (blankConfig) => {
  return Object.values(blankConfig).reduce((acc, curr) => {
    acc += curr.score === undefined ? 10 : curr.score;
    return acc;
  }, 0);
};
