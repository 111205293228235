import { v4 as uuidV4 } from 'uuid'
import { assignmentPages } from '../../../../../../constants'
import { map } from 'lodash'

const adaptLibraryQuestion = {
    [assignmentPages.MCQ.displayName]: (question, pageIndex = 1) => {
        const libraryPage = {
            id: uuidV4(),
            pageIndex: pageIndex,
            type: assignmentPages.MCQ.displayName,
            subType: 'library',
            isDraggable: true,
            isEditable: true,
            score: Number(question.score || 10),
        }

        const elemId = uuidV4()
        const libraryMcqElement = {
            type: 'libraryQuestion',
            ID: elemId,
            id: elemId,
        }

        const questions = [
            {
                type: 'uneditableHtml',
                id: uuidV4(),
                value: question.question_content_html,
            },
        ]

        const solution = [
            {
                type: 'uneditableHtml',
                id: uuidV4(),
                value: question.solution_content_html,
            },
        ]

        const options = map(question.options, (opt, idx) => {
            const optValues = [
                {
                    type: 'uneditableHtml',
                    id: uuidV4(),
                    value: opt.option_content_html,
                },
            ]

            return {
                id: opt._id,
                isCorrect: opt.is_correct,
                name: `Option ${idx + 1}`,
                value: optValues,
            }
        })

        libraryMcqElement.value = {
            questions,
            options,
            solution,
        }

        return { libraryPage, libraryMcqElement }
    },
}

export default adaptLibraryQuestion
