import React from 'react'
import { Box } from '@chakra-ui/react'
import AppCommonLayout from './AppCommonLayout'

// eslint-disable-next-line react/prop-types
// TODO: Rename as this has nothing to do with logging in
export default function LoggedInUserWrapper({
    selectedMenu = '',
    isLoading = false,
    children,
    wrapperHeight='100vh',
}) {
    return (
        <Box overflow="hidden" display="flex" flexDirection="column" h={wrapperHeight}>
            <AppCommonLayout selectedMenu={selectedMenu} isLoading={isLoading}>
                {children}
            </AppCommonLayout>
        </Box>
    )
}
