/* eslint-disable no-prototype-builtins */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Flex,
  useToast,
  Checkbox,
  Tooltip,
  ScaleFade,
  Alert,
  Card,
  HStack
} from '@chakra-ui/react';
import * as Yup from 'yup';
import AssignmentSettings from '../../../share/assignment-settings';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../auth/useAuth';
// import { getAssignment } from '../../redux/thunks/get-assignment';
import { onUpdateAssignmentSettings } from '../../../../auth/createAssignment';
import { createAiRequest } from '../../../../../api/assignmentApis';
import { CREATE_TYPES } from '../../../../../constants';
import { eventLogger } from '../../../../../api/eventLogger';
import useUpdateAssignment from '../hooks/use-update-assignment';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAiRequestStatus } from '../hooks';
import { isNil } from 'lodash';
import { minutesLabel, getMinutes } from '../../../../../utils';
import {
  getActiveAssignmentPageID,
  getAssignmentData,
  getPageElements,
  getAssignmentMetadata,
  getAssignmentSettingModalStatus
} from '../../redux/selectors';
import { useParams } from 'react-router-dom';
import { GiCrossedSwords, GiWhirlwind } from 'react-icons/gi';
import { MdBlurLinear } from 'react-icons/md';
import { SlSpeech } from 'react-icons/sl';

const validationSchema = Yup.object().shape({
  assignmentName: Yup.string().required('Please enter assignment name')
});

const ASSIGNMENT_AI_SUGGESTIONS = [
  { name: 'Civil War', icon: <GiCrossedSwords /> },
  { name: 'Forces and Motion', icon: <GiWhirlwind /> },
  { name: 'Linear Equations', icon: <MdBlurLinear /> },
  { name: 'Parts of Speech', icon: <SlSpeech /> }
];

export default function AssignmentSetting() {
  const { showAssignmentSetting } = useSelector(getAssignmentSettingModalStatus);
  const [status, setStatus] = React.useState(null);

  const assignmentSettings = useSelector(getAssignmentData);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSettingLoading, setSettingLoading] = React.useState(false);
  const updateAssignment = useUpdateAssignment({ forceUpdate: true });
  const welcomePageID = useSelector(getActiveAssignmentPageID);
  const pageElements = useSelector(getPageElements(welcomePageID));
  const { createType } = useSelector(getAssignmentMetadata);
  const { startAiRequestCall, startAiRequestLoading } = useAiRequestStatus();

  const defaultValuesRef = useRef();

  const [isFirstTime, setIsFirstTime] = useState(false);

  const { token } = useAuth();
  const toast = useToast();
  const dispatch = useDispatch();
  const routerParams = useParams();
  const assignmentID = routerParams.assignmentID;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const startDate =
    assignmentSettings?.hasOwnProperty('startDate') && !isNil(assignmentSettings?.startDate)
      ? new Date(assignmentSettings?.startDate)
      : null;
  const endDate =
    assignmentSettings?.hasOwnProperty('dueDate') && !isNil(assignmentSettings?.dueDate)
      ? new Date(assignmentSettings?.dueDate)
      : null;

  const assignmentDuration =
    assignmentSettings?.hasOwnProperty('duration') && !isNil(assignmentSettings?.duration)
      ? {
          value: getMinutes(assignmentSettings.duration),
          label: minutesLabel(getMinutes(assignmentSettings.duration))
        }
      : { value: null, label: 'No time Limit' };

  defaultValuesRef.current = {
    assignmentName: isFirstTime ? '' : assignmentSettings.name,
    aiQuestions: false,
    revealSolutions: assignmentSettings.settings.revealSolutions,
    randomiseQuestions: assignmentSettings.settings.randomiseQuestions,
    assignmentDate: [startDate, endDate],
    duration: assignmentDuration
  };

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    setFocus,
    formState: { errors, isValid },
    reset
  } = useForm({
    defaultValues: defaultValuesRef.current,
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  });

  // If the assignment was created with createType as non AI & hence should now be opened with the isFirstTime setting
  useEffect(() => {
    if (
      createType &&
      createType !== CREATE_TYPES.HOME_HERO_CTA_AI && // If the assignment was created through the AI CTA, we open the AI questions modal
      createType !== CREATE_TYPES.HOME_TEMPLATE_CLONE && // If the assignment was created as a clone of an existing template, no modals auto open
      createType !== CREATE_TYPES.HOME_LESSON_PLAN && // If the assignment was created for building a lesson plan, we open the lesson plan modal
      createType !== CREATE_TYPES.HOME_LEARNING_ACTIVITY // If the assignment was created for building a learning activity, we open the learning activity modal
    ) {
      dispatch({
        type: 'UPDATE_ASSIGNMENT_MODAL_STATUS',
        payload: { showAssignmentSetting: true }
      });

      setIsFirstTime(true);

      // It sometimes persists the name from a previous assignment, hence clearing this explicity in case this is the first time
      setValue('assignmentName', '', { shouldValidate: false });
    }
  }, [createType, dispatch, setValue]);

  // If the settings were opened, force the assignment settings form to take in the default values
  useEffect(() => {
    if (showAssignmentSetting) {
      reset(defaultValuesRef.current);
    }
  }, [reset, showAssignmentSetting]);

  const toastMessage = React.useCallback(
    (message, type = 'success') => {
      toast({
        title: message,
        status: type,
        duration: 3000
      });
    },
    [toast]
  );

  // Disabling this as if we click settings when changes to assignment aren't saved, those changes are lost
  // useEffect(() => {
  //   if (!token) return;
  //   dispatch(getAssignment(assignmentID, token));
  // }, [assignmentID, dispatch, token]);

  useEffect(() => {
    setSettingLoading(true);
    setTimeout(() => {
      setSettingLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (isFirstTime) {
      setValue('assignmentDate', [null, null]);
      setValue('duration', { value: null, label: 'No time Limit' });
      setValue('revealSolutions', true);
      setValue('randomiseQuestions', false);
      setTimeout(() => {
        setFocus('assignmentName');
      }, 0);
    }
  }, [isFirstTime, setValue, setFocus]);

  const onClose = React.useCallback(() => {
    dispatch({
      type: 'ASSIGNMENT_SETTINGS_MODAL_CLOSED'
    });

    setIsFirstTime(false);
  }, [dispatch]);

  const getDuration = (assignmentDuration) => {
    const { value } = assignmentDuration;
    if (value) {
      return +value * 60000;
    } else {
      return null;
    }
  };

  const validateDuration = useCallback(
    (duration) => {
      if (isNil(duration)) {
        toastMessage('Please select/add a duration', 'error');
        return;
      } else {
        const { value, label } = duration;
        if (label === 'No time Limit') {
          return true;
        }
        if (isFinite(value)) {
          if (+value > 0 && +value > 180) {
            toastMessage('Assignment duration should be between 5 minute to 180 minutes', 'error');
            return false;
          }
        } else {
          setValue('duration', null);
          toastMessage('Please input a valid duration', 'error');
          return false;
        }
        return true;
      }
    },
    [setValue, toastMessage]
  );

  const updateStore = useCallback(
    (response) => {
      const { name, settings, duration, dueDate, startDate } = response;
      dispatch({
        type: 'UPDATE_ASSIGNMENT_DURATION',
        payload: duration
      });
      dispatch({
        type: 'UPDATE_ASSIGNMENT_NAME',
        payload: name
      });
      dispatch({
        type: 'UPDATE_ALL_ASSIGNMENT_SETTINGS',
        payload: settings
      });
      dispatch({
        type: 'UPDATE_ASSIGNMENT_STARTDAY',
        payload: startDate
      });
      dispatch({
        type: 'UPDATE_ASSIGNMENT_DUEDATE',
        payload: dueDate
      });
    },
    [dispatch]
  );

  const onAddAiQuestionUsingStream = useCallback(
    async (assignmentName) => {
      setStatus('Hang tight. It can take upto 30s to generate questions');
      startAiRequestLoading();
      const aiRequest = await createAiRequest({ token, data: { topic: assignmentName } });
      startAiRequestCall({ id: aiRequest });
      dispatch({
        type: 'UPDATE_PAGE_ELEMENT',
        payload: {
          pageID: welcomePageID,
          elementID: Object.values(pageElements)[0].id,
          value:
            '<h2>Instructions</h2><p>Choose the correct answer for each question from the options provided</p>'
        }
      });
    },
    [
      startAiRequestCall,
      startAiRequestLoading,
      dispatch,
      setStatus,
      pageElements,
      welcomePageID,
      token
    ]
  );

  const onUpdateSettings = useCallback(
    async (formValues) => {
      const {
        assignmentName,
        aiQuestions,
        assignmentDate,
        revealSolutions,
        randomiseQuestions,
        duration
      } = formValues;
      const isDurationValidated = validateDuration(duration);
      if (isDurationValidated) {
        setIsSubmitting(true);
        eventLogger(token, 'canvas-settings-modal-save-clicked', assignmentID);
        const [startDate, dueDate] = assignmentDate;

        const start = startDate ? { startDate } : {};
        const end = dueDate ? { dueDate } : {};

        const payload = {
          name: assignmentName,
          settings: {
            ...assignmentSettings?.settings,
            revealSolutions: revealSolutions,
            randomiseQuestions: randomiseQuestions
          },
          ...start,
          ...end,
          duration: getDuration(duration)
        };

        if (isFirstTime && aiQuestions) {
          // await onAddAiQuestion(assignmentName);
          await onAddAiQuestionUsingStream(assignmentName);
        }
        const response = await onUpdateAssignmentSettings(token, assignmentID, payload);
        updateStore(response);
        setTimeout(updateAssignment, 100);
        toastMessage('Assignment updated successfully');
        setIsSubmitting(false);
        setStatus(null);
        onClose();
      }
    },
    [
      assignmentID,
      assignmentSettings?.settings,
      isFirstTime,
      onAddAiQuestionUsingStream,
      onClose,
      toastMessage,
      token,
      updateAssignment,
      updateStore,
      validateDuration
    ]
  );

  // const onAddAiQuestion = useCallback(
  //   async (assignmentName) => {
  //     startStatusTimer();
  //     const aiQuestions = await getAiGeneratedQuestions({
  //       token,
  //       data: { topic: assignmentName }
  //     });
  //     eventLogger(token, 'generated-AI-questions', assignmentID);
  //     if (aiQuestions?.length > 0) {
  //       aiQuestions.forEach((q, index) => {
  //         const question = {
  //           ...q,
  //           type: assignmentPages.MCQ.displayName
  //         };
  //         dispatch(addAiQuestion({ question, pageIndex: index + 1 }));
  //       });
  //       dispatch({
  //         type: 'UPDATE_PAGE_ELEMENT',
  //         payload: {
  //           pageID: welcomePageID,
  //           elementID: Object.values(pageElements)[0].id,
  //           value:
  //             '<h2>Instructions</h2><p>Choose the correct answer for each question from the options provided</p>'
  //         }
  //       });
  //     }
  //   },
  //   [assignmentID, dispatch, pageElements, startStatusTimer, token, welcomePageID]
  // );

  const onSelectAssignmentName = useCallback(
    (assignmentName) => {
      setValue('assignmentName', assignmentName, { shouldValidate: true, shouldDirty: true });

      // If the user selects a name, the AI checkbox should get selected
      setValue('aiQuestions', true, { shouldValidate: true });
    },
    [setValue]
  );

  useEffect(() => {
    // If the form input is invalid the AI questions checkbox should also deselect
    if (!isValid) {
      setValue('aiQuestions', false, { shouldValidate: true });
    }
  }, [isValid, setValue]);

  return (
    <>
      {showAssignmentSetting && (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={showAssignmentSetting}
          size="xl"
          isCentered
          onClose={onClose}
          closeOnOverlayClick={!isFirstTime}
        >
          <ModalOverlay bg="rgba(0,0,0,0.8)" />
          <ModalContent m={3}>
            {!isFirstTime && <ModalCloseButton />}
            <ModalBody pb={0}>
              <ModalHeader textAlign="center" my={5}>
                {`${isFirstTime ? 'Create New' : 'Update'} Assignment`}
                {isFirstTime && (
                  <Text
                    mt={1}
                    color={'rgba(0,0,0,0.5)'}
                    fontWeight={'normal'}
                    fontSize={['13px', '15px']}
                  >
                    Enter assignment name below to get started
                  </Text>
                )}
              </ModalHeader>
              <form onSubmit={handleSubmit(onUpdateSettings)} id="reset">
                <FormControl px={4}>
                  <Box width="100%" pl={0} className="durationDropdown" position={'relative'}>
                    {!isFirstTime && (
                      <Text position="absolute" fontSize="xs" left={1} top={-2} px={2} bg="#fff">
                        Assignment Name
                      </Text>
                    )}

                    <Input
                      borderWidth={0}
                      fontSize={'14px'}
                      p={3}
                      h={50}
                      ref={initialRef}
                      {...register('assignmentName')}
                      placeholder="Enter Assignment Name (e.g. Photosynthesis)"
                    />
                  </Box>
                  <Text
                    fontSize={'12px'}
                    pl={3}
                    color={'red'}
                    mt={2}
                    className="text-red-500 ml-2 mt-2"
                  >
                    {errors.assignmentName?.message}
                  </Text>
                </FormControl>
                <Box width="100%" mb={5} position={'relative'}>
                  {/* <Text
                  position="absolute"
                  fontSize="sm"
                  zIndex={99}
                  top={-8}
                  px={2}
                  bg="#fff"
                  color={'#805ad5'}
                >
                 
                </Text> */}
                  {isFirstTime && (
                    <Flex
                      mt={3}
                      gap={2}
                      justifyContent={'center'}
                      flexWrap={'wrap'}
                      alignItems={'center'}
                    >
                      {/* <Text color={'rgba(0,0,0,0.5)'} fontSize="13px">Suggestions - </Text> */}
                      {ASSIGNMENT_AI_SUGGESTIONS.map((assignment) => {
                        return (
                          <Card
                            key={assignment.name}
                            shadow={'sm'}
                            width="auto"
                            borderRadius={25}
                            border={'1px solid #eee'}
                            py={2}
                            fontSize={'11.1px'}
                            px={3}
                            cursor={'pointer'}
                            transition={'0.1s linear'}
                            color={
                              getValues('assignmentName') === assignment.name ? '#fff' : 'black'
                            }
                            bg={
                              getValues('assignmentName') === assignment.name ? '#151515ad' : '#fff'
                            }
                            _hover={{
                              color: '#fff',
                              bg: '#151515ad'
                            }}
                            onClick={() => onSelectAssignmentName(assignment.name)}
                          >
                            <HStack gap={0}>
                              <Box>{assignment?.icon} </Box>
                              <p>{assignment.name}</p>
                            </HStack>
                          </Card>
                        );
                      })}
                    </Flex>
                  )}
                </Box>
                {isFirstTime && (
                  <FormControl px={4} mt={3} mb={5}>
                    <Tooltip
                      label="Please give a name to assignment to unlock this feature"
                      // Tooltip should only show if the text name input is empty & the checkbox is disabled
                      isDisabled={isValid}
                    >
                      <span>
                        <Controller
                          control={control}
                          name="aiQuestions"
                          defaultValue={false}
                          render={({ field: { onChange, value, ref } }) => {
                            return (
                              <Checkbox
                                ref={ref}
                                colorScheme="purple"
                                size="lg"
                                isDisabled={!isValid}
                                onChange={onChange}
                                isChecked={value}
                              >
                                <Text fontSize="14px">
                                  Add Questions based on the assignment name (powered by AI) ✨
                                </Text>
                              </Checkbox>
                            );
                          }}
                        />
                        {errors.aiQuestions?.message}
                      </span>
                    </Tooltip>
                  </FormControl>
                )}
                {!isFirstTime && (
                  <FormControl my={3}>
                    <Accordion allowToggle borderWidth={0} defaultIndex={!isFirstTime && [0]}>
                      <AccordionItem borderWidth={0}>
                        <h2>
                          <AccordionButton
                            bg={'#fff'}
                            _hover={{ bg: '#fff' }}
                            _expanded={{ bg: '#fff', color: '#8954BA' }}
                          >
                            <Box as="span" flex="1" textAlign="left">
                              Advance Settings (Optional)
                            </Box>
                            <AccordionIcon fontWeight={'bold'} />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <AssignmentSettings
                            isSettingLoading={isSettingLoading}
                            register={register}
                            control={control}
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </FormControl>
                )}

                <Flex justifyContent="center" width="100%" alignItems="center" direction="column">
                  <Button
                    isLoading={isSubmitting}
                    loadingText="Saving..."
                    colorScheme="purple"
                    type="submit"
                    mb={4}
                  >
                    Save
                  </Button>
                  {!!status && (
                    <Box minH="32px" mb={4} w={'100%'} py="4px">
                      <ScaleFade initialScale={0.2} in={!!status}>
                        <Alert
                          opacity={0.8}
                          status="info"
                          borderRadius={5}
                          justifyContent={'center'}
                        >
                          {status}
                        </Alert>
                      </ScaleFade>
                    </Box>
                  )}
                </Flex>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
