import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  Button,
  Flex,
  ModalCloseButton,
  Box,
  Text,
  Alert,
  ScaleFade,
  useDisclosure,
  FormLabel,
  Select,
  useToast
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';

import { useAuth } from '../../../../../auth/useAuth';
import { CREATE_TYPES } from '../../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import useUpdateAssignment from '../../../shared/hooks/use-update-assignment';
import { eventLogger } from '../../../../../../api/eventLogger';
import { getAssignmentMetadata } from '../../../redux/selectors';
import CreatableSelect from 'react-select/creatable';
import { countries } from '../../../../../../utils/countriesList';
import { AiRequestTopicWithAttachment } from '../../../ai-question/AiRequestTopicWithAttachment';
import { createFormDataPayloadWithFile } from '../../../../../../helpers/data.helpers';
import {
  createAiLearningActivity,
  createAiLearningActivityPdf
} from '../../../../../../api/learningActivityApis';
import addLearningActivity from '../../../redux/thunks/add-learning-activity';
import { useParams } from 'react-router-dom';

const CreateLearningActivityModal = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { assignmentID } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const updateAssignment = useUpdateAssignment({ forceUpdate: true });
  const [status, setStatus] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState();
  const toast = useToast();

  // We use the AI createType to open this modal on creation of the assignment
  const { createType } = useSelector(getAssignmentMetadata);

  const onSubmit = async (data) => {
    console.log(data);
    setIsLoading(true);
    setStatus('Hang tight. It can take upto 30s to generate a learning Activity');

    // Since this modal was opened on create, save learning activity plan name as assignment name
    dispatch({
      type: 'UPDATE_ASSIGNMENT_NAME',
      payload: `Learning Activity - ${data.topic}`
    });

    const payload = {
      topic: data.topic,
      modelType: data.modelType,
      noOfActivities: data.noOfActivities,
      country: data.country.value
    };

    const formData = createFormDataPayloadWithFile({ data: payload, file: selectedFile });

    let learningActivity = null;
    if (selectedFile) {
      learningActivity = await createAiLearningActivityPdf({
        toast,
        token,
        data: formData
      });
    } else {
      learningActivity = await createAiLearningActivity({
        token,
        data: formData
      });
    }
    console.log('learningActivity: ', learningActivity);
    setIsLoading(false);

    if (!learningActivity) {
      setStatus('Unable to generate Learning Activity. Please try again.');
    } else {
      dispatch(addLearningActivity(learningActivity.data));
      eventLogger(token, 'ai-learning-activity', assignmentID, {
        ...payload,
        isPDFAdded: !!selectedFile
      });
      setStatus(null);
      updateAssignment();
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (createType === CREATE_TYPES.HOME_LEARNING_ACTIVITY) {
      onOpen();
    }
    // Props do not change & hence this should only be invoked once
  }, [createType, onOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      size="lg"
      isCentered
    >
      <ModalOverlay bg="rgba(0,0,0,0.8)" />
      <ModalContent m={3}>
        <ModalCloseButton />
        <ModalHeader textAlign="center" mt={4}>
          Generate using AI
          <Text fontSize="sm" fontWeight={300} color="#7B7B7B" mt={1}>
            Create learning activities using Classwise AI
          </Text>
        </ModalHeader>
        <ModalBody>
          {/* Text input for topic */}
          <FormControl isRequired>
            <AiRequestTopicWithAttachment
              maxPages={Infinity}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              formProps={{ ...register('topic', { required: true }) }}
            />
            <Text fontSize="12px" color="red">
              {errors.topic?.message}
            </Text>
          </FormControl>

          <FormControl mt={4} borderBottom="1px solid lightgrey" pb={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Country</FormLabel>
              <Box minWidth="248px">
                <Controller
                  control={control}
                  name="country"
                  defaultValue={{ label: 'United States', value: 'United States' }}
                  render={(renderProps) => {
                    const {
                      field: { onChange, value }
                    } = renderProps;
                    return (
                      <CreatableSelect
                        placeholder="Select / Add Country"
                        options={countries.map((c) => ({ label: c, value: c }))}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          option: (styles) => ({ ...styles, fontSize: '14px' })
                        }}
                      />
                    );
                  }}
                />
              </Box>
            </Flex>
          </FormControl>

          <FormControl mt={3} borderBottom="1px solid lightgrey" pb={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>Number of Activities</FormLabel>
              <Select size="sm" w="unset" {...register('noOfActivities')}>
                {[3, 4, 5].map((v) => (
                  <option key={`no-of-activities-option-${v}`} value={v}>
                    {v}
                  </option>
                ))}
              </Select>
            </Flex>
          </FormControl>

          <FormControl mt={3} borderBottom="1px solid lightgrey" pb={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel>AI Model Type</FormLabel>
              <Select size="sm" w="unset" {...register('modelType')}>
                <option value="basic">Basic</option>
                <option value="advanced">Advanced</option>
              </Select>
            </Flex>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Flex justifyContent="center" width="100%" alignItems="center" direction="column">
            <Button
              isLoading={isLoading}
              colorScheme="purple"
              mr={3}
              onClick={handleSubmit(onSubmit)}
            >
              Generate ✨
            </Button>
            {status ? (
              <Box minH="32px" mt={4} w={'100%'} py="4px">
                <ScaleFade initialScale={0.2} in={!!status}>
                  <Alert opacity={0.8} status="info" borderRadius={5} justifyContent={'center'}>
                    {status}
                  </Alert>
                </ScaleFade>
              </Box>
            ) : (
              <>
                <Text fontSize="sm" color="grey" mt={1} textAlign="center">
                  Content generated by Classwise AI is a teaching aid.
                </Text>
                <Text fontSize="sm" color="grey" textAlign="center">
                  We recommend verifying before use.
                </Text>
              </>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateLearningActivityModal;
