import { Flex, Image, Text } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { onAssignmentCreate } from '../auth/createAssignment';
import { CREATE_TYPES } from '../../constants';
import { useAuth } from '../auth/useAuth';
import { useDispatch } from 'react-redux';
import { AssignmentBox, AssignmentCreateButton } from './utils';
import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';

// Component that houses the create options buttons for mobile
export const CreateOptionsMobile = ({ setIsLoading }) => {
  const { onAssignmentCreation, onCreateLearningActivity, onCreateLessonPlan, onCreateUsingAI } =
    useCreateAssignmentOptions({ setIsLoading });

  return (
    <Flex direction="row" w="100%" my="4" gap={4} flexWrap="wrap">
      <AssignmentCreateButton
        leftIcon={<Image src="/assets/images/create-icon.png" boxSize="32px" mr={1} />}
        text="New Assignment"
        onClick={onAssignmentCreation}
      />
      <AssignmentCreateButton
        leftIcon={<Image src="/assets/images/lesson-plan.svg" boxSize="32px" mr={1} />}
        text="Lesson Plan"
        onClick={onCreateLessonPlan}
      />
      <AssignmentCreateButton
        leftIcon={<Image src="/assets/images/learning-activity.svg" boxSize="32px" mr={1} />}
        text="Learning Activity"
        onClick={onCreateLearningActivity}
      />
      <AssignmentCreateButton
        leftIcon={
          <Flex
            alignItems="center"
            justifyContent="center"
            background="#1A4D8E"
            boxSize="32px"
            borderRadius="50%"
          >
            <Text color="#EECA00" fontSize="22px">
              ✨
            </Text>
          </Flex>
        }
        text="Create Using AI"
        onClick={onCreateUsingAI}
      />
    </Flex>
  );
};

export const CreateOptionsDesktop = () => {
  const { onAssignmentCreation, onCreateLearningActivity, onCreateLessonPlan, onCreateUsingAI } =
    useCreateAssignmentOptions();

  return (
    <Flex direction="row" w="100%" my="4" gap={6} flexWrap="wrap">
      <AssignmentBox
        onClick={onAssignmentCreation}
        image={<Image mt={2} src="/assets/images/create-icon.png" boxSize="45px" />}
        text={
          <span>
            New <br />
            Assignment
          </span>
        }
      />
      <AssignmentBox
        onClick={onCreateLessonPlan}
        image={<Image mt={2} src="/assets/images/lesson-plan.svg" boxSize="45px" />}
        text={
          <span>
            Lesson <br /> Plan
          </span>
        }
      />
      <AssignmentBox
        onClick={onCreateLearningActivity}
        image={<Image mt={2} src="/assets/images/learning-activity.svg" boxSize="45px" />}
        text={
          <span>
            Learning
            <br /> Activity
          </span>
        }
      />
      <AssignmentBox
        onClick={() => onCreateUsingAI()}
        image={
          <Flex
            mt={2}
            alignItems="center"
            justifyContent="center"
            background="#1A4D8E"
            boxSize="45px"
            borderRadius="50%"
          >
            <Text color="#EECA00" fontSize="22px">
              ✨
            </Text>
          </Flex>
        }
        text={
          <span>
            Create Using
            <br /> AI
          </span>
        }
      />
    </Flex>
  );
};

export const useCreateAssignmentOptions = ({ setIsLoading = noop } = {}) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAssignmentCreation = useCallback(() => {
    setIsLoading(true);
    onAssignmentCreate(
      token,
      {
        source: 'createassignment-home-clicked',
        createType: CREATE_TYPES.HOME_HERO_CTA
      },
      navigate
    );
    dispatch({
      type: 'ASSIGNMENT_CREATED',
      payload: {
        createType: CREATE_TYPES.HOME_HERO_CTA
      }
    });
  }, [dispatch, setIsLoading, token]);

  const onCreateUsingAI = useCallback(
    (subject) => {
      setIsLoading(true);
      const createType = CREATE_TYPES.HOME_HERO_CTA_AI;
      if (subject) {
        onAssignmentCreate(
          token,
          {
            source: `createassignment-ai-template`,
            subject,
            createType
          },
          navigate
        );
      } else {
        onAssignmentCreate(
          token,
          {
            source: 'createassignment-ai-home-clicked',
            createType
          },
          navigate
        );
      }
      dispatch({
        type: 'ASSIGNMENT_CREATED',
        payload: {
          createType,
          subject
        }
      });
    },
    [dispatch, setIsLoading, token]
  );

  const onCreateLessonPlan = useCallback(() => {
    setIsLoading(true);
    onAssignmentCreate(
      token,
      {
        source: `createassignment-lesson-plan`,
        createType: CREATE_TYPES.HOME_LESSON_PLAN
      },
      navigate
    );
    dispatch({
      type: 'ASSIGNMENT_CREATED',
      payload: {
        createType: CREATE_TYPES.HOME_LESSON_PLAN
      }
    });
  }, [dispatch, setIsLoading, token]);

  const onCreateLearningActivity = useCallback(() => {
    setIsLoading(true);
    onAssignmentCreate(
      token,
      {
        source: `create-learning-activity`,
        createType: CREATE_TYPES.HOME_LEARNING_ACTIVITY
      },
      navigate
    );
    dispatch({
      type: 'ASSIGNMENT_CREATED',
      payload: {
        createType: CREATE_TYPES.HOME_LEARNING_ACTIVITY
      }
    });
  }, [dispatch, setIsLoading, token]);

  return useMemo(
    () => ({
      onAssignmentCreation,
      onCreateLessonPlan,
      onCreateLearningActivity,
      onCreateUsingAI
    }),
    [onAssignmentCreation, onCreateLearningActivity, onCreateLessonPlan, onCreateUsingAI]
  );
};
