import { Box, Stack } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import CanvasLeftSidebar from './sidebars/left-sidebar/left-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { throttle } from 'lodash';
import { getActivePageID, getLibraryDialogConfig, isAssignmentFetched } from './redux/selectors';
import RightSidebar from './sidebars/right-sidebar';
import LibraryBox from '../../app/canvas/shared/library';
import ClasswiseLoader from '../../core/loader';
import CanvasPageColumn from './page-column';
import AddingAiQuestions from './ai-question/adding-ai-questions';

export default function Canvas() {
  const elemID = useMemo(() => uuidV4(), []);
  const dispatch = useDispatch();

  // Assignment details are now fetched in the canvas header so all three pages - create, share & results have the reference to it from redux store

  const { isLibraryDialogOpen: showLibraryModal } = useSelector(getLibraryDialogConfig);
  const activePageID = useSelector(getActivePageID);
  const initialFetching = useSelector(isAssignmentFetched);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateElementValue = useCallback(
    throttle((elementID, value) => {
      dispatch({
        type: 'UPDATE_PAGE_ELEMENT',
        payload: {
          activePageID,
          elementID,
          value
        }
      });
    }, 300),
    [activePageID]
  );

  const onDelete = (elementID) => {
    if (!elementID) return;
    dispatch({
      type: 'DELETE_PAGE_ELEMENT',
      payload: { elementID, activePageID }
    });
  };

  return (
    <>
      {initialFetching && (
        <Box
          display="flex"
          alignSelf="center"
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <ClasswiseLoader />
        </Box>
      )}
      <AddingAiQuestions />
      <Stack
        direction={['column', 'row']}
        spacing="0px"
        overflow="hidden"
        flex="1"
        height="calc(100vh - 60px)"
        display={{ base: initialFetching ? 'none' : 'flex' }}
      >
        <CanvasLeftSidebar />
        {/* Shows the center page for all page types, adapts to mobile vs desktop layouts */}
        <CanvasPageColumn />
        {/* In desktop view, we have the right sidebar displayed all the time */}
        <Box display={{ base: 'none', lg: 'flex' }}>
          <RightSidebar />
        </Box>

        {showLibraryModal && (
          <LibraryBox elementID={elemID} onUpdate={updateElementValue} onDelete={onDelete} />
        )}
      </Stack>
    </>
  );
}
