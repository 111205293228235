import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPageElements } from '../../redux/selectors';
import { Text, Tooltip } from '@chakra-ui/react';
import { getFirstPageElement } from './utils';

function QuestionPagePreview({ pageID, title }) {
  const pageElements = useSelector(getPageElements(pageID));

  const elementText = React.useMemo(() => {
    return getFirstPageElement(title, pageElements);
  }, [pageElements, title]);

  // State & effect used so that the component does not re-render again
  const [firstPageElem, setFirstPageElem] = useState('');
  useEffect(() => {
    setFirstPageElem(elementText);
  }, [elementText]);

  return (
    <Tooltip
      placement="right"
      portalProps="containerRef"
      hasArrow
      label={<span className="preview-tooltip" dangerouslySetInnerHTML={{ __html: firstPageElem }}></span>}
      suppressContentEditableWarning
    >
      <Text
        _focus={{
          bg: 'transparent'
        }}
        _active={{
          bg: 'transparent'
        }}
        fontSize={'10px'}
        w={{ sm: 'auto', lg: 120 }}
        h={12}
        alignItems="start"
        borderRadius={0}
        shadow="sm"
        overflow="hidden"
        listStylePosition={'inside'}
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          wordWrap: 'break-word',
          overflowWrap: 'break-word'
        }}
        suppressContentEditableWarning
      >
        <span dangerouslySetInnerHTML={{ __html: firstPageElem }}></span>
      </Text>
    </Tooltip>
  );
}

export default QuestionPagePreview;
