import { Avatar } from '@chakra-ui/avatar';
import {
  Box,
  Flex,
  Text,
  Stack,
  Center,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tooltip
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../auth/useAuth';
import { getClasswiseStudents, getClasswiseSubmissions } from '../../../api/fetchClasswiseAssignmentData';
import { ResultsByStudents } from './results-by-students';
import { ResultsByQuestions } from './results-by-questions';
import { assignmentPages, QUESTION_RESPONSE_STATUS } from '../../../constants';
import { useParams } from 'react-router-dom';
import BigPictureResults from './BigPicture';
import EmptyResultsState from './EmptyResultsState';
import { getFIBQuestionStats, mergeBlankConfig } from './utils';
import FIBBlankDisplay from './FIBBlankDisplay';
import useParserOptions from './useParserOptions';

export default function Results() {
  const routerParams = useParams();
  const assignmentID = routerParams.assignmentID;
  const { token } = useAuth();
  const [studentSubmissions, setStudentSubmissions] = useState([]);
  const [status, setStatus] = useState([]);
  const [activeSub, setActiveSub] = useState('default');
  const [isEmptyState, setEmptyState] = useState(false);
  const [questionSubmissions, setQuestionSubmissions] = useState([]);
  const [blankConfigs, setBlankConfigs] = useState({});

  const getStatusText = (status) => {
    switch (status) {
      case 'COMPLETED':
        return <Text textColor="#389411">Submitted</Text>;
      case 'STARTED':
        return <Text textColor="#89A6FF">Started</Text>;
      case 'YET_TO_START':
        return <Text textColor="#FF9786">Yet to Start</Text>;
    }
  };

  useEffect(() => {
    if (!assignmentID || !token) return;

    const fetchData = async () => {
      const submissions = await getClasswiseSubmissions(token, assignmentID, true);
      const students = await getClasswiseStudents(submissions, token);

      const stat = { yet_to_start: 0, started: 0, submitted: 0 };
      const studentSubs = !Array.isArray(submissions)
        ? []
        : submissions?.map((sub) => {
            const totalAssignmentScore =
              sub?.assignment?.questions.reduce((sum, q) => {
                return sum + q.score;
              }, 0) || 0;

            const subinfo = {
              ...sub,
              result: {
                ...sub?.result,
                incorrect: sub?.result ? sub?.result.answered - sub?.result.correct - sub?.result.partial : 0,
                skipped: sub?.result ? sub?.assignment?.questions.length - sub?.result.answered : 0,
                totalAssignmentScore: totalAssignmentScore,
                totalQuestions: sub?.assignment?.questions.length || 0
              },
              studentinfo: students.find((s) => s.uid == sub.student)
            };

            switch (sub.status) {
              case 'YET_TO_START':
                stat.yet_to_start++;
                break;
              case 'STARTED':
                stat.started++;
                break;
              case 'COMPLETED':
                stat.submitted++;
                break;
            }
            return subinfo;
          });

      setStatus(stat);
      setStudentSubmissions(studentSubs);
      if (studentSubs.length === 0) {
        setEmptyState(true);
      } else {
        const allQuestionBlankConfig = {};
        const questions = studentSubs[0].assignment.questions.map((q) => {
          const questionType = q.type;
          const question_stat = { correct: 0, partial: 0, incorrect: 0, unanswered: 0 };
          if (questionType === assignmentPages.FIB.key) {
            // We're merging all blank configs from all questions within this assignment as we need to show the blank answers within the parsed FIB text
            // The parser options are generated once within the hook, hence we need to ensure those options have answers from all blanks in the assignment
            // Slightly hacky, but not a lot of other elegant solutions available
            mergeBlankConfig(allQuestionBlankConfig, q.fibMetadata.blankConfig);

            studentSubs.map((sub) => {
              const response = sub.studentResponse.find((r) => r.questionId == q.id);
              if (response?.status == QUESTION_RESPONSE_STATUS.ANSWERED) {
                // Student's response is response, the question is q
                const fibStats = getFIBQuestionStats(q, response);

                // If the response by the student has both right & wrong answers within FIB -> partial correctness
                if (fibStats.isPartial) question_stat.partial++;
                // Else, if the response has all correct -> all correct
                else if (fibStats.isCorrect) question_stat.correct++;
                // Else -> incorrect
                else question_stat.incorrect++;
              } else {
                question_stat.unanswered++;
              }
            });
          } else if (questionType === assignmentPages.MCQ.key) {
            const correct_answer = q.options.find((o) => o.isCorrect);
            studentSubs.map((sub) => {
              const response = sub.studentResponse.find((r) => r.questionId == q.id);
              if (response?.status == QUESTION_RESPONSE_STATUS.ANSWERED) {
                if (response.answer[0] == correct_answer?._id || response.answer[0] == correct_answer?.id) {
                  question_stat.correct++;
                } else {
                  question_stat.incorrect++;
                }
              } else {
                question_stat.unanswered++;
              }
            });
          }

          return {
            ...q,
            ...question_stat
          };
        });

        setQuestionSubmissions(questions);

        setBlankConfigs(allQuestionBlankConfig);
      }
    };

    fetchData();
  }, [assignmentID, token]);

  const parseReplaceOptions = useParserOptions({ BlankReviewComponent: FIBBlankDisplay, blankProps: blankConfigs });

  return studentSubmissions.length ? (
    <Stack
      direction={['column', 'row']}
      overflow="hidden"
      flex="1"
      m="20px"
      display="flex"
      border="1px"
      borderColor="#DDDFEC"
    >
      <Flex direction="column" w="900px">
        <BigPictureResults assignmentStatuses={status} />
        <Box overflow="auto">
          <Tabs variant="enclosed" mt="20px">
            <TabList>
              <Tab>By Students</Tab>
              <Tab>By Questions</Tab>
            </TabList>
            <TabPanels>
              {/* Panel for submissions by student */}
              <TabPanel>
                <Flex direction="row" py="8px" fontSize="14px" fontWeight="700" textColor="#686868">
                  <Box flex="1" px="4">
                    <Text>Student</Text>
                  </Box>
                  <Box width="80px">Status</Box>
                  <Box width="70px">Score</Box>
                  <Box width="30px">%</Box>
                </Flex>
                {studentSubmissions.map((sub) => (
                  <>
                    <Flex
                      direction="row"
                      fontSize="13px"
                      py="20px"
                      onClick={() => setActiveSub(sub._id)}
                      cursor="pointer"
                      bgColor={sub._id == activeSub ? '#0000001F' : '#FFFFFF'}
                      borderTop="1px"
                      borderColor="#DDDFEC"
                    >
                      <Box flex="1" flexDirection="row" display="flex" alignItems="center" gap="4" px="4">
                        <Avatar
                          referrerPolicy="no-referrer"
                          src={sub.studentinfo?.photoURL}
                          name={sub.studentinfo?.displayName}
                          size="sm"
                        />
                        <Text>{sub.studentinfo?.displayName}</Text>
                      </Box>
                      <Box width="80px" display="flex" alignItems="center">
                        {getStatusText(sub.status)}
                      </Box>
                      <Box width="70px" display="flex" alignItems="center">
                        {sub?.result.totalScore !== undefined &&
                          sub?.result?.totalScore + '/' + sub?.result?.totalAssignmentScore}
                      </Box>
                      <Box width="30px" display="flex" alignItems="center">
                        {sub?.result.totalScore !== undefined &&
                          sub?.result?.totalAssignmentScore !== undefined &&
                          sub?.result?.totalAssignmentScore !== 0 &&
                          Math.round((sub?.result?.totalScore / sub?.result?.totalAssignmentScore) * 100) + '%'}
                      </Box>
                    </Flex>
                  </>
                ))}
              </TabPanel>
              {/* Panel for viewing submissions by question */}
              <TabPanel>
                <Flex direction="column">
                  <Flex direction="row" py="8px" fontSize="14px" fontWeight="700" textColor="#686868" gap={2}>
                    <Box flex="1">
                      <Text>Question</Text>
                    </Box>
                    <Box width="50px">Score</Box>
                    <Box width="50px">Correct</Box>
                    <Box width="60px">Partially Correct</Box>
                    <Box width="60px">Incorrect</Box>
                    <Box width="85px">Unanswered</Box>
                  </Flex>
                  {questionSubmissions.map((q, id) => {
                    const html = q.elements[0].html;
                    const questionType = q.type;
                    return (
                      <>
                        <Flex
                          direction="row"
                          fontSize="13px"
                          py="10px"
                          onClick={() => setActiveSub(q.id)}
                          cursor="pointer"
                          bgColor={q.id == activeSub ? '#0000001F' : '#FFFFFF'}
                          alignItems={'center'}
                          borderTop="1px"
                          borderColor="#DDDFEC"
                          gap={2}
                        >
                          <Box flex="1" display="flex" flexDirection="column">
                            <Text color={'#686868'} fontSize="18px" fontWeight="700">
                              Question {id + 1}
                            </Text>
                            <Tooltip
                              placement="right"
                              portalProps="containerRef"
                              hasArrow
                              label={
                                html &&
                                (questionType === assignmentPages.FIB.key
                                  ? parse(html, parseReplaceOptions)
                                  : parse(html))
                              }
                            >
                              <Flex
                                fontSize="10px"
                                w={150}
                                h={8}
                                alignItems="start"
                                borderRadius={0}
                                shadow="sm"
                                overflow="hidden"
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  wordBreak: 'break-word',
                                  wordWrap: 'break-word',
                                  overflowWrap: 'break-word'
                                }}
                              >
                                {html &&
                                  (questionType === assignmentPages.FIB.key
                                    ? parse(html, parseReplaceOptions)
                                    : parse(html))}
                              </Flex>
                            </Tooltip>
                          </Box>
                          <Box width="50px" textAlign="center">
                            {q.score}
                          </Box>
                          <Box width="50px" textAlign="center">
                            {q.correct}
                          </Box>
                          <Box width="60px" textAlign="center">
                            {q.partial}
                          </Box>
                          <Box width="60px" textAlign="center">
                            {q.incorrect}
                          </Box>
                          <Box width="85px" textAlign="center">
                            {q.unanswered}
                          </Box>
                        </Flex>
                      </>
                    );
                  })}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
      <Box width="100%" overflow="auto" maxW="calc(100vw - 500px)" borderLeft="1px" borderColor="#DDDFEC">
        <Flex
          direction="column"
          id="default"
          flexGrow="1"
          boxShadow="xl"
          border="1px"
          borderColor="#DDDFEC"
          display={'default' == activeSub ? 'flex' : 'none'}
          m="15px"
          minHeight="calc(100vh - 110px)"
          alignItems="center"
          justifyContent="center"
          fontSize="15px"
          p={4}
        >
          <Text>Select a student on the left to preview submission by the student</Text>
        </Flex>
        <ResultsByStudents studentSubmissions={studentSubmissions} activeSub={activeSub} />
        <ResultsByQuestions
          blankConfigs={blankConfigs}
          studentSubmissions={studentSubmissions}
          questions={questionSubmissions}
          activeSub={activeSub}
        />
      </Box>
    </Stack>
  ) : isEmptyState ? (
    <EmptyResultsState />
  ) : (
    <Center h="100vh" w="100vw">
      <Spinner />
    </Center>
  );
}
