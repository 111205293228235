export const Classes = [
  'Class 1',
  'Class 2',
  'Class 3',
  'Class 4',
  'Class 5',
  'Class 6',
  'Class 7',
  'Class 8',
  'Class 9',
  'Class 10',
  'Class 11',
  'Class 12'
].reverse();

export const Sections = [
  { label: 'Learning Outcomes', value: 'Learning Outcomes' },
  { label: 'Subject Specific Skills', value: 'Subject Specific Skills' },
  { label: 'Prerequisites', value: 'Prerequisites' },
  { label: 'Key Vocabulary', value: 'Key Vocabulary' },
  { label: 'Curiosity Triggers', value: 'Curiosity Triggers' },
  { label: 'Learning Activities', value: 'Learning Activities' },
  { label: 'Classroom Strategies', value: 'Classroom Strategies' },
  { label: 'Teaching Pedagogy', value: 'Teaching Pedagogy' },
  { label: 'Common Misconceptions', value: 'Common Misconceptions' },
  { label: 'Resources', value: 'Resources' }
];

export const PracticeQuestions = new Array(10).fill(0).map((_, idx) => ({
  label: `${10 - idx} Questions`,
  value: 10 - idx
}));
