import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import { assignmentPages } from '../../../../../../constants';
import { adaptFIBToState, adaptMCQToState, fetchAssignmentByID } from './utils';

function addPageToAssignment({ assignment, elements }, page, pageIndex, canEdit) {
  const pageID = page.id;
  const pageElements = page.elements;
  const pageType = page.type?.toUpperCase();
  assignment.pages[pageID] = {
    id: pageID,
    pageIndex,
    type: pageType,
    isDraggable: canEdit,
    isEditable: canEdit,
    score: page.score,
    level: page.level,
    settings: {
      ...(page.settings || {})
    }
  };

  const pageElementState = {
    elements: {},
    elementOrder: []
  };

  if (pageType === assignmentPages.MCQ.displayName) {
    adaptMCQToState({ page, pageElementState });
  } else if (pageType === assignmentPages.FIB.displayName) {
    adaptFIBToState({ page, pageElementState });
  } else {
    // Add the elements coming from the question into the page's element state
    forEach(pageElements, (elem = {}) => {
      const elemId = elem.id;
      pageElementState.elements[elemId] = {
        ...elem,
        value: elem.value || elem.html || ''
      };

      pageElementState.elementOrder.push({ id: elemId });
    });
  }

  elements[pageID] = pageElementState;
}

export function getAssignment(assignmentID, token) {
  return async function getAssignmentThunk(dispatch) {
    if (!assignmentID) return;

    dispatch({
      type: 'FETCH_ASSIGNMENT',
      payload: { initialFetching: true }
    });

    const res = await fetchAssignmentByID(assignmentID, token);

    if (!res.ok) {
      return;
    }

    const assignmentData = await res.json();

    const welcomeScreen = assignmentData.welcomeScreen;
    const thankYouScreen = assignmentData.thankYouScreen;
    const questions = assignmentData?.questions || [];

    const elementData = {};
    const adaptedAssignment = omit(assignmentData, [
      'questions',
      'welcomeScreen',
      'thankYouScreen',
      'createdAt',
      'updatedAt',
      'createdBy'
    ]);

    adaptedAssignment.assignmentID = assignmentID;
    adaptedAssignment.activePage = welcomeScreen.id;
    adaptedAssignment.pages = {};

    // Add the welcome screen as the first page
    addPageToAssignment(
      { assignment: adaptedAssignment, elements: elementData },
      welcomeScreen,
      0,
      false
    );

    adaptedAssignment.pages[welcomeScreen.id].type = assignmentPages.WELCOME.displayName;
    adaptedAssignment.pages[welcomeScreen.id].config = {
      buttonName: adaptedAssignment.startButtonText
    };

    // Add each question as a separate page
    forEach(questions, (question, idx) => {
      addPageToAssignment(
        { assignment: adaptedAssignment, elements: elementData },
        question,
        idx + 1,
        true
      );
    });

    // Add the thank you screen as the last page
    addPageToAssignment(
      { assignment: adaptedAssignment, elements: elementData },
      thankYouScreen,
      (questions.length || 0) + 1,
      false
    );

    adaptedAssignment.pages[thankYouScreen.id].type = assignmentPages.END.displayName;
    adaptedAssignment.pages[thankYouScreen.id].config = {
      buttonName: adaptedAssignment.endButtonText,
      allowMultipleSubmissions: adaptedAssignment.allowMultipleSubmissions,
      randomiseQuestions: adaptedAssignment.randomiseQuestions,
      revealSolutions: adaptedAssignment.revealSolutions
    };
    // Dispatch to populate the state of assignments & elements
    dispatch({
      type: 'SET_ASSIGNMENT',
      payload: adaptedAssignment
    });

    dispatch({
      type: 'SET_ASSIGNMENT_ELEMENTS',
      payload: elementData
    });

    // thunk dispatches are sync, & many updates are still happening once the assignment is fetched.. so wait for 2s until everything is finished being set
    setTimeout(() => {
      dispatch({
        type: 'FETCH_ASSIGNMENT',
        payload: { initialFetching: false }
      });
    }, 1_500);
  };
}
