import React, { useEffect } from 'react'
import LoggedInUserWrapper from '../core/LoggedInUserWrapper'
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Button,
  Input,
  Text,
  Image
} from '@chakra-ui/react';
import { onAssignmentCreate } from '../auth/createAssignment';
import { useDispatch } from 'react-redux'
import { AssignmentsPageClasswise } from './assignments-home-classwise';
import { AssignmentsPageGoogle } from './assignments-home-google';
import { useAuth } from '../auth/useAuth';
import { eventLogger } from '../../api/eventLogger';
import { CREATE_TYPES, WELCOMEPAGE_TEXT_PLACEHOLDER } from '../../constants';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export function AssignmentsPage() {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    eventLogger(token, 'assignment-home-visited');
  }, []);

  const onAssignmentCreation = () => {
    setIsLoading(true);
    onAssignmentCreate(token, { source: 'createassignment-assignmenthome-clicked' }, navigate);
    dispatch({
      type: 'UPDATE_ASSIGNMENT_DURATION',
      payload: null
    });
    dispatch({
      type: 'UPDATE_ALL_ASSIGNMENT_SETTINGS',
      payload: {
        revealSolutions: true,
        randomiseQuestions: false,
        allowMultipleSubmissions: true
      }
    });
    dispatch({
      type: 'UPDATE_ASSIGNMENT_STARTDAY',
      payload: null
    });
    dispatch({
      type: 'UPDATE_ASSIGNMENT_DUEDATE',
      payload: null
    });
    dispatch({
      type: 'ASSIGNMENT_CREATED',
      payload: {
        createType: CREATE_TYPES.ASSIGNMENTS_LIST_CTA
      }
    });
    dispatch({
      type: 'UPDATE_WELCOME_PAGE_PLACEHOLDER',
      payload: {
        welcomePageTextPlaceholder: WELCOMEPAGE_TEXT_PLACEHOLDER.WITHOUT_AI
      }
    });
  };

  return (
    <LoggedInUserWrapper selectedMenu="assignments" isLoading={isLoading}>
      <Flex py="4" display="flex" width="100%" flexDirection="column">
        <Flex
          direction={['column', 'row']}
          width="100%"
          alignItems={['start', 'center']}
          justifyContent={'space-between'}
        >
          <Text fontSize="3xl" as="b" pl={4}>
            Your Assignments
          </Text>
          <Flex justifyContent="flex-end" my="22px" pr="8" pl={4}>
            <Input
              borderRadius="0"
              fontSize={['11px', '14px']}
              lineHeight="16px"
              fontWeight="400"
              textColor="#7D7D7D"
              placeholder="Search by assignment name"
              maxW="246px"
              backgroundImage="/assets/images/search.png"
              backgroundRepeat="no-repeat"
              backgroundPosition="95%"
            />
            <Button
              onClick={onAssignmentCreation}
              w="160px"
              marginLeft={['10px', '20px']}
              borderRadius="0"
              backgroundColor="#5D38DB"
              textColor="#FFFFFF"
              fontFamily="Helvetica"
              fontWeight="400"
              fontSize={['13px', '15px']}
              lineHeight="20px"
            >
              <Flex alignItems="center">
                <FaPlus size="16px" />
                <Text fontSize="16px" ml={2}>
                  Create
                </Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>

        <Box p={2} w="100%" height="calc(100vh - 230px)" overflow={'hidden'}>
          <Tabs isLazy colorScheme="purple" lazyBehavior="keepMounted">
            <TabList>
              <Tab>Classwise</Tab>
              <Tab>Google Classroom</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                <AssignmentsPageClasswise />
              </TabPanel>
              <TabPanel>
                <AssignmentsPageGoogle />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </LoggedInUserWrapper>
  );
}
