import React from 'react';
import { BsCardText, BsPlayBtn } from 'react-icons/bs';
import { HiOutlineCamera } from 'react-icons/hi';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { CiViewTable } from 'react-icons/ci';
import { PAGE_TYPES } from '../../../../../../constants';
import { RiPencilFill } from 'react-icons/ri';

export const canvasContentBlockSidebar = {
  title: 'Content Blocks',
  content: [
    {
      title: 'Text',
      description: 'Add text and play with fonts and colors',
      icon: <BsCardText size={25} color="#1D1D1B" />,
      type: 'textbox'
    },
    {
      title: 'Image',
      description: 'Upload and move image seamlessly',
      icon: <HiOutlineCamera size={25} color="#1D1D1B" />,
      type: 'image'
    },
    {
      title: 'Video',
      description: 'Record, upload or add video via link',
      icon: <BsPlayBtn size={25} color="#1D1D1B" />,
      type: 'video'
    },
    {
      title: 'Table',
      description: 'Add rows and columns ',
      icon: <CiViewTable size={25} color="#1D1D1B" />,
      type: 'table'
    }
  ]
};

export const canvasQuestionTypesSidebar = {
  title: 'Question Types',
  content: [
    {
      title: 'MCQ',
      description: 'Multiple choice question with options',
      icon: <AiOutlineUnorderedList size={25} color="#1D1D1B" />,
      type: PAGE_TYPES.MCQ
    },
    // {
    //   title: 'FIB',
    //   description: 'Paragraph with fill in the blanks',
    //   icon: <RiPencilFill size={25} color="#1D1D1B" />,
    //   type: PAGE_TYPES.FILL_IN_BLANKS
    // },
    {
      title: 'Pick from CW Library',
      description: '',
      type: PAGE_TYPES.LIBRARY_QUESTION
    },
    {
      title: 'Generate using AI ✨',
      description: '',
      type: PAGE_TYPES.AI_QUESTION
    }
  ]
};
