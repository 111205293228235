import { Box, Button, Flex, Heading, Spinner, Text, Skeleton } from '@chakra-ui/react';
import { get } from 'lodash';
import React, { memo, useContext, useState } from 'react';
import { LibraryContext } from '../library-context';
import CountryDropdown from './country-header';
import GenericTopicSelector from './generic-topic-selector';
import SearchTopics from './search-topics';

function QuestionBox({ topic, count, onTopicSelect }) {
  const [isQuestionSelect, setIsQuestionSelect] = React.useState(false);

  const handleTopicSelect = React.useCallback(
    (topic) => {
      setIsQuestionSelect(true);
      onTopicSelect(topic);
    },
    [onTopicSelect]
  );

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      opacity={!isQuestionSelect ? 1 : 0.7}
      border="1px"
      borderColor="gray.200"
      minHeight={{ base: '202px', lg: '110px' }}
      width={{ base: '45%', lg: '17%' }}
      m={{ base: 2, lg: 4 }}
    >
      <Flex height="100px" justifyContent="center">
        <Text px={3} textAlign="center" color="#373738" fontWeight="bold" pt={7}>
          {topic}
        </Text>
      </Flex>
      <Text color="#717CFF" textAlign="center" fontWeight="bold" pb={7}>
        {count} Questions
      </Text>
      <Button
        width="100%"
        rounded={false}
        color="#FFFFFF"
        height={'50px'}
        _hover={{
          backgroundColor: !isQuestionSelect ? '#6364D8' : 'gray.200',
          opacity: 0.8
        }}
        onClick={() => handleTopicSelect(topic)}
        backgroundColor={!isQuestionSelect ? '#6364D8' : 'gray.200'}
      >
        {!isQuestionSelect ? `See Question` : <Spinner color="purple.500" size="md" />}
      </Button>
    </Flex>
  );
}

function TopicList({ allSubjects, onSubjectChange, selectedCountry, onCountryChange }) {
  const {
    filterData: { goals, sources },
    query,
    setTopic,
    setQuestionsList,
    setQuestionsListFromTopicSearch
  } = useContext(LibraryContext);

  const [loading, setLoading] = useState(false);

  const onGoalChange = React.useCallback(
    (goal) => {
      setTopic(true, { goal, source: 'All' });
    },
    [setTopic]
  );

  const goalSources = React.useMemo(() => {
    const source = get(get(sources, query.goal), query.language) || [];
    return ['All', ...source];
  }, [query.goal, query.language, sources]);

  const onSourceChange = React.useCallback(
    (source) => {
      setTopic(true, { goal: query.goal, source });
    },
    [query.goal, setTopic]
  );

  const onTopicSelect = React.useCallback(
    (selectedTopic) => {
      setQuestionsList(selectedTopic);
    },
    [setQuestionsList]
  );

  const onTopicSelectFromSearch = (topicName, goalName) => {
    setLoading(true);
    setTopic(true, { goal: goalName, source: 'All' });
    setQuestionsListFromTopicSearch(topicName, goalName);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" px={3}>
      {loading ? (
        <Skeleton height="30px" width={400} />
      ) : (
        <Flex
          mb={{ base: 3, xl: 5, '2xl': 10 }}
          mt={3}
          direction={{ base: 'column', lg: 'row' }}
          gap={2}
        >
          <Flex gap={2} minWidth="25%">
            <GenericTopicSelector
              dataKey={'subject'}
              value={query.subject}
              data={allSubjects}
              onDataChange={onSubjectChange}
            />
            <GenericTopicSelector
              dataKey={'goal'}
              value={query.goal}
              data={goals}
              onDataChange={onGoalChange}
            />
            <GenericTopicSelector
              dataKey={'source'}
              value={query.source}
              data={goalSources}
              onDataChange={onSourceChange}
            />
          </Flex>
          <Flex>
            <CountryDropdown selectedCountry={selectedCountry} onCountryChange={onCountryChange} />
          </Flex>
          <Flex flex={1}>
            <SearchTopics subject={query.subject} onItemClick={onTopicSelectFromSearch} />
          </Flex>
        </Flex>
      )}
      <Flex justifyContent="flex-start" borderBottom="1px" borderColor="gray.300" pb={4}>
        {query.topics.length > 0 && (
          <Heading as="h5" fontSize={{ base: '2xl', xl: 'xl', '2xl': '2xl' }}>
            {query?.goal}
          </Heading>
        )}
      </Flex>

      {query?.topics?.length && !loading ? (
        <Flex py={5} overflowY={'auto'} flexWrap="wrap">
          {query.topics
            .filter((t) => t.count > 0)
            .map((t) => (
              <QuestionBox key={t?.topic} {...t} onTopicSelect={onTopicSelect} />
            ))}
        </Flex>
      ) : (
        <Flex justify="center" alignItems="center" flex="1">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      )}
    </Box>
  );
}

export default memo(TopicList);
