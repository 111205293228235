import React from 'react';
import { Box, Link, Image } from '@chakra-ui/react';
import { useAuth } from '../../auth/useAuth';
import { eventLogger } from '../../../api/eventLogger';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
export default function ShareBox(props) {
  const routerParams = useParams();
  const assignmentId = routerParams.assignmentID;
  const { token } = useAuth();

  const logAndClick = () => {
    eventLogger(token, 'canvas-share-' + props.type + '-clicked', assignmentId);
    props.onClick && props.onClick();
  };

  return (
    <Link
      href={props.linkUrl}
      target={props.isTargetBlank && '_blank'}
      onClick={() => logAndClick()}
    >
      <Box
        alignContent="center"
        boxShadow="base"
        alignItems="center"
        display="flex"
        flexDirection="column"
        width="129px"
        height="110px"
        borderRadius="2px"
      >
        <Box mt="12px">
          <Image boxSize="40px" src={props.imageSrc} alt={props.shareText} />
        </Box>
        <Box
          width="86px"
          height="40px"
          textAlign="center"
          textColor="#757575"
          fontFamily="Helvetica"
          fontSize="15px"
          lineHeight="20px"
          mt="9px"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {props.shareText}
        </Box>
      </Box>
    </Link>
  );
}
