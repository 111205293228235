import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Flex, HStack } from '@chakra-ui/react'
import React from 'react'

export default function SingleQuestionHeader({ onBackFromSingleQuestionHeader }) {
  return (
    <HStack>
        <Flex cursor="pointer" onClick={onBackFromSingleQuestionHeader} mr={1} justifyContent={'center'} rounded borderRadius={30} border='1px' borderColor='gray.600' w={6} h={6}>
            <ChevronLeftIcon w={6} h={6}/>
        </Flex>
        <h3>Back</h3>
    </HStack>
  )
}