import { find, map } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { appConfig } from '../../../../../../constants';

export function adaptMCQToState({ page, pageElementState }) {
  const elemId = uuidV4();
  const mcqElem = {
    type: 'mcq',
    ID: elemId,
    id: elemId
  };

  const value = {
    questions: adaptUnitElement(page.elements),
    options: map(page.options, (opt) => {
      const adaptedElems = adaptUnitElement(opt.elements);
      return {
        ...opt,
        value: adaptedElems,
        elements: undefined // delete elements
      };
    }),
    solution: adaptUnitElement(page.solution.elements)
  };

  // If this is a library question, it will have uneditable html as one of the elements
  const hasUneditableHtml = find(page.elements, (elem) => elem.type === 'uneditableHtml');
  if (hasUneditableHtml) mcqElem.type = 'libraryQuestion';

  mcqElem.value = value;

  pageElementState.elements[elemId] = mcqElem;
  pageElementState.elementOrder.push({ id: elemId });
}

function adaptUnitElement(elements) {
  return map(elements, (elem = {}) => {
    const element = {
      ...elem
    };
    delete element.html;
    element.id = elem._id || uuidV4();
    element.value = elem.html;

    delete element._id;

    return element;
  });
}

export async function fetchAssignmentByID(assignmentID, token) {
  const res = await fetch(appConfig.getAPIURL(`/assignments/${assignmentID}`), {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + `${token}`
    }
  });

  return res;
}

export function adaptFIBToState({ page, pageElementState }) {
  const elemId = uuidV4();
  const fibElem = {
    type: 'fib',
    ID: elemId,
    id: elemId
  };

  const value = {
    paragraph: adaptUnitElement(page.elements)[0],
    solution: adaptUnitElement(page.solution.elements),
    fibMetadata: page.fibMetadata
  };

  fibElem.value = value;

  pageElementState.elements[elemId] = fibElem;
  pageElementState.elementOrder.push({ id: elemId });
}
