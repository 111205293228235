import ReactQuill from 'react-quill';
const Quill = ReactQuill.Quill;

let InlineEmbed = Quill.import('blots/embed');

class BlankBlot extends InlineEmbed {
  static className = 'fib-blank-embed';
  static tagName = 'span';
  static blotName = 'blank-blot';

  static create(data) {
    const domNode = super.create();
    domNode.dataset.blank_id = data.blankID;
    domNode.innerHTML = `Blank`;
    return domNode;
  }

  constructor(node) {
    super(node);
  }

  static value(domNode) {
    return {
      blankID: domNode.dataset.blank_id
    };
  }

  static formats(domNode) {
    const format = {};
    if (domNode.hasAttribute('blank_id')) {
      format['blank_id'] = domNode.dataset.blank_id;
    }

    return format;
  }

  format(name, value) {
    if (name === 'blank_id') {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name, value);
      }
    } else {
      super.format(name, value);
    }
  }

  attach() {
    super.attach();
    this.scroll?.emitter.emit('blot-added', {
      bubbles: true,
      detail: { node: this.domNode, blotDetail: this.value?.() }
    });
  }

  detach() {
    super.detach();
    this.scroll?.emitter.emit('blot-removed', {
      bubbles: true,
      detail: { node: this.domNode, blotDetail: this.value?.() }
    });
  }
}

Quill.register(BlankBlot);

export default BlankBlot;
