import forEach from 'lodash/forEach';
import { appConfig, assignmentPages } from '../../../../../../constants';
import { pick } from 'lodash';
import { adaptUpdateData } from './utils';

export function updateAssignmentData(token) {
  return async function updateAssignmentDataThunk(dispatch, getState) {
    const store = getState();

    const { initialFetching, loading } = store.updateAssignmentState;

    if (initialFetching || loading) return;

    // Dispatch that we're now updating the data in the backend
    dispatch({
      type: 'UPDATING_ASSIGNMENT_STATE',
      payload: { loading: true }
    });

    const updateStartTime = Date.now();

    const assignmentState = store.assignment;
    const elementState = store.elements;

    const assignmentID = assignmentState.assignmentID || assignmentState._id;

    const updateData = pick(assignmentState, [
      'name',
      'dueDate',
      'duration',
      'settings',
      'startButtonText',
      'endButtonText',
      'assignmentType'
    ]);

    updateData.questions = [];

    // Sort the pages in the order of questions according to pageIndex
    const pages = Object.entries(assignmentState.pages);
    pages.sort((a, b) => {
      const pageA = a[1];
      const pageB = b[1];
      return pageA.pageIndex - pageB.pageIndex;
    });

    for (const [key, value] of pages) {
      const questionRef = {
        ...value,
        elements: []
      };

      const elements = elementState[key]?.elements || {};
      const elemOrder = elementState[key]?.elementOrder || [];

      // MCQ questions are saved differently
      if (questionRef.type === assignmentPages.MCQ.displayName) {
        // Modify MCQ to be saved in DB according to how backend expects it
        const mcqElem = elements[elemOrder[0]?.id];

        // In case of an error
        if (!mcqElem) continue;

        // We just attach it as value right now, it will be adapted via adaptUpdateData
        questionRef.value = mcqElem.value;
      } else if (questionRef.type === assignmentPages.FIB.displayName) {
        // Modifications for FIB
        const fibElement = elements[elemOrder[0]?.id];

        // In case of an error
        if (!fibElement) continue;

        // We just attach it as value right now, it will be adapted via adaptUpdateData
        questionRef.value = fibElement.value;
      } else {
        forEach(elemOrder, (order) => {
          const id = order?.id;
          const element = elements[id];
          if (!id || !element) return;

          const elemCopy = Object.assign({}, element);
          questionRef.elements.push(elemCopy);
        });
      }

      switch (value?.type) {
        case assignmentPages.WELCOME.displayName:
          updateData.welcomeScreen = questionRef;
          updateData.startButtonText = questionRef.config.buttonName || updateData.startButtonText;
          delete updateData.welcomeScreen.settings;
          break;
        case assignmentPages.END.displayName:
          updateData.thankYouScreen = questionRef;
          updateData.endButtonText = questionRef.config.buttonName || updateData.endButtonText;
          delete updateData.thankYouScreen.settings;
          break;
        default:
          updateData.questions.push(questionRef);
      }
    }

    adaptUpdateData(updateData);

    const res = await fetch(appConfig.getAPIURL(`/assignments/${assignmentID}`), {
      method: 'PUT',
      body: JSON.stringify(updateData),
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + `${token}`
      }
    });

    if (res.ok) {
      dispatch({
        type: 'UPDATING_ASSIGNMENT_STATE',
        payload: { loading: false, lastBackendUpdate: updateStartTime }
      });

      return true;
    } else {
      let error = await res.text();

      try {
        error = JSON.parse(error);
      } catch (e) {
        // nothing to do
      }

      console.error('Update assignment failure', res.status, error);
      dispatch({
        type: 'UPDATING_ASSIGNMENT_STATE',
        payload: {
          loading: false,
          error: error?.message ? error.message : error
        }
      });

      return false;
    }
  };
}
