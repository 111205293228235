import React, { useCallback, useEffect } from 'react';
import { getAiRequest } from '../../../../../api/assignmentApis';
import { useAuth } from '../../../../auth/useAuth';
import { assignmentPages } from '../../../../../constants';
import { useDispatch } from 'react-redux';
import addAiQuestion from '../../redux/thunks/add-ai-question';
import { useToast } from '@chakra-ui/react';
import useUpdateAssignment from './use-update-assignment';

const AiRequestContext = React.createContext();
const POLL_TIMEOUT = 2000;

const AiRequestProvider = ({ children }) => {
  const { token } = useAuth();
  const timeoutRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [questions, setQuestions] = React.useState([]);
  const [aiRequestId, setAiRequestId] = React.useState(null);
  const [isCreatingAiRequest, setIsCreatingAiRequest] = React.useState(false);
  const updateAssignment = useUpdateAssignment({ forceUpdate: true });
  const dispatch = useDispatch();
  const toast = useToast();

  const addQuestionAndUpdateUi = useCallback(
    ({ questionIndex, questionObj, shouldUpdateActivePage }) => {
      const question = {
        ...questionObj,
        type: assignmentPages.MCQ.displayName
      };
      dispatch(
        addAiQuestion({
          question: { ...question, id: question._id },
          pageIndex: questionIndex,
          shouldUpdateActivePage
        })
      );
    },
    [dispatch]
  );

  const startAiRequestLoading = () => {
    setIsCreatingAiRequest(true);
  };

  // Clear interval if ongoing
  useEffect(() => {
    const refPointer = timeoutRef.current;
    return () => {
      clearTimeout(refPointer);
    };
  }, []);

  const callTimeoutLogic = useCallback(
    async ({ id, index }) => {
      const data = await getAiRequest({ token, aiRequestId: id });
      if (data?.questions?.length > 0) {
        setQuestions((currentQuestions) => {
          // Get a diff of the current vs incoming questions
          const newQuestions = data.questions.filter((curr) => {
            const isAdded = currentQuestions.some((q) => q._id === curr._id);
            return !isAdded;
          });

          if (newQuestions.length > 0) {
            newQuestions.forEach((q, idx) => {
              const toAddIdx =
                index + // Index from where to start adding
                currentQuestions.length + // After the current questions that have already been added
                idx + // After the questions that have been added within this timeout flow
                1; // New question that will be added will be at position + 1 of the above

              addQuestionAndUpdateUi({
                questionIndex: toAddIdx,
                questionObj: q,
                shouldUpdateActivePage: currentQuestions.length === 0 && idx === 0
              });
            });

            return currentQuestions.concat(newQuestions);
          } else {
            return currentQuestions;
          }
        });
      }

      if (data === null || data?.status === 'DONE' || data?.status === 'ERROR') {
        setIsLoading(false);
        clearTimeout(timeoutRef.current); // clearTimeout()
        timeoutRef.current = null;
        toast({
          title:
            data?.status === 'DONE'
              ? `All set. Added ${data?.questions?.length ?? ''} questions`
              : 'Added some questions successfully.',
          status: data?.status === 'DONE' ? 'success' : 'warning',
          duration: 3000,
          isClosable: true
        });
        setQuestions([]);
        updateAssignment();
      } else {
        // Run timeout again
        timeoutRef.current = setTimeout(async () => {
          callTimeoutLogic({ id, index });
        }, POLL_TIMEOUT);
      }
    },
    [addQuestionAndUpdateUi, toast, token, updateAssignment]
  );

  const startAiRequestCall = ({ id, index = 0 }) => {
    setAiRequestId(id);
    setIsCreatingAiRequest(false);
    setIsLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      callTimeoutLogic({ id, index });
    }, POLL_TIMEOUT);
  };

  const contextValue = {
    startAiRequestCall,
    isAiRequestLoading: isLoading,
    questions,
    startAiRequestLoading,
    isCreatingAiRequest,
    aiRequestId
  };

  return <AiRequestContext.Provider value={contextValue}>{children}</AiRequestContext.Provider>;
};

const useAiRequestStatus = () => {
  const context = React.useContext(AiRequestContext);
  if (context === undefined) {
    throw new Error('useAiRequestStatus has to be used inside the AiRequestContext');
  }
  return context;
};

export { useAiRequestStatus, AiRequestProvider };
