import { produce } from 'immer';
// import { CREATE_TYPES } from '../../../../../constants';

const initialState = {
  createType: null,
  subject: ''
};

export const assignmentMetadataReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'ASSIGNMENT_CREATED': {
      draft.createType = payload.createType;
      draft.subject = payload.subject;
      return;
    }
    case 'CLEAR_ASSIGNMENT_STATE':
      return initialState;
    default:
      // Always return draft as the default is initial state otherwise the draft object
      return draft;
  }
});
