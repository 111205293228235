export const leftSidebarMenu = {
    appMenu: [
        { key: 'home', title: 'Home', smTitle: 'Home', link: '/' },
        {
            key: 'assignments',
            title: 'Your Assignments',
            smTitle: 'Assignments',
            link: '/assignments/',
        },
        {
            key: 'students',
            title: 'Your Students',
            smTitle: 'Students',
            link: '/students-home',
        },
    ],
    miscMenu: [
        // { key: 'settings', title: 'Apps and Integrations', smTitle: '' },
        // { key: 'help', title: 'Product tour', smTitle: '' },
        {
            key: 'book-demo-sm',
            title: 'Book a Free Demo',
            smTitle: 'Book Demo',
            link: '/',
        },
    ],
}
