import React, { useMemo } from 'react';
import { PureStaticContentWrap } from './library-mcq-question/utils';
import { PureHDivider, TextHeader, getHtml } from './utils';

const MCQQuestionOptionPreview = ({ questions, options, score, index, renderOptions = true }) => {
  const styles = useMemo(() => {
    const obj = {
      // breakInside: 'avoid',
      // breakBefore: 'auto',
      // breakAfter: 'auto',
      marginBottom: '5px'
    };

    if (index > 1) {
      obj.borderTop = '1px solid rgba(0, 0, 0, .1)';
    }
    return obj;
  }, [index]);

  const questionHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span
        style={{ fontStyle: 'italic', fontWeight: 800, fontSize: '16px', marginBottom: '3px' }}
      >{`Question ${index + 1}`}</span>
      <span
        style={{
          fontStyle: 'italic',
          fontWeight: 800,
          fontSize: '16px',
          marginBottom: '3px',
          paddingRight: '1px'
        }}
      >{`Score: ${score}`}</span>
    </div>
  );

  return (
    <div
      key={index}
      style={{
        paddingBottom: '1em',
        padding: '0 10px 0px 5px',
        boxSizing: 'border-box',
        ...(styles || {})
      }}
    >
      {index > 0 && <PureHDivider />}
      <PureStaticContentWrap type={questionHeader} contentArray={questions} />

      {renderOptions && (
        <>
          <PureHDivider />
          <TextHeader text="Options" />
          <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
            {options?.map((option, idx) => {
              const optId = option.id;
              const value = option.value;
              return (
                <div
                  key={`preview_${option.id}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '50%'
                  }}
                >
                  {/* Prints ascii like a), b) & so on.. */}
                  <p style={{ marginRight: '5px' }}>{`${String.fromCharCode(97 + idx)})`}</p>
                  {value?.map((val) => {
                    return getHtml(val.type, val.value || val.html, `${optId}_${val.id}`);
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default MCQQuestionOptionPreview;
