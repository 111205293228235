/* eslint-disable react/prop-types */
import { DeleteIcon } from '@chakra-ui/icons';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { v4 as uuidV4 } from 'uuid';
import CanvasContext from '../../../core/Providers/canvas-provider';
import GenericElementContainer from '../generic-element-container';

// eslint-disable-next-line react/prop-types
export default function SingleOption({
  option,
  optionIndex,
  onDeleteOption,
  setOptionValues,
  setIsCorrect,
  disabled
}) {
  const { id, isCorrect, value } = option;
  const canvasID = React.useMemo(() => `question-option-${uuidV4()}`, []);

  const setElements = useCallback(
    (newVal) => {
      setOptionValues(id, newVal);
    },
    [id, setOptionValues]
  );

  const setCorrectOption = () => {
    setIsCorrect(id);
  };

  const setOptionElementValue = useCallback(
    (values) => {
      setElements(values);
    },
    [setElements]
  );

  return (
    <CanvasContext.Provider value={{ canvasID }}>
      <Flex
        alignItems={{ base: 'flex-start', lg: 'center' }}
        my={5}
        direction={{ base: 'column', lg: 'row' }}
        width={{ base: '100%' }}
        flex={1}
        wordBreak="break-all"
        position="relative"
      >
        <GenericElementContainer
          name={`Option ${optionIndex + 1}`}
          textPlaceholder={`Option ${optionIndex + 1}`}
          setState={setOptionElementValue}
          key={optionIndex}
          value={value}
        />
        <Flex
          alignItems="center"
          ml={2}
          position="absolute"
          top="-1em"
          right="0"
          boxShadow="base"
          rounded="md"
          bg="white"
          p={1}
        >
          <Checkbox colorScheme="green" size="md" isChecked={isCorrect} onChange={setCorrectOption}>
            <Text fontSize="sm">Is Correct</Text>
          </Checkbox>
          <DeleteIcon
            color={disabled ? '#ddd' : '#1D1D1B'}
            height="14px"
            onClick={() => onDeleteOption(id)}
            marginLeft={2}
            cursor="pointer"
          />
        </Flex>
      </Flex>
    </CanvasContext.Provider>
  );
}
