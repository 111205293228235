import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/home';
import LoginPage from './pages/login';
import StudentsHome from './pages/students-home';
import HandleGoogleRedirect from './pages/handle-google-redirect';
import Assignments from './pages/assignments';
import AssignmentCreate from './pages/create';
import AssignmentResults from './pages/results';
import AssignmentShare from './pages/share';
import AssignmentLayout from '../assignments/assigment-layout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/students-home',
    element: <StudentsHome />
  },
  {
    path: '/handle-google-redirect',
    element: <HandleGoogleRedirect />
  },
  {
    path: '/assignments',
    element: <Assignments />
  },
  {
    path: '/assignments/:assignmentID',
    element: <AssignmentLayout />,
    children: [
      {
        path: '/assignments/:assignmentID/create',
        element: <AssignmentCreate />
      },
      {
        path: '/assignments/:assignmentID/results',
        element: <AssignmentResults />
      },
      {
        path: '/assignments/:assignmentID/share',
        element: <AssignmentShare />
      }
    ]
  }
]);

const AppRoutes = () => {
  return <RouterProvider router={router}></RouterProvider>;
};

export default AppRoutes;
