import React, { useCallback } from 'react';
import { v4 as uuidV4 } from 'uuid';
import SingleOption from './single-option';
import AddOption from './add-option';
import produce from 'immer';
import { Flex } from '@chakra-ui/react';

// eslint-disable-next-line react/prop-types
export default function McqOptions({ options, setOptions }) {
  // Add an option
  const onAddOption = useCallback(() => {
    const option = {
      id: uuidV4(),
      name: 'Option',
      value: [{ id: uuidV4(), type: 'textbox' }],
      isCorrect: false,
      tooltip: 'option hint'
    };
    setOptions((state) => {
      return [...state, option];
    });
  }, [setOptions]);

  // Delete an option
  const onDeleteOption = useCallback(
    (id) => {
      setOptions((state) => {
        if (state.length === 2) return state; // Can't delete if there are only two options
        return state.filter((option) => option.id !== id);
      });
    },
    [setOptions]
  );

  // Set the option's value
  const setOptionValues = useCallback(
    (optionId, values) => {
      setOptions(
        produce((draftState) => {
          const option = draftState.find((opt) => opt.id === optionId);
          if (option) {
            option.value = values;
          }
        })
      );
    },
    [setOptions]
  );

  // Set if the option is correct
  const setIsCorrect = useCallback(
    (optionId) => {
      setOptions(
        produce((draftState) => {
          draftState.forEach((opt) => {
            if (opt.id === optionId) opt.isCorrect = true;
            else opt.isCorrect = false;
          });
        })
      );
    },
    [setOptions]
  );

  const optionsLen = options?.length;

  return (
    <Flex direction="column">
      <Flex direction="column">
        {
          // eslint-disable-next-line react/prop-types
          !!options &&
            options.map((option, optionIndex) => {
              return (
                <SingleOption
                  option={option}
                  key={option.id}
                  optionIndex={optionIndex}
                  onDeleteOption={onDeleteOption}
                  setOptionValues={setOptionValues}
                  setIsCorrect={setIsCorrect}
                  // If there is only one option remaining, disable delete
                  disabled={optionsLen === 2}
                />
              );
            })
        }
      </Flex>
      <Flex>
        <AddOption onAddOption={onAddOption} />
      </Flex>
    </Flex>
  );
}
