import { useCallback } from "react";
import { useAuth } from '../../auth/useAuth';
import { eventLogger } from "../../../api/eventLogger";
import { useParams } from 'react-router-dom';

const useEventLogger = () => {

  const routerParams = useParams();
  const assignmentID = routerParams.assignmentID;
  const { token } = useAuth();

  return useCallback((activity, description) => {
    eventLogger(token, activity, assignmentID, description);
  }, [assignmentID, token])
}
export default useEventLogger;