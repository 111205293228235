/* eslint-disable no-undef */
import React from 'react';

import { useAuth } from '../auth/useAuth';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import { eventLogger } from '../../api/eventLogger';
import { AiOutlineDollar } from 'react-icons/ai';

const Upgrade = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { user, token } = useAuth();
  if (!user?.email) {
    return null;
  }

  const handleUpgradeClick = () => {
    onOpen();
    eventLogger(token, 'upgrade-clicked', null, user.email);
  };

  return (
    <Box mr={{ base: '8px', md: '50px' }}>
      <Button
        gap="2"
        px={2}
        _hover={{ opacity: 0.8 }}
        rounded={0}
        background="#5D38DB"
        fontWeight="400"
        border="1px solid"
        borderColor="#5D38DB"
        color="#FFFFFF"
        onClick={handleUpgradeClick}
      >
        <AiOutlineDollar />
        Go Pro
      </Button>
      <Modal onClose={onClose} size="xl" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent minW={{ base: '95%', md: '75%' }} maxW={{ base: '95%', md: '75%' }}>
          <ModalCloseButton />
          <ModalBody mx={{ base: 0, md: 5 }} px={{ base: 0 }}>
            <stripe-pricing-table
              pricing-table-id={import.meta.env.ASSIGNMENT_FE_STRIPE_PRICING_TABLE}
              publishable-key={import.meta.env.ASSIGNMENT_FE_STRIPE_PUBLISHABLE_KEY}
              customer-email={user.email}
              billing_address_collection="auto"
            ></stripe-pricing-table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Upgrade;
