import { useDisclosure, Flex, Stack, Box } from '@chakra-ui/react';
import React from 'react';
import AppHeader from '../../../app/header/app-header';
import LeftMenuSidebar from './LeftMenuSidebar';
import ClasswiseLoader from '../../loader';

// eslint-disable-next-line react/prop-types
export default function AppCommonLayout({ selectedMenu = '', isLoading = false, children }) {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Stack direction="column" spacing="0px" overflow="hidden" display={'flex'}>
        <Flex>
          <AppHeader onOpen={onToggle} ref={btnRef} isOpen={isOpen} />
        </Flex>
        {!isLoading ? (
          <Flex direction="row" h="100%">
            <Box minWidth={{ base: 0, sm: '135px' }}>
              <LeftMenuSidebar onCloseMenu={onClose} isSmallMenu={isOpen} selected={selectedMenu} />
            </Box>
            <Box flexGrow="1" overflow="auto" height="calc(100vh - 60px)">
              {children}
            </Box>
          </Flex>
        ) : (
          <Box
            display="flex"
            alignSelf="center"
            height="100vh"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <ClasswiseLoader />
          </Box>
        )}
      </Stack>
    </>
  );
}
