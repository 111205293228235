import { v4 as uuidV4 } from 'uuid';
import { assignmentPages } from '../../../../../../constants';

export const createMcqQuestion = (question, pageIndex = 1) => {
  const page = {
    id: question?.id ?? uuidV4(),
    pageIndex: pageIndex,
    type: assignmentPages.MCQ.displayName,
    isDraggable: true,
    isEditable: true,
    score: Number(question.score || 10)
  };

  const elemId = uuidV4();
  const mcqElement = {
    type: 'mcq',
    ID: elemId,
    id: elemId
  };

  const questions = [
    {
      type: 'textbox',
      id: uuidV4(),
      value: `<p>${question.questionText}</p>`
    }
  ];

  const solution = [
    {
      type: 'textbox',
      id: uuidV4(),
      value: `<p>${question.solution}</p>`
    }
  ];

  const options = question.options.map((option, index) => ({
    id: uuidV4(),
    isCorrect: option.isCorrect,
    name: `Option ${index + 1}`,
    value: [
      {
        type: 'textbox',
        id: uuidV4(),
        value: option.optionText
      }
    ]
  }));

  mcqElement.value = {
    questions,
    options,
    solution
  };

  return { page, mcqElement };
};
