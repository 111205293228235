import { post } from './APIClient';
import { browserName, osName } from 'react-device-detect';

export const eventLogger = (token, activity, assignmentId = '', description) => {
  post({
    url: 'event-logger',
    token,
    data: {
      activity,
      assignmentId,
      browserName,
      osName,
      description
    }
  });
};
