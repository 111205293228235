import React, { useCallback, useState } from 'react';
import { first, flatten } from 'lodash';
import { get, uniq } from 'lodash';
import { memo } from 'react';
import LibraryModal from './library-modal';
import { getFilter, getSpecificFilter } from '../utils/defaultFilters';
import { LibraryContext } from './library-context';
import { getQuestionsList, getSolutionByQuestionById, getTopic } from '../utils/fetch';

function QuestionModal({
  countryData,
  selectedCountry,
  isOpen,
  onClose,
  onCountryChange,
  onAddQuestion,
  onAddMultipleQuestion
}) {
  const [filterData , setFilterData] = useState({})
  React.useMemo(() => setFilterData(getFilter(countryData)), [countryData]);
  const goal = React.useMemo(() => first(filterData?.goals), [filterData]);
  const { language, source } = getSpecificFilter(filterData, goal);

  const [query, setQuery] = useState({
    goal,
    language,
    source,
    subject: 'Maths',
    topics: [],
    questions: [],
    selectedTopic: '',
    selectedQuestionId: '',
    selectedSingleQuestion: null,
    selectedMultipleQuestions: []
  });
  const [allSubjects, setAllSubjects] = useState([]);

  React.useEffect(() => {
    const subjectList = uniq(
      flatten(
        Object.values(get(countryData, 'subject'))
          .map((subject) => {
            return Object.values(subject);
          })
          .flat()
      )
    );
    setAllSubjects(subjectList);
  }, [countryData, selectedCountry, filterData]);

  const updateSubject = useCallback(
    (subject = 'Maths') => {
      const filteredGoals = getFilter(countryData).goals.filter((goal) => {
        const validSubjects = flatten(Object.values(getFilter(countryData).subjects[goal]));
        return validSubjects.includes(subject);
      });

      setFilterData((prev) => {
        return {
          ...prev,
          goals: filteredGoals
        };
      });
    },
    [countryData]
  );

  React.useEffect(() => {
    updateSubject('Maths');
  }, [updateSubject]);

  /* Subject Change = Only Maths available for now */
  const onSubjectChange = useCallback(
    async (subject) => {
      updateSubject(subject);
      setQuery((prev) => {
        return {
          ...prev,
          subject
        };
      });
    },
    [updateSubject]
  );

  

  const setTopic = React.useCallback(
    async (allowPrevState = true, appQuery) => {
      let params = {
        goal,
        language,
        source,
        subject: query.subject,
        ...appQuery
      };
      setQuery((prev) => {
        return {
          ...prev,
          topics: [],
          questions: []
        };
      });
      const topics = await getTopic(params);
      setQuery((prevState) => {
        return allowPrevState
          ? { ...prevState, ...appQuery, topics }
          : {
              goal,
              language,
              source,
              subject: query.subject,
              topics,
              questions: [],
              selectedTopic: '',
              selectedQuestionId: '',
              selectedMultipleQuestions: []
            };
      });
    },
    [goal, language, setQuery, source, query.subject]
  );

  const setQuestionsList = React.useCallback(
    async (selectedTopic) => {
      const params = {
        goal: query?.goal,
        language: query?.language,
        subject: query?.subject,
        source: query?.source,
        topic: selectedTopic
      };
      setQuery((prev) => {
        return {
          ...prev,
          questions: []
        };
      });
      const questions = await getQuestionsList(params);
      setQuery((prev) => {
        return {
          ...prev,
          selectedTopic,
          questions
        };
      });
    },
    [query]
  );

  const setQuestionsListFromTopicSearch = React.useCallback(
    async (topicName, goalName) => {
      console.log(topicName, goalName);
      const params = {
        goal: goalName,
        language: query?.language,
        subject: query?.subject,
        source: query?.source,
        topic: topicName
      };
      setQuery((prev) => {
        return {
          ...prev,
          questions: []
        };
      });
      const questions = await getQuestionsList(params);
      setQuery((prev) => {
        return {
          ...prev,
          selectedTopic: topicName,
          questions
        };
      });
    },
    [query]
  );

  const setSingleQuestion = React.useCallback(async (selectedQuestionId = '') => {
    let response = null;

    if (selectedQuestionId) {
      response = await getSolutionByQuestionById(selectedQuestionId);
    }
    setQuery((prev) => {
      return {
        ...prev,
        selectedQuestionId,
        selectedSingleQuestion: null,
        selectedMultipleQuestions: []
      };
    });
    if (response) {
      setQuery((prev) => {
        return {
          ...prev,
          selectedSingleQuestion: response
        };
      });
    }
  }, []);

  const setMultipleQuestion = React.useCallback(async (selectedQuestionIds) => {
    if (selectedQuestionIds.length > 0) {
      const fetchQuestions = selectedQuestionIds
        .reverse()
        .map(async (id) => await getSolutionByQuestionById(id));
      Promise.all(fetchQuestions).then((selectedMultipleQuestions) => {
        setQuery((prev) => {
          return {
            ...prev,
            selectedMultipleQuestions
          };
        });
      });
    } else {
      setQuery((prev) => {
        return {
          ...prev,
          selectedMultipleQuestions: []
        };
      });
    }
  }, []);

  const onQuestionRemove = React.useCallback(
    async (questionId) => {
      if (query.selectedMultipleQuestions.length) {
        const filteredQuestion = query.selectedMultipleQuestions.filter(
          (q) => q._id !== questionId
        );
        setQuery((prev) => {
          return {
            ...prev,
            selectedMultipleQuestions: filteredQuestion
          };
        });
      }
    },
    [query.selectedMultipleQuestions]
  );

  return (
    <LibraryContext.Provider
      value={{
        filterData,
        query,
        setQuery,
        setTopic,
        setQuestionsList,
        setQuestionsListFromTopicSearch,
        setSingleQuestion,
        setMultipleQuestion,
        onQuestionRemove
      }}
    >
      <LibraryModal
        {...{
          isOpen,
          selectedCountry,
          allSubjects,
          onClose,
          onCountryChange,
          onSubjectChange,
          onAddQuestion,
          onAddMultipleQuestion,
          onQuestionRemove
        }}
      />
    </LibraryContext.Provider>
  );
}

export default memo(QuestionModal);
