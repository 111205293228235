import React, { useMemo } from 'react';
import { assignmentPages, QUESTION_RESPONSE_STATUS } from '../../../constants';
import { Box, Text, Flex, RadioGroup, Radio, Divider, Stack } from '@chakra-ui/react';
import { HTMLRenderer } from './HTMLRenderer';
import { getFIBQuestionStats } from './utils';

export const QuestionStatus = ({ studentResponse, question }) => {
  let bgColor = '#000000';
  let text = '';

  const questionStats = useMemo(() => {
    if (question.type === assignmentPages.FIB.key) {
      return getFIBQuestionStats(question, studentResponse);
    } else {
      const correctAnswer = question.options.find((o) => o.isCorrect)?.id;
      return {
        isCorrect: correctAnswer == studentResponse?.answer[0]
      };
    }
  }, [question, studentResponse]);

  const status = studentResponse?.status;

  if (status == QUESTION_RESPONSE_STATUS.ANSWERED) {
    if (questionStats.isCorrect) {
      text = 'Correct';
      bgColor = '#BBE6CE';
    } else if (questionStats.isPartial) {
      text = 'Partially Correct';
      bgColor = '#F3BB51';
    } else {
      text = 'Incorrect';
      bgColor = '#F9C0C0';
    }
  } else if (status == QUESTION_RESPONSE_STATUS.SKIPPED) {
    text = 'Skipped';
    bgColor = '#0000001F';
  } else {
    text = 'Not Visited';
    bgColor = '#0000001F';
  }

  return (
    <Box borderRadius="5px" bgColor={bgColor} px="13px" py="9px" fontSize="12px" fontWeight="800">
      <Text>{text}</Text>
    </Box>
  );
};

export const AnswerStatus = ({ option, isCorrect, answer }) => {
  let bgColor = '#000000';
  let text = '';

  if (option == answer) {
    text = 'Selected Answer';
    if (isCorrect) {
      bgColor = '#BBE6CE';
    } else {
      bgColor = '#F9C0C0';
    }
  } else if (isCorrect) {
    bgColor = '#BBE6CE';
    text = 'Correct Answer';
  } else {
    return <></>;
  }

  return (
    <Box alignSelf="flex-end" borderRadius="19px" bgColor={bgColor} px="13px" py="9px" fontSize="12px" fontWeight="800">
      <Text>{text}</Text>
    </Box>
  );
};

export const Options = ({ options, studentResponse, byQuestion = false }) => {
  return (
    <Flex w="100%" flexDirection={'column'}>
      <Divider />
      <RadioGroup defaultValue={studentResponse?.answer[0]}>
        <Stack direction="column">
          {!byQuestion && (
            <Text mt={3} mb={1}>
              Select all that apply:
            </Text>
          )}
          <Divider />
          {options.map(({ id, elements, isCorrect }) => {
            if (byQuestion && studentResponse?.answer[0] != id && !isCorrect) {
              return <></>;
            }
            return (
              <Flex flexDirection="column" key={id}>
                <Flex flexDirection="row">
                  <Radio colorScheme="purple" value={id} flex="1" p={2}>
                    {elements.length ? elements.map((e) => <HTMLRenderer element={e} key={e.id} />) : <></>}
                  </Radio>
                  <Box justifySelf="flex-end">
                    <AnswerStatus option={id} isCorrect={isCorrect} answer={studentResponse?.answer[0]} />
                  </Box>
                </Flex>
                <Divider />
              </Flex>
            );
          })}
        </Stack>
      </RadioGroup>
    </Flex>
  );
};
