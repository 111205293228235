import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getBasicAssignmentData, getElements, getPages } from '../redux/selectors';
import { useSelector } from 'react-redux';
import { getPDFDownloadProps, renderToPDF } from './utils';
import PDFComponent from './pdf-component';

const DownloadOptionsModal = ({ isOpen, onClose }) => {
  const pages = useSelector(getPages);
  const elements = useSelector(getElements);
  const assignmentData = useSelector(getBasicAssignmentData);

  const [downloadOptions, setDownloadOptions] = useState(true);
  const [downloadSolutions, setDownloadSolutions] = useState(true);
  const [inputFile, setInputFile] = useState({ file: null, src: '' });
  const [fileError, setFileError] = useState('');
  const logoImageRef = useRef(null);

  const pdfProps = useMemo(() => {
    return getPDFDownloadProps(assignmentData, pages, elements);
  }, [assignmentData, elements, pages]);

  const startDownload = () => {
    renderToPDF(pdfProps, {
      options: downloadOptions,
      solutions: downloadSolutions,
      customImageSrc: inputFile.src
    });
  };

  const onFileUpload = (evt) => {
    const file = evt?.target?.files[0];
    if (!file) return;
    if (file.size > 5 * 1024 * 1024) {
      setFileError('File size greater than 5mb');
      return;
    }

    setFileError('');
    setInputFile({ file: evt?.target?.files[0] });
  };

  useEffect(() => {
    if (inputFile.file) {
      const fr = new FileReader();
      fr.onload = (evt) => {
        const src = evt.target.result;
        logoImageRef.current.src = src;
        setInputFile((state) => ({ ...state, src }));
      };
      fr.readAsDataURL(inputFile.file);
    }
  }, [inputFile.file]);

  const clearFileInput = () => {
    setInputFile({ file: null, src: '' });
    logoImageRef.current.src = 'https://placehold.co/1x1/white/white';
    setFileError('');
  };

  const getFileInputHelperText = () => {
    if (fileError) {
      return fileError;
    } else if (inputFile.file) {
      return inputFile?.file?.name;
    } else {
      return `Upload file less than 5mb`;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', lg: '6xl' }}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {/* isOpen conditional render to prevent this from continuously rendering */}
        <ModalBody py="0" px={{ base: 0, lg: 'unset' }}>
          <Flex flexDirection={{ base: 'column', lg: 'row' }}>
            <Flex maxW={{ base: 'unset', lg: '60%' }} flexDirection="column">
              <Flex
                backgroundColor="#ddd"
                overflow="auto"
                maxH={{ base: '55vh', lg: '80vh' }}
                px={{ base: 4, lg: 6 }}
                py={{ base: 4, lg: 8 }}
              >
                <Flex
                  backgroundColor="white"
                  boxShadow="lg"
                  px={1}
                  borderRadius={4}
                  className="pdf-preview-container"
                >
                  {isOpen && (
                    <PDFComponent
                      {...pdfProps}
                      options={{
                        options: downloadOptions,
                        solutions: downloadSolutions,
                        customImageSrc: inputFile.src
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              pl={{ base: 2, lg: 8 }}
              pt={{ base: 4, lg: 8 }}
              gap={4}
              flex="1"
              px={{ base: 4 }}
              pb={{ base: 8 }}
            >
              <Heading as="h2" size="md">
                Customise your PDF
              </Heading>
              <Checkbox
                colorScheme="purple"
                defaultChecked
                isChecked={downloadOptions}
                onChange={(evt) => {
                  setDownloadOptions(evt.target.checked);
                }}
              >
                Include options in PDF
              </Checkbox>
              <Checkbox
                colorScheme="purple"
                defaultChecked
                isChecked={downloadSolutions}
                onChange={(evt) => {
                  setDownloadSolutions(evt.target.checked);
                }}
              >
                Include solutions in PDF
              </Checkbox>
              <FormControl>
                <FormLabel cursor="pointer">
                  Add Logo (optional)
                  <Input
                    placeholder="Select logo"
                    size="sm"
                    type="file"
                    accept="image/*"
                    display="none"
                    onChange={onFileUpload}
                    onClick={(evt) => {
                      evt.target.value = null;
                    }}
                  />
                  <Flex>
                    <Image
                      ref={logoImageRef}
                      height="12"
                      width="70%"
                      border="1px solid #eee"
                      display={inputFile.src ? 'unset' : 'none'}
                    ></Image>
                    {!inputFile.src && (
                      <Flex
                        height="12"
                        width="70%"
                        border="1px dashed #aaa"
                        background="#eee"
                        color="#777"
                        alignItems="center"
                        justifyContent="center"
                        mt={1}
                      >
                        Upload your logo
                      </Flex>
                    )}
                  </Flex>
                </FormLabel>
                <Flex justifyContent="space-between">
                  <FormHelperText
                    color={fileError ? 'red' : 'unset'}
                    textOverflow="ellipsis"
                    wordBreak="break-all"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    maxW={{ base: '70%', lg: 'unset' }}
                    mt={0}
                  >
                    {getFileInputHelperText()}
                  </FormHelperText>
                  {inputFile.file && (
                    <FormHelperText cursor="pointer" onClick={clearFileInput} mt={0}>
                      Clear File
                    </FormHelperText>
                  )}
                  {/* <FormHelperText></FormHelperText> */}
                </Flex>
              </FormControl>
              <Button
                w={{ base: '100%', lg: 'max(70%, 300px)' }}
                borderRadius="none"
                fontSize={{ base: '12px', lg: '14px' }}
                variant="solid"
                borderColor="#5D38DB"
                background="#5D38DB"
                color="white"
                onClick={startDownload}
                alignSelf={{ base: 'center', lg: 'unset' }}
              >
                Download
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DownloadOptionsModal;
