import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIdToken, useSignOut } from 'react-firebase-hooks/auth';

import { firebaseAuth } from './firebase';
import { SUBSCRIPTION_PLANS } from '../../api/constants';

const DEFAULT_EMPTY = {};

export const AuthContext = React.createContext(DEFAULT_EMPTY);

export const AuthProvider = ({ children }) => {
  const [userSubscriptionPlan, setUserSubscriptionPlan] = useState(SUBSCRIPTION_PLANS.FREE);
  const [user, isLoginLoading, error] = useIdToken(firebaseAuth, {
    onUserChanged: (user) => {
      if (user?.reloadUserInfo?.customAttributes !== undefined) {
        const customClaims = JSON.parse(user.reloadUserInfo.customAttributes);
        if (
          customClaims?.subscriptionPlan &&
          customClaims.subscriptionPlan !== userSubscriptionPlan
        ) {
          console.log('changed subscription plan: ', customClaims.subscriptionPlan);
          setUserSubscriptionPlan(customClaims.subscriptionPlan);
        }
      }
    }
  });
  const [signOut, isLogoutLoading] = useSignOut(firebaseAuth);

  const timeoutHandle = useRef(null);
  const refreshTokenCallbackRef = useRef(() => {});

  useEffect(() => {
    firebaseAuth?.onIdTokenChanged(async (resUser) => {
      console.info(`token changed!`);
      if (!resUser) {
        console.info(`no token found`);
        return;
      }

      const token = await resUser.getIdToken();
      console.info(`updating token: `, token);
    });
  }, []);

  const refreshTokenTimer = useCallback(() => {
    clearTimeout(timeoutHandle.current);
    timeoutHandle.current = setTimeout(
      async () => {
        console.info(`refreshing token`);
        const user = firebaseAuth?.currentUser;
        if (user) await user.getIdToken(true);

        // Call this callback again via its ref
        refreshTokenCallbackRef.current();
      },
      20 * 60 * 1000
    );
  }, []);

  refreshTokenCallbackRef.current = refreshTokenTimer;

  // force refresh the token every 20 minutes
  useEffect(() => {
    refreshTokenTimer();
  }, [refreshTokenTimer]);

  const refreshTheToken = useCallback(async () => {
    await firebaseAuth?.currentUser?.getIdToken(/* forceRefresh */ true);
  }, []);

  //
  // NOTE: need to memoize the user?.accessToken explicitly because it seems that accessToken is being treated as a 'reference' value
  // When a new user is created, there is a new accessToken after we set the role in firebase
  // In this scenario, ONLY the token is updated and it seems that the value object is NOT recalculated because it doesn't think user object has been updated
  //
  const value = useMemo(() => {
    return {
      user,
      signOut,
      token: user?.accessToken, // Accessing a property from user
      error,
      firebaseAuth,
      refreshTheToken,
      userSubscriptionPlan,
      loading: isLoginLoading || isLogoutLoading
    };
  }, [
    error,
    isLoginLoading,
    isLogoutLoading,
    signOut,
    user,
    user?.accessToken, // Include user?.accessToken directly in the dependency array
    refreshTheToken,
    userSubscriptionPlan
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth can only be used inside AuthContext');
  }
  return context;
};
