import { requestOptions } from "./constants";
import { CLASSWISE_API, getClasswiseAPIURL } from "./constants";

export async function getClasswiseAssignments(accessToken, noOfRecs, currentPage) {
    try {
        let apiURL = getClasswiseAPIURL(CLASSWISE_API.ASSIGNMENTS);
        if(noOfRecs !== undefined && currentPage !== undefined) {
            apiURL += `?noOfRecs=${noOfRecs}&currentPage=${currentPage}`;
        }

        let assignments = [];
        const result = await fetch(
            apiURL,
            requestOptions(accessToken)
        );
        const data = await result.json();
        data?.map((assignment) => {
          const dueDate = assignment.dueDate && new Date(assignment.dueDate);
          assignments.push({
            title: assignment.name,
            id: assignment._id,
            dueDate: dueDate
              ? {
                  month: dueDate.getMonth() + 1,
                  day: dueDate.getDate(),
                  year: dueDate.getFullYear()
                }
              : null,
            state: assignment.status,
            source: 'Classwise',
            questionCount: assignment.questions?.length,
            assignmentType: assignment.assignmentType
          });
        });

        return assignments;
    } catch (err) {
        console.log(err);
        return [];
    }
}


export async function getClasswiseSubmissions(accessToken, assignmentID, shouldFetchResults) {
    try {
        let apiURL, options;
        if (assignmentID) {
            apiURL = CLASSWISE_API.STUDENT_ASSIGNMENTS_BY_ASSIGNMENT;
            
            options = requestOptions(accessToken, 'POST');
            options['body'] = JSON.stringify({
                assignmentID
            });
        } else {
            apiURL = CLASSWISE_API.STUDENT_ASSIGNMENTS_BY_TEACHER;
            options = requestOptions(accessToken);
        }

        const response = await fetch(
            getClasswiseAPIURL(apiURL),
            options
        );
        let data = await response.json();
        
        if(shouldFetchResults) {
            data = await getClasswiseStudentResults(data, accessToken);
        }

        return data;
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const updateClasswiseAssignments = async(assignments, submissions) => {
    let newAssignments = assignments;
    for (let idx in assignments) {
        newAssignments[idx]['yet_to_start'] = 0;
        newAssignments[idx]['started'] = 0;
        newAssignments[idx]['submitted'] = 0;
        for (let sub of submissions) {
            if(assignments[idx].id == sub.assignment?._id) {
                newAssignments[idx]['started']++;
            }
        }
    }
    return newAssignments;
}

export const getClasswiseStudents = async(classwiseSubmissions, token) => {
    if (
        !classwiseSubmissions || 
        classwiseSubmissions?.statusCode == 500 || 
        classwiseSubmissions?.message == 'Invalid or expired token'
    ) {
        return [];
    }

    const userIds = [...new Set(classwiseSubmissions?.map((user) => {
        return user.student;
    }))];

    return await getClasswiseStudentInfo(userIds, token);
}

export const getClasswiseStudentInfo = async(userIds, token) => {
    try {
        const options = requestOptions(token, 'POST');
        options['body'] = JSON.stringify({
            'userIds': userIds, 
        });
        const res =  await fetch(
            getClasswiseAPIURL(CLASSWISE_API.GET_USERS),
            options
        );
        if(res.ok) {
            const data = await res.json();
            return data.users;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const getClasswiseStudentResults = async(submissions, token) => {
    try {
        await Promise.all(
          submissions.map(async (sub) => {
            if (sub.status == 'COMPLETED') {
              const res = await fetch(
                getClasswiseAPIURL(`/student-assignments/${sub._id}/result`),
                requestOptions(token)
              );
              if (res.ok) {
                const data = await res.json();
                sub['result'] = {
                  ...data
                };
              }
            }
          })
        );
        return submissions;
    } catch (err) {
        console.log(err);
        return submissions;
    }
}

export const mergeStudents = async(classwiseStudents, classwiseSubmissions, googleStudents, googleAssignments, googleSubmissions) => {
    try {
        let mergedStudents = [];
        let visitedStudents = [];

        for (let student of classwiseStudents) {
            if (visitedStudents.includes(student.email)) {
                next;
            }
            visitedStudents.push(student.email);
            let s = {
                id: student.uid,
                name: student.displayName,
                email: student.email,
                photoUrl: student.photoURL,
                submissions: []
            }

            classwiseSubmissions?.filter((sub) => sub.student  == student.uid)?.map((sub) => {
                const totalAssignmentScore =
                    sub?.assignment?.questions.reduce((sum, q) => {
                        return sum + q.score
                    }, 0) || 0;

                s = {
                    ...s,
                    submissions: [...s.submissions, {
                        id: sub._id,
                        assignmentName: sub.assignment.name,
                        assignmentId: sub.assignment._id,
                        status: sub.status,
                        assignmentScore: totalAssignmentScore,
                        score: sub?.result?.totalScore, 
                        source: 'Classwise',
                        updateTime: sub.updatedAt,
                        submissionUrl: `/assignments/${sub.assignment._id}/results?referrerUrl=/students-home/`
                    }]
                }
            })

            mergedStudents.push(s);
        }

        if (googleStudents) {
            for (let student of googleStudents) {
                const idx = mergedStudents.findIndex(m => m.email == student.profile.emailAddress);
                let s;

                if (idx == -1) {
                    s = {
                        id: student.userId,
                        name: student.profile.name.fullName,
                        email: student.profile.emailAddress,
                        photoUrl: student.profile.photoUrl,
                        submissions: []
                    }
                } else {
                    s = mergedStudents[idx];
                }

                if (googleSubmissions) {
                    for (let gs of googleSubmissions) {
                        const sub = gs.values.find((s) => s.userId == student.userId);
                        if (!sub) {
                            continue;
                        }
                        const assignment = googleAssignments.find(a => a.id == sub.courseWorkId);
                        s = {
                            ...s,
                            class: assignment.courseName,
                            submissions: [...s.submissions, {
                                id: sub.id,
                                assignmentName: assignment.title,
                                assignmentId: assignment.id,
                                status: sub.state,
                                assignmentScore: assignment.maxPoints,
                                score: sub.assignedGrade ?? sub.draftGrade,
                                source: 'Google',
                                submissionUrl: sub.alternateLink,
                                updateTime: sub.updateTime
                            }]
                        }
                    }
                }

                if (idx == -1) {
                    mergedStudents.push(s);
                } else {
                    mergedStudents[idx] = s;
                }
            }
        }

        return mergedStudents;
    } catch (err) {
        console.log(err);
        return [];
    }
}
