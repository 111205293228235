import React from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { useAuth } from '../auth/useAuth';
import LabelValue from './LabelValue';
import { getCurrentSubscription } from '../../api/userApis';
import { useNavigate, useSearchParams } from 'react-router-dom';

const UpgradeSuccessModal = () => {
  const [searchParams] = useSearchParams();
  const upgradeStatus = searchParams.get('upgradeStatus');
  const checkoutId = searchParams.get('checkoutId');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [subscription, setSubscription] = React.useState(undefined);
  const navigate = useNavigate();
  const {
    user: { displayName, email },
    token
  } = useAuth();

  React.useEffect(() => {
    if (upgradeStatus === 'success') {
      onOpen();
      getCurrentSubscription({ token, checkoutId })
        .then((response) => {
          // setSubscription(response);
          setTimeout(() => setSubscription(response), 2000);
        })
        .catch(() => {
          // Error
          setSubscription(null);
        });
    }
  }, [upgradeStatus, onOpen, token, checkoutId]);

  const handleClose = () => {
    onClose();
    navigate('/');
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: '95%', md: '75%' }}
        maxW={{ base: '95%', md: '75%' }}
        minH={{ base: '90%', md: '75%' }}
        maxH={{ base: '90%', md: '75%' }}
        borderRadius="8px"
      >
        <ModalHeader
          bg="#5D38DB"
          color="white"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
        >
          <Box>
            <Image src="/assets/images/classwise-white-logo.svg" />
            <ModalCloseButton />
          </Box>
          <Center>
            <Text fontSize={{ base: '16px', md: '20px' }}>
              {`Thank you, ${displayName.split(' ')[0]}. Your order was placed successfully`}
            </Text>
          </Center>
        </ModalHeader>
        <ModalBody
          p={{ base: '16px', md: '32px' }}
          bg="#E8EBF2"
          overflowY="scroll"
          borderRadius="8px"
        >
          <Grid templateColumns="repeat(12, 1fr)" gap={8}>
            <GridItem colSpan={{ base: 12, md: 7, lg: 7 }} w="100%">
              <Card>
                <CardHeader>
                  <Heading size={{ base: 'sm', md: 'md' }} color="#686868">
                    Get Started with Classwise Pro
                  </Heading>
                </CardHeader>
                <CardBody fontSize={{ base: '14px', md: '15px' }}>
                  <Box>
                    Congratulations on successfully completing your payment for Classwise! 🎉
                  </Box>
                  <Box mt="2">
                    You&apos;re now equipped with a powerful tool that&apos;s set to transform your
                    teaching experience.
                  </Box>
                  <Button
                    borderRadius="20px"
                    px="48px"
                    mt="16px"
                    bg="#5D38DB"
                    color="white"
                    onClick={handleClose}
                  >
                    Get Started
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={{ base: 12, md: 5, lg: 5 }} pt="4px">
              <Heading size="sm">Plan Details</Heading>
              <Flex mt="2" alignItems="center">
                <Image src="/assets/images/classwise-logo-rectangle.png" />
                <Text as="b" ml="2" fontSize="12">
                  Classwise Pro
                </Text>
              </Flex>
              {!subscription ? (
                <>
                  <Skeleton height="40px" mt={3} />
                  <Skeleton height="40px" mt={3} />
                  <Skeleton height="40px" mt={3} />
                  <Skeleton height="40px" mt={3} />
                </>
              ) : (
                <>
                  <LabelValue label="Confirmation Email Sent to:" value={email} />
                  <LabelValue label="Order Number:" value={subscription?._id} />
                  <LabelValue
                    label="You are being charged:"
                    value={`${subscription?.currency.toUpperCase()} ${subscription?.amount / 100}/${
                      subscription?.commitmentLevel
                    }`}
                  />
                  <Box mt="8px">
                    <Text as="b" fontSize="12px">
                      Questions?
                    </Text>
                    <Box>
                      <Link href="https://getclasswise.com/contact_us" isExternal fontSize="12px">
                        Contact Us
                      </Link>
                    </Box>
                  </Box>
                </>
              )}
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpgradeSuccessModal;
