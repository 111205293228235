import { first, get } from "lodash";

export const getFilter = (countryData) => {
    const goals = get(countryData, 'classes');
    const languages = (get(countryData, 'language'));
    const sources =  get(countryData, 'source');
    const subjects =  get(countryData, 'subject');
    return {
        goals,
        languages,
        sources,
        subjects
    }
}

export const getSpecificFilter = ( filterData, goal, isFilteredSource = false ) => {
    const language =  first(get(get(filterData, 'languages'), goal));
    const source = isFilteredSource ? get(get(get(filterData, 'sources'), goal), language) : 'All';
    return { goal, language, source }
}