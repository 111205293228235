import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { MdCheckBoxOutlineBlank } from 'react-icons/md'

const ResizeContainer = ({
    canvasRef,
    children,
    resizeMode = false,
    onMouseUp,
    onMouseMove,
    maxX = Infinity,
    maxY = Infinity,
}) => {
    // const canvasRef = useRef()

    const resizeStyle = useMemo(() => {
        return {
            border: '2px dashed black',
            display: 'inline-flex',
            position: 'relative',
        }
    }, [])

    const defaultStyles = useMemo(() => {
        return {
            display: 'inline-flex',
        }
    }, [])

    const mouseDown = (e) => {
        e.preventDefault()
        document.addEventListener('mousemove', mouseMove)
        document.addEventListener('mouseup', mouseUp)
    }

    const mouseUp = () => {
        document.removeEventListener('mousemove', mouseMove)
        document.removeEventListener('mouseup', mouseUp)

        onMouseUp()
    }

    const mouseMove = (e) => {
        if (e.buttons === 1) {
            e.preventDefault()

            const mx = e.movementX
            const my = e.movementY

            const newW = canvasRef.current.width + mx
            const newH = canvasRef.current.height + my
            canvasRef.current.width = newW > maxX ? maxX : newW
            canvasRef.current.height = newH > maxY ? maxY : newH

            onMouseMove()
        }
    }

    return (
        <Box style={resizeMode ? resizeStyle : defaultStyles}>
            {children}
            {resizeMode && (
                <MdCheckBoxOutlineBlank
                    onMouseDown={mouseDown}
                    style={{
                        position: 'absolute',
                        right: '-8px',
                        bottom: '-8px',
                        cursor: 'nwse-resize',
                    }}
                />
            )}
        </Box>
    )
}

export default ResizeContainer
