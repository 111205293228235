import {
  Box,
  Button,
  Flex,
  Input,
  useMediaQuery,
  Spinner,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  DrawerCloseButton
} from '@chakra-ui/react';
import {
  AiOutlineCloudUpload,
  AiFillCheckCircle,
  AiOutlineSetting,
  AiOutlineQuestionCircle
} from 'react-icons/ai';
import { TiArrowBack } from 'react-icons/ti';
import { FiShare } from 'react-icons/fi';
import React, { useCallback, useEffect, useState } from 'react';
import Steps from './Steps';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssignmentUpdateState,
  getBasicAssignmentData,
  isAssignmentFetched
} from '../canvas/redux/selectors';
import { appConfig } from '../../../constants';
import useAutoAssignmentUpdate from './use-auto-assignment-update';
import useUpdateAssignment from '../canvas/shared/hooks/use-update-assignment';
import { getAssignment } from '../canvas/redux/thunks/get-assignment';
import { useAuth } from '../../auth/useAuth';
import AssignmentSetting from '../canvas/shared/assignmentSetting';
import { isMobile } from 'react-device-detect';
import { GoDot } from 'react-icons/go';
import useCommonDispatchFunctions from '../common/joyride-onboarding-tour/use-common-dispatch-fns';
import useEventLogger from '../common/use-event-logger';
import ShareModalContent from '../common/share-modal-content';
import TotalScore from './total-score';
import { useNavigate, useParams } from 'react-router-dom';

export default function CanvasHeader() {
  const routerParams = useParams();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [referrerUrl, setReferrerUrl] = useState('');

  const isFetching = useSelector(isAssignmentFetched);

  const [isTablet] = useMediaQuery('(max-width: 768px)');

  const { name: assignmentName } = useSelector(getBasicAssignmentData);
  const { needsUpdate } = useSelector(getAssignmentUpdateState);
  const dispatch = useDispatch();
  const updateAssignment = useUpdateAssignment();

  const {
    isOpen: isShareDrawerOpen,
    onOpen: openShareDrawer,
    onClose: closeShareDrawer
  } = useDisclosure();

  const { startTour, isOnboardingReminderOpen } = useCommonDispatchFunctions();

  const eventLoggerHook = useEventLogger();

  const assignmentID = routerParams.assignmentID;
  useEffect(() => {
    if (!token || !assignmentID) return;

    dispatch(getAssignment(assignmentID, token));
    dispatch({
      type: 'SET_ASSIGNMENT_ID',
      payload: { assignmentID }
    });
  }, [assignmentID, dispatch, token]);

  useEffect(() => {
    const docUrl = new URL(document.location);
    setReferrerUrl(docUrl.searchParams.get('referrerUrl'));
    docUrl.searchParams.delete('referrerUrl');

    console.warn('INFINITE LOOP > REMOVE ROUTER AS DEPENDENCY FROM HERE');

    // navigate(docUrl?.toString(), undefined, {
    //   shallow: true,
    //   scroll: false
    // });
    // WARNING - DO NOT ADD ROUTER AS A DEP HERE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAssignmentName = useCallback(
    (event) => {
      dispatch({
        type: 'UPDATE_ASSIGNMENT_NAME',
        payload: event.target.value
      });
    },
    [dispatch]
  );

  const { loading: assignmentUpdating, error } = useSelector(getAssignmentUpdateState);

  useAutoAssignmentUpdate();

  const goHome = useCallback(() => {
    eventLoggerHook('canvas-exit-clicked');
    updateAssignment();

    // Clears the canvas state when the user chooses to travel back to home or assignments pages
    dispatch({
      type: 'CLEAR_ASSIGNMENT_STATE'
    });

    navigate(referrerUrl || appConfig.landingPage);
  }, [eventLoggerHook, updateAssignment, dispatch, navigate, referrerUrl]);

  const startAssignmentTour = () => {
    if (isFetching) {
      return;
    }

    startTour();

    // User started the tour by clicking the ? icon on the header
    eventLoggerHook(
      'onboarding-tour-user-start-manual',
      'User started the onboarding tour manually'
    );
  };

  const onAssignmentSettingOpen = useCallback(() => {
    dispatch({
      type: 'UPDATE_ASSIGNMENT_MODAL_STATUS',
      payload: { showAssignmentSetting: true }
    });
  }, [dispatch]);

  const openShareDrawerAndTrack = () => {
    if (isMobile) {
      openShareDrawer();
    }

    eventLoggerHook('canvas-share-clicked');

    // Save the assignment if it needs updating
    updateAssignment();
  };

  return (
    <Box style={{ ...(isMobile ? { position: 'sticky', top: '0', zIndex: 2 } : {}) }}>
      <Flex
        borderBottom="1px"
        backgroundColor="#ffffff"
        borderColor="#F2F2F2"
        width="100%"
        // pt={2}
        flexDirection={{ base: 'column', sm: 'row' }}
      >
        <Flex ml={{ base: 0, sm: 4 }} align="center" width={{ base: '100%', md: 'auto', xl: '33%' }} pt={2} pb={2}>
          <Button onClick={goHome} mr={2} variant="ghost" borderRight="1px" borderColor="gray.200" borderRadius={0}>
            <TiArrowBack cursor="pointer" size={20} />
          </Button>
          {!isMobile && (
            <Input
              value={assignmentName}
              width={{ md: 'auto' }}
              fontSize={{ base: 14, md: 14, lg: 16 }}
              onChange={updateAssignmentName}
            />
          )}
          <Box px={3}>
            <Tooltip hasArrow label={'Assignment Settings'} placement="bottom">
              <span id="assignment-settings-container">
                <AiOutlineSetting size={isTablet ? 20 : 25} onClick={onAssignmentSettingOpen} cursor="pointer" />
              </span>
            </Tooltip>
          </Box>
          <Box mr={3}>
            <Tooltip
              hasArrow
              label={'You can restart the onboarding tour by clicking here'}
              placement="bottom"
              isOpen={isOnboardingReminderOpen.tooltip}
              zIndex={1}
            >
              <span id="assignment-tour-cta" style={{ position: 'relative' }}>
                <AiOutlineQuestionCircle
                  size={isTablet ? 20 : 25}
                  onClick={startAssignmentTour}
                  cursor="pointer"
                  style={{
                    opacity: isFetching ? 0.3 : 1,
                    cursor: isFetching ? 'not-allowed' : 'pointer'
                  }}
                />
                {isOnboardingReminderOpen.notification && (
                  <GoDot
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-7px',
                      color: 'red'
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            {assignmentUpdating ? (
              <Flex color="gray.400" alignItems="center" justifyContent="center">
                Saving... <Spinner ml={2} size="sm" />
              </Flex>
            ) : (
              <Tooltip
                hasArrow
                label={error ? `Error updating assignment: ${error}` : needsUpdate ? '' : 'Assignment saved'}
                bg={needsUpdate ? 'red.600' : 'green'}
                placement="bottom"
              >
                <span>
                  {needsUpdate ? (
                    <AiOutlineCloudUpload
                      color={error ? 'red' : 'black'}
                      onClick={updateAssignment}
                      size={isTablet ? 20 : 25}
                      cursor="pointer"
                    />
                  ) : (
                    <AiFillCheckCircle color={'green'} size={isTablet ? 20 : 25} />
                  )}
                </span>
              </Tooltip>
            )}
          </Box>
          {isMobile && (
            <Box marginLeft="auto" mr={2}>
              <Button
                size="sm"
                bg="#5D38DB"
                color="white"
                leftIcon={<FiShare />}
                onClick={openShareDrawerAndTrack}
                borderRadius={4}
                className="share-modal-trigger"
              >
                Share with students
              </Button>
              <Drawer placement="bottom" isOpen={isShareDrawerOpen} onClose={closeShareDrawer}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerHeader borderBottomWidth="1px">
                    <Heading as="h4" size="md">
                      Share this assignment as
                    </Heading>
                  </DrawerHeader>
                  <DrawerCloseButton mt="4px" />
                  <DrawerBody>
                    <ShareModalContent />
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </Box>
          )}
        </Flex>
        <Flex
          justifyContent={{ base: 'center' }}
          borderTop={{ base: '1px solid #eee', sm: 'none' }}
          minWidth={{ base: 'unset', lg: '30vw' }}
        >
          <Flex flex={1} justifyContent={{ base: 'center' }} pl={{ base: '20vw', lg: 0 }}>
            <Steps />
          </Flex>
          {isMobile && (
            <Flex justifySelf="flex-end">
              <TotalScore />
            </Flex>
          )}
        </Flex>
        {!isMobile && (
          <Flex flex={1} justifyContent="end" mr={4} alignItems="center">
            <TotalScore />
            <Popover closeOnBlur={false}>
              <PopoverTrigger>
                <Button
                  size="sm"
                  bg="#5D38DB"
                  color="white"
                  leftIcon={<FiShare />}
                  borderRadius={4}
                  className="share-modal-trigger"
                  onClick={openShareDrawerAndTrack}
                >
                  Share with students
                </Button>
              </PopoverTrigger>
              <PopoverContent minW={{ base: '100%', lg: 'min(31vw, 440px)' }} className="share-modal-popover">
                <PopoverArrow />
                <PopoverCloseButton mt="4px" />
                <PopoverHeader>
                  <Heading as="h4" size="md">
                    Share this assignment as
                  </Heading>
                </PopoverHeader>
                <PopoverBody>
                  <ShareModalContent pl={2} />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        )}
      </Flex>
      <AssignmentSetting />
    </Box>
  );
}
