import { assignmentPages } from '../../../../../../constants';
import { createMcqQuestion } from './helper';

export const addMultipleAiQuestions = ({ questions, startIndex }) => {
  return (dispatch) => {
    if (!Array.isArray(questions)) {
      throw new Error('Question array not found');
    }

    questions.forEach((question, idx) => {
      dispatch(
        addAiQuestion({
          question: { ...question, type: assignmentPages.MCQ.displayName },
          pageIndex: startIndex + idx + 1,
          shouldUpdateActivePage: idx === 0
        })
      );
    });
  };
};

const addAiQuestion =
  ({ question, pageIndex, shouldUpdateActivePage }) =>
  async (dispatch, getState) => {
    const {
      assignment: { pages }
    } = getState();
    if (question?.id && !!pages[question.id]) {
      // Question already present. Skip addition
      console.log('Question already present. Skipping addition.');
      return;
    }
    switch (question.type) {
      case assignmentPages.MCQ.displayName:
        {
          const { page, mcqElement } = createMcqQuestion(question, pageIndex);
          dispatch({
            type: 'ADD_PAGE',
            payload: { page }
          });

          dispatch({
            type: 'ADD_PAGE_ELEMENT',
            payload: {
              pageID: page.id,
              element: mcqElement,
              directAdd: true
            }
          });
          dispatch({
            type: 'UPDATE_PAGE_SETTING',
            payload: {
              pageID: page.id,
              [`settings.randomizeOptions`]: true
            }
          });

          if (shouldUpdateActivePage) {
            dispatch({
              type: 'UPDATE_ACTIVE_PAGE',
              payload: {
                pageID: page.id
              }
            });
            // Scroll the newly added element into view
            setTimeout(() => {
              const element = document.getElementById(`canvas-${page.id}`);
              if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }, 0);
          }
        }
        break;
      default:
        return;
    }
  };

export default addAiQuestion;
