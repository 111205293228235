/* eslint-disable react/prop-types */
import { Box, Heading, HStack, Text } from '@chakra-ui/react'
import { noop } from 'lodash'
import { Tooltip as ReactTooltip } from "react-tooltip";
import React from 'react'

export default function Card({ title, desc, icon, onContentBlockClick = noop, ...rest }) {
  const cardHeaderColor = icon ? "#686868" : "#ffffff"

  return (
    <>
      <Box p={3} shadow="sm" id={`app-title-${rest.type}`} transition='0.4s linear' onClick={() => onContentBlockClick(rest.type)} _hover={{ shadow: 'xl' }} borderWidth="1px" {...rest} bg={!icon ? '#744cc6' : ''}>
        <HStack justify="space-between" align="center" py={!icon && 3} textAlign={"center"}>
          <Heading
            fontSize={icon ? "lg" : "xl"}
            fontWeight={500}
            color={cardHeaderColor}
            userSelect="none"
          >
            {title}
          </Heading>
          {icon}
        </HStack>
        <Text mt={2} color="#686868" fontSize="sm" userSelect="none">
          {desc}
        </Text>
      </Box>
      <ReactTooltip
        anchorId={`app-title-${rest.type}`} 
        place="left"
        content={`Drag or click to add ${title}`}
      />
    </>
  )
}
