import React from 'react';
import {
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react';
import { pdfjs, Document } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const AiRequestTopicWithAttachment = (props) => {
  const { setSelectedFile, formProps, selectedFile, maxPages } = props;
  const [file, setFile] = React.useState(selectedFile);
  const toast = useToast();

  const handleFileChange = (event) => {
    if (event.target.files.length > 1) {
      alert('You can only upload one file');
      event.target.value = ''; // Clear the file input
    } else {
      setFile(event.target.files[0]);
    }
  };

  const handleClear = () => {
    setFile(null);
    setSelectedFile(null);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    if (numPages && numPages > maxPages) {
      toast({
        status: 'error',
        title: `We currently support PDFs upto ${maxPages} pages. Please select a smaller PDF.`,
        isClosable: true
      });
      setFile(null);
    } else {
      setSelectedFile(file);
    }
  };

  return (
    <Flex flexDirection="column" border="2px solid grey" borderRadius="5px">
      <Input
        type="text"
        placeholder="Enter Topic Name (Eg. Photosynthesis)"
        border="none"
        {...formProps}
      />
      <FormControl isRequired={false}>
        <Input
          type="file"
          display="none"
          id="attach-reference"
          name="attach-reference"
          accept="application/pdf"
          style={{ maxWidth: '250px' }}
          onChange={handleFileChange}
        />
        <Box display="none">
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(le) => {
              console.log('load error in react PDF: ', le);
            }}
            onError={(e) => {
              console.log('error in react PDF: ', e);
            }}
          />
        </Box>
        <FormLabel htmlFor="attach-reference" _after={{ content: 'none' }}>
          <Tooltip label={`Attach PDF file`}>
            <Flex cursor="pointer">
              <Image src="/assets/images/attach-icon.svg" mx={3} width="16px" />
              <Text noOfLines={1}>
                {selectedFile ? selectedFile.name : 'Attach reference material'}
              </Text>
              {selectedFile && <CloseButton ml={2} onClick={handleClear} size="sm" />}
            </Flex>
          </Tooltip>
        </FormLabel>
      </FormControl>
    </Flex>
  );
};

export { AiRequestTopicWithAttachment };
