import { Box, HStack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPage, getPageElementOrder, getPageElements } from '../../../redux/selectors';

export default function FibPage({ pageID }) {
  const fibPage = useSelector(getPage(pageID));
  const dispatch = useDispatch();

  const pageElements = useSelector(getPageElements(pageID));
  const pageElementOrder = useSelector(getPageElementOrder(pageID));

  const [score, setScore] = useState(0);

  const fibElement = pageElements[pageElementOrder?.[0]?.id];
  const blankConfig = fibElement?.value?.fibMetadata?.blankConfig;

  useEffect(() => {
    if (!blankConfig) return;
    const score = Object.values(blankConfig).reduce((acc, curr) => {
      const currScore = curr?.score ?? 0;
      return acc + currScore;
    }, 0);

    setScore(score);
  }, [blankConfig]);

  React.useEffect(() => {
    if (!fibPage.score || isNaN(fibPage.score) || Number(fibPage.score) < 0) {
      // Dispatch with 0 value
      dispatch({
        type: 'UPDATE_PAGE_SETTING',
        payload: {
          pageID,
          score: 1
        }
      });
    }
  }, [dispatch, fibPage.score, pageID]);

  return (
    <>
      <Box mb={5} p={6}>
        <Text fontSize="18px" color="gray" fontWeight="bold" right={4}>
          Total FIB Score
        </Text>
        <HStack spacing={0}>
          <Text fontSize="28px" color="#5D38DB" borderBottom={'1px solid'} fontWeight="bold" py={'9px'} pr={3}>
            {score} Points
          </Text>
        </HStack>
      </Box>
    </>
  );
}
