import React from 'react';
import CanvasHeader from '../app/header/canvas-header';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import SwitchToDesktopComponent from '../canvas-utils/swith-to-desktop-drawer';
import JoyrideContainer from '../app/common/joyride-onboarding-tour/joyride-tour';
import { Outlet } from 'react-router-dom';

export default function AssignmentLayout() {
  return (
    <Box display="flex" flexDirection="column" h={{ sm: 'auto', lg: '100vh' }} style={{ ...(isMobile ? {} : { overflow: 'hidden' }) }}>
      <CanvasHeader />
      <Outlet />
      <SwitchToDesktopComponent />

      {/* Onboarding tour container */}
      <JoyrideContainer />
    </Box>
  );
}
