import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Spinner,
  Text,
  Button
} from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { LibraryContext } from '../library-context';
import { QuestionDataTable } from './questions-data-table';

export const ALL_LEVELS_FILTER = 'All Levels';

function QuestionSubHeader({ query, onTopicChange, levels, selectedLevel, setSelectedLevel }) {
  return (
    <Flex
      // justifyContent="space-between"
      borderBottom="1px"
      borderTop="1px"
      borderColor="gray.300"
      py={5}
      px={2}
    >
      <Flex>
        {query?.questions?.length > 0 ? (
          <>
            <Heading as="h5" fontSize={{ base: '2xl', xl: 'xl' }}>
              {query?.selectedTopic}
            </Heading>
            <Flex ml={5}>
              <Menu>
                <MenuButton
                  fontSize={'sm'}
                  as={Button}
                  rightIcon={<ChevronDownIcon ml={0} mr={{ base: 0, lg: 2 }} />}
                  variant="link"
                >
                  <Text as="span" fontSize="sm" fontWeight="bold" color="#6364D8">
                    Change Topic
                  </Text>
                </MenuButton>
                <MenuList fontSize={'sm'} zIndex={2}>
                  {query?.topics.map((t) => (
                    <MenuItem key={t.topic} onClick={() => onTopicChange(t.topic)}>
                      {t.topic}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          </>
        ) : (
          <Skeleton height="30px" width={400} />
        )}
      </Flex>
      <Flex>
        <Flex ml={5}>
          <Menu preventOverflow>
            <MenuButton
              fontSize={'sm'}
              as={Button}
              rightIcon={<ChevronDownIcon ml={0} mr={{ base: 0, lg: 2 }} />}
              variant="link"
            >
              <Text as="span" fontSize="sm" fontWeight="bold" color="#6364D8">
                {selectedLevel}
              </Text>
            </MenuButton>
            <MenuList fontSize={'sm'} zIndex="2">
              {levels.map((level) => (
                <MenuItem key={level} onClick={() => setSelectedLevel(level)}>
                  {level}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default function QuestionsList() {
  const { query, setQuestionsList } = useContext(LibraryContext);

  const onTopicChange = useCallback(
    (selectedTopic) => {
      setQuestionsList(selectedTopic);
    },
    [setQuestionsList]
  );

  const [selectedLevel, setSelectedLevel] = useState(ALL_LEVELS_FILTER);

  const levels = useMemo(() => {
    if (query?.questions?.length) {
      const levelsMap = query?.questions.reduce(
        (acc, curr) => {
          acc[curr.level] = 1;
          return acc;
        },
        { [ALL_LEVELS_FILTER]: 1 }
      );

      return Object.keys(levelsMap);
    }

    return [];
  }, [query?.questions]);

  return (
    <Box overflow="hidden" display="flex" flexDirection="column" w={'100%'}>
      <QuestionSubHeader
        {...{
          query,
          onTopicChange
        }}
        levels={levels}
        selectedLevel={selectedLevel}
        setSelectedLevel={setSelectedLevel}
      />
      {query?.questions?.length ? (
        <QuestionDataTable selectedLevel={selectedLevel} />
      ) : (
        <Flex justify="center" alignItems="center" height="90vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      )}
    </Box>
  );
}
