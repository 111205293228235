import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useClipboard,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React, { useEffect, useState, useCallback } from 'react';
import { appConfig } from '../../../constants';
import { QRCodeModal } from '../share/qrcode-modal';
import { useAuth } from '../../auth/useAuth';
import { eventLogger } from '../../../api/eventLogger';
import DownloadOptionsModal from '../canvas/download-pdf/download-options-modal';
import GcAssignmentModal from './create-google-classroom-assignment';
import { useDispatch } from 'react-redux';
import { getGoogleToken } from '../../../api/userApis';
import { useParams } from 'react-router-dom';

function ShareBox(props) {
  const routerParams = useParams();
  const assignmentId = routerParams.assignmentID;
  const { token } = useAuth();

  const logAndClick = () => {
    eventLogger(token, 'canvas-share-' + props.type + '-clicked', assignmentId);
    props.onClick && props.onClick();
  };

  return (
    <Link
      href={props.linkUrl}
      target={props.isTargetBlank && '_blank'}
      onClick={() => logAndClick()}
    >
      <Box
        justifyContent="center"
        boxShadow="base"
        alignItems="center"
        display="flex"
        flexDirection="column"
        width="60px"
        height="60px"
        borderRadius="50%"
        bg={'blackAlpha.300'}
      >
        <Image boxSize="30px" src={props.imageSrc} alt={props.shareText} />
      </Box>
      <Box
        width="60px"
        textAlign="center"
        textColor="#757575"
        fontFamily="Helvetica"
        fontSize="14px"
        lineHeight="20px"
        mt="9px"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        {props.shareText}
      </Box>
    </Link>
  );
}

const ShareModalContent = (styleProps = {}) => {
  const toast = useToast();
  const routerParams = useParams();
  const assignmentID = routerParams.assignmentID;
  const assignmentURL = `${appConfig.studentAppUrl}/start/${assignmentID}`;
  const { onCopy } = useClipboard(assignmentURL);
  const { token } = useAuth();
  const [isGoogleAuthDone, setIsGoogleAuthDone] = useState(true);

  const checkGoogleAuth = useCallback(async () => {
    if (!token) return;

    const [accessToken, googleUserId, missingScopes] = await getGoogleToken(token);
    if (!accessToken || missingScopes) {
      setIsGoogleAuthDone(false);
    }
  }, [token]);

  useEffect(() => {
    checkGoogleAuth();
  }, [checkGoogleAuth]);

  const dispatch = useDispatch();

  const {
    isOpen: isQRCodeModalOpen,
    onOpen: onQRCodeModalOpen,
    onClose: onQRCodeModalClose
  } = useDisclosure();

  const {
    isOpen: isDownloadOptionsModalOpen,
    onOpen: openDownloadOptionsModal,
    onClose: closeDownloadOptionsModal
  } = useDisclosure();

  const openGcAssignmentModal = React.useCallback(() => {
    dispatch({
      type: 'UPDATE_GC_CREATE_ASSIGNMENT_MODAL_STATUS',
      payload: { showGcCreateAssignment: true }
    });
  }, [dispatch]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.async = true;
    document.body.appendChild(script);

    // No exhaustive deps as the deps here are somehow reconstructed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCopyLink = React.useCallback(() => {
    onCopy();
    toast({
      title: `Assignment link copied`,
      status: 'success',
      isClosable: true
    });
  }, [toast, onCopy]);

  return (
    <Box {...styleProps}>
      <Box my={4}>
        <Text fontSize="14px" color="blackAlpha.600">
          Student Link (click link to preview)
        </Text>
        <Flex>
          <Box border={'1px solid #bbb'} maxW="70%" mr={3} display="flex" alignItems="center">
            <Text
              color="blue.500"
              textDecoration="underline"
              fontSize="14px"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              ml={2}
            >
              <Link href={assignmentURL} target={'_blank'}>
                {assignmentURL}
              </Link>
            </Text>
          </Box>
          <Button size="sm" bg="#5D38DB" color="white" borderRadius={4} onClick={onCopyLink}>
            Copy Link
          </Button>
        </Flex>
      </Box>
      <Box
        mt="18px"
        display="flex"
        gap="24px"
        flexWrap="wrap"
        justifyContent="start"
        id="share-box-container"
      >
        <ShareBox
          onClick={openDownloadOptionsModal}
          imageSrc={'/assets/images/pdf-icon.svg'}
          shareText={'Download PDF'}
          type={'pdf'}
        />
        {isGoogleAuthDone && (
          <ShareBox
            onClick={openGcAssignmentModal}
            imageSrc={'/assets/images/share-googleclassroom.png'}
            shareText={'Google Classroom'}
            type={'google'}
          />
        )}
        {!isGoogleAuthDone && (
          <ShareBox
            linkUrl={
              'https://classroom.google.com/u/0/share?url=' +
              assignmentURL +
              '&title=Testing-Share-Button&itemType=assignment&body=share-button-body'
            }
            imageSrc={'/assets/images/share-googleclassroom.png'}
            shareText={'Google Classroom'}
            isTargetBlank
            type={'google'}
          />
        )}
        <ShareBox
          linkUrl={'mailTo:?subject=New%20PrepHub%20Assignment&body=' + assignmentURL + ''}
          imageSrc={'/assets/images/share-email.png'}
          shareText={'Email'}
          isTargetBlank
          type={'email'}
        />
        <ShareBox
          linkUrl={
            'https://web.whatsapp.com/send?text=' + assignmentURL + '&type=text&preview_url=true'
          }
          imageSrc={'/assets/images/share-whatsapp.png'}
          shareText={'Whatsapp'}
          isTargetBlank
          type={'whatsapp'}
        />
        <ShareBox
          onClick={onQRCodeModalOpen}
          imageSrc={'/assets/images/share-qrcode.png'}
          shareText={'QR Code'}
          type={'qrcode'}
        />
      </Box>
      {/* Modals */}
      <QRCodeModal
        assignmentURL={assignmentURL}
        isOpen={isQRCodeModalOpen}
        onClose={onQRCodeModalClose}
      />
      {isDownloadOptionsModalOpen && (
        <DownloadOptionsModal
          isOpen={isDownloadOptionsModalOpen}
          onClose={closeDownloadOptionsModal}
        />
      )}
      <GcAssignmentModal />
    </Box>
  );
};

export default ShareModalContent;
