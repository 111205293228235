import React, { useEffect, useRef } from 'react';
// import katex from 'katex';
import 'suneditor/dist/css/suneditor.min.css';
// import 'katex/dist/katex.min.css';

import SunEditor from 'suneditor-react';
import { en } from 'suneditor/src/lang';

const defaultTable =
  '<table class="se-table-layout-fixed"><tbody><tr><th><div><br></div></th><th><div><br></div></th><th><div><br></div></th></tr><tr><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td></tr><tr><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td></tr><tr><td><div><br></div></td><td><div><br></div></td><td><div><br></div></td></tr></tbody></table>';

const editorOptions = {
  height: 200,
  buttonList: [
    ['bold', 'underline', 'italic', 'fontSize'],
    ['fontColor', 'hiliteColor'],
    ['align', 'list'],
    ['link']
  ],
  // katex: katex,
  fontSize: [12, 14, 16, 18, 20],
  colorList: [
    [
      '#828282',
      '#FF5400',
      '#676464',
      '#F1F2F4',
      '#FF9B00',
      '#F00',
      '#fa6e30',
      '#000',
      'rgba(255, 153, 0, 0.1)',
      '#FF6600',
      '#0099FF',
      '#74CC6D',
      '#FF9900',
      '#CCCCCC'
    ]
  ]
};

const TableEditor = (props) => {
  const { elementID, onUpdate, value } = props;
  const editorRef = useRef();

  useEffect(() => {
    if (!value) {
      onUpdate(elementID, defaultTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (content) => {
    onUpdate(elementID, content);
  };

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  return (
    <div>
      <SunEditor
        ref={editorRef}
        setOptions={editorOptions}
        lang={en}
        setContents={value}
        getSunEditorInstance={getSunEditorInstance}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default TableEditor;
