import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { assignmentPages } from '../../../constants';

const Stats = ({
  type = assignmentPages.FIB.key /* intent: defaulted to show the partially incorrect badge */,
  correct,
  partial,
  incorrect,
  unanswered
}) => {
  return (
    <Flex direction="row" gap="10px" fontSize="12px" fontWeight="800" alignItems="center">
      <Box borderRadius="5px" bgColor="#BBE6CE" px="13px" py="9px">
        <Text>{(correct || '0') + ' Correct'}</Text>
      </Box>
      {type === assignmentPages.FIB.key && (
        <Box borderRadius="5px" bgColor="#F3BB51" px="13px" py="9px">
          <Text>{(partial || '0') + ' Partially Correct'}</Text>
        </Box>
      )}
      <Box borderRadius="5px" bgColor="#F9C0C0" px="13px" py="9px">
        <Text>{(incorrect || '0') + ' Incorrect'}</Text>
      </Box>
      <Box borderRadius="5px" bgColor="#0000001F" px="13px" py="9px">
        <Text>{(unanswered || '0') + ' Unaswered'}</Text>
      </Box>
    </Flex>
  );
};

export default Stats;
