export function getLSItem(key, jsonParse) {
  const item = window?.localStorage.getItem(key);
  if (jsonParse) {
    try {
      return JSON.parse(item);
    } catch (e) {
      console.warn(e);
    }
  }

  return item;
}

export function setLSItem(key, value) {
  const item = typeof value !== 'string' ? JSON.stringify(value) : value;
  return window?.localStorage.setItem(key, item);
}

export function removeLSItem(key) {
  return window?.localStorage.removeItem(key);
}
