import { Box, Heading } from '@chakra-ui/react'
import React from 'react'
import PageDropDown from '../utils/page-dropdown'

export default function Settings({ pageID, pageName }) {
  return (
    <Box display="flex" flexDirection="column">
      <Heading
        as="h5"
        px={4}
        mt={6}
        mb={2}
        position="relative"
        fontWeight={700}
        color="#686868"
        size="md"
        noOfLines={1}
      >
        Settings
      </Heading>
      <Box p={3}>
        <PageDropDown pageID={pageID} activePageName={pageName} />
      </Box>
    </Box>
  )
}
