import React from 'react';
import 'suneditor/dist/css/suneditor.min.css';
import UneditableHtml from './uneditable-html';

const TablePreview = ({ value, key }) => {
  return (
    <div className="sun-editor-editable">
      <UneditableHtml key={key} value={value} />
    </div>
  );
};

export default TablePreview;
