export const availableCountries = [
  { name: 'en-us', label: 'Global' },
  { name: 'en-us', label: 'United States' },
  { name: 'en-in', label: 'India' },
]

export const questionFilters = {
  "en-us": {
    "classes": [
      "Algebra1",
      "Algebra 2",
      "Others United States",
      "AMC 10",
      "AMC Class 12",
      "PreCalculus",
      "Calculus",
      "Trigonometry",
      "High School Geometry",
      "Class 6",
      "Class 7",
      "Class 8",
      "IGCSE X",
      "Structure IB Chem",
      "Reactivity IB Chem"
    ],
    "language": {
      "Others United States": [
        "English"
      ],
      "AMC 10": [
        "English"
      ],
      "AMC Class 12": [
        "English"
      ],
      "PreCalculus": [
        "English"
      ],
      "Calculus": [
        "English"
      ],
      "Algebra1": [
        "English"
      ],
      "Algebra 2": [
        "English"
      ],
      "Trigonometry": [
        "English"
      ],
      "High School Geometry": [
        "English"
      ],
      "Class 6": [
        "English"
      ],
      "Class 7": [
        "English"
      ],
      "Class 8": [
        "English"
      ],
      "IGCSE X": [
        "English"
      ],
      "Structure IB Chem": [
        "English"
      ],
      "Reactivity IB Chem": [
        "English"
      ]
    },
    "subject": {
      "Others United States": {
        "English": [
          "Maths",
          "Science"
        ]
      },
      "AMC 10": {
        "English": [
          "Maths"
        ]
      },
      "AMC Class 12": {
        "English": [
          "Maths"
        ]
      },
      "PreCalculus": {
        "English": [
          "Maths"
        ]
      },
      "Calculus": {
        "English": [
          "Maths"
        ]
      },
      "Algebra1": {
        "English": [
          "Maths"
        ]
      },
      "Algebra 2": {
        "English": [
          "Maths"
        ]
      },
      "Trigonometry": {
        "English": [
          "Maths"
        ]
      },
      "High School Geometry": {
        "English": [
          "Maths"
        ]
      },
      "Class 6": {
        "English": [
          "Maths"
        ]
      },
      "Class 7": {
        "English": [
          "Maths"
        ]
      },
      "Class 8": {
        "English": [
          "Maths"
        ]
      },
      "IGCSE X": {
        "English": [
          "Maths"
        ]
      },
      "Structure IB Chem": {
        "English": [
          "Chemistry"
        ]
      },
      "Reactivity IB Chem": {
        "English": [
          "Chemistry"
        ]
      }
    },
    "source": {
      "Others United States": {
        "English": []
      },
      "AMC 10": {
        "English": []
      },
      "AMC Class 12": {
        "English": []
      },
      "PreCalculus": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "Calculus": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "Algebra1": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "Algebra 2": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "Trigonometry": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "High School Geometry": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "Class 6": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "Class 7": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "Class 8": {
        "English": [
          "Ohio State",
          "California State",
          "Texas State",
          "New York State"
        ]
      },
      "Structure IB Chem": {
        "English": [
          "IB Chem"
        ]
      },
      "IGCSE X": {
        "English": []
      },
      "Reactivity IB Chem": {
        "English": [
          "IB Chem"
        ]
      }
    }
  },
  "en-in": {
    "classes": [
      "CBSE Class 10",
      "GSEB Class 10",
      "CBSE Class 9",
      "GSEB Class 9",
      "CBSE Class 8",
      "GSEB Class 8",
      "ICSE Class 10",
      "ICSE Class 9",
      "CBSE Class 12",
      "IGCSE X"
    ],
    "language": {
      "CBSE Class 10": [
        "English"
      ],
      "GSEB Class 10": [
        "English",
        "Gujarati"
      ],
      "CBSE Class 9": [
        "English"
      ],
      "GSEB Class 9": [
        "English",
        "Gujarati"
      ],
      "CBSE Class 8": [
        "English"
      ],
      "GSEB Class 8": [
        "English",
        "Gujarati"
      ],
      "ICSE Class 10": [
        "English"
      ],
      "ICSE Class 9": [
        "English"
      ],
      "CBSE Class 12": [
        "English"
      ],
      "IGCSE X": [
        "English"
      ]
    },
    "subject": {
      "CBSE Class 10": {
        "English": [
          "Maths"
        ]
      },
      "GSEB Class 10": {
        "English": [
          "Maths"
        ],
        "Gujarati": [
          "ગણિત"
        ]
      },
      "CBSE Class 9": {
        "English": [
          "Maths"
        ]
      },
      "GSEB Class 9": {
        "English": [
          "Maths"
        ],
        "Gujarati": [
          "ગણિત"
        ]
      },
      "CBSE Class 8": {
        "English": [
          "Maths"
        ]
      },
      "GSEB Class 8": {
        "English": [
          "Maths"
        ],
        "Gujarati": [
          "ગણિત"
        ]
      },
      "ICSE Class 10": {
        "English": [
          "Maths"
        ]
      },
      "ICSE Class 9": {
        "English": [
          "Maths"
        ]
      },
      "CBSE Class 12": {
        "English": [
          "Maths"
        ]
      },
      "IGCSE X": {
        "English": [
          "Maths"
        ]
      }
    },
    "source": {
      "CBSE Class 10": {
        "English": [
          "NCERT Textbook",
          "R D Sharma",
          "CBSE Board Question Paper 2022"
        ]
      },
      "GSEB Class 10": {
        "English": [
          "NCERT Textbook",
          "R D Sharma",
          "CBSE Board Question Paper 2022"
        ],
        "Gujarati": []
      },
      "CBSE Class 9": {
        "English": [
          "NCERT Textbook",
          "R D Sharma",
          "CBSE Board Question Paper 2022"
        ]
      },
      "GSEB Class 9": {
        "English": [
          "NCERT Textbook",
          "R D Sharma",
          "CBSE Board Question Paper 2022"
        ],
        "Gujarati": []
      },
      "CBSE Class 8": {
        "English": [
          "NCERT Textbook",
          "R D Sharma",
          "CBSE Board Question Paper 2022"
        ]
      },
      "GSEB Class 8": {
        "English": [
          "NCERT Textbook",
          "R D Sharma",
          "CBSE Board Question Paper 2022"
        ],
        "Gujarati": []
      },
      "ICSE Class 10": {
        "English": []
      },
      "ICSE Class 9": {
        "English": []
      },
      "CBSE Class 12": {
        "English": [
          "NCERT Textbook",
          "R D Sharma",
          "CBSE Board Question Paper 2022"
        ]
      },
      "IGCSE X": {
        "English": []
      }
    }
  }
}

export const questionLevel = {
  'Level 1': { name: 'Level 1', levelType: 'Easy', bgColor: 'blue.500' },
  'Level 2': { name: 'Level 2', levelType: 'Medium', bgColor: 'green.500' },
  'Level 3': { name: 'Level 3', levelType: 'Medium', bgColor: 'green.500' },
  'Level 4': { name: 'Level 4', levelType: 'Hard', bgColor: 'red.800' },
  'Level 5': { name: 'Level 5', levelType: 'Hard', bgColor: 'red.800' }
}

export function showDottedString({ string, minStringChar, maxStringChar, conditionalNumber = 100 }) {
  return !!string ? conditionalNumber > 100 ? `${string.slice(0, maxStringChar)}......` : `${string.slice(0, minStringChar)}......` : ''
}