import React from 'react';
import Results from '../../app/results';
import { withAuth } from '../../auth/withAuth';

function AssignmentResults() {
  return <Results />;
}

const AssignmentResultsWithAuth = withAuth(AssignmentResults);

export default AssignmentResultsWithAuth;
