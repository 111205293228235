import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTotalScoreSelector } from '../canvas/redux/selectors';
import { isMobile } from 'react-device-detect';

const TotalScore = () => {
  const totalScore = useSelector(getTotalScoreSelector);

  return (
    <Flex mr={2} borderColor="#5D38DB" borderWidth={1} p={1.5} my={{ base: 1, lg: 0 }}>
      <Heading as="h6" size="xs">
        {isMobile ? '' : 'Total'} Score: {totalScore}
      </Heading>
    </Flex>
  );
};

export default TotalScore;
