const languageList = [
  'English',
  'Albanian',
  'Arabic',
  'Armenian',
  'Awadhi',
  'Azerbaijani',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bhojpuri',
  'Bosnian and Herzegovina',
  'Brazilian',
  'Bulgarian',
  'Cantonese (Yue)',
  'Catalan',
  'Chhattisgarhi',
  'Chinese',
  'Croatian',
  'Czech',
  'Danish',
  'Dogri',
  'Dutch',
  'Estonian',
  'Faroese',
  'Finnish',
  'French',
  'Galician',
  'Georgian',
  'German',
  'Greek',
  'Gujarati',
  'Haryanvi',
  'Hindi',
  'Hungarian',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kannada',
  'Kashmiri',
  'Kazakh',
  'Konkani',
  'Korean',
  'Kyrgyz',
  'Latvian',
  'Lithuanian',
  'Macedonian',
  'Maithili',
  'Malay',
  'Maltese',
  'Mandarin',
  'Marathi',
  'Marwari',
  'Min Nan',
  'Moldovan',
  'Mongolian',
  'Montenegrin',
  'Nepali',
  'Norwegian',
  'Oriya',
  'Pashto',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Rajasthani',
  'Romanian',
  'Russian',
  'Sanskrit',
  'Santali',
  'Serbian',
  'Sindhi',
  'Sinhala',
  'Slovak',
  'Slovene',
  'Slovenian',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Vietnamese',
  'Welsh',
  'Wu'
];

export default languageList;
