import { get } from 'lodash';
import { assignmentPages } from '../../../../../constants';
import { v4 as uuidV4 } from 'uuid';

export const setPageDefaults = (page, dispatch) => {
  // Adding defaults for MCQ page type
  if (page.type === assignmentPages.MCQ.displayName) {
    dispatch({
      type: 'ADD_PAGE_ELEMENT',
      payload: {
        pageID: page.id,
        element: {
          type: 'mcq',
          ID: uuidV4()
        }
      }
    });
  } else if (page.type === assignmentPages.FIB.displayName) {
    const blankUuid = uuidV4();
    const blankElement = `<span class="fib-blank-embed" data-blank_id="${blankUuid}"><span contenteditable="false">Blank</span></span>`;
    dispatch({
      type: 'ADD_PAGE_ELEMENT',
      payload: {
        pageID: page.id,
        element: {
          type: 'fib',
          ID: uuidV4(),
          value: {
            // Adding some base value when a new FIB element is created
            paragraph: {
              type: 'textbox',
              id: uuidV4(),
              value: `Type in your sentence and add a ${blankElement}!`
            },
            solution: [{ type: 'textbox', id: uuidV4() }],
            fibMetadata: {
              blankConfig: {
                [blankUuid]: {
                  answers: ['Blank'],
                  score: 10
                }
              }
            }
          }
        }
      }
    });
  } else if (page.type === assignmentPages.FIB.displayName) {
    dispatch({
      type: 'ADD_PAGE_ELEMENT',
      payload: {
        pageID: page.id,
        element: {
          type: 'fib',
          ID: uuidV4(),
          value: {
            paragraph: { type: 'textbox', id: uuidV4() },
            solution: [{ type: 'textbox', id: uuidV4() }],
            fibMetadata: {
              blankConfig: {}
            }
          }
        }
      }
    });
  } else if (page.type === assignmentPages.FIB.displayName) {
    dispatch({
      type: 'ADD_PAGE_ELEMENT',
      payload: {
        pageID: page.id,
        element: {
          type: 'fib',
          ID: uuidV4(),
          value: {
            paragraph: { type: 'textbox', id: uuidV4() },
            solution: [{ type: 'textbox', id: uuidV4() }],
            fibMetadata: {
              blankConfig: {}
            }
          }
        }
      }
    });
  } else if (page.type === assignmentPages.FIB.displayName) {
    dispatch({
      type: 'ADD_PAGE_ELEMENT',
      payload: {
        pageID: page.id,
        element: {
          type: 'fib',
          ID: uuidV4(),
          value: {
            paragraph: { type: 'textbox', id: uuidV4() },
            solution: [{ type: 'textbox', id: uuidV4() }],
            fibMetadata: {
              blankConfig: {}
            }
          }
        }
      }
    });
  }

  dispatch({
    type: 'UPDATE_ACTIVE_PAGE',
    payload: {
      pageID: page.id
    }
  });
};

export const isQuestionType = (pageType) => {
  switch (pageType) {
    case assignmentPages.MCQ.displayName:
    case assignmentPages.FIB.displayName:
      return true;
    default:
      return false;
  }
};

export const getPreviewHeight = (pageType) => {
  if (isQuestionType(pageType)) {
    return '100px';
  }

  return 'unset';
};

export const getFIBParagraphDisplayValue = (paragraph = '', blanks) => {
  Object.entries(blanks).forEach(([blankID, value]) => {
    const answer = value?.answers?.[0];
    const blankIdx = paragraph.indexOf(blankID);
    const firstRightArr = paragraph.indexOf('>', blankIdx + 1);
    const secondRightArr = paragraph.indexOf('>', firstRightArr + 1);
    const leftArr = paragraph.indexOf('<', secondRightArr + 1);

    paragraph =
      paragraph.substring(0, secondRightArr + 1) + answer + paragraph.substring(leftArr, paragraph.length - 1);
  });

  return paragraph;
};

export const getFirstPageElement = (pageType, pageElements) => {
  const pageElementValues = Object.values(pageElements);

  switch (pageType) {
    case assignmentPages.MCQ.displayName: {
      const element = get(
        pageElementValues.find((elem) => elem.value),
        'value'
      );
      return get(element, 'questions.0.value') || null;
    }
    case assignmentPages.FIB.displayName: {
      return getFIBParagraphDisplayValue(
        get(pageElementValues, '0.value.paragraph.value'),
        get(pageElementValues, '0.value.fibMetadata.blankConfig')
      );
      // return get(pageElementValues, '0.value.paragraph.value') || null;
    }

    default:
      return false;
  }
};
