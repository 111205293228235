import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const TemplateBox = ({ template, openTemplatePreview }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      w={{ base: '45%', lg: '220px' }}
      minH="100px"
      pt={2}
      border="1px"
      borderColor="#9E9E9E"
      shadow={'md'}
      borderRadius={5}
      cursor="pointer"
      onClick={() => openTemplatePreview(template.assignmentId)}
    >
      <Text mx={4} fontSize={{ base: 'md' }} fontWeight="bold" noOfLines={2}>
        {template.assignmentName}
      </Text>
      <Text mx={4} fontSize={{ base: 'xs' }} mb={2} flexGrow="1">
        {template.noOfQuestions} Questions | {template.difficulty}
      </Text>
      <Flex borderTop="1px solid black">
        <Button
          bg="#5D38DB"
          color="white"
          whiteSpace="normal"
          height="auto"
          blockSize="auto"
          width="100%"
          fontSize={{ base: '12px', lg: '12px' }}
          py={{ base: 2, lg: 1 }}
          m={2}
          _hover={{ bg: '#6d4ed4' }}
          position="unset"
        >
          Customise
        </Button>
      </Flex>
    </Box>
  );
};

export default TemplateBox;
