import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithCredential,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  updateProfile
} from 'firebase/auth';
import {
  Flex,
  Box,
  Button,
  Image,
  Link,
  Text,
  Center,
  useClipboard,
  useToast,
  Input,
  InputGroup,
  InputRightElement,
  Spacer
} from '@chakra-ui/react';
import { appConfig } from '../../constants';
import { createTeacherApi } from '../../api/userApis';
import { USER_ROLES } from '../../constants';
import {
  CustomView,
  browserName,
  deviceType,
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from 'react-device-detect';
import { useAuth } from './useAuth';
import { LinkIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { eventLogger } from '../../api/eventLogger';
import ClasswiseLoader from '../core/loader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export default function Login() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user, token, firebaseAuth, refreshTheToken } = useAuth();
  const { autoLogin } = useParams();
  const [source, setSource] = React.useState('direct');
  const [isLoading, setIsLoading] = React.useState(false);
  // eslint-disable-next-line no-undef
  const teacherAppUrl = import.meta.env.ASSIGNMENT_FE_TEACHER_APP_URL;
  const { onCopy, hasCopied } = useClipboard(teacherAppUrl);
  const [emailLogin, setEmailLogin] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [signup, setSignup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAirtel, setIsAirtel] = useState(true);

  const mainLoginDisplay = !emailLogin && !forgotPassword && !signup;

  const unsupportedBrowserCondition =
    ['Facebook', 'LinkedIn', 'Instagram'].indexOf(browserName) >= 0;

  React.useEffect(() => {
    if (token) {
      navigate({
        pathname: appConfig.landingPage
      });
    }
  }, [navigate, token]);

  const loginCallback = React.useCallback(
    async (result) => {
      setIsLoading(true);
      let isNewUser = false;
      if (result?.user) {
        const { email, displayName, uid, accessToken } = result.user;
        const detail = getAdditionalUserInfo(result);
        isNewUser = detail?.isNewUser || false;

        eventLogger(accessToken, 'login-successful');

        let locationData = {};
        try {
          const response = await axios.get('https://ipapi.co/json/');
          if (response.status == 200) {
            locationData = response.data;
          }
        } catch (err) {
          console.log(err);
        }

        const today = new Date();

        let additionalInfo = {
          lastLoginDate: today,
          country: locationData?.country_name,
          city: locationData?.city,
          zip: locationData?.postal,
          deviceType: deviceType,
          browserName: browserName,
          osName: osName,
          osVersion: osVersion,
          mobileVendor: mobileVendor,
          mobileModel: mobileModel,
          currentSource: source ?? 'direct'
        };

        if (isNewUser) {
          additionalInfo.signupDate = today;
          additionalInfo.source = source ?? 'direct';
        }

        await createTeacherApi({
          token: accessToken,
          teacher: { email, name: displayName, uid, role: USER_ROLES.TEACHER, additionalInfo }
        });
      }

      await refreshTheToken();
      setIsLoading(false);
      navigate({
        pathname: appConfig.landingPage,
        search: `${isNewUser ? 'isNewUser=true' : ''}`
      });
    },
    [navigate, source, refreshTheToken]
  );

  const onCopyLink = React.useCallback(() => {
    onCopy();
    toast({
      title: 'Link copied',
      description: 'Please paste this link in your browser',
      status: 'success',
      isClosable: true,
      position: 'top'
    });
  }, [onCopy, toast]);

  const googleSignInButtonClickHandler = React.useCallback(
    async (e) => {
      e && e.preventDefault();
      const result = await signInWithPopup(firebaseAuth, new GoogleAuthProvider());
      await loginCallback(result);
    },
    [loginCallback, firebaseAuth]
  );

  const emailSignInButtonClickHandler = React.useCallback(async () => {
    setErrorMessage('');
    if (document) {
      const email = document.getElementsByName('emailAuthEmailAddress')[0].value;
      const pass = document.getElementsByName('emailAuthPassword')[0].value;
      let result = null;
      try {
        result = await signInWithEmailAndPassword(firebaseAuth, email, pass);

        if (result) {
          await loginCallback(result);
        } else {
          setErrorMessage('Something went wrong! Try again.');
        }
      } catch (error) {
        if (error.code == 'auth/user-not-found') {
          setErrorMessage('User Not Found. Create a New Account');
        } else if (error.code == 'auth/missing-password') {
          setErrorMessage('Password cannot be empty');
        } else if (error.code == 'auth/wrong-password') {
          const providers = await fetchSignInMethodsForEmail(firebaseAuth, email);
          if (providers.includes('password')) {
            setErrorMessage('Incorrect Password');
          } else if (providers.includes('google.com')) {
            setErrorMessage('Previously signed in with Google. Redirecting to Google Sign in...');
            setTimeout(() => {
              googleSignInButtonClickHandler();
            }, 2000);
          } else {
            setErrorMessage('Something went wrong!');
          }
        } else {
          setErrorMessage('Something went wrong!');
        }
        console.log(error.code + ':' + error.message);
      }
    }
  }, [loginCallback, firebaseAuth]);

  const emailSignupButtonClickHandler = React.useCallback(async () => {
    setErrorMessage('');
    if (document) {
      const email = document.getElementsByName('emailSignupEmailAddress')[0].value;
      const pass = document.getElementsByName('emailSignupPassword')[0].value;
      const name = document.getElementsByName('emailSignupName')[0].value;
      let result = null;
      try {
        result = await createUserWithEmailAndPassword(firebaseAuth, email, pass);

        if (result) {
          result.user['displayName'] = name;
          await updateProfile(result.user, { displayName: name });
          await loginCallback(result);
        } else {
          setErrorMessage('Something went wrong! Try again.');
        }
      } catch (error) {
        if (error.code == 'auth/email-already-in-use') {
          setErrorMessage('You already have an account with that email');
        } else if (error.code == 'auth/invalid-email') {
          setErrorMessage('Invalid Email');
        } else if (error.code == 'auth/weak-password') {
          setErrorMessage('Password is too weak');
        } else {
          setErrorMessage('Something went wrong! Try again later.');
        }
        console.log(error.code + ':' + error.message);
      }
    }
  }, [loginCallback, firebaseAuth]);

  const emailResetPasswordButtonClickHandler = React.useCallback(async () => {
    setErrorMessage('');
    if (document) {
      const email = document.getElementsByName('emailPasswordResetEmailAddress')[0].value;
      const actionCodeSettings = {
        url: import.meta.env.ASSIGNMENT_FE_TEACHER_APP_URL
      };
      try {
        await sendPasswordResetEmail(firebaseAuth, email, actionCodeSettings);
        setErrorMessage('Password Reset Email sent!');
      } catch (error) {
        if (error.code == 'auth/invalid-email') {
          setErrorMessage('Invalid Email');
        } else if (error.code == 'auth/user-not-found') {
          setErrorMessage('No account found. Signup to create an account');
        } else {
          setErrorMessage('Something went wrong!');
        }
        console.log('error:', error);
      }
    }
  }, [firebaseAuth]);

  React.useEffect(() => {
    const docUrl = new URL(document.location);
    setSource(docUrl.searchParams.get('source') ?? 'direct');

    async function checkAirtel() {
      const airtel = docUrl.searchParams.get('secret');
      if (airtel && airtel == 'wng6pjr7YVX!zwz1rgy') {
        setIsLoading(true);
        console.log('in airtel');

        const result = await signInWithEmailAndPassword(
          firebaseAuth,
          'amit1.taneja@bhartifoundation.org',
          'Amit@123'
        );

        if (result) {
          await loginCallback(result);
        }
      } else {
        setIsAirtel(false);
      }
    }

    checkAirtel();
    setIsLoading(false);

    const timeout = setTimeout(() => {
      if (autoLogin && !user) {
        googleSignInButtonClickHandler();
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [autoLogin, user, googleSignInButtonClickHandler]);

  React.useEffect(() => {
    window.__classwiseGoogleAuthHandler__ = async (response) => {
      const idToken = response.credential;
      const credential = GoogleAuthProvider.credential(idToken);
      try {
        const result = await signInWithCredential(firebaseAuth, credential);
        await loginCallback(result);
      } catch (error) {
        console.error('firebase login with credential error: ', error);
      }
    };
    setTimeout(() => {
      // Following to be used if the Google YOLO is to be opened as a floating object
      //   window?.google?.accounts.id.initialize({
      //     client_id: import.meta.env.ASSIGNMENT_FE_CLIENT_ID,
      //     callback: window.__classwiseGoogleAuthHandler__
      //   });
      // if (!user) {
      //   window?.google?.accounts.id.prompt((notification) => {
      //     console.log('notification:', notification);
      //   });
      // }
    }, 1000);
  }, [firebaseAuth, loginCallback, user]);

  const setEmailLoginDisplay = () => {
    setErrorMessage('');
    setEmailLogin(true);
    setForgotPassword(false);
    setSignup(false);
  };

  const setForgotPasswordDisplay = () => {
    setErrorMessage('');
    setEmailLogin(false);
    setForgotPassword(true);
    setSignup(false);
  };

  const setSignupDisplay = () => {
    setErrorMessage('');
    setEmailLogin(false);
    setForgotPassword(false);
    setSignup(true);
  };

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams.size > 0) {
      if (searchParams.get('auth') === 'google' && !token) {
        setIsLoading(true);
        googleSignInButtonClickHandler();
      } else if (searchParams.get('auth') === 'email' && !token) {
        setSignupDisplay();
      }
    }
  }, [googleSignInButtonClickHandler, searchParams, token]);

  return isAirtel || token ? (
    <Flex
      flexDir="column"
      gap={8}
      justify="center"
      align="center"
      p="8"
      w="100%"
      h="100vh"
      backgroundColor="#F3F5F9"
    >
      <ClasswiseLoader />
    </Flex>
  ) : (
    <Flex
      flexDir="column"
      gap={8}
      justify="center"
      align="center"
      p="8"
      w="100%"
      h="100vh"
      backgroundColor="#F3F5F9"
    >
      <Box position="absolute" top="15%" flexDir="column" d="flex" align="center" px={8}>
        <Image src="/assets/images/logo.png" alt="classwise-logo" pb={8} flexDir="column" />
        <CustomView condition={unsupportedBrowserCondition}>
          <Center gap={6} flexDir="column">
            <Text fontWeight="700' fontSize='19px' align='center">
              Your browser does not support Classwise
            </Text>
            <Center flexDir="column" gap={2} fontSize="13px">
              <Text align="center">
                To open the app, simply copy the link and paste it into the address bar of your
                Chrome or Safari browser
              </Text>
              <Text>{teacherAppUrl}</Text>
            </Center>
            <Button
              flexDir="row"
              onClick={onCopyLink}
              leftIcon={<LinkIcon />}
              type="secondary"
              bgColor="#5D38DB"
              color="#FFFFFF"
              gap={4}
              p={6}
            >
              {hasCopied ? 'Copied!' : 'Copy Link'}
            </Button>
          </Center>
        </CustomView>
        <CustomView condition={!unsupportedBrowserCondition}>
          <CustomView condition={mainLoginDisplay}>
            <Center flexDir="column" gap="6">
              <Center flexDir="column" gap={8}>
                <Button
                  maxWidth="227px"
                  width="100%"
                  _hover={{
                    opacity: 0.8
                  }}
                  rounded={0}
                  background="#FFFFFF"
                  fontWeight="400"
                  border="1px solid"
                  borderColor="#FFFFFF"
                  fontFamily="Helvetica"
                  fontSize="14"
                  color="#757575"
                  height="40px"
                  shadow="md"
                  onClick={() => setEmailLoginDisplay()}
                >
                  <Image
                    src="/assets/images/email.png"
                    boxSize="20px"
                    width="24px"
                    marginRight="13px"
                  />
                  Sign in with Email
                </Button>
                <Button
                  maxWidth="227px"
                  width="100%"
                  _hover={{
                    opacity: 0.8
                  }}
                  rounded={0}
                  background="#FFFFFF"
                  fontWeight="400"
                  border="1px solid"
                  borderColor="#FFFFFF"
                  fontFamily="Helvetica"
                  fontSize="14"
                  color="#757575"
                  height="40px"
                  shadow="md"
                  isLoading={isLoading}
                  onClick={googleSignInButtonClickHandler}
                >
                  <Image src="/assets/images/google.png" boxSize="20px" marginRight="13px" />
                  Sign in with Google
                </Button>
                <Box id="classwise-google-login">
                  <Box
                    id="g_id_onload"
                    position="relative"
                    data-client_id={import.meta.env.ASSIGNMENT_FE_CLIENT_ID}
                    data-callback="__classwiseGoogleAuthHandler__"
                    data-prompt_parent_id="classwise-google-login"
                    data-cancel_on_tap_outside="true"
                  />
                </Box>
                <Box>
                  <Text fontSize="15px">
                    By signing up for a Classwise account you are agreeing to our{' '}
                    <Link href={appConfig.tosURL} target="_blank" color="blue.400">
                      terms of service
                    </Link>{' '}
                    and{' '}
                    <Link href={appConfig.privacyURL} target="_blank" color="blue.400">
                      data privacy policy
                    </Link>
                    .
                  </Text>
                </Box>
              </Center>
            </Center>
          </CustomView>
          <CustomView condition={emailLogin}>
            <Center flexDir="column" gap="2" maxWidth="400px">
              <Text
                alignSelf="flex-start"
                fontSize="12px"
                color="#4A90E2"
                cursor="pointer"
                onClick={() => setEmailLogin(false)}
              >
                Back
              </Text>
              <Center flexDir="column" width="100%">
                <Input
                  placeholder="Enter email address (e.g. abc@example.com)"
                  fontSize="14px"
                  variant="filled"
                  bgColor="white"
                  size="md"
                  rounded={0}
                  name="emailAuthEmailAddress"
                />
                <PasswordInput rounded={0} name="emailAuthPassword" />
              </Center>
              <Flex flexDir="row" width="100%" fontSize="14px">
                <Flex color="red">
                  <Text noOfLines="2" maxWidth="225px">
                    {errorMessage}
                  </Text>
                </Flex>
                <Spacer />
                <Text color="#4A90E2" cursor="pointer" onClick={() => setForgotPasswordDisplay()}>
                  Forgot Password?
                </Text>
              </Flex>
              <Button
                maxWidth="227px"
                width="100%"
                _hover={{
                  opacity: 0.8
                }}
                rounded={0}
                background="#5D38DB"
                fontWeight="400"
                border="1px solid"
                borderColor="#5D38DB"
                fontFamily="Helvetica"
                fontSize="14"
                color="#FFFFFF"
                height="40px"
                shadow="md"
                mt="4"
                isLoading={isLoading}
                onClick={(e) => emailSignInButtonClickHandler(e)}
              >
                Sign in
              </Button>
              <Text fontSize="14px">
                New User?{' '}
                <Link color="#4A90E2" onClick={() => setSignupDisplay()}>
                  Click here to Create a free account
                </Link>
              </Text>
            </Center>
          </CustomView>
          <CustomView condition={forgotPassword}>
            <Center flexDir="column" gap="2" maxWidth="400px">
              <Text color="#686868" fontWeight="700" fontSize="19px">
                Reset Password
              </Text>
              <Text alignSelf="flex-start" fontSize="13px" color="#686868">
                Enter your registered email address below to reset password. We will send a reset
                password link to your registered email address
              </Text>
              <Input
                placeholder="Enter email address (e.g. abc@example.com)"
                fontSize="14px"
                variant="filled"
                bgColor="white"
                size="md"
                rounded={0}
                name="emailPasswordResetEmailAddress"
                mt="4"
              />
              <Text color="red" fontSize="14px" alignSelf="flex-start">
                {errorMessage}
              </Text>
              <Button
                maxWidth="227px"
                width="100%"
                _hover={{
                  opacity: 0.8
                }}
                rounded={0}
                background="#5D38DB"
                fontWeight="400"
                border="1px solid"
                borderColor="#5D38DB"
                fontFamily="Helvetica"
                fontSize="14"
                color="#FFFFFF"
                height="40px"
                shadow="md"
                mt="4"
                onClick={(e) => emailResetPasswordButtonClickHandler(e)}
              >
                Reset Password
              </Button>
              <Text fontSize="14px">
                Remember Password?{' '}
                <Link color="#4A90E2" onClick={() => setEmailLoginDisplay()}>
                  Click here to login
                </Link>
              </Text>
            </Center>
          </CustomView>
          <CustomView condition={signup}>
            <Center flexDir="column" gap="2" maxWidth="400px">
              <Text color="#686868" fontWeight="700" fontSize="19px">
                Create New Account
              </Text>
              <Center flexDir="column" width="100%">
                <Input
                  placeholder="Enter your name"
                  fontSize="14px"
                  variant="filled"
                  bgColor="white"
                  size="md"
                  rounded={0}
                  name="emailSignupName"
                />
                <Input
                  placeholder="Enter email address (e.g. abc@example.com)"
                  fontSize="14px"
                  variant="filled"
                  bgColor="white"
                  size="md"
                  rounded={0}
                  name="emailSignupEmailAddress"
                />
                <PasswordInput rounded={0} name="emailSignupPassword" />
              </Center>
              <Flex color="red" fontSize="14px" alignSelf="flex-start">
                <Text>{errorMessage}</Text>
              </Flex>
              <Button
                maxWidth="227px"
                width="100%"
                _hover={{
                  opacity: 0.8
                }}
                rounded={0}
                background="#5D38DB"
                fontWeight="400"
                border="1px solid"
                borderColor="#5D38DB"
                fontFamily="Helvetica"
                fontSize="14"
                color="#FFFFFF"
                height="40px"
                shadow="md"
                mt="4"
                isLoading={isLoading}
                onClick={(e) => emailSignupButtonClickHandler(e)}
              >
                Create Account
              </Button>
              <Text fontSize="14px">
                Already have an account?{' '}
                <Link color="#4A90E2" onClick={() => setEmailLoginDisplay()}>
                  Already have an account
                </Link>
              </Text>
            </Center>
          </CustomView>
        </CustomView>
      </Box>
    </Flex>
  );
}

function PasswordInput(props) {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size='md'>
      <Input
        type={show ? 'text' : 'password'}
        placeholder='Enter password'
        bgColor='white'
        fontSize='14px'
        {...props}
      />
      <InputRightElement width='80px'>
        <Button h='30px' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}