import { get, post } from './APIClient';

export const getAiGeneratedQuestions = async ({ data, token }) => {
  try {
    const response = await post({ url: `questions`, token, data: { ...data, ai: true } });
    return response?.data?.questions;
  } catch (e) {
    console.error('error fetching AI questions: ', e);
    return [];
  }
};

export const createAiRequest = async ({ data, token }) => {
  try {
    const response = await post({ url: 'questions/ai', token, data });
    return response?.data?.aiRequest?._id;
  } catch (e) {
    console.error('error creating AI request: ', e);
    return null;
  }
};

export const createAiRequestForFile = async ({ data, token, toast }) => {
  try {
    const response = await post({
      url: 'questions/ai/v2/pdf',
      token,
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response?.data?._id;
  } catch (e) {
    if (e.response.data.message) {
      toast({
        status: 'error',
        title: `Unable to generate Questions`,
        description: e.response.data.message,
        isClosable: true,
        duration: 15000
      });
    }
    console.error('error creating AI Questions: ', e.response.data.message);
    return null;
  }
};

export const createAILessonPlan = async ({ data, token }) => {
  try {
    const response = await post({ url: 'lessons/ai', token, data });
    return response;
  } catch (e) {
    console.error('error creating AI request: ', e);
    return null;
  }
};

export const createAILessonPlanPDF = async ({ data, token, toast }) => {
  try {
    const response = await post({
      url: 'lessons/ai/pdf',
      token,
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
  } catch (e) {
    if (e.response.data.message) {
      toast({
        status: 'error',
        title: `Unable to generate Lesson Plan`,
        description: e.response.data.message,
        isClosable: true,
        duration: 15000
      });
    }
    console.error('error creating AI Lesson Plan: ', e.response.data.message);
    return null;
  }
};

export const getAiRequest = async ({ aiRequestId, token }) => {
  try {
    const response = await get({ url: `questions/ai/${aiRequestId}`, token });
    return response;
  } catch (e) {
    console.error('error fetching AI request: ', e);
    return null;
  }
};
