import React from 'react'
import { Flex, Spacer, Box, Image } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import Upgrade from '../../upgrade/Upgrade';
import { useAuth } from '../../auth/useAuth';
import { SUBSCRIPTION_PLANS } from '../../../api/constants';

// eslint-disable-next-line react/prop-types
const AppHeader = (props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { onOpen, isOpen } = props;
  const { userSubscriptionPlan } = useAuth();
  return (
    <>
      <Flex
        minWidth="max-content"
        alignItems="center"
        borderBottom="1px"
        backgroundColor="#ffffff"
        borderColor="#F2F2F2"
        width="100%"
        minHeight={71}
        zIndex={9}
        px={{ base: 2, sm: 0 }}
      >
        <Box
          align="center"
          flexDir="row"
          display="flex"
          alignItems="center"
          px={{ base: 2, sm: '50px' }}
        >
          <HamburgerIcon w={8} h={8} ref={ref} color="gray.400" cursor="pointer" onClick={onOpen} />
          <Box
            p="2"
            mx="12px"
            display={{
              base: userSubscriptionPlan === SUBSCRIPTION_PLANS.FREE ? 'none' : 'block',
              md: 'block'
            }}
          >
            <Image src="/assets/images/logo.png" htmlWidth="171px" alt="Classwise" />
          </Box>
        </Box>
        <Spacer />
        {userSubscriptionPlan === SUBSCRIPTION_PLANS.FREE && <Upgrade />}
        {/* <RightMenu /> */}
      </Flex>
    </>
  );
};

export default React.forwardRef(AppHeader)
