import React from "react";
import { Box,Heading, Stack, Text, Button } from "@chakra-ui/react";

export const EmptyState = ({type, onClick}) => {
    return (
      <Box
        w='100%'
        backgroundColor="white"
        borderRadius={8}
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      >
        <Box
          backgroundColor="gray.50"
          borderTopLeftRadius={8}
          borderTopRightRadius={8}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
          height="30px"
        />
        <Stack
          justifyContent="center"
          alignItems="center"
          p={16}
          borderRadius={8}
        >
          { type == 'assignments-home-classwise' && (
            <>
                <Heading size="lg">You haven’t added any Assignments.</Heading>
                <Text>Welcome 👋🏼 Let’s get started.</Text>
                <Button
                    maxWidth="250px"
                    backgroundColor="#5D38DB"
                    color="white"
                    fontWeight="medium"
                    mt={4}
                    p={4}
                    _hover={{
                        opacity: 0.8
                    }}
                    onClick={onClick}
                >
                    Add Your First Assignment
                </Button>
            </>
          )}
          { type == 'assignments-home-google' && (
            <>
                <Heading size="lg">You don't have any Assignments in you Google Classroom account.</Heading>
                <Text>Once you add some assignments in Google Classroom, they will be shown here.</Text>
            </>
          )}
          { type == 'students-home' && (
            <>
                <Heading size="lg">There are no students currently linked to your profile.</Heading>
                <Text>You can ask students to take a new assignment or connect your Google Classroom account.</Text>
            </>
          )}
        </Stack>
      </Box>
    )
  }