import { Box } from '@chakra-ui/react';
import React from 'react';

export const TextToolbar = ({ toolbarID }) => (
  <Box id={toolbarID} className="quill-custom-toolbar ql-toolbar">
    <div className="quill-toolbar-btn-section">
      <select className="ql-header" defaultValue={''} onChange={(e) => e.persist()}>
        <option value="1" />
        <option value="2" />
        <option value="3" />
        <option value="4" />
        <option value="5" />
        <option selected />
      </select>
    </div>
    <div className="quill-toolbar-btn-section">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-link" />
    </div>
    <div className="quill-toolbar-btn-section">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </div>
    <div className="quill-toolbar-btn-section">
      <button className="ql-clean" />
    </div>
    {/* Only show this for textbox mode of fill in the blanks */}
    {/* {mode === TextboxModes.FILL_IN_BLANKS && (
      <div className="quill-toolbar-btn-section">
        <button className="ql-blank" style={{ width: 'unset', padding: '0px' }}>
          <span className="fib_icon">FIB</span>
        </button>
      </div>
    )} */}
  </Box>
);
