import { produce } from 'immer';

const initialState = {
  // States when updating the assignment
  lastBackendUpdate: 0,
  lastUserUpdate: 0,
  needsUpdate: false,
  loading: false,
  error: '',

  // Assignment state when fetching the first time
  initialFetching: true
};

export const updateStateReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATING_ASSIGNMENT_STATE': {
      const { loading, error } = payload;
      draft.loading = loading;
      draft.error = error;
      if (payload.lastBackendUpdate) {
        draft.lastBackendUpdate = payload.lastBackendUpdate;
      }
      return;
    }

    case 'FETCH_ASSIGNMENT': {
      const { initialFetching } = payload;

      // Once initialFetching is completed, set all the update flags for updating the assignment
      if (draft.initialFetching && !initialFetching) {
        draft.lastBackendUpdate = Date.now();
        draft.lastUserUpdate = 0;
        draft.needsUpdate = false;
      }

      draft.initialFetching = initialFetching;
      return;
    }

    case 'UPDATE_ASSIGNMENT_NAME':
    case 'ADD_PAGE':
    case 'UPDATE_PAGE_INDICES':
    case 'DELETE_PAGE':
    case 'UPDATE_CORE_PAGE_BUTTON_NAME':
    case 'UPDATE_ASSIGNMENT_SETTING':
    case 'UPDATE_PAGE_SETTING':
    case 'ADD_PAGE_ELEMENT':
    case 'DELETE_PAGE_ELEMENT':
    case 'DELETE_ALL_PAGE_ELEMENTS':
    case 'UPDATE_PAGE_ELEMENT':
    case 'UPDATE_ELEMENT_ORDER': {
      draft.lastUserUpdate = Date.now();
      return;
    }

    case 'ASSIGNMENT_NEEDS_UPDATE': {
      draft.needsUpdate = payload;
      return;
    }

    case 'CLEAR_ASSIGNMENT_STATE':
      return initialState;
    default:
      // Always return draft as the default is initial state otherwise the draft object
      return draft;
  }
});
