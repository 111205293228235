import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getTemplateAssignments } from '../../app/canvas/shared/library/utils/fetch';

const TEMPLATES_FETCH_LIMIT = 50;

const useTemplatesFetcher = () => {
  const [templates, setTemplates] = useState([]);

  // Ref to keep the offset
  const offsetRef = useRef(0);

  // Ref to prevent multiple calls to fetcher
  const callRef = useRef(false);

  // Boolean to indicate the first parts of templates have been fetched
  const [templatesLoading, setTemplatesLoading] = useState(false);

  // Boolean to indicate all templates have been fetched
  const [allTemplatesFetched, setAllTemplatesFetched] = useState(false);

  // Use a memo without deps to fetch templates & set in state as original data
  const fetchTemplates = useCallback(async () => {
    if (callRef.current) return;
    callRef.current = true;

    if (offsetRef.current === 0) {
      setTemplatesLoading(true);
    }

    // Get template assignments from admin URL for offset
    const assignments = await getTemplateAssignments(offsetRef.current, TEMPLATES_FETCH_LIMIT);

    const fetchedCount = assignments.length;

    // Set loading as false to indicate the first set of assignments have loaded
    if (offsetRef.current === 0) {
      setTemplatesLoading(false);
    }

    // Keep fetching until all templates are fetched
    if (fetchedCount === TEMPLATES_FETCH_LIMIT) {
      offsetRef.current += fetchedCount;
      setTimeout(fetchTemplates, 500);
    } else if (fetchedCount < TEMPLATES_FETCH_LIMIT) {
      setAllTemplatesFetched(true);
    }

    // Set templates that are fetched in the assignments state
    setTemplates((state) => {
      return [...state, ...assignments];
    });

    callRef.current = false;
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  return useMemo(
    () => ({
      templatesLoading,
      allTemplatesFetched,
      templates
    }),
    [allTemplatesFetched, templatesLoading, templates]
  );
};

export default useTemplatesFetcher;
