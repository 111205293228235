import { useDispatch, useSelector } from 'react-redux';
import { getAIQuestionsModalState } from '../../redux/selectors';
import { useCallback, useMemo } from 'react';

const useAiModalState = () => {
  const dispatch = useDispatch();

  const { isOpen, openProps } = useSelector(getAIQuestionsModalState);

  const openAiQuestionsModal = useCallback(
    (props) => {
      dispatch({
        type: 'SET_AI_QUESTIONS_MODAL_STATE',
        payload: {
          isOpen: true,
          openProps: props
        }
      });
    },
    [dispatch]
  );

  const closeAiQuestionsModal = useCallback(() => {
    dispatch({
      type: 'SET_AI_QUESTIONS_MODAL_STATE',
      payload: {
        isOpen: false
      }
    });
  }, [dispatch]);

  return useMemo(
    () => ({
      openAiQuestionsModal,
      isAiQuestionsModalOpen: isOpen,
      closeAiQuestionsModal,
      modalProps: openProps
    }),
    [closeAiQuestionsModal, isOpen, openAiQuestionsModal, openProps]
  );
};

export default useAiModalState;
