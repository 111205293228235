import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, TabList, Tab } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../auth/useAuth';
import { eventLogger } from '../../../api/eventLogger';
import { Link, useLocation, useParams } from 'react-router-dom';

const PAGES_INDEX = ['create', 'results'];

export default function Steps() {
  const [tab, setTab] = useState(0);
  const routerParams = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { token } = useAuth();

  const assignmentID = routerParams.assignmentID;

  useEffect(() => {
    const pagePath = location.pathname.split('/');
    const page = pagePath[pagePath.length - 1];
    const tabIndex = PAGES_INDEX.indexOf(page);
    setTab(tabIndex);
  }, [dispatch, location.pathname]);

  const selectedTabStyle = useMemo(
    () => ({
      _selected: {
        color: '#8954BA',
        fontWeight: 800,
        borderColor: '#8954BA'
      }
    }),
    []
  );

  const styles = useMemo(
    () => ({
      color: '#686868',
      fontSize: { md: 16, lg: 18, xl: 18 },
      width: 'auto',
      borderBottom: '3px solid',
      borderColor: '#ffffff',
      px: 10,
      pt: 3
    }),
    []
  );

  return (
    <Tabs
      display="flex"
      size={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xs' }}
      variant="unstyled"
      index={tab}
      mt={{ base: 2, lg: 0 }}
    >
      <TabList justifyContent="center">
        <Tab
          {...styles}
          {...selectedTabStyle}
          onClick={() => eventLogger(token, 'canvas-create-clicked', assignmentID)}
          data-step-link="create-step-link"
        >
          <Link to={`/assignments/${assignmentID}/create`}>Create</Link>
        </Tab>
        <Tab
          {...styles}
          {...selectedTabStyle}
          onClick={() => eventLogger(token, 'canvas-results-clicked', assignmentID)}
          data-step-link="results-step-link"
        >
          <Link to={`/assignments/${assignmentID}/results`}>Results</Link>
        </Tab>
      </TabList>
    </Tabs>
  );
}
