import React, { useEffect } from 'react';

import { useAuth } from './useAuth';
import { Center } from '@chakra-ui/react';
import { getLoginUrl } from '../../utils';
import ClasswiseLoader from '../core/loader';
import { useLocation, useNavigate } from 'react-router-dom';

const LoaderWrapper = () => {
  return (
    <Center h="100vh">
      <ClasswiseLoader />
    </Center>
  );
};

export const withAuth = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { user, loading, error } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!loading && !user) {
        navigate(`${getLoginUrl()}${location.pathname}`);
      }
    }, [user, loading, navigate, location.pathname]);

    if (loading || !user) {
      return <LoaderWrapper />;
    }

    if (error) {
      navigate('/');
      console.error('Error while checking auth state: ', error);
      return <LoaderWrapper />;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

/*
  function(Comp) -> HOC -> <HOC><Comp /></HOC>

*/
