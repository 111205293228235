/* eslint-disable no-undef */

export const appConfig = {
  apiURL: import.meta.env.ASSIGNMENT_FE_API_BASE_URL,
  wsURL: import.meta.env.ASSIGNMENT_FE_SOCKET_BASE_URL,
  studentAppUrl: import.meta.env.ASSIGNMENT_FE_STUDENT_APP_URL,
  prefix: '/api/v1',
  getAPIURL: (postfix) => {
    return `${appConfig.apiURL}${appConfig.prefix}${postfix}`;
  },
  landingPage: '/',
  googleRedirectPage: `${import.meta.env.ASSIGNMENT_FE_TEACHER_APP_URL}/handle-google-redirect`,
  tosURL: 'https://getclasswise.com/Terms_Of_Use',
  privacyURL: 'https://getclasswise.com/Privacy_Policy',

  // Admin template features
  adminTemplateURL: import.meta.env.ASSIGNMENT_FE_ADMIN_URL
};

console.log('----Loaded teacher app URL: ', import.meta.env.ASSIGNMENT_FE_TEACHER_APP_URL);

export const assignmentPages = {
  WELCOME: {
    key: 'welcome-page',
    displayName: 'Welcome',
    config: {
      buttonName: 'Start'
    }
  },
  MCQ: {
    key: 'mcq',
    displayName: 'MCQ',
    config: {
      randomiseOptions: false,
      multipleCorrect: false,
      score: 4
    }
  },
  FIB: {
    key: 'fib',
    displayName: 'FIB',
    config: {}
  },
  END: {
    key: 'end-page',
    displayName: 'End',
    config: {
      buttonName: 'See Result',
      showResult: true,
      revealAnswers: false
    }
  }
};

export const USER_ROLES = {
  ADMIN: 'admin',
  STUDENT: 'student',
  TEACHER: 'teacher'
};

export const QUESTION_RESPONSE_STATUS = {
  ANSWERED: 'answered',
  SKIPPED: 'skipped',
  NOT_VISITED: 'notVisited'
};

export const UNTITLED_ASSIGNMENT = 'Untitled Assignment';

export const PAGE_TYPES = {
  MCQ: 'mcq',
  LIBRARY_QUESTION: 'library',
  AI_QUESTION: 'ai-question',
  FILL_IN_BLANKS: 'fib'
};

export const WELCOMEPAGE_TEXT_PLACEHOLDER = {
  WITHOUT_AI: 'You can start adding instructions on this page',
  WITH_AI: 'Choose the correct answer for each question from the options provided'
};

export const CREATE_TYPES = Object.freeze({
  HOME_HERO_CTA: 'HOME_HERO_CTA',
  HOME_NEW_USER_ONBOARD_MODAL: 'HOME_NEW_USER_ONBOARD_MODAL',
  ASSIGNMENTS_EMPTY_CTA: 'ASSIGNMENTS_EMPTY_CTA',
  ASSIGNMENTS_LIST_CTA: 'ASSIGNMENTS_LIST_CTA',
  HOME_HERO_CTA_AI: 'HOME_HERO_CTA_AI',
  HOME_TEMPLATE_CLONE: 'HOME_TEMPLATE_CLONE',
  HOME_LESSON_PLAN: 'HOME_LESSON_PLAN',
  HOME_LEARNING_ACTIVITY: 'HOME_LEARNING_ACTIVITY'
});

export const ENVIRONMENTS = Object.freeze({
  development: 'development',
  staging: 'staging',
  production: 'production'
});

export const APP_ENV = import.meta.env.ASSIGNMENT_FE_APP_ENVIRONMENT;

// For all envs except production, clone the same staging template for all cards
export const isProduction = () => APP_ENV === ENVIRONMENTS.production;
export const STAGING_TEMPLATE = '65350f5711d1bd2e0b898247';
export const CLONE_TEMPLATES = {
  '64c126c56f0f20ab5556f8af': {
    id: isProduction() ? '64c126c56f0f20ab5556f8af' : STAGING_TEMPLATE,
    name: 'Arithmetic Sequence',
    difficulty: 'Medium',
    questionCount: 10
  },
  '64ca20b16f0f20ab5558fd4a': {
    id: isProduction() ? '64ca20b16f0f20ab5558fd4a' : STAGING_TEMPLATE,
    name: 'Complex Numbers',
    difficulty: 'Medium',
    questionCount: 10
  },
  '64b8edab6f0f20ab55548e93': {
    id: isProduction() ? '64b8edab6f0f20ab55548e93' : STAGING_TEMPLATE,
    name: 'Equation of Straight Line',
    difficulty: 'Medium',
    questionCount: 10
  },
  '64c27d426f0f20ab55575c23': {
    id: isProduction() ? '64c27d426f0f20ab55575c23' : STAGING_TEMPLATE,
    name: 'Exponential Growth & Decay',
    difficulty: 'Medium',
    questionCount: 10
  },
  '64c0f4ac6f0f20ab5556b1e1': {
    id: isProduction() ? '64c0f4ac6f0f20ab5556b1e1' : STAGING_TEMPLATE,
    name: 'Functions',
    difficulty: 'Easy',
    questionCount: 10
  },
  '64be7fec6f0f20ab55561b21': {
    id: isProduction() ? '64be7fec6f0f20ab55561b21' : STAGING_TEMPLATE,
    name: 'Inequalities(System & Graphs)',
    difficulty: 'Easy',
    questionCount: 10
  },
  '64be8b396f0f20ab5556426f': {
    id: isProduction() ? '64be8b396f0f20ab5556426f' : STAGING_TEMPLATE,
    name: 'Inequalities(System & Graphs)',
    difficulty: 'Medium',
    questionCount: 10
  },
  '64cceb866f0f20ab5559bd7e': {
    id: isProduction() ? '64cceb866f0f20ab5559bd7e' : STAGING_TEMPLATE,
    name: 'Polynomial Graphs',
    difficulty: 'Medium',
    questionCount: 10
  },
  '64abc1600caf7248ed9fbf7c': {
    id: isProduction() ? '64abc1600caf7248ed9fbf7c' : STAGING_TEMPLATE,
    name: 'Solving Inequality',
    difficulty: 'Easy',
    questionCount: 10
  },
  '64d4add76f0f20ab555b1d17': {
    id: isProduction() ? '64d4add76f0f20ab555b1d17' : STAGING_TEMPLATE,
    name: 'Transformation of Functions',
    difficulty: 'Medium',
    questionCount: 10
  },
  '64d4e9b86f0f20ab555b97be': {
    id: isProduction() ? '64d4e9b86f0f20ab555b97be' : STAGING_TEMPLATE,
    name: 'Trigonometry',
    difficulty: 'Easy',
    questionCount: 10
  },
  '64d4e9dd6f0f20ab555b97c7': {
    id: isProduction() ? '64d4e9dd6f0f20ab555b97c7' : STAGING_TEMPLATE,
    name: 'Trigonometry',
    difficulty: 'Medium',
    questionCount: 10
  }
};

// Synced between BE & FE here: https://github.com/Silverhook-Innovations/classwise-backend/blob/ee75ac2a71cd9fa21d043d91503f96a2e02c0bb8/src/config/constants.js#L13
export const ASSIGNMENT_TYPES = {
  PLAIN: 'PLAIN',
  TEMPLATE: 'TEMPLATE',
  AI_QUESTIONS: 'AI_QUESTIONS',
  LESSON_PLAN: 'LESSON_PLAN',
  LEARNING_ACTIVITY: 'LEARNING_ACTIVITY'
};
