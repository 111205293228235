import React, { useEffect } from 'react'
import { Box, Checkbox, Flex, SkeletonText, Stack, Text, VStack } from '@chakra-ui/react'
import CreatableSelect from 'react-select/creatable';
import { minutesLabel } from '../../../utils';
import { Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const durationOptions = [
  {value: null, label: "No time Limit"},
  {value: 30, label: "30 minutes"},
  {value: 60, label: "60 minutes"},
  {value: 90, label: "90 minutes"},
  {value: 120, label: "120 minutes"},
  {value: 150, label: "150 minutes"},
  {value: 180, label: "180 minutes"},
]

export default function AssignmentSettings({ 
  isSettingLoading, 
  register,
  control,
}) {

  useEffect(() => {
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)));
  }, [])
 
  if (isSettingLoading) {
    return (
      <Box padding='6' bg='white' w={'100%'}>
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='4' />
      </Box>
    )
  }

  return (
    <Box w="100%" py={4}>
      <VStack alignItems={'flex-start'}>
        <Stack direction={{base: 'column', md: 'row'}} w='100%'>
          <Box w='100%'>
            <Controller
              control={control}
              name="assignmentDate"
              render={({
                field: { onChange, value },
              }) => {
                return (
                    <ReactDatePicker
                      selected={value[0]}
                      onChange={(start) => onChange([start, value[1]])}
                      selectsStart
                      startDate={value[0]}
                      showTimeInput
                      dateFormat="MMMM d, yyyy h:mm aa"
                      name="dueDate"
                    />
                )
              }}
            />
            <Text position="absolute" fontSize={'xs'} marginLeft={1} marginTop={-14} px={2} bg="#fff">Assignment Start Date</Text>
          </Box>
          <Box w={'100%'}>
            <Controller
              control={control}
              name="assignmentDate"
              render={({
                field: { onChange, value },
              }) => {
                return (
                  <ReactDatePicker
                    selected={value[1]}
                    onChange={(end) => onChange([value[0], end])}
                    selectsEnd
                    startDate={value[0]}
                    endDate={value[1]}
                    minDate={value[0]}
                    showTimeInput
                    dateFormat="MMMM d, yyyy h:mm aa"
                    name="dueDate"
                  />
                )
              }}
            />
            <Text position="absolute" fontSize={'xs'} marginLeft={1} marginTop={-14} px={2} bg="#fff">Assignment Due Date</Text>
         </Box>
        </Stack>
        <Box width="100%" pl={0} className="durationDropdown">
          <Controller
            control={control}
            name="duration"
            render={({
              field: { onChange, value },
            }) => {
              return (
                <CreatableSelect
                  isClearable
                  className='durationSelectBox'
                  placeholder="Select/Add duration"
                  options={durationOptions}
                  formatCreateLabel={label => `Add ${minutesLabel(label)} duration`}
                  onChange={onChange}
                  value={value}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    option: (styles) => ({ ...styles, fontSize: '14px' }),
                  }}
                />
              )}
            }
          />
          <Text position="absolute" fontSize={'xs'} left={1} top={-2} px={2} bg="#fff">Duration in Minutes</Text>
        </Box>
      </VStack>
      <VStack mt={5}>
        <Flex alignItems="start" width="100%" justifyContent="flex-start" py={1} fontSize='xs'>
          <Checkbox
            {...register("randomiseQuestions")}            
            colorScheme="purple" size='lg'>
            <Text color="#686868" fontSize='sm'>Randomise Question sequence</Text>
          </Checkbox>
        </Flex>
        <Flex alignItems="start" width="100%" justifyContent="flex-start" fontSize='xs'>
          <Checkbox
            {...register("revealSolutions")}            
            colorScheme="purple"
            size='lg'>
            <Text color="#686868" fontSize='sm'>Reveal solutions to students after they submit</Text></Checkbox>
        </Flex>
      </VStack>
    </Box>
  )
}
