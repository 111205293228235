export default function addContentBlock(pageId, elemId, type) {
    return async function addContentBlockThunk(dispatch) {
        dispatch({
            type: 'ADD_PAGE_ELEMENT',
            payload: {
                pageID: pageId,
                directAdd: true,
                element: {
                    type: type,
                    ID: elemId,
                    id: elemId,
                },
            },
        })
    }
}
