import { produce } from 'immer';
import { WELCOMEPAGE_TEXT_PLACEHOLDER } from '../../../../../constants';

const initialState = {
  showAssignmentSetting: false,
  showGcCreateAssignment: false,
  welcomePageTextPlaceholder: WELCOMEPAGE_TEXT_PLACEHOLDER.WITHOUT_AI
};

export const assignmentSettingReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_ASSIGNMENT_MODAL_STATUS': {
      const { showAssignmentSetting } = payload;
      draft.showAssignmentSetting = showAssignmentSetting;
      return;
    }
    case 'ASSIGNMENT_SETTINGS_MODAL_CLOSED':
      draft.showAssignmentSetting = false;
      return;
    case 'UPDATE_WELCOME_PAGE_PLACEHOLDER': {
      const { welcomePageTextPlaceholder } = payload;
      draft.welcomePageTextPlaceholder = welcomePageTextPlaceholder;
      return;
    }
    case 'CLEAR_ASSIGNMENT_STATE':
      return initialState;
    
    case 'UPDATE_GC_CREATE_ASSIGNMENT_MODAL_STATUS':
      const { showGcCreateAssignment } = payload;
      draft.showGcCreateAssignment = showGcCreateAssignment;
      return;

    case 'GC_CREATE_ASSIGNMENT_MODAL_CLOSED':
      draft.showGcCreateAssignment = false;
      return;

    default:
      // Always return draft as the default is initial state otherwise the draft object
      return draft;
  }
});
