import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

const UneditableHtml = (props) => {
    const { elementID, value } = props

    return (
        <div
            className="uneditable-html"
            style={{
                minHeight: '24px',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            }}
            id={elementID}
        >
            {parse(value)}
        </div>
    )
}

export default UneditableHtml

UneditableHtml.propTypes = {
    elementID: PropTypes.string,
    onUpdate: PropTypes.func,
}
