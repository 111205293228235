import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './components/app/canvas/redux/store';
import { AuthProvider } from './components/auth/useAuth';
import { AiRequestProvider } from './components/app/canvas/shared/hooks';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import AppRoutes from './components/routes';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <AiRequestProvider>
          <ChakraProvider theme={theme}>
            <AppRoutes />
          </ChakraProvider>
        </AiRequestProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);
