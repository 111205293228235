import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../auth/useAuth';
import { updateAssignmentData } from '../../redux/thunks/update-assignment';
import { useCallback } from 'react';
import { getAssignmentUpdateState } from '../../redux/selectors';

// Flags initialFetching & loading will be handled on the redux thunk directly
const useUpdateAssignment = ({ forceUpdate } = { forceUpdate: false }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();

  const { needsUpdate } = useSelector(getAssignmentUpdateState);

  const updateAssignmentAction = useCallback(() => {
    if (!token) return;
    // When the user calls to update the assignment
    // if not already updated or if we require it to update anyway, call update
    if (forceUpdate || needsUpdate) {
      dispatch(updateAssignmentData(token));
    }
  }, [dispatch, forceUpdate, needsUpdate, token]);
  return updateAssignmentAction;
};

export default useUpdateAssignment;
