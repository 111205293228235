import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react'
import YoutubeIframe from '../../core/video/YouTubeVideoIframe';

export default function ProductTourModal({isOpen, onClose }) {

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size={'3xl'}>
		<ModalOverlay />
		<ModalContent>
			<ModalBody p={2}>
			<ModalCloseButton bg={'#fff'} mt={1} borderRadius={20} color={'#000'} />
			<YoutubeIframe videoId={'XB16yWGSnho'} autoPlay/>
			</ModalBody>
		</ModalContent>
	</Modal>
	)
}
