import React, { useCallback, useState } from 'react';
import { Divider, Flex, IconButton } from '@chakra-ui/react';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { StaticContentWrap } from '../../canvas-utils/library-mcq-question/utils';

const QuestionPreview = ({ question }) => {
  return (
    <Flex flexDirection="column">
      <StaticContentWrap type="Question" contentArray={question.elements} />
      <Divider />
      {question.options.map((op, idx) => {
        return (
          <StaticContentWrap key={op.id} type={`Option ${idx + 1}`} contentArray={op.elements} />
        );
      })}
      <Divider />
      <StaticContentWrap type="Solution" contentArray={question.solution.elements} />
    </Flex>
  );
};

const AssignmentCarousel = (assignmentData) => {
  const welcome = assignmentData.welcomeScreen;
  const endScreen = assignmentData.thankYouScreen;
  const questions = assignmentData.questions;

  const totalSeek = questions.length + 1; // Seek starts from 0

  const [seek, setSeek] = useState(0);

  const pageToShow = () => {
    switch (seek) {
      case 0:
        return <StaticContentWrap type="Welcome" contentArray={welcome.elements} />;
      case totalSeek:
        return <StaticContentWrap type="End" contentArray={endScreen.elements} />;
      default:
        return <QuestionPreview question={questions[seek - 1]} />;
    }
  };

  const changeSeek = useCallback(
    (add) => {
      setSeek((seek) => {
        if (add) {
          return Math.min(seek + 1, totalSeek);
        } else {
          return Math.max(seek - 1, 0);
        }
      });
    },
    [totalSeek]
  );

  return (
    <Flex flexDirection="column" width="100%">
      <Flex minH="50vh" maxH="50vh" border="1px" borderColor="gray.200" p={3} overflowY="scroll">
        {pageToShow(seek)}
      </Flex>
      <Flex alignSelf="center" w="30%" justifyContent="space-between" mt={4}>
        {/* Left traversal */}
        <IconButton
          isDisabled={seek === 0}
          isRound={true}
          variant="solid"
          aria-label="Done"
          fontSize="20px"
          icon={<FaChevronCircleLeft />}
          _disabled={{
            opacity: '0.3',
            cursor: 'not-allowed'
          }}
          onClick={() => changeSeek(false)}
        />
        {/* Right traversal */}
        <IconButton
          isDisabled={seek === totalSeek}
          isRound={true}
          variant="solid"
          aria-label="Done"
          fontSize="20px"
          icon={<FaChevronCircleRight />}
          _disabled={{
            opacity: '0.3',
            cursor: 'not-allowed'
          }}
          onClick={() => changeSeek(true)}
        />
      </Flex>
    </Flex>
  );
};

export default AssignmentCarousel;
