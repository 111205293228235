import { produce } from 'immer';

const initialState = {
  isOpen: false,
  openProps: {}
};

export const aiQuestionsModalStateReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_AI_QUESTIONS_MODAL_STATE': {
      draft.isOpen = payload.isOpen;
      draft.openProps = payload.openProps || {};
      return;
    }

    case 'CLEAR_ASSIGNMENT_STATE':
      return initialState;
    default:
      // Always return draft as the default is initial state otherwise the draft object
      return draft;
  }
});
